import React from "react";
import { connect } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HistoricalRunChart = ({ chartData = [], labels = [] }) => {
  const options = {
    responsive: true,
    aspectRatio: 1, // this would be a 1:1 aspect ratio,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: chartData.map((item) => item.score),
        backgroundColor: "#4eafb3",
        barThickness: "25",
      },
    ],
  };
  return (
    <>
      <Bar options={options} data={data} height={null} width={null} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(HistoricalRunChart));
