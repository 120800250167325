import React, { useState } from "react";

import { Box } from "@material-ui/core";
import ItAndBusinessRulesCompareDataset from "./../../../components/RulesSelection/ItAndBusinessRulesCompareDataset";

export function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabs-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Box style={{ padding: "0px 20px 10px 20px" }}>{children}</Box>
      )}
    </div>
  );
}

const RulesModal = (props = {}) => {
  // # PROPS
  const {
    columnName,
    columnDatatype,
    parentClasses,
    rulesItAndBusinessSelections,
    dispatchRulesItAndBusinessSelections,
    allRulesData,
    columnsPair,
    columnsPairSelected,
    handleColumnsPairSelectedChange,
  } = props;

  // # STATES
  const [searchText, setSearchText] = useState({ searchText: "" });
  const [value, setValue] = useState(0);

  // # HANDLERS
  const handleSearchTextChange = (key, value) => {
    setSearchText({ searchText: value });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabNames = [{ name: `IT Validations & Business Rules ` }];

  return (
    <>
      <div
        className={`${parentClasses.rulesModalHeader} models-menu-new`}
        style={{ display: "flex", alignItems: "center" }}
      >
        <h4
          style={{
            textAlign: "center",
            fontWeight: 600,
            color: "#46596a",
            margin: 0,
          }}
        >
          Execute specific rules column pair
        </h4>
      </div>

      <div className={parentClasses.rulesModalBody}>
        <TabPanel value={value} index={0}>
          <ItAndBusinessRulesCompareDataset
            columnsPair={columnsPair}
            columnsPairSelected={columnsPairSelected}
            handleColumnsPairSelectedChange={handleColumnsPairSelectedChange}
            rulesItAndBusinessSelections={rulesItAndBusinessSelections}
            dispatchRulesItAndBusinessSelections={
              dispatchRulesItAndBusinessSelections
            }
            allRulesData={allRulesData.filter((row) =>
              ["it", "business"].includes(row.categoryId)
            )}
            columnDetails={{ columnName, columnDatatype }}
            categoryType={["it", "business"]}
            searchText={searchText}
            handleSearchTextChange={handleSearchTextChange}
          />
        </TabPanel>
      </div>
    </>
  );
};

export default RulesModal;
