// Import required libraies
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Import Custom Component & hooks
import Search from "../../../components/Search/Search";
import Table from "../../../components/DataDomainTables/Table";
import Loader from "../../../components/Loader/Loader";
import DQAppContainer from "../../DQAppContainer/DQAppContainer";
import useFetchCategoryTreeData from "./hooks/useFetchCategoryTreeData";

// Import utils
import {
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";
import { getDataDomainTables } from "../../../services/apis";
import {
  getCategoryChildrenHierarchyByParams,
  filterDataWithSearchText,
  validateParamsWithTableSelections,
} from "./utils";
import noDataImage from "../../../../assets/images/undraw_void.svg";
import "./DataDomainTables.scss";

// # COMPONENT
const DataDomainTables = ({ match }) => {
  // HOOKS
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.data);
  const userInputs = useSelector((state) => state.userInputs);

  // # SIDE EFFECTS
  useFetchCategoryTreeData();

  // Update tableSelections based on current route
  useEffect(() => {
    const runFlag = data.categoryTreeData.status === "success";
    if (runFlag) {
      // Using a function, get tableSelections from match.params
      const tableSelections = getCategoryChildrenHierarchyByParams(
        data.categoryTreeData.result,
        match.params
      );

      const isParamsValidWithTableSelections =
        validateParamsWithTableSelections(match.params, tableSelections);
      if (!isParamsValidWithTableSelections) {
        dispatch(
          updateData("dataTables", {
            status: "success",
            result: [],
            message: "",
          })
        );
      }

      // Update userInputs.tableSelections based on route
      dispatch(
        updateUserInputs("tableSelections", {
          ...tableSelections,
        })
      );
    }
    // eslint-disable-next-line
  }, [data.categoryTreeData.status, match.params.layer]);

  // Fetch dataset details
  useEffect(() => {
    const fetchDataDomainTables = async () => {
      dispatch(
        updateData("dataTables", {
          status: "loading",
          result: [],
          message: "",
        })
      );
      let reqObj = {
        dataset_name: userInputs?.tableSelections?.datasets?.name,
        layer_id: userInputs?.tableSelections?.dataLayers?.id,
        sub_data_domain_name: userInputs?.tableSelections?.subDomain?.name,
        secondary_data_domain_name:
          userInputs?.tableSelections?.secondaryDomain?.name,
        primary_data_domain_name:
          userInputs?.tableSelections?.primaryDomain?.name,
      };
      let dataTables = await getDataDomainTables(reqObj);

      if (dataTables && dataTables.length > 0) {
        dispatch(
          updateData("dataTables", {
            status: "success",
            result: dataTables,
            message: "",
          })
        );
      } else {
        dispatch(
          updateData("dataTables", {
            status: "error",
            result: [],
            message: "Could not load details! Please try again later!",
          })
        );
      }
    };

    const runFlag =
      data.categoryTreeData.status === "success" &&
      userInputs.tableSelections.dataLayers &&
      userInputs.tableSelections.datasets;

    if (runFlag) {
      fetchDataDomainTables();
    }

    // eslint-disable-next-line
  }, [userInputs.tableSelections, data.categoryTreeData.status]);

  // # STATIC VARIABLES
  const filteredData = filterDataWithSearchText(
    data.dataTables.result,
    user.selections.searchText
  );

  // # JSX
  return (
    <DQAppContainer match={match}>
      <div className="standard-dataset-register-container">
        <Search
          placeholderText="Search Tables"
          user={user}
          updateSelections={(key, data) =>
            dispatch(updateSelections(key, data))
          }
        />
      </div>
      {data.dataTables.status === "success" ? (
        <Table
          data={filteredData}
          paginationFlag={true}
          id="tables"
          iconName="view_module"
          aggregateCellFlag={true}
          tblHeaders={[
            { id: "table_name", label: "Table Name" },
            { id: "aggregated_score", label: "Aggregated Score" },
            { id: "report", label: "Report" },
            // { id: "", label: "" },
          ]}
        />
      ) : data.dataTables.status === "loading" ? (
        <Loader />
      ) : (
        <div className="no-configs">
          {" "}
          <img src={noDataImage} alt="noDataImage" />
          <p className="no-data">
            <b>{data.dataTables.message}</b>
          </p>
        </div>
      )}
    </DQAppContainer>
  );
};

// # EXPORTS
export default DataDomainTables;
