import { Select } from "@material-ui/core";
import { styled } from "@material-ui/core";

const StyledSelect = styled(
  Select,
  {}
)(({ theme, customProps }) => ({
  fontFamily: "inherit",
  height: "2em !important",
  width: "120px",
  // '&.MuiInputBase-root.MuiOutlinedInput-root': {
  //   height: '30px !important',
  // },
  "&.MuiInputBase-root": {
    backgroundColor: "white",
    color: "#46596a",
    border: "1px solid #dedede",
    fontSize: "12px",
    alignItems: "center",
    borderRadius: "5px",
    paddingLeft: "5px",
    height: "30px",
    "&:hover": {
      backgroundColor: "white",
    },
    margin: "5px",
    width: "120px",
    "&::before": {
      borderBottom: "none !important",
    },
    "&::after": {
      borderBottom: "none !important",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
    },
  },
  "& .MuiPaper-root": {
    outline: 0,
    minHeight: "16px",
    maxHeight: "150px !important",
  },
  "& .MuiInputBase-root": {
    height: "2em !important",
    borderRadius: "4px",
    border: "1px solid black",
  },
  "& .MuiSelect-select": {},
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },
  "& .MuiSelect-select.MuiSelect-outlined": {
    fontSize: "14px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent !important",
  },
}));

export default StyledSelect;
