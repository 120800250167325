import {
  makeStyles,
  styled,
  TableCell,
  withStyles,
  createStyles,
  TextField,
} from "@material-ui/core";
import Rating from "@mui/material/Rating";
import MenuItem from "@material-ui/core/MenuItem";
import { InputBase, TableRow } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontFamily: "Hind Siliguri",
    backgroundColor: "#f3f3f3",
    // backgroundColor: 'rgba(230 233 238)',
    borderRadius: "4px",
    borderColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "23px",
    padding: "4px 8px",
    border: "transparent",
  },
  "&:focus": {},
}));

export const CssTextField = withStyles((theme) => ({
  root: {
    width: "90%",
    backgroundColor: "transparent",
    padding: "3px 10px",
    borderRadius: "4px",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
}))(TextField);
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    padding: "6px 16px",
  },
  "&:nth-of-type(even)": {
    // backgroundColor: "#F5F6F8",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.dark.main : "#F5F6F8",
  },
  "&:nth-of-type(odd)": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.white.main,
  },
  "&.MuiTableRow-hover": {
    "& .edit-btn": {
      display: "none",
    },
    "& svg": {
      opacity: 0,
    },
    "&:hover": {
      "&:nth-of-type(even)": {
        // backgroundColor: "#F5F6F8",
        backgroundColor:
          theme.palette.mode === "dark" ? theme.palette.dark.main : "#F5F6F8",
      },
      "&:nth-of-type(odd)": {
        // backgroundColor: "#fff",
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.dark.main
            : theme.palette.white.main,
      },
      // backgroundColor: "#FBEDF2",
      "& .edit-btn": {
        display: "inline-flex",
        marginLeft: "30px",
        color: "#ea3592",
        "& .material-icons": {
          fontSize: "20px",
        },
      },
      "& svg": {
        opacity: 1,
      },
    },
  },
}));

export const useStyles1 = makeStyles((theme) => ({
  tableRowCell: {
    fontFamily: "Hind Siliguri !important",
    fontSize: "13px !important",
    color: theme.palette.mode === "dark" ? "#fff" : "#465A69 !important",
    "&.MuiTableCell-root": {
      padding: "6px 16px",
      borderBottom: "none",
    },
    // cursor: "pointer !important",
  },
  tableHeadCell: {
    fontFamily: "Hind Siliguri !important",
    fontSize: "13px !important",
    fontWeight: "700 !important",
    color: "#ffffff !important",
    backgroundColor: " #46596D !important",
    "&.MuiTableCell-root": {
      padding: "6px 16px",
      borderBottom: "none",
    },
    // color: "#465A69 !important",
    // backgroundColor: "#E6E9EE !important",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#F5F6F8!important",
      // backgroundColor: "#FCFCFC !important",
      color: "#D8477E !important",
    },
  },
  pagination: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.white.main,
    // },
    "& .MuiTablePagination-selectLabel": {
      fontFamily: "Hind Siliguri",
      // color: "#465A69",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#465A69",
    },
    "& .MuiTablePagination-select": {
      fontFamily: "Hind Siliguri",
      // color: "#465A69",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#465A69",
    },
    "& .MuiTablePagination-displayedRows": {
      fontFamily: "Hind Siliguri",
      // color: "#465A69",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#465A69",
    },
  },
  search: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "4px",
    marginLeft: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  menu: {
    boxShadow: "none",
    "& .MuiMenuItem-root": {
      fontFamily: "Hind Siliguri",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "23px",
      color: theme.palette.mode === "dark" ? "#fff" : "#46596a",
      boxShadow: "none",
      padding: "2px 20px",
    },
    "& .MuiPaper-root": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.dark.light
          : theme.palette.white.main,
    },
  },
  fixedWidth: {
    maxWidth: "100px",
  },
  rolePadding: {
    padding: "6px 12px",
    textTransform: "capitalize",
  },
  provisingAccess: {
    "& .MuiTableCell-root": {
      fontFamily: "Hind Siliguri",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "23px",
      padding: "8px",
      color: theme.palette.mode === "dark" ? "#fff" : "#465a69",
    },
  },
  userId: {
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontWeight: "700",
    // fontSize: "12px",
    lineHeight: "19px",
    // color: "#42596C",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#42596C",
  },
  menuStyle: {
    "& .MuiMenuItem-root": {
      backgroundColor: "#FBEDF2",
      color: theme.palette.dark.main,
      "&:hover": {
        backgroundColor: "#FBEDF2",
      },
    },
  },
  inputField: {
    flex: 1,
    "& .MuiInputBase-input": {
      width: "100% !important",
    },
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    width: "140px",
    height: "10px",
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "23px",
    borderRadius: "4px",
    color: "#46596a",
  },
  "& .Mui-disabled": {
    cursor: "pointer",
  },
}));

export const paperStyle = {
  width: "100%",
  overflow: "hidden",
  borderRadius: "0px",
  boxShadow: "none",
};

export const menuStyle = {
  backgroundColor: "#FBEDF2",
  color: "#33424F !important",
  "&:hover": {
    backgroundColor: "#FBEDF2",
  },
  textTransform: "capitalize",
};
export const defaultStyle = {
  textTransform: "capitalize",
};

export const StyledTableCell = withStyles(() => ({
  body: {
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    padding: "20px 0px",
    alignItems: (props) => (props.center ? "center" : null),
    display: (props) => (props.flex ? "flex" : null),
  },
}))(TableCell);

export const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    display: "flex",
  },
  drawer: {
    width: "70vw",
    flexShrink: 0,
    display: "flex",
    alignSelf: "center",
    height: "60vh",
    alignItems: "center",
    marginTop: "15%",
  },
  drawerPaper: {
    width: "50vw",
  },
  bottomPush: {
    position: "relative",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      fontFamily: "Hind Siliguri",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      color: "#46596a",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#EB367F",
      color: "#ffffff",
    },
  },
  paginationSelect: {
    paddingLeft: "10px",
    paddingRight: "10px",
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "23px",
    color: "#ffffff",
    backgroundColor: "#46596A",
    borderRadius: "5px",
    "& .MuiSvgIcon-root": {
      color: "#ffffff",
    },
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    minWidth: "100px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px !important",
    lineHeight: "23px",
    borderBottom: "1px solid #E6E8ED",
    cursor: "pointer !important",
    "&:last-child th": {
      borderBottom: 0,
    },
    backgroundColor: "white !important",
    "&:hover": {
      backgroundColor: "#4eafb342 !important",
    },
  },
  activeTableRow: {
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px !important",
    color: "#4eafb3",
    lineHeight: "23px",
    borderBottom: "1px solid #E6E8ED",
    "&:last-child th": {
      borderBottom: 0,
    },
    backgroundColor: "#4eafb342 !important",
  },
  gridRoot: {
    flexGrow: 1,
    cursor: "default !important",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
    marginTop: "8px",
    marginBottom: "6px",
  },
  ratingBox: {
    width: "10px",
    height: "14px",
    marginRight: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rating: {
    height: "100%",
  },
  empty: {
    height: "100%",
    backgroundColor: "#ECECEC",
  },
  fontStyle: {
    paddingLeft: "10px",
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "19px",
    color: "#465A69",
    background: "#fff",
  },
  rafifyBtn: {
    border: "1.3px solid #4EAFB3",
    boxSizing: "border-box",
    borderRadius: "23px",
    color: "#4EAFB3",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "16px",
    textTransform: "none",
    // backgroundColor: 'transparent',
    "&:hover": {
      color: "#fff",
      backgroundColor: "#4EAFB3",
    },
  },
  reportBtn: {
    color: "#fff",
    border: "1px solid #4eafb3",
    background: "#4eafb3",
    fontSize: "12px",
    height: "24px",
    padding: "0px 10px",
    fontFamily: "Hind Siliguri",
    textTransform: "none",
    "&:hover": {
      background: "#4eafb3",
      color: "#ffffff",
    },
    "&.Mui-disabled": {
      color: "#ffffff",
      opacity: 0.7,
    },
  },
  cancelBtn: {
    color: "#4eafb3",
    border: "1px solid #4eafb3",
    fontSize: "12px",
    height: "24px",
    padding: "0px 10px",
    fontFamily: "Hind Siliguri",
    textTransform: "none",
    marginRight: "10px",
  },
  container: {
    minWidth: "400px",
    maxWidth: "600px",
    padding: "10px 0",
  },
  textWraping: {
    whiteSpace: "nowrap",
  },
  textAlignment: {
    textAlign: "center",
  },
  tableSubHead: {
    borderBottom: "none",
    fontFamily: "Hind Siliguri",
    backgroundColor: "#E6E8ED",
    color: "rgba(70, 89, 106, 1)",
    fontWeight: 600,
    fontSize: "11px",
    textTransform: "capitalize",
    marginTop: "2px",
  },
  tablecell: {
    padding: "5px 12px",
  },
  displayNone: {
    opacity: 0,
  },
}));

export const rowStyle = {
  lineHeight: "24px",
  padding: "5px 5px",
  cursor: "default",
};

export const iconStyle = {
  fontSize: "14px",
  marginLeft: "5px",
  color: "#4eafb3",
  marginTop: "3px",
};

export const popOverHeading = {
  fontWeight: 500,
  fontSize: "11px",
  textTransform: "capitalize",
  color: "#B6B6B6",
};

export const popOverBody = {
  fontWeight: 600,
  fontSize: "13px",
  textTransform: "capitalize",
  color: "#465A69",
};

export const popOverContent = {
  minWidth: "200px",
  minHeight: "100px",
  padding: "10px",
};

export const statusIconStyle = {
  width: "22px",
  height: "22px",
  float: "left",
};

export const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: (props) => props.backColor,
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

export const selectStyle = {
  paddingLeft: "10px",
  fontFamily: "Hind Siliguri",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "19px",
  color: "#465A69",
  background: "#EDEDED",
  width: "100%",
};

export const customMenuItemStyle = {
  paddingLeft: "10px",
  fontFamily: "Hind Siliguri",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "19px",
  color: "#465A69",
  background: "#fff",
};

export const CustomMenuItem = withStyles(() =>
  createStyles({
    // root: {
    // 	"&$selected": {
    // 		backgroundColor: "red",
    // 		"&:hover": {
    // 			backgroundColor: "green",
    // 		},
    // 	},
    // 	'&:hover': {
    // 		backgroundColor: 'blue',
    // 	},
    // },
    selected: {},
  })
)(MenuItem);
