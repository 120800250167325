import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function CustomizedLinearProgressBar({ percentage, color }) {
  const progressPercentage = percentage || 0;
  const progressColor = color || "#4eafb3";
  const useStyles = makeStyles({
    root: {
      height: 10,
      borderRadius: "4px",
    },
    colorPrimary: {
      backgroundColor: "#E9E9E9",
    },
    bar: {
      backgroundColor: progressColor,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ backgroundColor: "#d0d0ce" }}>
      <LinearProgress
        variant="determinate"
        value={progressPercentage}
        classes={{
          root: classes.root,
          colorPrimary: classes.colorPrimary,
          bar: classes.bar,
        }}
      />
    </div>
  );
}

CustomizedLinearProgressBar.propTypes = {
  percentage: PropTypes.any.isRequired,
  color: PropTypes.any.isRequired,
};
