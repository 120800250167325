import axiosInstance from "./axios";
import config from "../config/config";
import { createCancelTokenHandler } from "./axiosUtils";
import qs from "qs";
import { updateData } from "../redux/actions";

// import { getReplaceUniqueKeyByV4 } from "../utils/utils";

const getPrimaryDomainOptions = async ({ categoryId }) => {
  try {
    let url = `${config.api.primaryDomainUrl}/${categoryId}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

const getPrimaryDomainOptionsWithoutId = async () => {
  try {
    let url = `${config.api.primaryDomainUrl}/`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

const getSecondaryDomainOptions = async ({ primaryDomainId }) => {
  try {
    let url = `${config.api.secondaryDomainUrl}/${primaryDomainId}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

const getSecondaryDomainOptionsWithoutId = async () => {
  try {
    let url = `${config.api.secondaryDomainUrl}/`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

const getSubDataDomainOptions = async ({
  primaryDomainId,
  secondaryDomainId,
}) => {
  try {
    let url = `${config.api.subDataDomainUrl}/${secondaryDomainId}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

const getSubDataDomainOptionsWithoutId = async () => {
  try {
    let url = `${config.api.subDataDomainUrl}/`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

const testConnection = async (reqBody, cluster_id) => {
  try {
    let url = `${config.api.testConnectionUrl}?cluster_id=${1}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getEnvironmentData = async () => {
  try {
    let url = `${config.api.environmentUrl}/`;
    const resp = await axiosInstance.get(url);
    return resp.data;
  } catch (err) {
    return err;
  }
};

const getCategoryData = async () => {
  try {
    let url = `${config.api.categoryUrl}/`;
    const resp = await axiosInstance.get(url);
    return resp.data;
  } catch (err) {
    return err;
  }
};

const getDatasetData = async ({ subDataDomainId }) => {
  try {
    let url = `${config.api.datasetUrl}/${subDataDomainId}`;
    const resp = await axiosInstance.get(url);
    return resp.data;
  } catch (err) {
    return err;
  }
};

const getLayersData = async () => {
  try {
    let url = `${config.api.layersUrl}/`;
    const resp = await axiosInstance.get(url);
    return resp.data;
  } catch (err) {
    return err;
  }
};

const postClusterData = async (reqBody) => {
  try {
    let url = `${config.api.layersUrl}/cluster`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

const getCatalogs = async (reqBody) => {
  try {
    let url = `${config.api.catalogsUrl}/`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    updateData("catalogs", {
      status: "success",
      message: "",
      result: response.data,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getDataLayerData = async ({ datasetId }) => {
  try {
    let url = `${config.api.dataLayerUrl}/${datasetId}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getDatabases = async (reqBody) => {
  try {
    let url = `${config.api.catalogsUrl}/schemas`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getTables = async (reqBody) => {
  try {
    let url = `${config.api.catalogsUrl}/schemas/tables`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getColumns = async ({ catalogId, schemaId, tableId, clusterId }) => {
  try {
    let url = `${
      config.api.catalogsUrl
    }/${catalogId}/schemas/${schemaId}/tables/${tableId}/columns?cluster_id=${1}`;
    // let url = `/v1/catalogs/{id}/schemas/{schema_id}/tables/{table_id}/columns`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getColumnsForDatasetTables = async (reqBody) => {
  try {
    let url = `${config.api.catalogsUrl}/schemas/tables/columns`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
const getAllRules = async () => {
  try {
    let url = `${config.api.allRulesUrl}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};
const getAllRulesData = async () => {
  try {
    let url = `${config.api.allRulesDataUrl}`;
    const response = await axiosInstance.get(url);
    // return getReplaceUniqueKeyByV4(response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};
const getDataTables = async () => {
  try {
    let url = `${config.api.tableUrl}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getMyDataset = async () => {
  try {
    let url = `${config.api.myDatasetUrl}/`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};
const findDuplicateDataset = async (reqBody = {}) => {
  try {
    let url = `${config.api.checkForDuplicateDataset}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getDataTableDetails = async ({ tableId }) => {
  try {
    let url = `${config.api.tableUrl}/${tableId}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const saveLandingLayerFilePath = async (reqObj = {}) => {
  const { tableId, reqBody = {} } = reqObj;

  try {
    let url = `${config.api.tableUrl}/${tableId}/file`;
    console.log("monu url", url);
    const response = await axiosInstance.put(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    console.log("monu error");
    return error;
  }
};

const getMyDatasetDetails = async ({ datasetId }) => {
  try {
    let url = `${config.api.myDatasetUrl}/${datasetId}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

const createOrUpdateTable = async (reqBody = {}) => {
  try {
    let url = `${config.api.detailedDatasetUrl}/`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const deleteTableRow = async ({ id }) => {
  try {
    let url = `${config.api.tableUrl}/${id}`;
    const response = await axiosInstance.delete(url, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const handleAuth = async (reqBody = {}) => {
  try {
    let url = `${config.api.authUrl}`;
    const data = qs.stringify(reqBody);
    const response = await axiosInstance.post(url, data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getUserDetails = async (token = "") => {
  try {
    let url = `${config.api.userDetailsUrl}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateDataset = async (reqBody = {}) => {
  try {
    let url = `${config.api.myDatasetUrl}/${reqBody.id}`;
    const response = await axiosInstance.put(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateTable = async (reqBody = {}) => {
  try {
    let url = `${config.api.tableUrl}/${reqBody.id}`;
    const response = await axiosInstance.put(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getDataDomainTables = async (reqBody = {}) => {
  try {
    let url = `${config.api.detailedDatasetUrl}/tables`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getTableDiagnosticReportDetails = async (reqBody = {}) => {
  try {
    // let url = `${config.api.reportUrl}/${reqBody.tableId}/diagnostic_report/${reqBody.runDateId}`;
    let url = `${config.api.diagnosticReportDetailsUrl}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    // const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

const getTableDiagnosticReportFilteredDetails = async (id) => {
  try {
    let url = `${config.api.diagnosticReportGetStatusUrl}/${id}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

const getDiagnosticReportStatusDetails = async (id) => {
  try {
    let url = `${config.api.diagnosticReportGetStatusUrl}/${id}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};
const getTableDiagnosticReportFilters = async (reqBody = {}) => {
  try {
    let url = `${config.api.reportFiltersUrl}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

const getTableHistoricalRunsDetails = async ({ tableId }) => {
  try {
    let url = `${config.api.reportUrl}/${tableId}/historical_runs`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (err) {
    return err;
  }
};

const downloadReportCSV = async (reqBody = {}) => {
  try {
    // let url = `${config.api.reportUrl}/${tableId}/diagnostic_report/${runDateId}/download`;
    // const response = await axiosInstance.get(url);
    let url = `${config.api.diagnosticReportDownloadUrl}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
      // responseType: "arraybuffer",
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

const getReportHierarchicalData = async ({ searchValue }) => {
  try {
    let url = "";
    if (searchValue && searchValue.length > 0) {
      url = `${config.api.reportHierarchyUrl}?name=${searchValue}`;
    } else {
      url = `${config.api.reportHierarchyUrl}`;
    }
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const syncDRFiltersApi = async (reqBody = {}) => {
  try {
    let url = `${config.api.syncFiltersUrl}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getTableStructure = async (reqBody = {}) => {
  try {
    let url = `${config.api.getTableStructureUrl}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const allColumnsData = async (reqBody = {}) => {
  try {
    let url = `${config.api.allColumnsUrl}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getGlobalRulesList = async () => {
  try {
    let url = `${config.api.globalRulesUrl}/`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getGlobalRuleDetails = async ({ ruleName }) => {
  try {
    let url = `${config.api.globalRulesUrl}/${ruleName}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const requestNewRuleApi = async (reqBody = {}) => {
  try {
    let url = `${config.api.requestOrManageRuleUrl}/`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateglobalRuleDetails = async (reqBody = {}) => {
  try {
    let url = `${config.api.globalRulesUrl}/${reqBody.id}`;
    const response = await axiosInstance.put(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getManageRulesList = async () => {
  try {
    let url = `${config.api.requestOrManageRuleUrl}/`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const approveOrRejectGlobalRule = async (reqBody = {}, id = "") => {
  try {
    let url = `${config.api.requestOrManageRuleUrl}/${id}`;
    const response = await axiosInstance.put(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCategoryTreeData = async () => {
  try {
    let url = `${config.api.categoryTreeDataUrl}`;
    const response = await axiosInstance.get(url, {
      cancelToken:
        cancelTokenHandlerObject[
          "getCategoryTreeData"
        ].handleRequestCancellation().token,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getReckittUsersList = async (
  reqBody = {
    key: "",
  }
) => {
  try {
    let url = `${config.api.userTeamReckittUsersUrl}?skip=0&limit=2000`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getAdminPanelAccessManagmentUsersList = async () => {
  try {
    let url = `${config.api.userTeamUrl}/`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const deleteAdminPanelAccessManagmentUser = async ({ id }) => {
  try {
    let url = `${config.api.userTeamUrl}/${id}`;
    const response = await axiosInstance.delete(url, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const postAdminPanelAccessManagmentUser = async (reqBody = {}) => {
  try {
    let url = `${config.api.userTeamUrl}/`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const putAdminPanelAccessManagmentUser = async (reqBody = {}) => {
  try {
    let url = `${config.api.userTeamUrl}/${reqBody.id}`;
    const response = await axiosInstance.put(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getAdminPanelTeamsList = async () => {
  try {
    let url = `${config.api.teamUrl}/`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const deleteAdminPanelTeams = async ({ id }) => {
  try {
    let url = `${config.api.teamUrl}/${id}`;
    const response = await axiosInstance.delete(url, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const postAdminPanelTeams = async (reqBody = {}) => {
  try {
    let url = `${config.api.teamUrl}/`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const putAdminPanelTeams = async (reqBody = {}) => {
  try {
    let url = `${config.api.teamUrl}/${reqBody.id}`;
    const response = await axiosInstance.put(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const deleteCompareDataset = async ({ id }) => {
  try {
    let url = `${config.api.compareDatasetUrl}/${id}`;
    const response = await axiosInstance.delete(url); // Use DELETE instead of GET
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCompareDatasetList = async () => {
  try {
    let url = `${config.api.compareDatasetUrl}/`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCompareDatasetSource = async () => {
  try {
    let url = `${config.api.compareDatasetUrl}/source`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCatalogsDatabase = async (reqBody) => {
  try {
    let url = `${config.api.catalogsUrl}/databases`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCatalogsTable = async (reqBody) => {
  try {
    let url = `${config.api.catalogsUrl}/tables`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCatalogsColumns = async (reqBody) => {
  try {
    let url = `${config.api.catalogsUrl}/columns`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCompareDatasetCriteria = async () => {
  try {
    let url = `${config.api.compareDatasetUrl}/criteria`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const postCompareDataset = async (reqBody = {}) => {
  try {
    let url = `${config.api.compareDatasetUrl}/`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const runCompareDataset = async (reqBody = {}) => {
  try {
    let url = `${config.api.compareDatasetUrl}/run`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCompareDatasetById = async (id) => {
  try {
    let url = `${config.api.compareDatasetUrl}/${id}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getUserClusters = async () => {
  try {
    let url = `${config.api.environmentUrl}/user`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getTablesList = async () => {
  try {
    let url = `${config.api.tableUrl}/`;
    const response = await axiosInstance.get(url, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const createTablePipeline = async (reqBody = []) => {
  try {
    let url = `${config.api.tableUrl}/create`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const runTableDataProfiling = async (reqBody = []) => {
  try {
    let url = `${config.api.tableUrl}/run_data_profiling`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getTableDataProfiling = async (reqBody = []) => {
  try {
    let url = `${config.api.tableUrl}/list_data_profiling`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// creating the cancel token handler object
const cancelTokenHandlerObject = createCancelTokenHandler({
  getCategoryTreeData,
});

const checkReportName = async (fetchProps = {}) => {
  try {
    let url = `${config.api.compareDatasetUrl}/check_report`;
    const response = await axiosInstance.post(url, fetchProps, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
const compareDatasetdownloadReportCSV = async (reqBody = {}) => {
  try {
    // let url = `${config.api.reportUrl}/${tableId}/diagnostic_report/${runDateId}/download`;
    // const response = await axiosInstance.get(url);
    let url = `${config.api.compareDatasetReportDownloadUrl}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
      responseType: "arraybuffer",
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

const compareDatasetDownloadJson = async (fetchProps = {}) => {
  try {
    let url = `${config.api.compareDatasetReportDownloadUrl}`;
    const response = await axiosInstance.post(url, fetchProps, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getMyDatasetColumns = async (fetchProps = {}) => {
  try {
    let url = `${config.api.myDatasetColumns}`;
    const response = await axiosInstance.post(url, fetchProps, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getMyDatasetCondition = async (fetchProps = {}) => {
  try {
    let url = `${config.api.myDatasetCondition}`;
    const response = await axiosInstance.get(url, fetchProps, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const runMyDatasetCondition = async (fetchProps = {}) => {
  try {
    let url = `${config.api.runMyDatasetCondition}`;
    const response = await axiosInstance.post(url, fetchProps, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
const getTableSummary = async (fetchProps = {}) => {
  try {
    let url = `${config.api.getTableSummary}`;
    const response = await axiosInstance.post(url, fetchProps, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const downloadGlobalRulesSummary = async (reqBody = {}) => {
  try {
    // let url = `${config.api.reportUrl}/${tableId}/diagnostic_report/${runDateId}/download`;
    // const response = await axiosInstance.get(url);
    let url = `${config.api.globalRulesDownloadUrl}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
      responseType: "arraybuffer",
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

const suggestRulesForTables = async (reqBody) => {
  try {
    let url = `${config.api.suggestedRules}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const clusterData = async (reqBody = {}) => {
  try {
    let url = `${config.api.clusterData}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const readBlobData = async (reqBody = {}) => {
  try {
    let url = `${config.api.readBlob}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const listDirectory = async (reqBody = {}) => {
  try {
    let url = `${config.api.listDirectory}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const fileUpload = async (reqBody = {}, reqParams) => {
  const { cluster_id, report_name } = reqParams;
  try {
    let url = `${config.api.compareDatasetUrl}/upload_file/${report_name}/${cluster_id}`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "multipart/form-data;boundary='boundary'" },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getSchemaHistory = async (props) => {
  const { tableId } = props;
  try {
    let url = `${config.api.tableUrl}/${tableId}/list_schema_history`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getSchemaSync = async (props) => {
  const { tableId } = props;
  try {
    let url = `${config.api.tableUrl}/${tableId}/sync_schema_history`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const startCluster = async (reqBody) => {
  try {
    let url = `${config.api.catalogsUrl}/start_cluster`;
    const response = await axiosInstance.post(url, reqBody, {
      headers: { "Content-Type": "application/json" },
    });
    updateData("catalogs", {
      status: "success",
      message: "",
      result: response.data,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const userTeamInfo = async (props) => {
  const {} = props;
  try {
    let url = `${config.api.userTeamInfo}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getAllRulesDownload = async () => {
  try {
    let url = `${config.api.allRulesDownload}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

export {
  testConnection,
  getPrimaryDomainOptions,
  getPrimaryDomainOptionsWithoutId,
  getSecondaryDomainOptions,
  getSecondaryDomainOptionsWithoutId,
  getSubDataDomainOptions,
  getSubDataDomainOptionsWithoutId,
  getCategoryData,
  getCategoryTreeData,
  getEnvironmentData,
  getDatasetData,
  getLayersData,
  postClusterData,
  getAllRules,
  getAllRulesData,
  getCatalogs,
  getDataTables,
  getDatabases,
  getColumns,
  getDataTableDetails,
  saveLandingLayerFilePath,
  getTables,
  createOrUpdateTable,
  deleteTableRow,
  getDataLayerData,
  handleAuth,
  getUserDetails,
  getMyDataset,
  getMyDatasetDetails,
  getColumnsForDatasetTables,
  updateDataset,
  getDataDomainTables,
  getTableDiagnosticReportDetails,
  getTableDiagnosticReportFilters,
  getTableHistoricalRunsDetails,
  downloadReportCSV,
  getReportHierarchicalData,
  getTableStructure,
  allColumnsData,
  getGlobalRulesList,
  getGlobalRuleDetails,
  requestNewRuleApi,
  updateglobalRuleDetails,
  getManageRulesList,
  approveOrRejectGlobalRule,
  updateTable,
  findDuplicateDataset,
  syncDRFiltersApi,
  getReckittUsersList,
  getAdminPanelAccessManagmentUsersList,
  deleteAdminPanelAccessManagmentUser,
  postAdminPanelAccessManagmentUser,
  putAdminPanelAccessManagmentUser,
  getAdminPanelTeamsList,
  deleteAdminPanelTeams,
  postAdminPanelTeams,
  putAdminPanelTeams,
  getCompareDatasetList,
  getCompareDatasetSource,
  getCatalogsDatabase,
  getCatalogsTable,
  getCatalogsColumns,
  getCompareDatasetCriteria,
  postCompareDataset,
  getCompareDatasetById,
  getTableDiagnosticReportFilteredDetails,
  getUserClusters,
  getTablesList,
  createTablePipeline,
  runCompareDataset,
  deleteCompareDataset,
  checkReportName,
  compareDatasetdownloadReportCSV,
  compareDatasetDownloadJson,
  getMyDatasetColumns,
  getMyDatasetCondition,
  runMyDatasetCondition,
  getTableSummary,
  downloadGlobalRulesSummary,
  getDiagnosticReportStatusDetails,
  runTableDataProfiling,
  getTableDataProfiling,
  suggestRulesForTables,
  clusterData,
  readBlobData,
  listDirectory,
  fileUpload,
  getSchemaHistory,
  getSchemaSync,
  startCluster,
  userTeamInfo,
  getAllRulesDownload,
};
