import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";

// * Import Kit
import Select from "../../../kit/Select";
import MenuItem from "../../../kit/MenuItem";

// Import actions
import { updateSelections } from "../../../redux/actions";

// Import data & utils
import config from "../../../config/config";
import { convertToLinearArray, getTagsFromCategoryLineaData } from "./utils";

// styles
const useStyles = makeStyles(() => ({
  select: {
    height: "35px !important",
  },
}));
function getStyles(name, selectedSearchTags, theme) {
  return {
    fontWeight:
      selectedSearchTags.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ExploreDatasetsSearchByTags = (props) => {
  // # hooks
  const dispatch = useDispatch();
  const selectedSearchTags = useSelector(
    (state) => state.user.selections.selectedSearchTags
  );
  const categoryTreeData = useSelector(
    (state) => state.data.categoryTreeData.result
  );

  // # HANDLERS
  const handleChange = (event) => {
    dispatch(updateSelections("selectedSearchTags", event.target.value));
  };

  // # STATIC VARIABLES
  const searchTags = useMemo(
    () => getTagsFromCategoryLineaData(convertToLinearArray(categoryTreeData)),
    // eslint-disable-next-line
    [categoryTreeData.length]
  );
  const classes = useStyles();
  const theme = useTheme();

  // # JSX
  return (
    <Select
      labelId="mutiple-tags-label"
      multiple
      value={selectedSearchTags}
      onChange={handleChange}
      input={<Input />}
      displayEmpty
      MenuProps={config.hardCoded.MenuProps}
      renderValue={(values) =>
        values && values.length ? values.join(", ") : "Filter by Tags"
      }
      className={classes.select}
      title={selectedSearchTags}
    >
      {searchTags.length ? (
        searchTags.map((searchTag) => (
          <MenuItem
            key={searchTag}
            value={searchTag}
            style={getStyles(searchTag, selectedSearchTags, theme)}
            title={searchTag}
          >
            {searchTag}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="" disabled>
          No Options
        </MenuItem>
      )}
    </Select>
  );
};

export default ExploreDatasetsSearchByTags;
