import C from "../constants";

export default function data(state = {}, payload) {
  let { type, key, data } = payload;
  switch (type) {
    case C.UPDATE_DATA:
      return { ...state, [key]: data };
    default:
      return state;
  }
}
