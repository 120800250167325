// ### Imports
// * Import lib

import { makeStyles, useTheme } from "@material-ui/core";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { Box } from "@material-ui/core";
import C from "../../redux/constants";
import Checkbox from "../../kit/Checkbox";
import CustomCheckStringSAPWithCondition from "./components/CustomCheckStringSAPWithCondition";
import CustomCheckStringWithCondition from "./components/CustomCheckStringWithCondition";
import CustomFieldCompareWithCondition from "./components/CustomFieldCompareWithCondition";
import CustomFieldCompareWithConditionSAP from "./components/CustomFieldCompareWithConditionSAP";
import CustomFieldCompareWithMathematicalOperations from "./components/CustomFieldCompareWithMathematicalOperations";
import CustomIsExistingCondAdvanced from "./components/CustomIsExistingCondAdvanced";
import CustomMathematicalOperationsWithColumn from "./components/CustomMathematicalOperationsWithColumn";
import FieldCompareComponent from "./components/FieldCompareComponent";
import Icon from "../Icon/Icon";
import IsExistingRule from "./components/IsExistingRule";
import IsMappedRuleComponent from "./components/IsMappedRuleComponent";
import IsNotExistingRule from "./components/IsNotExistingRule";
import MenuItem from "../../kit/MenuItem";
import MultipleRules from "./components/MultipleRules";
import React from "react";
import { WithContext as ReactTags } from "react-tag-input";
import SearchTableSelection from "../SearchTableSelection/SearchTableSelection";
import Select from "../../kit/Select";
import TextField from "../../kit/TextField";
import Typography from "../../kit/Typography";
import config from "../../config/config";
import { getTotalSelectedRules } from "../../utils/utils";
import groupBy from "lodash.groupby";
import { masterGroupData } from "./schema";
import noDataImage from "../../../assets/images/undraw_void.svg";
import { useSelector } from "react-redux";
import { useState } from "react";

// * Import Kit

// * Import custom components

// * Import utils

// # UTILITY FUNCTIONS
// TODO : Move to the right folder
const selectAllReduxData = (store) => store.data;
const findGroupNameFromGroupId = (groupId, groupData) =>
  groupData.find((row) => row.id === groupId)?.name || "undefined";

// # STYLES
const useStyles = makeStyles((theme) => ({
  autoComplete: {
    fontSize: "12px",
  },
  formControl: { margin: theme.spacing(1), minWidth: 120, maxWidth: 300 },
  autoCompleteRule: {
    width: 300,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  groupsRoot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  groupsRootText: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    border: "1px solid transparent",
  },
  groupsRootTextLeft: { gridColumn: "1 / span 11" },
  groupsRootTextRight: { gridColumn: "12 / span 1" },
  groupsRootTextRightWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "7px",
  },
  rulesRowRootContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
  },
  rulesAllRow: {
    display: "flex",
    alignItems: "center",
    gridColumn: "1 / span 12",
  },
  rulesLeftRow: {
    display: "flex",
    alignItems: "center",
    gridColumn: "1 / span 11",
  },
  rulesRightRow: { gridColumn: "12" },
  ruleRowWrapper: {
    display: "flex",
    alignItems: "center",
  },
  ruleRowItem: {
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    "&.input_tags": { width: "200px" },
    "&.custom_field_compare_rule": { marginRight: "0px", width: "100%" },
    "&.custom_isExisting_Cond": { marginRight: "0px", width: "100%" },
    "&.custom_is_mapped_rule": { marginRight: "0px", width: "100%" },
  },
  noDataWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
  },
  rulesMenuWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  accordionMargin: {
    marginBottom: "10px",
  },
}));
// # HELPER COMPONENTS
function RulesRow(props = {}) {
  // # PROPS
  const {
    formSelections,
    dispatchFormSelections,
    columnDetails,
    currentTable,
    isLastRow,
    currentColumnPair,
  } = props;

  // # HANDLERS
  const handleChange = (e, elementType, formKey, formValue) => {
    const action = {
      type: C.UPDATE_RULES_IT_AND_BUSINESS_FORM_FIELD,
      columnPair: currentColumnPair,
      name: props.name,
      formKey: formKey,
      formValue: formValue,
    };
    dispatchFormSelections(action);
  };

  // # STATIC VARIABLES
  const classes = useStyles();
  const allReduxData = useSelector(selectAllReduxData);
  const theme = useTheme();
  const inlineDynamicStyle = {
    border: "2px solid transparent",
    borderRadius: "4px",
  };

  // # JSX
  return (
    <>
      <Box className={classes.rulesRowRootContainer} style={inlineDynamicStyle}>
        <Box className={classes.rulesAllRow}>
          {props.elementData.map((ruleDataRow) => {
            const OLD_PROPS_FOR_CUSTOM_MULTIPLE_RULES = {
              columnName: columnDetails.columnName,
              id: 1,
              name: props.name,
              onChange: (...args) => {
                const [id, value] = args;
                handleChange(null, null, "value1", value.value.value1);
              },
              record: formSelections.value?.["value1"] || undefined,
              stateData: allReduxData,
            };
            const OLD_PROPS_FOR_CUSTOM_FIELD_COMPARE_RULE = {
              columnName: columnDetails.columnName,
              id: 1,
              name: props.name,
              onChange: (value) => {
                handleChange(null, null, "value1", value);
              },
              selections: formSelections.value?.["value1"] || [],
              stateData: allReduxData,
            };
            const OLD_PROPS_FOR_CUSTOM_IS_MAPPED_RULE = {
              columnName: columnDetails.columnName,
              id: 1,
              name: props.name,
              onChange: (value) => {
                handleChange(null, null, "value1", value);
              },
              selections: formSelections.value?.["value1"] || [],
              stateData: allReduxData,
            };
            const OLD_PROPS_FOR_CUSTOM_ISEXISTING_COND = {
              id: 1,
              onChange: (value) => {
                handleChange(null, null, "value1", value);
              },
              selections: formSelections.value?.["value1"] || [],
              stateData: allReduxData,
              currentTable: currentTable,
            };
            const OLD_PROPS_FOR_CUSTOM_CHECK_STRING_SAP_WITH_CONDITION = {
              columnName: columnDetails.columnName,
              id: 1,
              name: props.name,
              onChange: (value) => {
                handleChange(null, null, "value1", value);
              },
              selections: formSelections.value?.["value1"] || [],
              stateData: allReduxData,
            };
            const OLD_PROPS_FOR_CUSTOM_FIELD_COMPARE_WITH_CONDITION_SAP = {
              columnName: columnDetails.columnName,
              id: 1,
              name: props.name,
              onChange: (value) => {
                handleChange(null, null, "value1", value);
              },
              selections: formSelections.value?.["value1"] || [],
              stateData: allReduxData,
            };

            return (
              <Box
                className={`${classes.ruleRowItem} ${ruleDataRow.type}`}
                key={ruleDataRow.formKey}
              >
                {ruleDataRow.type === "checkbox" &&
                  ruleDataRow.isVisibleOnUi && (
                    <Checkbox
                      checked={
                        formSelections.value?.[ruleDataRow.formKey]
                          ? formSelections.value?.[ruleDataRow.formKey]
                          : false
                      }
                      value={ruleDataRow.formKey}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      disabled={ruleDataRow.disabled}
                      variant="secondary"
                      size="small"
                      onClick={(e) =>
                        handleChange(
                          e,
                          ruleDataRow.type,
                          ruleDataRow.formKey,
                          e.target.checked
                        )
                      }
                    />
                  )}
                {ruleDataRow.type === "typography" && (
                  <Typography>{ruleDataRow.formLabel}</Typography>
                )}
                {ruleDataRow.type === "select_single" && (
                  <Select
                    value={
                      formSelections.value?.[ruleDataRow.formKey]
                        ? formSelections.value?.[ruleDataRow.formKey]
                        : ""
                    }
                    title={formSelections.value?.[ruleDataRow.formKey]}
                    displayEmpty
                    inputProps={{ "aria-label": "Secondary Color" }}
                    color="secondary"
                    onChange={(e) => {
                      handleChange(
                        e,
                        ruleDataRow.type,
                        ruleDataRow.formKey,
                        e.target.value
                      );
                    }}
                    MenuProps={config.hardCoded.MenuProps}
                    renderValue={(selected) => {
                      if (["", undefined, null].includes(selected)) {
                        return ruleDataRow?.placeholder || <p>Select option</p>;
                      }
                      let label = ruleDataRow.metadata.options.find(
                        (row) => row.id === selected
                      )?.name;
                      return label;
                    }}
                  >
                    {ruleDataRow.metadata.options.length ? (
                      ruleDataRow.metadata.options.map((option) => {
                        return (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            title={option.name}
                          >
                            {option.name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem value="" disabled>
                        No Options
                      </MenuItem>
                    )}
                  </Select>
                )}

                {ruleDataRow.type === "input_text" && (
                  <TextField
                    id="standard-required"
                    value={
                      formSelections.value?.[ruleDataRow.formKey]
                        ? formSelections.value?.[ruleDataRow.formKey]
                        : ""
                    }
                    placeholder={ruleDataRow.metadata.placeholder}
                    onChange={(e) =>
                      handleChange(
                        e,
                        ruleDataRow.type,
                        ruleDataRow.formKey,
                        e.target.value
                      )
                    }
                  />
                )}
                {ruleDataRow.type === "input_number" && (
                  <TextField
                    id="standard-required"
                    type="number"
                    value={
                      formSelections.value?.[ruleDataRow.formKey]
                        ? formSelections.value?.[ruleDataRow.formKey]
                        : ""
                    }
                    onChange={(e) =>
                      handleChange(
                        e,
                        ruleDataRow.type,
                        ruleDataRow.formKey,
                        e.target.value
                      )
                    }
                  />
                )}
                {ruleDataRow.type === "input_tags" && (
                  <ReactTags
                    tags={
                      Array.isArray(formSelections.value?.[ruleDataRow.formKey])
                        ? formSelections.value?.[ruleDataRow.formKey].map(
                            (val) => ({ id: val, text: val })
                          )
                        : []
                    }
                    handleDelete={(i) => {
                      let tags = Array.isArray(
                        formSelections.value?.[ruleDataRow.formKey]
                      )
                        ? formSelections.value?.[ruleDataRow.formKey]
                        : [];

                      handleChange(
                        null,
                        ruleDataRow.type,
                        ruleDataRow.formKey,
                        tags.filter((tag, index) => index !== i)
                      );
                    }}
                    handleAddition={(tag) => {
                      let tags = Array.isArray(
                        formSelections.value?.[ruleDataRow.formKey]
                      )
                        ? formSelections.value?.[ruleDataRow.formKey]
                        : [];
                      handleChange(
                        null,
                        ruleDataRow.type,
                        ruleDataRow.formKey,
                        [...tags, tag.text]
                      );
                    }}
                    allowDragDrop={false}
                    placeholder={ruleDataRow.metadata.placeholder}
                    autofocus={false}
                    handleInputBlur={(value) => {
                      let tags = Array.isArray(
                        formSelections.value?.[ruleDataRow.formKey]
                      )
                        ? formSelections.value?.[ruleDataRow.formKey]
                        : [];
                      if (value !== "") {
                        handleChange(
                          null,
                          ruleDataRow.type,
                          ruleDataRow.formKey,
                          [...tags, value]
                        );
                      }
                    }}
                  />
                )}
                {ruleDataRow.type === "custom_multiple_rules" && (
                  <MultipleRules {...OLD_PROPS_FOR_CUSTOM_MULTIPLE_RULES} />
                )}
                {ruleDataRow.type === "custom_is_not_existing_rule" && (
                  <IsNotExistingRule {...OLD_PROPS_FOR_CUSTOM_MULTIPLE_RULES} />
                )}
                {ruleDataRow.type === "custom_field_compare_rule" && (
                  <FieldCompareComponent
                    {...OLD_PROPS_FOR_CUSTOM_FIELD_COMPARE_RULE}
                  />
                )}
                {ruleDataRow.type === "custom_is_mapped_rule" && (
                  <IsMappedRuleComponent
                    {...OLD_PROPS_FOR_CUSTOM_IS_MAPPED_RULE}
                  />
                )}
                {ruleDataRow.type === "custom_isExisting_Cond" && (
                  <IsExistingRule {...OLD_PROPS_FOR_CUSTOM_ISEXISTING_COND} />
                )}
                {ruleDataRow.type ===
                  "custom_checkstring_sap_with_condition" && (
                  <CustomCheckStringSAPWithCondition
                    {...OLD_PROPS_FOR_CUSTOM_CHECK_STRING_SAP_WITH_CONDITION}
                  />
                )}
                {ruleDataRow.type === "custom_is_existing_cond_advanced" && (
                  <CustomIsExistingCondAdvanced
                    selections={formSelections.value?.["value1"]}
                    onChange={(value) => {
                      handleChange(null, null, "value1", value);
                    }}
                  />
                )}
                {ruleDataRow.type ===
                  "custom_field_compare_with_condition_sap" && (
                  <CustomFieldCompareWithConditionSAP
                    {...OLD_PROPS_FOR_CUSTOM_FIELD_COMPARE_WITH_CONDITION_SAP}
                  />
                )}
                {ruleDataRow.type ===
                  "custom_mathematical_operations_with_column" && (
                  <CustomMathematicalOperationsWithColumn
                    columnName={columnDetails.columnName}
                    selections={formSelections.value?.["value1"]}
                    onChange={(value) => {
                      handleChange(null, null, "value1", value);
                    }}
                  />
                )}
                {ruleDataRow.type ===
                  "custom_field_compare_with_mathematical_operations" && (
                  <CustomFieldCompareWithMathematicalOperations
                    columnName={columnDetails.columnName}
                    selections={formSelections.value?.["value1"]}
                    onChange={(value) => {
                      handleChange(null, null, "value1", value);
                    }}
                  />
                )}
                {ruleDataRow.type === "custom_checkstring_with_condition" && (
                  <CustomCheckStringWithCondition
                    columnName={columnDetails.columnName}
                    selections={formSelections.value?.["value1"]}
                    onChange={(value) => {
                      handleChange(null, null, "value1", value);
                    }}
                  />
                )}
                {ruleDataRow.type === "custom_field_compare_with_condition" && (
                  <CustomFieldCompareWithCondition
                    columnName={columnDetails.columnName}
                    selections={formSelections.value?.["value1"]}
                    onChange={(value) => {
                      handleChange(null, null, "value1", value);
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      {!isLastRow && <hr style={{ width: "100%", borderColor: "#ffffff40" }} />}
    </>
  );
}

// # COMPONENT
function ItAndBusinessRulesCompareDataset(props = {}) {
  const {
    rulesItAndBusinessSelections: formSelections,
    dispatchRulesItAndBusinessSelections: dispatchFormSelections,
    allRulesData,
    columnDetails,
    currentTable,
    searchText,
    handleSearchTextChange,
    columnsPair,
    columnsPairSelected,
    handleColumnsPairSelectedChange,
  } = props;

  // # STATIC VARIABLES
  let filteredAllRulesData = allRulesData.filter(
    (row) =>
      row.searchText
        .toLowerCase()
        .includes(searchText.searchText.toLowerCase()) ||
      row.name.toLowerCase().includes(searchText.searchText.toLowerCase())
  );

  const groupedRules = groupBy(filteredAllRulesData, (item) => item.groupId);
  let groupedRulesMod = Object.entries(groupedRules).map(
    ([groupId, groupedData]) => ({
      id: groupId,
      name: findGroupNameFromGroupId(groupId, masterGroupData),
      data: groupedData,
    })
  );
  const classes = useStyles();
  const theme = useTheme();

  // # JSX
  return (
    <div>
      <Box className={classes.rulesMenuWrapper}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography style={{ marginLeft: 5 }}>Select Column Pair</Typography>
          <Select
            style={{ width: "300px" }}
            value={columnsPairSelected}
            onChange={(e) => {
              handleColumnsPairSelectedChange(e.target.value);
            }}
          >
            {columnsPair.map((e) => {
              return (
                <MenuItem
                  key={e}
                  value={e}
                >{`Source : ${e[0]} - Destination : ${e[1]}`}</MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box style={{ width: "300px" }}>
          <SearchTableSelection
            criteria={searchText}
            changeCriteria={handleSearchTextChange}
            placeholderText="Search rule"
            width={"100%"}
            className="rules-search"
          />
        </Box>
      </Box>

      {groupedRulesMod.length === 0 && (
        <Box className={classes.noDataWrapper}>
          <img src={noDataImage} alt="noDataImage" width="200" />
          <p
            className="no-data"
            style={{ color: theme.palette.text.primary, fontSize: "14px" }}
          >
            No matching rules
          </p>
        </Box>
      )}

      {groupedRulesMod.length > 0 &&
        groupedRulesMod.map((group) => {
          return (
            <Accordion
              key={group.id}
              defaultExpanded={true}
              className={classes.accordionMargin}
            >
              <AccordionSummary
                expandIcon={
                  <i className={`material-icons-outlined`}>expand_more</i>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{group.name} Checks </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.groupsRoot}>
                  <Box className={classes.groupsRootText}>
                    <Box className={classes.groupsRootTextLeft}></Box>
                    <Box className={classes.groupsRootTextRight}></Box>
                  </Box>
                  {group.data.map((ruleRow, index) => {
                    return (
                      <RulesRow
                        key={ruleRow.name}
                        groupId={group.id}
                        {...ruleRow}
                        formSelections={
                          formSelections[columnsPairSelected][ruleRow.name]
                        }
                        currentColumnPair={columnsPairSelected}
                        dispatchFormSelections={dispatchFormSelections}
                        columnDetails={columnDetails}
                        currentTable={currentTable}
                        isLastRow={index === group.data.length - 1}
                      />
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
}

// # EXPORTS
export default ItAndBusinessRulesCompareDataset;
