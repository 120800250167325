// Import required libraries
import React from "react";
import { Route } from "react-router-dom";

//Import custom components

// Import utils

function ProtectedRoute(props) {
  const { path, component: Component } = props;

  return (
    <Route
      path={path}
      render={(ownProps) => (
        <Component
          location={props.location}
          match={ownProps.match}
        />
      )}
    />
  );

  // return user.isAuth ? (
  //   <Route
  //     path={path}
  //     render={(ownProps) => (
  //       <Component
  //         user={props.user}
  //         location={props.location}
  //         match={ownProps.match}
  //       />
  //     )}
  //   />
  // ) : (
  //   <Loader
  //     fullPage={true}
  //     message={config.messages.authenticatingUserMessage}
  //   />
  // );
}

export default ProtectedRoute;
