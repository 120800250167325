// Import required libraies

import {
  FormControlLabel,
  FormGroup,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  getAllRulesData,
  getCatalogsColumns,
  getCompareDatasetCriteria,
  postCompareDataset,
  updateTable,
} from "../../../services/apis";
import {
  resetUserInputs,
  updateAlertInfo,
  updateChangeFlagUiRules,
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import DQButton from "../../../components/Common/DQButton";
import FilterRun from "./FilterRun";
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import { Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import RulesModal from "./RulesModal";
import { connect } from "react-redux";
import { initialApiData } from "../../../utils/utils";
import { makeStyles } from "@material-ui/core/styles";
import { modifyAllRulesDataBasedOnColumns } from "../../../components/RulesSelection/schema";
import { union } from "lodash";

// # COMPONENT
function SelectCriteria(props) {
  const { user, data, updateData, match, updateAlertInfo, userInputs } = props;

  // # HOOKS
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [compareDatasetState] = useState(userInputs.compareDataset);
  const [compareDatasetColumnsState] = useState(
    userInputs.compareDatasetColumns
  );
  const [compareDatasetCriteriaState] = useState(
    userInputs.compareDatasetCriteria
  );

  const [criteriaData, setCriteriaData] = useState(initialApiData);
  const [rulesSuccess, setRulesSuccess] = useState(initialApiData);
  const [checked, setChecked] = React.useState(
    compareDatasetCriteriaState.selectedCriteria
  );

  const [customCriteria, setCustomCriteria] = React.useState([]);
  ///////////
  const [open, setOpen] = useState({
    columnName: "",
    columnDatatype: "",
    isOpened: false,
  });

  const [allRulesData, setAllRulesData] = useState([]);

  const [rulesItAndBusinessSelections, setRulesItAndBusinessSelections] =
    useState([]);

  const [columnsPair, setColumnsPair] = useState(
    Object.entries(compareDatasetColumnsState.map).filter(([k, v]) => {
      return v != "";
    })
  );
  const [columnsPairSelected, setColumnsPairSelected] = useState(
    columnsPair[0]
  );

  const [sourceColumnsData, setSourceColumnsData] = useState([]);
  const [destinationColumnsData, setDestinationColumnsData] = useState([]);

  const [filterRunOpt, setFilterRunOpt] = useState({
    source: false,
    destination: false,
  });

  const [filterRunSource, setFilterRunSource] = useState([]);
  const [filterRunDestination, setFilterRunDestination] = useState([]);

  const handleToggle = (value) => () => {
    if (value.type === "custom") {
      setOpen({ ...open, isOpened: true });
    }

    const currentIndex = checked.findIndex((e) => e.name === value.name);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      if (value.type !== "custom") {
        newChecked.splice(currentIndex, 1);
      }
    }

    dispatch(
      updateUserInputs("compareDatasetCriteria", {
        selectedCriteria: newChecked,
      })
    );

    setChecked(newChecked);
  };

  const fetchCriteriaData = async () => {
    let data = await getCompareDatasetCriteria();
    if (data && data?.length > 0) {
      setCriteriaData({
        status: "success",
        message: "",
        result: data,
      });
    } else {
      setCriteriaData({
        status: "error",
        message: "No result found! Please try again Later!",
        result: [],
      });
    }
  };

  const fetchAllRulesData = async () => {
    setRulesSuccess({ status: "loading", message: "" });
    let allRulesData = await getAllRulesData();

    let rulesObj = {};
    allRulesData
      .map((c) => c.name)
      .forEach((c) => {
        rulesObj[c] = {};
      });
    var d = Object.entries(compareDatasetColumnsState.map).filter(([k, v]) => {
      return v != "";
    });

    let selections = {};
    d.forEach((element) => {
      selections[element] = rulesObj;
    });

    var c = modifyAllRulesDataBasedOnColumns(
      allRulesData.map((r) => ({ ...r, searchText: "" })),
      data
    );
    setAllRulesData(c);
    setRulesItAndBusinessSelections(selections);
    setRulesSuccess({ status: "success", message: "" });
  };

  const fetchColumnsData = async (db) => {
    dispatch(
      updateData("datasetTablesColumns", {
        status: "loading",
        message: "",
        result: [],
      })
    );
    let data = await getCatalogsColumns({
      cluster: db.source.cluster,
      catalog: db.source.catalog,
      schema_name: db.source.schema_name,
      table_name: db.source.table_name,
    });
    let data2 = await getCatalogsColumns({
      cluster: db.destination.cluster,
      catalog: db.destination.catalog,
      schema_name: db.destination.schema_name,
      table_name: db.destination.table_name,
    });

    if ((data && data?.length > 0) || (data2 && data2?.length > 0)) {
      setSourceColumnsData(data);
      setDestinationColumnsData(data2);
      dispatch(
        updateData("datasetTablesColumns", {
          status: "success",
          message: "",
          result: union(
            data.map((c) => ({ name: c.name, id: c.name })),
            data2.map((c) => ({ name: c.name, id: c.name }))
          ),
        })
      );
    } else {
      dispatch(
        updateData("datasetTablesColumns", {
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        })
      );
    }
  };

  useEffect(() => {
    if (!compareDatasetState.reportName) {
      history.push("/home/comparedatasets");
      return;
    }
    fetchCriteriaData();
    fetchColumnsData({
      source: {
        cluster: compareDatasetState.selectedCluster.cluster,
        catalog: compareDatasetState.selectedSourceCatalog,
        schema_name: compareDatasetState.selectedSourceDatabase,
        table_name: compareDatasetState.selectedSourceTable.name,
      },
      destination: {
        cluster: compareDatasetState.selectedCluster.cluster,
        catalog: compareDatasetState.selectedDestinationCatalog,
        schema_name: compareDatasetState.selectedDestinationDatabase,
        table_name: compareDatasetState.selectedDestinationTable.name,
      },
    });
  }, []);

  useEffect(() => {
    fetchAllRulesData();
  }, [data.datasetTablesColumns]);

  const handleSave = async () => {
    if (compareDatasetState && compareDatasetColumnsState) {
      let finalObj = {
        report_name: compareDatasetState.reportName,
        team: compareDatasetState.selectedCluster.cluster,
        source: {
          filter: filterRunSource.filter(
            (c) => c.columnName != "" && c.condition != ""
          ),
          catalog: compareDatasetState.selectedSourceCatalog,
          database: compareDatasetState.selectedSourceDatabase,
          table: compareDatasetState.selectedSourceTable.name,
          path:
            compareDatasetState.sourceFileUpload === "landingLayer"
              ? compareDatasetState.sourceLandingLayerFile
              : undefined,
          columns: Object.entries(compareDatasetColumnsState.map)
            .filter(([k, v]) => {
              return v != "";
            })
            .map(([k, v]) => k),
        },
        destination: {
          filter: filterRunDestination.filter(
            (c) => c.columnName != "" && c.condition != ""
          ),
          catalog: compareDatasetState.selectedDestinationCatalog,
          database: compareDatasetState.selectedDestinationDatabase,
          table: compareDatasetState.selectedDestinationTable.name,
          path:
            compareDatasetState.destinationFileUpload === "landingLayer"
              ? compareDatasetState.destinationLandingLayerFile
              : undefined,
          columns: Object.entries(compareDatasetColumnsState.map)
            .filter(([k, v]) => {
              return v != "";
            })
            .map(([k, v]) => v),
        },
        result: union(
          checked
            .filter((c) => c.type !== "custom")
            .map((c) => {
              return {
                criteria: c.function,
                result: [],
              };
            }),
          customCriteria
        ),
      };

      let responseData = await postCompareDataset(finalObj);

      if ("id" in responseData) {
        dispatch(
          updateAlertInfo({
            open: true,
            message: `New compare dataset request: ${responseData.report_name} done`,
            severity: "success",
          })
        );
        history.push("/home/comparedatasets");
      } else {
        dispatch(
          updateAlertInfo({
            open: true,
            message: "Something went wrong...",
            severity: "error",
          })
        );
      }
    }
  };

  const handleClose = async () => {
    setOpen({
      ...open,
      isOpened: false,
    });
    var c = checked.filter((c) => c.type !== "custom");
    setChecked(c);
    setColumnsPairSelected(columnsPair[0]);
    await fetchAllRulesData();
  };

  const handleApply = () => {
    var c = rulesItAndBusinessSelections;

    var b = Object.entries(rulesItAndBusinessSelections).filter((c) =>
      Object.values(c[1]).some((c) => Object.keys(c).length > 0)
    );

    var final = [];

    b.forEach((e) => {
      let columns = e[0].split(",");

      let criteriaAndInput = Object.entries(e[1]).filter(
        (c) => JSON.stringify(c[1]) != JSON.stringify({})
      );

      criteriaAndInput.forEach((c) => {
        if (c[1]?.value?.isEnabled) {
          final.push({
            criteria: c[0],
            source: columns[0],
            destination: columns[1],
            input: c[1],
            result: [],
          });
        }
      });
    });

    setCustomCriteria(final);
    setOpen({
      ...open,
      isOpened: false,
    });
  };

  const handleChanges = (a) => {
    setRulesItAndBusinessSelections({
      ...rulesItAndBusinessSelections,
      [a.columnPair]: {
        ...rulesItAndBusinessSelections[a.columnPair],
        [a.name]: {
          ...rulesItAndBusinessSelections[a.name],
          value: {
            ...rulesItAndBusinessSelections[a.columnPair][a.name].value,
            [a.formKey]: a.formValue,
          },
        },
      },
    });
  };

  const handleSetFilterRunOptChange = useCallback(
    (e, direction) => {
      if (direction === "source" && !e.target.checked) {
        setFilterRunSource([]);
      } else if (direction === "destination" && !e.target.checked) {
        setFilterRunDestination([]);
      }

      setFilterRunOpt({ ...filterRunOpt, [direction]: e.target.checked });
    },
    [filterRunOpt]
  );

  return (
    <LayoutTopSideBottom match={match}>
      <Breadcrumbs match={match} />
      <>
        <h1
          style={{
            textAlign: "center",
            fontWeight: 600,
            textTransform: "uppercase",
            color: "#46596a",
            marginBottom: 0,
          }}
        >
          Select Criteria
        </h1>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",

            mt: 4,
          }}
        >
          {(criteriaData.status === "loading" ||
            rulesSuccess.status === "loading") && (
            <Box
              sx={{
                display: "flex",
                height: 300,
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Loader />
            </Box>
          )}
          {criteriaData.status === "success" &&
            rulesSuccess.status === "success" &&
            criteriaData.result.map((c) => {
              const labelId = `checkbox-list-label-${c.name}`;

              return (
                <Box
                  sx={{
                    height: 100,
                    width: 300,
                    backgroundColor: "#f3f3f3",
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                    cursor: "pointer",
                  }}
                  className={
                    checked.findIndex((e) => e.name === c.name) !== -1
                      ? classes.selectedCriteria
                      : null
                  }
                  key={labelId}
                  onClick={handleToggle(c)}
                >
                  <span
                    style={{
                      color: "#46596a",
                      alignSelf: "center",
                      userSelect: "none",
                      fontWeight: 500,
                    }}
                  >
                    {c.name}
                  </span>
                </Box>
              );
            })}
        </Box>

        <Box>
          <h3
            style={{
              marginTop: 20,
              textAlign: "start",
              fontWeight: 600,
              textTransform: "uppercase",
              color: "#46596a",
              marginBottom: 0,
            }}
          >
            Filter Run?
          </h3>
          <FormGroup sx={{ flexDirection: "row", marginLeft: 2 }}>
            <FormControlLabel
              sx={{ color: "#46596a" }}
              control={
                <Switch
                  className={classes.switchDefault}
                  checked={filterRunOpt.source}
                  onChange={(e) => handleSetFilterRunOptChange(e, "source")}
                />
              }
              label="In Source"
            />
            <FormControlLabel
              sx={{ color: "#46596a" }}
              required
              control={
                <Switch
                  className={classes.switchDefault}
                  checked={filterRunOpt.destination}
                  onChange={(e) =>
                    handleSetFilterRunOptChange(e, "destination")
                  }
                />
              }
              label="In Destination"
            />
          </FormGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            marginTop: 10,
          }}
        >
          {filterRunOpt.source && (
            <FilterRun
              title={"Source"}
              columns={sourceColumnsData}
              onChange={(e) => {
                setFilterRunSource(e);
              }}
            />
          )}

          {filterRunOpt.destination && (
            <Box sx={{ marginLeft: filterRunOpt.source ? 10 : 0 }}>
              <FilterRun
                title={"Destination"}
                columns={destinationColumnsData}
                onChange={(e) => {
                  setFilterRunDestination(e);
                }}
              />
            </Box>
          )}
        </Box>

        <Modal
          open={open.isOpened}
          onClose={handleClose}
          className={classes.rulesModal}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box className={classes.rulesSelectionWrapper}>
              <RulesModal
                columnName={open.columnName}
                columnDatatype={open.columnDatatype}
                parentClasses={classes}
                rulesItAndBusinessSelections={rulesItAndBusinessSelections}
                dispatchRulesItAndBusinessSelections={handleChanges}
                allRulesData={allRulesData}
                columnsPair={columnsPair}
                columnsPairSelected={columnsPairSelected}
                handleColumnsPairSelectedChange={setColumnsPairSelected}
              />
              <Box className={classes.rulesModalFooter}>
                <>
                  <DQButton
                    title="Clear and Cancel"
                    disabled={false}
                    variant="outlined"
                    onclick={handleClose}
                  />
                  <DQButton
                    title="Apply"
                    variant="contained"
                    onclick={handleApply}
                  />
                </>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Box
          sx={{
            display: "flex",
            position: "fixed",
            bottom: 40,
            right: 0,
            mr: 2,
          }}
        >
          <Link
            className="disabled-style"
            to={"/home/comparedatasets/onboarding/"}
          >
            <DQButton title="Back" disabled={false} variant="outlined" />
          </Link>
          <Link className="disabled-style" to={"#"} onClick={handleSave}>
            <DQButton title="Save" disabled={false} variant="contained" />
          </Link>
        </Box>
      </>
    </LayoutTopSideBottom>
  );
}

SelectCriteria.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateData,
  updateUserInputs,
  updateSelections,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCriteria);

export const useStyles = makeStyles((theme) => ({
  switchDefault: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#4EAFB3",
      "&:hover": {
        backgroundColor: alpha("#4EAFB3", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#4EAFB3",
    },
  },
  root: {
    width: "100%",
    flexGrow: 1,
  },
  rightAlign: {
    marginLeft: "auto",
  },
  selectedCriteria: {
    border: "3px solid",
    borderColor: "#4EAFB3",
    margin: 7,
  },
  drawerRoot: {
    display: "flex",
  },
  drawer: {
    width: "70vw",
    flexShrink: 0,
    display: "flex",
    alignSelf: "center",
    height: "60vh",
    alignItems: "center",
    marginTop: "15%",
  },
  drawerPaper: {
    width: "50vw",
  },
  rulesModalHeader: {
    height: "50px",
    display: "flex",
    width: "100%",
    padding: "0px 20px",
  },
  rulesModalBody: {
    height: "calc(100% - 100px)",
    width: "100%",
    padding: "10px 0px",
    boxSizing: "border-box",
    overflow: "auto",
  },
  rulesModalFooter: {
    // position: "relative",
    // bottom: 0,
    // textAlign: "center",
    // paddingBottom: 10,
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      fontFamily: "Hind Siliguri",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      color: "#46596a",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#EB367F",
      color: "#ffffff",
    },
  },
  paginationSelect: {
    paddingLeft: "10px",
    paddingRight: "10px",
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "23px",
    color: "#ffffff",
    backgroundColor: "#46596A",
    borderRadius: "5px",
    "& .MuiSvgIcon-root": {
      color: "#ffffff",
    },
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    minWidth: "100px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    borderBottom: "1px solid #E6E8ED",
    "&:last-child th": {
      borderBottom: 0,
    },
    backgroundColor: "white !important",
    "&:hover": {
      backgroundColor: "#4eafb342 !important",
    },
  },
  gridRoot: {
    flexGrow: 1,
    cursor: "default !important",
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
    marginTop: "8px",
    marginBottom: "6px",
  },
  ratingBox: {
    width: "10px",
    height: "14px",
    marginRight: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rating: {
    height: "100%",
  },
  empty: {
    height: "100%",
    backgroundColor: "#ECECEC",
  },
  fontStyle: {
    paddingLeft: "10px",
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "19px",
    color: "#465A69",
    background: "#fff",
  },
  rafifyBtn: {
    border: "1.3px solid #4EAFB3",
    boxSizing: "border-box",
    borderRadius: "23px",
    color: "#4EAFB3",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "16px",
    textTransform: "none",
    // backgroundColor: 'transparent',
    "&:hover": {
      color: "#fff",
      backgroundColor: "#4EAFB3",
    },
  },
  reportBtn: {
    color: "#fff",
    border: "1px solid #4eafb3",
    background: "#4eafb3",
    fontSize: "12px",
    height: "24px",
    padding: "0px 10px",
    fontFamily: "Hind Siliguri",
    textTransform: "none",
    "&:hover": {
      background: "#4eafb3",
      color: "#ffffff",
    },
    "&.Mui-disabled": {
      color: "#ffffff",
      opacity: 0.7,
    },
  },
  cancelBtn: {
    color: "#4eafb3",
    border: "1px solid #4eafb3",
    fontSize: "12px",
    height: "24px",
    padding: "0px 10px",
    fontFamily: "Hind Siliguri",
    textTransform: "none",
    marginRight: "10px",
  },
  container: {
    minWidth: "400px",
    maxWidth: "600px",
    padding: "10px 0",
  },
  textWraping: {
    whiteSpace: "nowrap",
  },
  textAlignment: {
    textAlign: "center",
  },
  tableSubHead: {
    borderBottom: "none",
    fontFamily: "Hind Siliguri",
    backgroundColor: "#E6E8ED",
    color: "rgba(70, 89, 106, 1)",
    fontWeight: 600,
    fontSize: "11px",
    textTransform: "capitalize",
    marginTop: "2px",
  },
  tablecell: {
    padding: "5px 12px",
  },
  displayNone: {
    opacity: 0,
  },
  rulesModal: {},
  rulesSelectionWrapper: {
    backgroundColor: "#fafafa",
    borderRadius: "4px",
    width: "45%",
    minWidth: "600px",
    height: "80%",
    boxSizing: "border-box",
    padding: "10px 10px 5px 10px",
  },
}));
