// Import required libraies

import "./TeamsTab.scss";

import React, { useEffect, useRef, useState } from "react";
import {
  deleteAdminPanelTeams,
  getAdminPanelTeamsList,
  postAdminPanelTeams,
} from "../../../services/apis";
import {
  filterColumnsForSelectionsAdminPanelTeams,
  initialApiData,
} from "../../../utils/utils";
import {
  updateAlertInfo,
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";

import { Box } from "@material-ui/core";
import DQButton from "../../../components/Common/DQButton";
import DQInput from "./../../../components/Common/DQInput";
import PropTypes from "prop-types";
import TeamsListTable from "./TeamsListTable/TeamsListTable";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useMsal } from "@azure/msal-react";

// Import Custom Component

const TeamsTab = (props) => {
  const { user, data, updateData, match, updateAlertInfo } = props;
  const history = useHistory();
  const { accounts } = useMsal();

  const dispatch = useDispatch();

  const datasetsObj = data.adminPanelTeamList.result;
  const [filteredData, setFilteredData] = useState(datasetsObj || []);
  const [newTeam, setNewTeam] = useState("");

  const fetchUsersList = async () => {
    updateData("adminPanelTeamList", {
      status: "loading",
      result: [],
      message: "",
    });
    let usersList = await getAdminPanelTeamsList();
    if (usersList && usersList.length > 0) {
      updateData("adminPanelTeamList", {
        status: "success",
        result: usersList,
        // status: "loading",
        // result: [],
        message: "",
      });
    } else {
      updateData("adminPanelTeamList", {
        status: "error",
        result: [],
        message: "Could not load Users! Please try again later!",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUsersList();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const updatedData = filterColumnsForSelectionsAdminPanelTeams(
      data.adminPanelTeamList.result,
      user.selections
    );
    setFilteredData(updatedData);

    // eslint-disable-next-line
  }, [user.selections, data.adminPanelTeamList]);

  const handleSubmit = async (value) => {
    if (
      data.adminPanelTeamList.result.some(
        (i) => i.name.toLowerCase() === value.toLowerCase()
      )
    ) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: "Team with same name already exists.",
          severity: "success",
        })
      );
      return;
    }

    let reqObj = {
      name: value,
      owner: accounts[0]?.username,
    };

    let responseData = await postAdminPanelTeams(reqObj);

    if ("id" in responseData) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: `New Team Added: ${responseData.name}`,
          severity: "success",
        })
      );
    } else {
      dispatch(
        updateAlertInfo({
          open: true,
          message: "Something went wrong...",
          severity: "error",
        })
      );
    }
    setNewTeam("");
    await fetchUsersList();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          mt: 3,
        }}
      >
        <DQInput
          value={newTeam}
          handleChange={async (value) => {
            setNewTeam(value);
          }}
          placeholder="Add New Team"
          isDisabled={false}
          onEnter={async (e, value) => {
            setNewTeam(value);
            await handleSubmit(value);
          }}
        />

        <DQButton
          title="Add"
          disabled={false}
          variant="contained"
          styles={{ height: 44, minWidth: "0", marginLeft: 10 }}
          onclick={async () => {
            if (isEmpty(newTeam)) {
              dispatch(
                updateAlertInfo({
                  open: true,
                  message: "Write a team name first",
                  severity: "info",
                })
              );
              return;
            }
            await handleSubmit(newTeam);
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3,
          flexDirection: "column",
        }}
      >
        <label className="amt-label">List of all teams:</label>

        <TeamsListTable
          data={filteredData}
          allData={data.adminPanelTeamList.result}
          paginationFlag={true}
          loading={data.adminPanelTeamList.status === "loading"}
          refreshList={fetchUsersList}
        />
      </Box>
    </>
  );
};

TeamsTab.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateUserInputs,
  updateSelections,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsTab);
