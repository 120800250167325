import React from "react";

// Import Custom Component
import DQAppContainer from "../../DQAppContainer/DQAppContainer";
import ExploreDatasetsSearch from "./ExploreDatasetsSearch";

// # COMPONENT
const ExploreDatasets = (props) => {
  const { match } = props;

  // # JSX
  return (
    <DQAppContainer match={match}>
      <ExploreDatasetsSearch match={match} />
    </DQAppContainer>
  );
};

export default ExploreDatasets;
