// Import required libraies

import "./Login.scss";

import React, { useEffect } from "react";

import LayoutNone from "../../layouts/LayoutNone/LayoutNone";
import config from "../../config/config";
import loginImage4 from "../../../assets/images/undraw_reviews.svg";
import { loginRequest } from "../../../authConfig";
import logo from "../../../assets/images/rb-logo.png";
import { updateAlertInfo } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

// Import Custom Component

// Import Context

// Import utils/data

// Import data

//Import styles

function Login() {
  const { instance } = useMsal();
  const history = useHistory();
  const dispatch = useDispatch();

  // to take care a scenario, if user is trying to access /login route, after auth it will redirected back to /login page
  // we are checking if user is already authenticated then from login page redirect them to landing page
  useEffect(() => {
    if (localStorage.getItem("secret")) {
      setTimeout(
        () =>
          dispatch(
            updateAlertInfo({
              open: true,
              message: config.messages.UnauthorisedMessageUser,
              severity: "info",
            })
          ),
        1000
      );
      history.push(`/home/mydataset`);
    }
    // eslint-disable-next-line
  }, []);

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <LayoutNone>
      <div className="logo-container">
        <img className="rb-logo" src={logo} alt="RB Logo" />
      </div>
      <div className="login-container">
        <div className="login-container-left">
          <div className="login-carousel-container">
            <div>
              <img
                className="carousel-image"
                src={loginImage4}
                alt="loginImage4"
              />
            </div>
            <div className="carousel-texts-container">
              <p className="reckitt-text">TRIQ</p>
              <p className="carousel-primary-text">
                {config.messages.loginPrimaryText}
              </p>
              <p className="carousel-secondary-text-1">
                {config.messages.loginSecondaryText_1}
              </p>
              <p className="carousel-secondary-text-2">
                {config.messages.loginSecondaryText_2}
              </p>
            </div>
          </div>
        </div>
        <div className="login-container-right">
          <div className="login-page-sign-in-container">
            <div className="login-welcome-container">
              <p className="login-welcome-text">Hi, Welcome back</p>
              <p className="login-info-text">
                Sign-in with your Reckitt credentials to experience the portal
              </p>
            </div>
            <div className="sign-in-button-container">
              {/* <a href={config.api.signInUrl}> */}
              <button
                type="button"
                className="sign-in-button"
                onClick={handleLogin}
              >
                Sign In
              </button>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </LayoutNone>
  );
}

Login.propTypes = {};

export default Login;
