import C from "../constants";

import rulesItAndBusinessReducer from "./rulesItAndBusiness";

export default function userInputs(state = {}, payload) {
  let { type, key, data } = payload;
  switch (type) {
    case C.UPDATE_USER_INPUTS:
      return { ...state, [key]: data };
    case C.UPDATE_RULES_IT_AND_BUSINESS_FORM_FIELD:
      return {
        ...state,
        rulesItAndBusiness: rulesItAndBusinessReducer(
          state.rulesItAndBusiness,
          payload
        ),
      };
    case C.RESET_USER_INPUTS:
      return {
        ...state,
        categoryName: "",
        primaryDomain: "",
        secondaryDomain: "",
        subDataDomain: "",
        datasetName: "",
        environment: "",
        tags: [],
        hierarchy: {},
      };
    default:
      return state;
  }
}
