// Import required libraies
import React from "react";
import PropTypes from "prop-types";

// Import Custom Component
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";

// Import Context

// Import utils/data

function Error(props) {
  let { location } = props;

  return (
    <LayoutNone>
      <h1>Error</h1>
      <p>{location.pathname}</p>
    </LayoutNone>
  );
}

Error.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Error;
