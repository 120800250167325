// Import required libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TableBody, TableRow, Grid, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pagination } from "@material-ui/lab";

// Import data & utils
import config from "../../config/config";
import useTable from "../../utils/useTable";
import noDataImage from "../../../assets/images/undraw_void.svg";

// Import action creators
import {
  updateDialogInfo,
  updateAlertInfo,
  reloadData,
  updateUserInputs,
} from "../../redux/actions";

// Import styles
import {
  StyledTableCell,
  useStyles,
  customMenuItemStyle,
  CustomMenuItem,
} from "../../../styles/tblStyle";
import "./ManageRules.scss";

const ManageRules = ({
  data = [],
  paginationFlag,
  criteria,
  tblHeaders,
  id,
  handleReject,
  handleApprove,
}) => {
  const classes = useStyles();
  const filterFn = { fn: (items) => items };

  const { TblContainer, TblHead } = useTable(
    data,
    tblHeaders,
    filterFn,
    {},
    () => {},
    criteria
  );

  const pageSizes = [...config.hardCoded.pageSizes];

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const displayRecords =
    paginationFlag && data.length > page
      ? [...data].splice((page - 1) * pageSize, pageSize)
      : data;
  const noOfPages = Math.ceil(data.length / pageSize);

  return (
    <>
      <>
        {" "}
        <div className="manage-rules-container">
          <TblContainer>
            <TblHead />
            {data?.length ? (
              <TableBody>
                {displayRecords.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow className={classes.tableRow} hover>
                        <StyledTableCell>{item.name}</StyledTableCell>
                        <StyledTableCell>
                          {item.rule_description}
                        </StyledTableCell>
                        <StyledTableCell>{item.email}</StyledTableCell>
                        <StyledTableCell>
                          {item.status.toLowerCase() === "approved" ||
                          item.status.toLowerCase() === "rejected" ? (
                            <div
                              className={
                                item.status.toLowerCase() === "approved"
                                  ? "status-approved"
                                  : "status-rejected"
                              }
                            >
                              {item.status}
                            </div>
                          ) : (
                            <Grid
                              container
                              alignItems="flex-end"
                              sx={{ mx: "24px" }}
                            >
                              <button
                                className={`btn-cancel shake-category-field`}
                                onClick={() => handleReject(item)}
                                style={{
                                  width: "60px",
                                  height: "30px",
                                }}
                              >
                                <span className="material-icons-outlined">
                                  close
                                </span>
                              </button>
                              <button
                                className={`btn-done shake-category-field`}
                                onClick={() => handleApprove(item)}
                                style={{
                                  width: "60px",
                                  height: "30px",
                                }}
                              >
                                <span className="material-icons-outlined">
                                  done
                                </span>
                              </button>
                            </Grid>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody className="no-configs">
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <img src={noDataImage} alt="noDataImage" />
                    <p className="no-data">
                      <b>{config.messages.noRecordsFound}</b>
                    </p>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            )}
          </TblContainer>
        </div>
        {paginationFlag && data.length > 10 && (
          <Grid container style={{ marginTop: "15px" }}>
            <Grid item>
              <Pagination
                count={noOfPages}
                page={page}
                shape="rounded"
                onChange={handleChangePage}
                siblingCount={0}
                boundaryCount={2}
                className={classes.pagination}
              />
            </Grid>
            <Grid item xs={1}>
              <Select
                onChange={handlePageSizeChange}
                value={pageSize}
                disableUnderline
                className={classes.paginationSelect}
                IconComponent={ExpandMoreIcon}
              >
                {pageSizes.map((size) => (
                  <CustomMenuItem
                    key={size}
                    value={size}
                    style={customMenuItemStyle}
                  >
                    View {size}
                  </CustomMenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}{" "}
      </>
    </>
  );
};

ManageRules.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  userInputs: state.userInputs,
  stateData: state.data,
});

const mapDispatchToProps = {
  reloadData,
  updateDialogInfo,
  updateAlertInfo,
  updateUserInputs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ManageRules));
