import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";

import MultipleTablesRender from "./MultipleTablesRender";
import Loader from "../Loader/Loader";
import DQButton from "../../components/Common/DQButton";

import { suggestRulesForTables, readBlobData } from "../../services/apis";

import { wrapperFunctionForTables } from "../../utils/utils";

const ManageTableSelection = (props) => {
  const {
    userInputs,
    setApiData = () => {},
    cancelSave = () => {},
    proceedToBackPage = () => {},
    proceedToNextPage = () => {},
  } = props;
  const [rowData, setRowData] = useState({
    status: "loading",
    message: "",
    data: [],
  });
  const [tableArray, setTableArray] = useState([]);

  useEffect(() => {
    const check = wrapperFunctionForTables(userInputs.hierarchy).map((p) => ({
      ...p,
    }));
    let tablesData;
    let checkForLandingLayer = false;
    for (const [key, value] of Object.entries(userInputs.hierarchy)) {
      if (key === "Landing Layer") {
        checkForLandingLayer = true;
        tablesData = value.databases[0].schemas[0].tables;
      }
    }

    // * This condition is for when Landing layer is selected
    if (checkForLandingLayer) {
      const readBlobApiCall = async (schema_name, table_name) => {
        let reqObj = {
          schema_name,
          table_name,
          cluster_id: userInputs.clusterName.id,
          path: userInputs.pathForLandingLayerBlob
            ? userInputs.pathForLandingLayerBlob
            : undefined,
        };
        await readBlobData(reqObj);
        let tableDataPromise = Promise.all(
          check.map((el) => suggestRulesForTables(el))
        );

        tableDataPromise
          .then((value) => {
            let newValue = [];
            for (const val of value) {
              const newVal = val.map((el) => ({ ...el }));
              newValue = [...newValue, newVal];
            }
            setRowData({
              status: "success",
              message: "",
              data: newValue,
            });
            setApiData(newValue);
          })
          .catch((err) => {
            setRowData({
              status: "error",
              message: "",
              data: [],
            });
          });
      };

      if (tablesData) {
        tablesData.map((el) => {
          readBlobApiCall(el.metadata.db_name, el.metadata.table_name);
        });
      }

      setTableArray(check);

      // setTimeout(() => {}, 10000);
    } else {
      setTableArray(check);

      let tableDataPromise = Promise.all(
        check.map((el) => suggestRulesForTables(el))
      );

      tableDataPromise
        .then((value) => {
          let newValue = [];
          for (const val of value) {
            const newVal = val.map((el) => ({ ...el }));
            newValue = [...newValue, newVal];
          }
          setRowData({
            status: "success",
            message: "",
            data: newValue,
          });
          setApiData(newValue);
        })
        .catch((err) => {
          setRowData({
            status: "error",
            message: "",
            data: [],
          });
        });
    }

    return () => {
      checkForLandingLayer = false;
    };
  }, []);

  // TODO cancelSave functionality

  // * Static variable for the jsx

  return (
    <div
      style={{
        paddingLeft: "50px",
        width: "100%",
        height: "calc(100vh - 270px)",
        overflowY: "auto",
      }}
    >
      {rowData.status === "loading" && <Loader />}
      {rowData.status === "success" && (
        <>
          <MultipleTablesRender
            rowData={rowData}
            setRowData={setRowData}
            tableArray={tableArray}
            setApiData={setApiData}
          />
          <Box
            sx={{
              display: "flex",
              position: "fixed",
              bottom: 40,
              right: 0,
              mr: 2,
              height: "30px",
            }}
          >
            <DQButton
              title="Cancel"
              disabled={false}
              variant="outlined"
              onclick={cancelSave}
            />
            <DQButton
              title="Back"
              disabled={false}
              variant="contained"
              onclick={proceedToBackPage}
            />
            <DQButton
              title={"Next"}
              disabled={false}
              variant="contained"
              onclick={proceedToNextPage}
            />
          </Box>
        </>
      )}
      {rowData.status === "error" && <h1>"Error on loading"</h1>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInputs: state.userInputs,
});

export default connect(mapStateToProps)(ManageTableSelection);
