// Import required libraries
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TableBody, TableRow } from "@material-ui/core";

// Import data & utils
import config from "../../../../config/config";
import useTable from "../../../../utils/useTable";
import noDataImage from "../../../../../assets/images/no_data.svg";

// Import action creators
import { resetSelections } from "../../../../redux/actions";

// Import styles
import { StyledTableCell, useStyles } from "../../../../../styles/tblStyle";

import ColumnListGlobalRulesTableHeader from "./ColumnListGlobalRulesTableHeader";

import "./ColumnListGlobalRulesTable.scss";

const ColumnsListGlobalRulesTable = ({
  data = [],
  criteria,
  tblHeaders,
  id,
  allData,
  resetSelections,
}) => {
  const classes = useStyles();
  const filterFn = { fn: (items) => items };

  const { TblContainer } = useTable(
    data,
    tblHeaders,
    filterFn,
    {},
    () => {},
    criteria
  );

  //reset filters on screen change
  useEffect(() => {
    return () => {
      resetSelections("datasetNameForGRM");
      resetSelections("databaseNameForGRM");
      resetSelections("tableNameForGRM");
      resetSelections("columnNameForGRM");
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <>
        {" "}
        <div className="column-list-container">
          <TblContainer>
            <ColumnListGlobalRulesTableHeader fetchedData={allData} />
            {data?.length ? (
              <TableBody>
                {data.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow className={classes.tableRow} hover>
                        {/* Remove this row on Aditiya Request */}
                        {/* <StyledTableCell>{item.dataset_name}</StyledTableCell> */}
                        <StyledTableCell>{item.database_name}</StyledTableCell>
                        <StyledTableCell>{item.table_name}</StyledTableCell>
                        <StyledTableCell>{item.column_name}</StyledTableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody className="no-rules">
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <img src={noDataImage} alt="noDataImage" />
                    <p className="no-data">
                      <b>{config.messages.ruleNotAppliedMessage}</b>
                    </p>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            )}
          </TblContainer>
        </div>
      </>
    </>
  );
};

ColumnsListGlobalRulesTable.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  resetSelections,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ColumnsListGlobalRulesTable));
