// * Import 3rd party
import { Checkbox } from "@material-ui/core";
import { styled } from "@material-ui/core";

const StyledCheckbox = styled(
  (props) => <Checkbox color={props.variant} {...props} />,
  {}
)(({ theme, customProps }) => ({
  "&.MuiCheckbox-root": {},
  "&.MuiCheckbox-root:hover": {
    backgroundColor: "transparent !important",
  },
  "&.MuiButtonBase-root.MuiCheckbox-root>svg": {
    fontSize: "1.3rem",
    borderRadius: theme.shape.borderRadius2,
  },
}));

export default StyledCheckbox;
