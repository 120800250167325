// Import required libraries
import React from "react";
import { Link } from "react-router-dom";

import config from "../../config/config";

import logo from "../../../assets/images/rb-logo.png";

function RbLogo() {
  return (
    <div className="top-left-nav">
      <Link to={config.hardCoded.homeLocation} className="rb-logo-container">
        <img className="rb-logo" src={logo} alt="RB Logo" />
      </Link>
      <Link to={config.hardCoded.homeLocation} className="rb-logo-text">
        {config.hardCoded.appName}
      </Link>
    </div>
  );
}

export default RbLogo;
