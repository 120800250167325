//Import required libraies

import React, { useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";

// import { splitSemanticOptions } from "../../../utils/utils";

// const StyledCheckbox = withStyles(() => ({
//   root: {
//     color: "#4eafb3 !important",
//     paddingLeft: "0px",
//     "& .MuiSvgIcon-root": {
//       width: 18,
//       height: 18,
//     },
//     "&:hover": {
//       backgroundColor: "transparent",
//     },
//   },
// }))(Checkbox);

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "200px",
    height: "24px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
      borderRadius: "4px",
    },
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "4px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function IsNotExistingRule(props) {
  let { id, onChange, columnName, record, stateData, placeholder } = props;
  const initialState =
    record?.length && Array.isArray(record)
      ? [...record]
      : [{ tableName: "", columnName: "", operator: "", id: v4() }];
  const [localState, setLocalState] = useState(initialState);
  const [semanticColumnData, setSemanticColumnData] = useState([]);
  const [error, setError] = useState("");
  const classes = useStyles();

  const data = [
    { id: "OR", name: "OR" },
    { id: "AND", name: "AND" },
  ];

  let semanticTableData = stateData.allColumns.result[0].children;

  // Add empty test row
  const addNewRow = () => {
    const newData = [...localState, { value: "", operator: "OR", id: v4() }];
    setLocalState(newData);
    setError("");
  };

  const handleDelete = (id) => {
    if (localState.length > 1) {
      let newData = localState.filter((item) => item.id !== id);
      setLocalState(newData);
      setError("");
    } else {
      setError("Cannot delete last selection");
    }
  };

  return (
    <div
      style={{ display: "flex", alignItems: "baseline", marginBottom: "10px" }}
    >
      <div
        style={{
          width: "fit-content",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          marginTop: "5px",
          fontSize: "14px",
        }}
      >
        {/* <label className="bold-text">{columnName + " "}</label>
        <label className="light-text">{name}</label> */}
        <label>Check if all records in {columnName} does not exist in</label>
      </div>
      <div>
        {localState.map((elem, index) => {
          const handleChange = (key, value) => {
            if (key === "tableName") {
              setSemanticColumnData([
                ...semanticTableData.find((elem) => elem.name === value)
                  .children,
              ]);
            }
            const newData = localState.map((item) =>
              item.id !== elem.id
                ? item
                : {
                    ...item,
                    [key]: value,
                  }
            );
            setLocalState([...newData]);
            onChange(id, {
              value: { value1: [...newData] },
            });
          };
          return (
            <div
              key={elem.id}
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {elem.operator && (
                  <Select
                    value={elem.operator}
                    onChange={(e) => {
                      handleChange("operator", e.target.value);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return placeholder || <p>Select option</p>;
                      }
                      return selected;
                    }}
                    className={classes.multiSelect}
                    style={{
                      width: "60px",
                      marginRight: "10px",
                      marginLeft: "-70px",
                    }}
                    MenuProps={MenuProps}
                    title={elem.operator}
                  >
                    {data.length ? (
                      data.map((obj) => (
                        <MenuItem
                          key={obj.id}
                          value={obj.id}
                          className={classes.singleMenuItem}
                          title={obj.name}
                        >
                          {obj.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        value=""
                        disabled
                        className={classes.singleMenuItem}
                      >
                        No Options
                      </MenuItem>
                    )}
                  </Select>
                )}
                <Select
                  value={elem?.tableName || ""}
                  onChange={(e) => {
                    handleChange("tableName", e.target.value);
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <p>Select table</p>;
                    }
                    return selected;
                  }}
                  className={classes.multiSelect}
                  style={{ margin: "0px", width: "140px" }}
                  MenuProps={MenuProps}
                  title={elem?.tableName || ""}
                >
                  {semanticTableData.length ? (
                    semanticTableData.map((obj) => (
                      <MenuItem
                        key={v4()}
                        value={obj.name}
                        className={classes.singleMenuItem}
                        title={obj.name}
                      >
                        {obj.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      value=""
                      disabled
                      className={classes.singleMenuItem}
                    >
                      No Options
                    </MenuItem>
                  )}
                </Select>
                <p className="light-text" style={{ margin: "0px 5px" }}>
                  .
                </p>
                <Select
                  value={elem?.columnName || ""}
                  onChange={(e) => {
                    handleChange("columnName", e.target.value);
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <p>Select column</p>;
                    }
                    return selected;
                  }}
                  className={classes.multiSelect}
                  style={{ margin: "0px", width: "140px" }}
                  MenuProps={MenuProps}
                  title={elem?.columnName || ""}
                >
                  {semanticColumnData.length ? (
                    semanticColumnData.map((obj) => {
                      return (
                        <MenuItem
                          key={v4()}
                          value={obj.name}
                          className={classes.singleMenuItem}
                          title={obj.name}
                        >
                          {obj.name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      value=""
                      disabled
                      className={classes.singleMenuItem}
                    >
                      No Options
                    </MenuItem>
                  )}
                </Select>
                <p
                  style={{
                    fontSize: "14px",
                    padding: "0px 20px 0px 0px",
                    color: "#4eafb3",
                    cursor: "pointer",
                    margin: "0px 0px 0px 10px",
                  }}
                  onClick={() => handleDelete(elem.id)}
                >
                  Delete
                </p>
                {index === localState.length - 1 && (
                  <p
                    style={{
                      fontSize: "14px",
                      padding: "0px 20px 0px 0px",
                      color: "#4eafb3",
                      cursor: "pointer",
                      margin: "0px 0px 0px 10px",
                    }}
                    onClick={() => addNewRow()}
                  >
                    Add New Condition
                  </p>
                )}
                {error && <p className="error-msg">{error}</p>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default IsNotExistingRule;
