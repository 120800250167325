/* eslint-disable indent */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell, Box } from "@material-ui/core";
import { useStyles } from "./style";

import Filters from "../../Filters/Filters";

const RulesStatusTableHeader = (props) => {
  const { user, allData } = props;
  const classes = useStyles();
  const allColumnNames = [...new Set(allData?.map((item) => item.column_name))];
  const allBusinessAttributeNames = [
    ...new Set(allData?.map((item) => item.business_attribute_name)),
  ];
  // const allRulesApplied = [
  //   ...new Set(allData?.map((item) => item.description)),
  // ];
  return (
    <TableHead>
      <TableRow>
        <TableCell
          className={classes.tablecell}
          style={{
            padding: "10px",
          }}
          align="left"
          width="10%"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box>Column Name</Box>
            <Filters
              flag={
                user.selections.columnNameForReport.value?.length ? true : false
              }
              id="columnNameForReport"
              data={allColumnNames}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell
          className={classes.tablecell}
          style={{ padding: "10px" }}
          align="left"
          width="10%"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box>Business Attribute Name</Box>
            <Filters
              flag={
                user.selections.businessAttributeNameForReport.value?.length
                  ? true
                  : false
              }
              id="businessAttributeNameForReport"
              data={allBusinessAttributeNames}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell} align="center" width="50%">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box>Rules Applied</Box>
            {/* <Filters
              flag={
                user.selections.rulesAppliedForReport.value?.length
                  ? true
                  : false
              }
              id="rulesAppliedForReport"
              data={allRulesApplied}
              filterType="checkbox"
            /> */}
          </div>
        </TableCell>
        <TableCell className={classes.tablecell} align="center" width="10%">
          <Box>Weightage</Box>
        </TableCell>
        <TableCell className={classes.tablecell} align="center" width="10%">
          <Box>Status</Box>
        </TableCell>
        <TableCell className={classes.tablecell} align="center" width="10%">
          <Box>Pass %</Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

RulesStatusTableHeader.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(RulesStatusTableHeader);
