// Import required libraies
import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Loader from "../../components/Loader/Loader";

import noDataImage from "../../../assets/images/undraw_void.svg";

import {
  approveOrRejectGlobalRule,
  getManageRulesList,
} from "../../services/apis";
import { updateAlertInfo, updateData } from "../../redux/actions";

import "./ManageRules.scss";
import ManageRulesTable from "./ManageRulesTable";
import config from "../../config/config";

const ManageRules = (props) => {
  const { data, updateData, updateAlertInfo } = props;

  const fetchManageRulesList = async () => {
    updateData("manageRulesList", {
      status: "loading",
      result: {},
      message: "",
    });
    let allRulesList = await getManageRulesList();
    if (allRulesList && allRulesList.length > 0) {
      updateData("manageRulesList", {
        status: "success",
        result: allRulesList,
        message: "",
      });
    } else {
      updateData("manageRulesList", {
        status: "error",
        result: {},
        message: "Could not load Rules! Please try again later!",
      });
    }
  };

  useEffect(() => {
    fetchManageRulesList();
    // eslint-disable-next-line
  }, []);

  const approveOrRejectRule = async (actionValue, rowItem) => {
    updateAlertInfo({
      open: true,
      message:
        actionValue === "Approved"
          ? config.messages.ruleApprovalRequestMessage
          : config.messages.ruleRejectionRequestMessage,
      severity: "info",
    });
    let body = {
      name: rowItem.name,
      rule_description: rowItem.rule_description,
      status: actionValue,
    };
    let respData = await approveOrRejectGlobalRule(body, rowItem.id);
    if (respData) {
      const rulesTable = JSON.parse(
        JSON.stringify(data.manageRulesList.result)
      );
      let rulesTableNew = rulesTable.map((item) => {
        if (item.id === rowItem.id) {
          return { ...item, status: actionValue };
        } else {
          return { ...item };
        }
      });
      updateData("manageRulesList", {
        status: "success",
        message: "",
        result: rulesTableNew,
      });
      updateAlertInfo({
        open: true,
        message:
          actionValue === "Approved"
            ? config.messages.ruleApprovalSuccessMessage
            : config.messages.ruleRejectionSuccessMessage,
        severity: "success",
      });
    } else {
      updateAlertInfo({
        open: true,
        message:
          actionValue === "Approved"
            ? config.messages.ruleApprovalFailureMessage
            : config.messages.ruleRejectionFailureMessage,
        severity: "error",
      });
    }
  };

  const handleApprove = (item) => {
    approveOrRejectRule("Approved", item);
  };

  const handleReject = (item) => {
    approveOrRejectRule("Rejected", item);
  };

  return (
    <>
      {data.manageRulesList.status === "loading" && <Loader />}
      {data.manageRulesList.status === "success" && (
        <ManageRulesTable
          data={data.manageRulesList.result}
          paginationFlag={false}
          tblHeaders={[
            { id: "name", label: "Rule Name" },
            { id: "rule_description", label: "Rule Description" },
            { id: "email", label: "Requester Email" },
            { id: "", label: "Status" },
          ]}
          handleApprove={handleApprove}
          handleReject={handleReject}
        />
      )}
      {data.manageRulesList.status === "error" && (
        <div className="no-configs">
          {" "}
          <img src={noDataImage} alt="noDataImage" />
          <p className="no-data">
            <b>{data.manageRulesList.message}</b>
          </p>
        </div>
      )}
    </>
  );
};

ManageRules.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageRules);
