// Import required libraries
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import Login from "./screens/Login/Login";
import Loader from "./components/Loader/Loader";
import MaintainancePage from "./MaintainancePage";

import { updateUserInfo } from "./redux/actions";

// Import styles
import "./App.scss";

// Import routes
import Routes from "./routes/Routes";

// Import action creators

// Import utils
import { userTeamInfo } from "../js/services/apis";
import { updateData } from "../js/redux/actions";

// Import Configuration
import { env } from "./utils/env";
const scopes = env("scopes");

//Import custom components

function App({ user }) {
  const [authResolved, setAuthResolved] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [showMaintenancePage, setShowMaintenancePage] = useState(false);

  const ReduxDispatcher = useDispatch();
  // const userInfo = useSelector((state) => state.data.userInfo);

  function ProtectedComponent() {
    const { instance, inProgress, accounts } = useMsal();

    useEffect(() => {
      const accessTokenRequest = {
        scopes: [`${scopes}`],
        account: accounts[0],
      };
      if (inProgress === InteractionStatus.None) {
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            localStorage.setItem("secret", accessToken);
            setAuthResolved(true);
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest);
            }
            // console.log(error);
          });
      }
      // eslint-disable-next-line
    }, [instance, accounts, inProgress]);
    return <></>;
  }

  // ! This Api Call is to get the User Inforamtion
  useEffect(() => {
    if (authResolved) {
      let userTeamInfoPromise = userTeamInfo({});
      userTeamInfoPromise
        .then((responseData) => {
          ReduxDispatcher(
            updateData("userInfo", {
              status: "success",
              message: "",
              result: responseData,
            })
          );
          setUserInfo(true);
          for (let el of responseData) {
            if (el.label === "Maintenance") {
              setShowMaintenancePage(true);
            }
          }
        })
        .catch(() => {
          ReduxDispatcher(
            updateData("userInfo", {
              status: "error",
              message: "Something went wrong",
              result: [],
            })
          );
        });
    }
  }, [authResolved]);
  // console.log("sonu authResolved", authResolved);
  // console.log("sonu showMaintenancePage", showMaintenancePage);
  // console.log("sonu userInfo", userInfo);

  // console.log(
  //   "sonu showMaintenancePage && userInfo",
  //   showMaintenancePage && userInfo
  // );

  return (
    <>
      <AuthenticatedTemplate>
        <ProtectedComponent />
        {authResolved ? (
          showMaintenancePage && userInfo ? (
            <MaintainancePage setShowMaintenancePage={setShowMaintenancePage} />
          ) : (
            <Routes />
          )
        ) : (
          <Loader fullPage={true} message="Authenticating User" />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
      {/* <Loader fullPage={true} message="Authenticating User" /> */}
    </>
  );
}

App.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = { updateUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(App);
