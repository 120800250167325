// ### HIERARCHY
// --ColumnRulesTable
//    --ItAndBusinessRules
//       --RulesRow

// # IMPORTS
// Import required libraries

import "./ColumnRulesTable.scss";

import {
  Box,
  MenuItem,
  Modal,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  MenuProps,
  StyledTableCell,
  TabPanel,
  useStyles,
  CustomMenuItem,
  customMenuItemStyle,
} from "./styles";
import React, { useEffect, useReducer, useState } from "react";
import {
  allRulesToSelections,
  isRulesSelectionsInvalid,
  modifyAllRulesDataBasedOnColumns,
} from "../RulesSelection/schema";
import {
  divideRulesIntoArrays,
  filterColumnsForSelections,
  getTotalSelectedRules,
  sortRulesDataFromFormselections,
} from "../../utils/utils";
import {
  resetSelections,
  updateAlertInfo,
  updateChangeFlagUiRules,
  updateUserInputs,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import C from "../../redux/constants";
import DQButton from "../Common/DQButton";
import Filters from "../Filters/Filters";
import ItAndBusinessRules from "../RulesSelection/ItAndBusinessRules";
import StyledCheckbox from "../../kit/Checkbox";
import TextFieldWithIcons from "../TextFieldWithIcons/TextFieldWithIcons";
import config from "../../config/config";
import noDataImage from "../../../assets/images/undraw_void.svg";
import rulesItAndBusinessReducer from "../../redux/reducers/rulesItAndBusiness";
import useReducerLogger from "../../utils/useReducerLogger";
import useTable from "../../utils/useTable";

// Import components

// Import data & utils

// Import styles

// # STATIC VARIABLES
const headCells1 = [
  { id: "table_name", label: "Table Name", disableSorting: true },
  { id: "dataset_name", label: "Dataset Name", disableSorting: true },
  { id: "no_of_columns", label: "# of Columns", disableSorting: true },
  { id: "location", label: "Platform", disableSorting: true },
  { id: "last_run_details_1", label: " ", disableSorting: true },
];

const DRFilterType = [
  { id: "singleSelect", name: "Select Single Value" },
  { id: "multiSelect", name: "Select Multiple Values" },
];

// # HELPER COMPONENTS
const ColumnRulesTableHeader = (props = {}) => {
  // # PROPS
  const { allData } = props;

  // # HOOKS
  const classes = useStyles();
  const user = useSelector((state) => state.user);

  // # STATIC VARIABLES
  let columnsList = [...new Set(allData?.map((itm) => itm.column_name))];
  let datatypeList = [...new Set(allData?.map((item) => item.datatype))];

  // # JSX
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <div style={{ display: "flex", width: "150px" }}>
            <Box>Column Name</Box>
            <Filters
              flag={user.selections.columnName.value?.length ? true : false}
              id="columnName"
              data={columnsList}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <div style={{ display: "flex", width: "120px" }}>
            <Box>Datatype</Box>
            <Filters
              flag={user.selections.datatype.value?.length ? true : false}
              id="datatype"
              data={datatypeList}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Business Attribute Name</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>IT Validations & Business Rules Selected</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Contextual Rules Selected</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Can filters be applied?</Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const ColumnRulesTableRow = (props = {}) => {
  // # PROPS
  const { item, handleClickOpen, handleRowValueChange } = props;

  // # HOOKS
  const classes = useStyles();
  const userInputs = useSelector((state) => state.userInputs);
  const user = useSelector((state) => state.user);

  // # UTIL FUNCS
  const getITRulesCount = (colName) => {
    // Get selected rules from userInputs.tablesAddedForDatabase
    const currTableId = user.selections.columnSelected.id;
    const currTableInTablesAddedForDataset =
      userInputs.tablesAddedForDatabase.find((r) => r.id === currTableId) ||
      userInputs.tablesAddedForDatabase[0];

    let appliedItAndBusinessRules = [
      ...(currTableInTablesAddedForDataset.it[colName] || []),
      ...(currTableInTablesAddedForDataset.business[colName] || []),
    ];

    let str = `${getTotalSelectedRules(
      appliedItAndBusinessRules
    )} rule(s) applied`;
    return str;
  };
  const getSemanticRulesCount = (colName) => {
    // Get selected rules from userInputs.tablesAddedForDatabase
    const currTableId = user.selections.columnSelected.id;
    const currTableInTablesAddedForDataset =
      userInputs.tablesAddedForDatabase.find((r) => r.id === currTableId) ||
      userInputs.tablesAddedForDatabase[0];

    let appliedItAndBusinessRules = [
      ...(currTableInTablesAddedForDataset.semantic[colName] || []),
    ];

    let str = `${getTotalSelectedRules(
      appliedItAndBusinessRules
    )} rule(s) applied
    `;
    return str;
  };

  // # JSX
  return (
    <TableRow className={classes.tableRow} hover>
      <StyledTableCell
        onClick={() =>
          handleClickOpen(
            item.column_name,
            item.datatype,
            item.id,
            item.business_attribute_name
          )
        }
        style={{
          cursor: "pointer",
          fontWeight: "600",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            color: "#4EAFB3",
          }}
        >
          <div
            className="dataset-location-name"
            title={item.column_name}
            style={{ textDecoration: "underline" }}
          >
            {item.column_name}
          </div>
        </span>
      </StyledTableCell>
      <StyledTableCell>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="dataset-location-name datatype" title={item.datatype}>
            {item.datatype}
          </div>
        </div>
      </StyledTableCell>
      {/* <StyledTableCell>
      <BusinessAttributeName
        required={true}
        id={item.id}
        defaultValue={item.business_attribute_name}
        onChange={handleChange}
        value={item.business_attribute_name}
      />
    </StyledTableCell> */}
      <StyledTableCell>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            title={item.business_attribute_name}
            style={{
              width: "100%",
            }}
          >
            <TextFieldWithIcons
              value={item.business_attribute_name}
              onChange={(newValue) =>
                handleRowValueChange(
                  "business_attribute_name",
                  item.id,
                  newValue
                )
              }
            />
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell>{getITRulesCount(item.column_name)}</StyledTableCell>
      <StyledTableCell>
        {getSemanticRulesCount(item.column_name)}
      </StyledTableCell>
      <StyledTableCell>
        <div>
          <StyledCheckbox
            checked={item.filter_status || false}
            //disabled={item.datatype.toLowerCase() !== "string"}
            onChange={(e, newValue) =>
              handleRowValueChange("filter_status", item.id, newValue)
            }
          />
        </div>

        {item.filter_status === true && (
          <>
            <Select
              name={"Enter Filter Type"}
              id={"filter_type"}
              value={item.filter_type}
              label="Enter Filter Type"
              onChange={(e) => {
                handleRowValueChange("filter_type", item.id, e.target.value);
              }}
              placeholder={"Enter Value Type"}
              className={classes.multiSelect}
              MenuProps={MenuProps}
              title={item.filter_type}
            >
              {DRFilterType.map((obj) => (
                <MenuItem
                  value={obj.id}
                  key={obj.id}
                  className={classes.singleMenuItem}
                  title={obj.name}
                >
                  {obj.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </StyledTableCell>
    </TableRow>
  );
};

const ColumnRulesModal = (props = {}) => {
  // # PROPS
  const {
    rulesArrayObj,
    columnName,
    columnDatatype,
    parentClasses,
    rulesItAndBusinessSelections,
    dispatchRulesItAndBusinessSelections,
    allRulesData,
    showErrorOnUi,
    currentTable,
  } = props;

  // # STATES
  const [searchText, setSearchText] = useState({ searchText: "" });
  const [value, setValue] = useState(0);

  // # HANDLERS
  const handleSearchTextChange = (key, value) => {
    setSearchText({ searchText: value });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // # STATIC VARIABLES
  const itBusinessRulesCount = getTotalSelectedRules(
    rulesItAndBusinessSelections.filter(
      (row) =>
        ["it", "business"].includes(row.categoryId) && row.value.isEnabled
    )
  );
  const semanticRulesCount = getTotalSelectedRules(
    rulesItAndBusinessSelections.filter(
      (row) => ["semantic"].includes(row.categoryId) && row.value.isEnabled
    )
  );

  const tabNames = [
    { name: `IT Validations & Business Rules (${itBusinessRulesCount})` },
    { name: `Contextual Rules (${semanticRulesCount})` },
  ];

  return (
    <>
      <div className={`${parentClasses.rulesModalHeader} models-menu-new`}>
        {tabNames.map((tabName, index) => (
          <div
            key={tabName.name}
            className={`models-item`}
            onClick={(e) => {
              handleChange(e, index);
            }}
          >
            <div
              className={`models-item-div ${
                index === value ? "active" : "not-active"
              }`}
            >
              <p>{tabName.name}</p>
            </div>
          </div>
        ))}
      </div>

      <div className={parentClasses.rulesModalBody}>
        <TabPanel value={value} index={0}>
          <ItAndBusinessRules
            rulesArrayObj={rulesArrayObj}
            rulesItAndBusinessSelections={rulesItAndBusinessSelections}
            dispatchRulesItAndBusinessSelections={
              dispatchRulesItAndBusinessSelections
            }
            allRulesData={allRulesData.filter((row) =>
              ["it", "business"].includes(row.categoryId)
            )}
            columnDetails={{ columnName, columnDatatype }}
            showErrorOnUi={showErrorOnUi}
            categoryType={["it", "business"]}
            searchText={searchText}
            handleSearchTextChange={handleSearchTextChange}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ItAndBusinessRules
            rulesArrayObj={rulesArrayObj}
            rulesItAndBusinessSelections={rulesItAndBusinessSelections}
            dispatchRulesItAndBusinessSelections={
              dispatchRulesItAndBusinessSelections
            }
            allRulesData={allRulesData.filter(
              (row) => row.categoryId === "semantic"
            )}
            columnDetails={{ columnName, columnDatatype }}
            showErrorOnUi={showErrorOnUi}
            currentTable={currentTable}
            categoryType={["semantic"]}
            searchText={searchText}
            handleSearchTextChange={handleSearchTextChange}
          />
        </TabPanel>
      </div>
    </>
  );
};

// # COMPONENT
const ColumnRulesTable = (props = {}) => {
  // # PROPS
  const {
    allRulesDataStatic = [],
    data = [],
    allData = [],
    rulesData = [],
    criteria,
    isCreatePage,
    isDataDomainPage,
    currentTable,
    paginationFlag,
  } = props;

  // # HOOKS
  const classes = useStyles();
  const filterFn = { fn: (items) => items };
  const { TblContainer } = useTable(
    data,
    () => headCells1,
    filterFn,
    {},
    () => {},
    criteria
  );
  const userInputs = useSelector((state) => state.userInputs);
  const user = useSelector((state) => state.user);
  const storeAllData = useSelector((state) => state.data);
  const dispatch = useDispatch();

  // # STATES
  const [open, setOpen] = useState({
    columnName: "",
    columnDatatype: "",
    columnId: "",
    columnBusinessAttributeName: "",
    isOpened: false,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const initialRulesItAndBusinessSelections = allRulesToSelections(
    modifyAllRulesDataBasedOnColumns(allRulesDataStatic, storeAllData),
    ""
  );
  const [allRulesData, setAllRulesData] = useState(
    modifyAllRulesDataBasedOnColumns(allRulesDataStatic, storeAllData)
  );
  const [rulesItAndBusinessSelections, dispatchRulesItAndBusinessSelections] =
    useReducer(
      useReducerLogger(rulesItAndBusinessReducer),
      initialRulesItAndBusinessSelections
    );
  const [showErrorOnUi, setShowErrorOnUi] = useState(false);

  // # SIDE EFFECTS
  useEffect(() => {
    return () => {
      // clear filter value on table unmount
      dispatch(resetSelections("columnName"));
      dispatch(resetSelections("datatype"));
    };
    // eslint-disable-next-line
  }, []);

  // # EVENT HANDLERS
  const handleClickOpen = (
    columnName,
    columnDatatype,
    columnId,
    columnBusinessAttributeName
  ) => {
    // Get selected rules from userInputs.tablesAddedForDatabase
    const currTableId = user.selections.columnSelected.id;
    const currTableInTablesAddedForDataset =
      userInputs.tablesAddedForDatabase.find((r) => r.id === currTableId) ||
      userInputs.tablesAddedForDatabase[0];

    // Already applied rules for clicked column row
    let alreadyAppliedRules = [
      ...(currTableInTablesAddedForDataset.it[columnName] || []),
      ...(currTableInTablesAddedForDataset.business[columnName] || []),
      ...(currTableInTablesAddedForDataset.semantic[columnName] || []),
    ];
    // Already applied rules for clicked column row (IDs only)
    let alreadyAppliedRulesIdsOnly = alreadyAppliedRules.map((row) => row.name);

    // Subset allRules based on clicked column row data type
    let modifiedAllRulesDataStatic = allRulesDataStatic.filter(
      (a) =>
        a.supportedDataTypes.includes(columnDatatype) ||
        a.supportedDataTypes.includes("all")
    );
    // Add options filler for certain rules
    let modifiedAllRulesDataDynamic = modifyAllRulesDataBasedOnColumns(
      modifiedAllRulesDataStatic,
      storeAllData
    );

    // All default rules selections
    let allDefaultRulesSelections = allRulesToSelections(
      modifiedAllRulesDataDynamic,
      columnName
    );
    // New rules (rules not present in already applied)
    let allDefaultRulesSelectionsRemaining = allDefaultRulesSelections.filter(
      (row) => !alreadyAppliedRulesIdsOnly.includes(row.name)
    );

    // Final new form selections for clicked row
    let finalNewFormSelections = [
      ...allDefaultRulesSelectionsRemaining,
      ...alreadyAppliedRules,
    ];
    // Update groupId and categoryId based on modifiedAllRulesDataDynamic
    finalNewFormSelections = finalNewFormSelections.map((selectedRow) => {
      let newSelectedRow = { ...selectedRow };
      let ruleRowInAllRules = modifiedAllRulesDataDynamic.find(
        (ruleRow) => ruleRow.name === selectedRow.name
      );
      if (ruleRowInAllRules) {
        newSelectedRow = {
          ...newSelectedRow,
          categoryId: ruleRowInAllRules.categoryId,
          groupId: ruleRowInAllRules.groupId,
        };
      }
      return newSelectedRow;
    });
    let action = {
      type: C.REPLACE_IT_AND_BUSINESS_FORM,
      formSelections: finalNewFormSelections,
    };

    // * SET STATES
    // 1. Update form selections
    dispatchRulesItAndBusinessSelections(action);

    // 2. Update allRulesData based on clicked column data type
    setAllRulesData(
      sortRulesDataFromFormselections(
        modifiedAllRulesDataDynamic,
        finalNewFormSelections
      )
    );

    // 3. Update local state which has clicked column details
    setOpen({
      columnName: columnName,
      columnDatatype: columnDatatype,
      columnId: columnId,
      columnBusinessAttributeName: columnBusinessAttributeName,
      isOpened: true,
    });
  };

  const handleClose = () => {
    setOpen({
      ...open,
      isOpened: false,
    });
  };

  const navigateToPage = () => {
    handleClose();
  };

  const saveRulesForColumn = () => {
    const isRulesSelectionsInvalidFlag = isRulesSelectionsInvalid(
      rulesItAndBusinessSelections
    );
    if (isRulesSelectionsInvalidFlag) {
      setShowErrorOnUi(true);
      return;
    }

    handleClose();

    let columnIdSelected = user.selections.columnSelected.id;
    let tablesAdded = JSON.parse(
      JSON.stringify(userInputs.tablesAddedForDatabase)
    );
    let objIndex = tablesAdded.findIndex((obj) => obj.id === columnIdSelected);
    let itSelections = rulesItAndBusinessSelections.filter(
      (row) => row.categoryId === "it" && row.value.isEnabled
    );
    let businessSelections = rulesItAndBusinessSelections.filter(
      (row) => row.categoryId === "business" && row.value.isEnabled
    );
    let semanticSelections = rulesItAndBusinessSelections.filter(
      (row) => row.categoryId === "semantic" && row.value.isEnabled
    );
    // console.log("tablesAdded", tablesAdded);
    // console.log("rulesItAndBusinessSelections", rulesItAndBusinessSelections);
    // console.log("semanticSelections", semanticSelections);
    tablesAdded[objIndex].it = {
      ...tablesAdded[objIndex].it,
      [open.columnName]: itSelections,
    };
    tablesAdded[objIndex].business = {
      ...tablesAdded[objIndex].business,
      [open.columnName]: businessSelections,
    };
    tablesAdded[objIndex].semantic = {
      ...tablesAdded[objIndex].semantic,
      [open.columnName]: semanticSelections,
    };

    dispatch(updateChangeFlagUiRules(true));
    dispatch(updateUserInputs("tablesAddedForDatabase", tablesAdded));
  };

  const handleRowValueChange = (key, id, newValue) => {
    let updatedChanges = JSON.parse(
      JSON.stringify(userInputs.datasetTablesColumns)
    );
    const currTableId = user.selections.columnSelected.id;
    const currTableInTablesAddedForDataset =
      userInputs.tablesAddedForDatabase.find((r) => r.id === currTableId) ||
      userInputs.tablesAddedForDatabase[0];

    if (key === "filter_status" && newValue === true) {
      let filterSelectionLength = updatedChanges.filter(
        (item) => item.filter_status === true
      ).length;
      if (
        filterSelectionLength >= currTableInTablesAddedForDataset.filter_limit
      ) {
        dispatch(
          updateAlertInfo({
            open: true,
            message: `Currently ${currTableInTablesAddedForDataset.filter_limit} filter selections are allowed. Kindly select any other filter to proceed!`,
            severity: "info",
          })
        );
        return;
      }
    }
    if (key === "filter_type" && newValue === "multiSelect") {
      let filterAppliedLength = updatedChanges.filter(
        (item) => item.filter_type === newValue
      ).length;
      if (
        filterAppliedLength >=
        currTableInTablesAddedForDataset.filter_limit_multiselect
      ) {
        dispatch(
          updateAlertInfo({
            open: true,
            message: `Only ${currTableInTablesAddedForDataset.filter_limit_multiselect} multiselect filter allowed`,
            severity: "info",
          })
        );
        return;
      }
    }
    updatedChanges = updatedChanges.map((item) => {
      if (item.id === id) {
        if (key === "filter_status" && newValue) {
          return {
            ...item,
            [key]: newValue,
            filter_type: "singleSelect",
          };
        }
        if (key === "filter_status" && !newValue) {
          return {
            ...item,
            [key]: newValue,
            filter_type: "",
          };
        }
        return {
          ...item,
          [key]: newValue,
        };
      }
      return item;
    });

    dispatch(updateChangeFlagUiRules(true));
    dispatch(updateUserInputs("datasetTablesColumns", updatedChanges));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  // # UTIL FUNCS
  const getITRulesCountNumber = (colName) => {
    // Get selected rules count from userInputs.tablesAddedForDatabase
    const currTableId = user.selections.columnSelected.id;
    const currTableInTablesAddedForDataset =
      userInputs.tablesAddedForDatabase.find((r) => r.id === currTableId) ||
      userInputs.tablesAddedForDatabase[0];

    let appliedItAndBusinessRules = [
      ...(currTableInTablesAddedForDataset.it[colName] || []),
      ...(currTableInTablesAddedForDataset.business[colName] || []),
    ];

    let count = getTotalSelectedRules(appliedItAndBusinessRules);
    return count;
  };
  const getSemanticRulesCountNumber = (colName) => {
    // Get selected rules count from userInputs.tablesAddedForDatabase
    const currTableId = user.selections.columnSelected.id;
    const currTableInTablesAddedForDataset =
      userInputs.tablesAddedForDatabase.find((r) => r.id === currTableId) ||
      userInputs.tablesAddedForDatabase[0];

    let appliedItAndBusinessRules = [
      ...(currTableInTablesAddedForDataset.semantic[colName] || []),
    ];

    let count = getTotalSelectedRules(appliedItAndBusinessRules);
    return count;
  };

  // # STATIC VARIABLES
  const rulesArrayObj = divideRulesIntoArrays(rulesData);
  const isRulesSelectionsInvalidFlag = isRulesSelectionsInvalid(
    rulesItAndBusinessSelections
  );
  const isUpdateDisabledFlag = showErrorOnUi
    ? isRulesSelectionsInvalidFlag
    : false;
  let updatedData = filterColumnsForSelections(
    userInputs.datasetTablesColumns,
    user.selections
  );
  updatedData = updatedData.sort((a, b) =>
    getITRulesCountNumber(a.column_name) +
      getSemanticRulesCountNumber(a.column_name) >
    getITRulesCountNumber(b.column_name) +
      getSemanticRulesCountNumber(b.column_name)
      ? -1
      : 1
  );
  // ! PERFORMANCE OPTIMIZATION
  // * With any state change, the entire huge table of columns was getting re-painted
  updatedData = updatedData?.length
    ? open.isOpened
      ? updatedData.slice(1, 5)
      : [...updatedData]
    : [];
  let rootClassName = isDataDomainPage
    ? "dataset-domains-container"
    : "dataset-details-container";
  let rulesSelectionRoot = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  updatedData =
    updatedData.length > page
      ? [...updatedData].splice((page - 1) * pageSize, pageSize)
      : updatedData;
  const noOfPages = Math.ceil(data.length / pageSize);
  const pageSizes = [...config.hardCoded.pageSizes];

  // # JSX
  return (
    <>
      <div className={rootClassName}>
        <div>
          <TblContainer>
            <ColumnRulesTableHeader allData={allData} />
            {data?.length ? (
              <TableBody>
                {updatedData.map((item) => (
                  <ColumnRulesTableRow
                    key={item.name}
                    item={item}
                    handleClickOpen={handleClickOpen}
                    handleRowValueChange={handleRowValueChange}
                  />
                ))}
              </TableBody>
            ) : (
              <TableBody className="no-configs">
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <img src={noDataImage} alt="noDataImage" />
                    <p className="no-data">
                      <b>{config.messages.noRecordsFound}</b>
                    </p>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            )}
          </TblContainer>
        </div>
        <div style={{ height: "100px" }}></div>

        <Modal
          open={open.isOpened}
          onClose={handleClose}
          className={classes.rulesModal}
        >
          <Box style={rulesSelectionRoot}>
            <Box className={classes.rulesSelectionWrapper}>
              <ColumnRulesModal
                handleClose={handleClose}
                rulesData={rulesData}
                columnsData={data}
                rulesArrayObj={rulesArrayObj}
                columnName={open.columnName}
                columnDatatype={open.columnDatatype}
                columnId={open.columnId}
                columnBAttrName={open.columnBusinessAttributeName}
                isCreatePage={isCreatePage}
                parentClasses={classes}
                rulesItAndBusinessSelections={rulesItAndBusinessSelections}
                dispatchRulesItAndBusinessSelections={
                  dispatchRulesItAndBusinessSelections
                }
                allRulesData={allRulesData}
                showErrorOnUi={showErrorOnUi}
                currentTable={currentTable}
              />
              <Box className={classes.rulesModalFooter}>
                {isCreatePage && (
                  <>
                    <DQButton
                      title="Cancel"
                      disabled={false}
                      variant="outlined"
                      onclick={handleClose}
                    />
                    <DQButton
                      title="Apply"
                      disabled={isUpdateDisabledFlag}
                      variant="contained"
                      onclick={saveRulesForColumn}
                    />
                  </>
                )}
                {!isCreatePage && (
                  <>
                    <DQButton
                      title="Done"
                      disabled={false}
                      variant="contained"
                      onclick={navigateToPage}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
      {paginationFlag && data.length > 10 && (
        <Grid container style={{ marginTop: "15px" }}>
          <Grid item>
            <Pagination
              count={noOfPages}
              page={page}
              shape="rounded"
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={2}
              className={classes.pagination}
            />
          </Grid>
          <Grid item xs={1}>
            <Select
              onChange={handlePageSizeChange}
              value={pageSize}
              disableUnderline
              className={classes.paginationSelect}
              IconComponent={ExpandMoreIcon}
            >
              {pageSizes.map((size) => (
                <CustomMenuItem
                  key={size}
                  value={size}
                  style={customMenuItemStyle}
                >
                  View {size}
                </CustomMenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      )}
    </>
  );
};

// # EXPORTS
export default ColumnRulesTable;
