import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Import Custom Component & hooks
import Search from "../../../components/Search/Search";
import Loader from "../../../components/Loader/Loader";
import ExploreDatasetsTable from "./ExploreDatasetsTable";
import ExploreDatasetsSearchByTags from "./ExploreDatasetsSearchByTags";
import useFetchCategoryTreeData from "./hooks/useFetchCategoryTreeData";

import {
  convertToLinearArray,
  getCategoryTreeChildrenByParams,
  getCategoryChildrenHierarchyByParams,
  getTblHeaders,
  filterDataWithSearchTextAndTags,
} from "./utils";
import { updateUserInputs, updateSelections } from "../../../redux/actions";
import noDataImage from "../../../../assets/images/undraw_void.svg";
import "./ExploreDatasetsSearch.scss";

// # COMPONENT
const ExploreDatasetsSearch = (props) => {
  // # PROPS
  const { match } = props;

  // # HOOKS
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  const user = useSelector((state) => state.user);
  const searchText = useSelector((state) => state.user.selections.searchText);
  const selectedSearchTags = useSelector(
    (state) => state.user.selections.selectedSearchTags
  );

  // # SIDE EFFECTS
  useFetchCategoryTreeData();

  // Update tableSelections based on current route
  useEffect(() => {
    const runFlag = data.categoryTreeData.status === "success";
    if (runFlag) {
      // Using a function, get tableSelections from match.params
      const tableSelections = getCategoryChildrenHierarchyByParams(
        data.categoryTreeData.result,
        match.params
      );
      // Update userInputs.tableSelections based on route
      dispatch(
        updateUserInputs("tableSelections", {
          ...tableSelections,
        })
      );
    }
    // eslint-disable-next-line
  }, [data.categoryTreeData.status, match.params]);

  // # STATIC VARIABLES
  const filteringFlag = !!searchText.trim() || selectedSearchTags.length;
  const filteredArr = filteringFlag
    ? filterDataWithSearchTextAndTags(
        convertToLinearArray(data.categoryTreeData.result),
        searchText,
        selectedSearchTags,
        match.params
      )
    : getCategoryTreeChildrenByParams(
        data.categoryTreeData.result,
        match.params
      );
  const tblHeaders = getTblHeaders(filteringFlag, match.params);
  // # JSX
  return (
    <>
      <div className="searchContainer">
        <Search
          placeholderText="Search"
          user={user}
          updateSelections={(key, data) => {
            dispatch(updateSelections(key, data));
          }}
        />
        <ExploreDatasetsSearchByTags />
      </div>
      {data.categoryTreeData.status === "success" ? (
        <ExploreDatasetsTable
          match={match}
          categoryTreeData={data.categoryTreeData.result}
          data={filteredArr}
          paginationFlag={!filteringFlag}
          tblHeaders={tblHeaders}
        />
      ) : data.categoryTreeData.status === "loading" ? (
        <Loader />
      ) : (
        <div className="no-configs">
          {" "}
          <img src={noDataImage} alt="noDataImage" />
          <p className="no-data">
            <b>{data.categoryTreeData.message}</b>
          </p>
        </div>
      )}
    </>
  );
};

export default ExploreDatasetsSearch;
