import React, { useState, useRef } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
// import { useStyles } from "../../../styles/tblStyle";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Filters from "../Filters/Filters";
import DQMultiAutoComplete from "../../components/Common/DQMutliAutocomplete";
// * Making styles - Common style of all  Table
export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "0px 22px",
    height: "30px",
    display: "flex",
    alignItems: "center",
  },
  table: {
    width: "100% !important",
  },

  tableMain: {
    // margin: " 50px 0px 20px 20px",
  },
  tableHead: {
    width: "100%",
  },
  tableHeadTitle: {
    // width: `calc(100% - 50px)`,
    boxSizing: "border-box",
  },
  tableHeadIcon: {
    // width: "10px !important",
  },
  tableBody: {
    width: "100% !important",
  },
  tablerow: {
    color: "green",
    padding: "20px",
    backgroundColor: "#b1b1b1 !important",
  },
  tableRowHeader: {
    paddingTop: "500px !important",
  },

  tableCellHeader: {
    // color: "#46596a",
    // backgroundColor: "#eff2f5",
    // borderRightStyle: "solid",
    // borderRightColor: "#dfdfdf",
    // display: "tableRowGroup",
    // fontFamily: "Fira Sans",
    // fontWeight: "500 !important",
    // fontSize: "12px !important",
    // padding: "5px 11px 5px 11px",
    // // maxWidth: "70px !important",
    // // minWidth: "70px !important",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    color: "rgba(70, 89, 106, 1)",
    fontSize: "13px",
    fontFamily: "Hind Siliguri",
    fontWeight: "600",
    borderBottom: "none",
    textTransform: "capitalize",
    backgroundColor: "#E6E8ED",
    textTransform: "capitalize !important",
  },
  totaltableCellHeader: {
    backgroundColor: "#ddeeff !important",
    borderRightStyle: "solid",
    borderRightColor: "#dfdfdf",
    fontFamily: "Fira Sans",
    fontWeight: "400",
    fontSize: "11px !important",
    padding: "5px 11px 5px 11px",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tableCellHeaderRow2: {
    // color: themeColors["secondaryColor"],
    // backgroundColor: themeColors["headerBgColor"],
    borderRightStyle: "solid",
    borderRightColor: "#dfdfdf",
    display: "tableRowGroup",
    fontFamily: "Fira Sans",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    minWidth: "90px", // Date wrap in table CSS
    padding: "3px !important",
    textAlign: "center !important",
  },
  upperTableRow: {
    width: "100% !important",
    backgroundColor: "#eaf3ff",
  },
  innerTableRow: {
    // paddingLeft: "100px",
  },

  sticky: {
    position: "sticky",
    left: 0,
  },

  tableCell: {
    borderRightStyle: "solid",
    borderRightColor: "#dfdfdf",
    fontFamily: "Fira Sans",
    fontSize: "11px !important",
    textAlign: "center",
    padding: "3px 11px 3px 11px",
    whiteSpace: "nowrap",
  },
  tableData: {},
  elementActive: {
    // color: themeColors["primaryColor"],
    cursor: "pointer",
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  elementDisabled: {
    // color: themeColors["secondaryColor"],
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  tableColumns: {
    border: "10px solid white",
    background: "grey",
  },
  totalRow: {
    backgroundColor: "#C4C9CC !important",
  },
  arrowRow: {
    width: "1%",
  },
  upperTableRowName: {
    width: "30%",
    textTransform: "capitalize !important",
  },
  remaningUpperTableRow: {
    width: "calc(100% - 21%)",
  },
  tableRow: {
    width: "100%",
  },
}));

function Row(props) {
  const {
    row,
    index,
    rowData,
    classes,
    setRowData = () => {},
    setApiData = () => {},
  } = props;
  const [open, setOpen] = useState(false);
  const autoC = useRef(null);

  const handleTagsChange = (item, index, newVal) => {
    if (newVal.length === 0) {
      const changedRowData = rowData.data[index].map((el) => {
        if (el.name === item.name) {
          const newSuggestedRules = el.suggested_rules.map((elf) => ({
            ...elf,
            status: false,
          }));
          return { ...el, suggested_rules: newSuggestedRules };
        } else return el;
      });
      let finalRowData = [];
      let rowDatas = rowData.data;
      for (let i = 0; i < rowDatas.length; i++) {
        if (i === index) {
          finalRowData = [...finalRowData, changedRowData];
        } else {
          finalRowData = [...finalRowData, rowDatas[i]];
        }
      }
      setRowData({ status: "success", message: "", data: finalRowData });
      setApiData(finalRowData);
    } else {
      const changedRowData = rowData.data[index].map((el) => {
        if (el.name === item.name) {
          const newSuggestedRules = el.suggested_rules.map((elf) => {
            if (elf.name === newVal[newVal.length - 1]) {
              return {
                ...elf,
                status: true,
              };
            } else return elf;
          });
          return { ...el, suggested_rules: newSuggestedRules };
        } else return el;
      });
      let finalRowData = [];
      let rowDatas = rowData.data;
      for (let i = 0; i < rowDatas.length; i++) {
        if (i === index) {
          finalRowData = [...finalRowData, changedRowData];
        } else {
          finalRowData = [...finalRowData, rowDatas[i]];
        }
      }
      setRowData({ status: "success", message: "", data: finalRowData });
      setApiData(finalRowData);
    }
  };

  const deleteValues = (item, index, regValue) => {
    const changedRowData = rowData.data[index].map((el) => {
      if (el.name === item.name) {
        const newSuggestedRules = el.suggested_rules.map((elf) => {
          if (elf.name === regValue) {
            return {
              ...elf,
              status: false,
            };
          } else return elf;
        });
        return { ...el, suggested_rules: newSuggestedRules };
      } else return el;
    });
    let finalRowData = [];
    let rowDatas = rowData.data;
    for (let i = 0; i < rowDatas.length; i++) {
      if (i === index) {
        finalRowData = [...finalRowData, changedRowData];
      } else {
        finalRowData = [...finalRowData, rowDatas[i]];
      }
    }
    setRowData({ status: "success", message: "", data: finalRowData });
    setApiData(finalRowData);
  };
  const handleCheck = (item, index) => {
    const changedRowData = rowData.data[index].map((el) => {
      if (el.name === item.name) {
        return {
          ...el,
          map_rule: !el.map_rule,
        };
      } else return el;
    });
    let finalRowData = [];
    let rowDatas = rowData.data;
    for (let i = 0; i < rowDatas.length; i++) {
      if (i === index) {
        finalRowData = [...finalRowData, changedRowData];
      } else {
        finalRowData = [...finalRowData, rowDatas[i]];
      }
    }
    setRowData({ status: "success", message: "", data: finalRowData });
    setApiData(finalRowData);
  };

  return (
    <>
      <TableRow className={classes.upperTableRow}>
        <TableCell className={classes.arrowRow}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <span class="material-symbols-outlined">keyboard_arrow_up</span>
            ) : (
              <span class="material-symbols-outlined">keyboard_arrow_down</span>
            )}
          </IconButton>
        </TableCell>
        <TableCell
          className={classes.upperTableRowName}
        >{`${row.db_name}.${row.table_name}`}</TableCell>
        <TableCell style={{ width: "10%" }}>{}</TableCell>
        <TableCell style={{ width: "10%" }}>{}</TableCell>
        <TableCell style={{ width: "30%" }}>{}</TableCell>
        <TableCell style={{ width: "9%" }}>{}</TableCell>
        {/* <TableCell className={classes.upperTableRowName}>{}</TableCell>
        <TableCell className={classes.upperTableRowName}>{}</TableCell>
        <TableCell className={classes.upperTableRowName}>{}</TableCell> */}
      </TableRow>
      {/* <TableRow> */}
      {/* <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingRight: 0,
            paddingLeft: 0,
          }}
          colSpan={6}
        > */}
      {open &&
        // <Table size="small">
        //   <TableBody style={{ paddingLeft: "0px" }}>
        rowData.data[index].map((el) => (
          <TableRow key={el.column_name} className={classes.innerTableRow}>
            <>
              <TableCell style={{ width: "1% !important" }}></TableCell>
              <TableCell style={{ width: "10% !important" }}></TableCell>
              <TableCell
                style={{
                  width: "10%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {el.name}
              </TableCell>
              <TableCell
                style={{
                  width: "10%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {el.datatype}
              </TableCell>

              <TableCell
                style={{
                  width: "60%",
                  overflowY: "auto",
                  overflowX: "clip",
                }}
              >
                <DQMultiAutoComplete
                  value={el.suggested_rules
                    .filter((el) => el.status === true)
                    .map((el) => el.name)}
                  handleChange={(e, newValue) =>
                    handleTagsChange(el, index, newValue)
                  }
                  placeholderText="Add rules"
                  isDisabled={false}
                  autoC={autoC}
                  deleteValues={(deletedValue) =>
                    deleteValues(el, index, deletedValue)
                  }
                  optionsData={el.suggested_rules.map((el) => el.name) || []}
                  styles={{ height: 70, width: "94%" }}
                  limitTags={20}
                />
              </TableCell>
              <TableCell style={{ width: "9%" }}>
                <Checkbox
                  checked={el.map_rule === true ? true : false}
                  onClick={() => {
                    handleCheck(el, index);
                  }}
                />
              </TableCell>
            </>
          </TableRow>
        ))}
      {/* </TableBody>
            </Table> */}
      {/* </TableCell> */}
      {/* </TableRow> */}
    </>
  );
}

const ManageTableSelection = (props) => {
  const {
    rowData,
    setRowData = () => {},
    tableArray,
    setApiData = () => {},
  } = props;

  const classes = useStyles();
  let headCells = [
    {
      id: "icon",
      label: "",
      width: "1%",
      disableSorting: "disable",
    },
    {
      id: "icon",
      label: "Table Name",
      width: "10%",
      disableSorting: "disable",
    },
    {
      id: "icon",
      label: "List of columns",
      width: "10%",
      disableSorting: "disable",
    },
    {
      id: "icon",
      label: "Data Type",
      width: "10%",
      disableSorting: "disable",
    },
    {
      id: "icon",
      label: "Suggested Rules",
      width: "60%",
      disableSorting: "disable",
    },
    {
      id: "icon",
      label: "Map rules",
      width: "9%",
      disableSorting: "disable",
    },
  ];

  return (
    <Table stickyHeader className={classes.table}>
      <TableHead className={classes.tableHead}>
        <TableRow className={classes.tableRow}>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              width={headCell?.width}
              align={headCell?.align}
              className={classes.tableCellHeader}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        {tableArray.map((row, index) => (
          <Row
            key={`${row.db_name}.${row.table_name}`}
            row={row}
            index={index}
            rowData={rowData}
            classes={classes}
            setRowData={setRowData}
            setApiData={setApiData}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ManageTableSelection;
