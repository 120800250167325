//Import required libraies

import "./Filters.scss";

import { Fade, Menu, MenuItem, Popover } from "@material-ui/core";
import React, { useState } from "react";
import { defaultStyle, menuStyle } from "../../../styles/styles";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateSelections } from "../../redux/actions";

// Import action creators

// Import styles

const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#46596a !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

const useStyles = makeStyles(() => ({
  contained: {
    color: "white",
    backgroundColor: "#46596A",
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1.5px solid #46596A",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596A",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

function Filters(props) {
  const { flag, id, user, updateSelections, data, filterType } = props;
  const [localData, setLocalData] = useState({
    searchText: user.selections[id].searchText,
    value: user.selections[id].value,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChanges = () => {
    handleClose();
    updateSelections(id, {
      ...user.selections[id],
      searchText: localData.searchText,
      value: localData.value,
    });
  };

  const filteredData = () => {
    let arr = data.filter((item) =>
      item.toLowerCase().includes(localData.searchText.toLowerCase())
    );
    return arr;
  };

  return (
    <>
      {flag ? (
        <i className="material-icons filter-alt-icon" onClick={handleClick}>
          filter_list
        </i>
      ) : (
        <i
          className="material-icons-outlined filter-alt-icon"
          onClick={handleClick}
        >
          filter_alt
        </i>
      )}
      {filterType === "checkbox" ? (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="filter-container">
            <div className="filter-top">
              <div className="input-search">
                <i className={`material-icons-outlined search-icon `}>search</i>
                <input
                  type="text"
                  id="search"
                  name="search"
                  value={localData.searchText}
                  className="input-label"
                  onChange={(e) => {
                    setLocalData({
                      ...localData,
                      searchText: e.target.value,
                    });
                  }}
                  placeholder="Search"
                />
                <i
                  className={`material-icons-outlined cancel-icon ${
                    localData.searchText ? "visible" : ""
                  }`}
                  onClick={() => {
                    setLocalData({
                      ...localData,
                      searchText: "",
                    });
                  }}
                >
                  clear
                </i>
              </div>
              <div
                className="filter-selections"
                onClick={() => {
                  setLocalData({
                    ...localData,
                    value: filteredData(),
                  });
                }}
              >
                Select All
              </div>
              <div
                className="filter-selections"
                onClick={() => {
                  setLocalData({
                    ...localData,
                    value: [],
                  });
                }}
              >
                Clear
              </div>
            </div>
            <div className="checkbox-container">
              {filteredData().map((obj) => {
                const isChecked = localData.value.includes(obj);
                return (
                  <div key={obj} className="checkbox-item">
                    <StyledCheckbox
                      id={obj}
                      checked={isChecked}
                      onChange={() => {
                        setLocalData({
                          ...localData,
                          value: isChecked
                            ? localData.value.filter((elem) => elem !== obj)
                            : [...localData.value, obj],
                        });
                      }}
                    />
                    <label
                      htmlFor={obj}
                      style={{ userSelect: "none", cursor: "pointer" }}
                    >
                      {obj}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="button-container">
              <Button
                variant="outlined"
                className={classes.outlined}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.contained}
                onClick={handleChanges}
              >
                Done
              </Button>
            </div>
          </div>
        </Popover>
      ) : (
        <Menu
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          className={classes.menu}
          anchorEl={anchorEl}
          open={open}
          onClose={handleChanges}
          TransitionComponent={Fade}
        >
          {filteredData().map((item) => (
            <MenuItem
              onClick={() => {
                setLocalData({
                  ...localData,
                  value: item,
                });
                handleChanges();
              }}
              sx={localData.value === item ? menuStyle : defaultStyle}
            >
              {item}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}

Filters.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateSelections,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
