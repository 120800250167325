// * Import 3rd party
import { TextField } from "@material-ui/core";
import { styled } from "@material-ui/core";

const StyledTextField = styled(
  TextField,
  {}
)(({ theme, customProps }) => ({
  // height: "2em !important",
  margin: "0px 5px 5px",
  background: "white",
  border: 0,
  color: "#46596a",
  padding: "3px !important",
  borderRadius: "4px",
  width: "120px",
  "& .MuiInputLabel-formControl": {
    fontSize: "12px",
    paddingLeft: "5px",
  },
  "& .MuiFormControl-root": {
    background: "white",
    width: "350px",
    boxSizing: "border-box",
    height: "48px !important",
    padding: "5px",
    "&:hover": {
      border: "1px solid #dedede",
    },
    "&::placeholder": {
      color: "#ff0000",
    },
    "& .MuiInput-formControl": {
      padding: "0 !important",
    },
  },

  "& .MuiInputBase-root": {
    "&::before": {
      borderBottom: "none !important",
    },
    "&::after": {
      borderBottom: "none !important",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "12px",
    border: "1px solid #dedede",
    borderRadius: "4px",
    height: "9px",
    width: "150px",
    marginTop: "4px",
    paddingLeft: "5px",
  },
  "& .MuiAutocomplete-inputFocused": {
    margin: 0,
    border: 0,
  },
  "& .MuiAutocomplete-inputRoot": {
    fontSize: "12px",
    border: "1px solid #dedede",
    borderRadius: "4px",
    height: "30px",
    width: "150px",
    marginBottom: "12px",
    alignContent: "center",
    paddingRight: 0,
  },
  "& .MuiChip-label": {
    fontSize: "14px",
  },
  "& .MuiTextField-root": {
    border: "1px solid #dedede",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent !important",
  },
}));

export default StyledTextField;
