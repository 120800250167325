// Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Import Custom Component
import Search from "../../components/Search/Search";
import Loader from "../../components/Loader/Loader";

import noDataImage from "../../../assets/images/undraw_void.svg";

import {
  getGlobalRulesList,
  requestNewRuleApi,
  getTableSummary,
  downloadGlobalRulesSummary,
  getAllRulesDownload,
} from "../../services/apis";
import {
  filterGlobalRules,
  filterSummaryRules,
  downloadCsvFile,
} from "../../utils/utils";
import {
  updateAlertInfo,
  updateData,
  updateSelections,
} from "../../redux/actions";
import GlobalRulesListTable from "./GlobalRulesListTable/GlobalRulesListTable";
import SummaryTable from "./GlobalRulesListTable/SummaryTable";

import "./GlobalRulesManager.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import DQButton from "../../components/Common/DQButton";
import DQInput from "../../components/Common/DQInput";
import config from "../../config/config";
import ManageRules from "../../components/ManageRules/ManageRules";

const useStyles = makeStyles(() => ({
  contained: {
    "&:disabled": {
      backgroundColor: "#A4D4D7",
      cursor: "default",
    },
    root: {
      width: "100%",
      flexGrow: 1,
    },
    minWidth: "100px",
    color: "white",
    width: "max-content",
    backgroundColor: "#46596a",
    border: "0px",
    fontSize: "12px",
    padding: "15px",
    fontFamily: "Hind Siliguri",
    textTransform: "none",
    marginRight: "10px",
    fontWeight: "700",
    "&:hover": {
      borderColor: "#46596a",
      backgroundColor: "#46596a",
      color: "white",
    },
  },
  dialogContentRoot: {
    padding: "8px 16px !important",
  },
  paperDialog: {
    // width: "85vw",
    // height: "85vh",
  },
}));

const GlobalRulesManager = (props) => {
  const { user, data, updateData, updateSelections, updateAlertInfo } = props;
  const datasetsObj = data.globalRulesList.result;
  const classes = useStyles();
  const [filteredData, setFilteredData] = useState(datasetsObj || []);
  const [filteredSummaryData, setFilteredSummaryData] = useState(
    data.summaryTableList.result || []
  );
  const [openRulesManage, setOpenRulesManage] = useState(false);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [requestNewRuleName, setRequestNewRuleName] = useState("");
  const [value, setValue] = React.useState(0);
  const [tab, setTab] = React.useState("globalRules");
  const [requestNewRuleDescription, setRequestNewRuleDescription] =
    useState("");

  const handleCloseRulesManage = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpenRulesManage(false);
    }
  };

  const handleCloseRequestDialog = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setRequestNewRuleName("");
      setRequestNewRuleDescription("");
      setOpenRequestDialog(false);
    }
  };

  const openRequestNewRulePopup = () => {
    setOpenRequestDialog(true);
  };

  const openManageRulesPopup = () => {
    // setOpenRulesManage(true);
    setTab("summaryTable");
  };

  const handleRuleNameChange = (val) => {
    setRequestNewRuleName(val);
  };

  const handleRuleDescChange = (val) => {
    setRequestNewRuleDescription(val);
  };

  const requestNewRule = async () => {
    updateAlertInfo({
      open: true,
      message: config.messages.requestRuleMessage,
      severity: "info",
    });
    let body = {
      name: requestNewRuleName,
      rule_description: requestNewRuleDescription,
    };
    let respData = await requestNewRuleApi(body);
    if (respData) {
      handleCloseRequestDialog();
      updateAlertInfo({
        open: true,
        message: config.messages.successRequestRuleMessage,
        severity: "success",
      });
    } else {
      updateAlertInfo({
        open: true,
        message: config.messages.errorRequestRuleMessage,
        severity: "error",
      });
    }
  };

  const fetchGlobalRulesList = async () => {
    updateData("globalRulesList", {
      status: "loading",
      result: [],
      message: "",
    });
    let globalRules = await getGlobalRulesList();
    if (globalRules && globalRules.length > 0) {
      updateData("globalRulesList", {
        status: "success",
        result: globalRules,
        message: "",
      });
    } else {
      updateData("globalRulesList", {
        status: "error",
        result: [],
        message: "Could not load Rules! Please try again later!",
      });
    }
  };

  const fetchSummaryRulesList = async () => {
    updateData("summaryTableList", {
      status: "loading",
      result: [],
      message: "",
    });
    let summaryRules = await getTableSummary({});
    if (summaryRules && summaryRules.length > 0) {
      updateData("summaryTableList", {
        status: "success",
        result: summaryRules,
        message: "",
      });
    } else {
      updateData("summaryTableList", {
        status: "error",
        result: [],
        message: "Could not load Rules! Please try again later!",
      });
    }
  };

  useEffect(() => {
    fetchGlobalRulesList();
    fetchSummaryRulesList();
    return () => {
      updateSelections("searchText", "");
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updatedData = filterGlobalRules(
      data.globalRulesList.result,
      user.selections
    );
    setFilteredData(updatedData);
    // eslint-disable-next-line
  }, [user.selections, data.globalRulesList]);
  useEffect(() => {
    const updatedData = filterSummaryRules(
      data.summaryTableList.result,
      user.selections
    );
    setFilteredSummaryData(updatedData);

    // eslint-disable-next-line
  }, [user.selections, data.summaryTableList]);

  const ruleAndDesCheck = () => {
    if (requestNewRuleName && requestNewRuleDescription) {
      return false;
    }
    return true;
  };

  const handleChange = (e, index) => {
    setValue(index);
  };
  const handleGoBackToGlobalRules = () => {
    setTab("globalRules");
  };

  const handleDownloadAsCSV = async () => {
    try {
      updateAlertInfo({
        open: true,
        message: config.messages.waitForDownload,
        severity: "success",
      });
      let reportDetails = await downloadGlobalRulesSummary({});
      downloadCsvFile(reportDetails, "Summary Tables");
      updateAlertInfo({
        open: true,
        message: config.messages.successSummaryTableCSV,
        severity: "success",
      });
    } catch (error) {
      updateAlertInfo({
        open: true,
        message: config.messages.errorSummaryTableCSV,
        severity: "error",
      });
    }
  };

  const handleDownloadGlobalRulesAsCSV = async () => {
    try {
      updateAlertInfo({
        open: true,
        message: config.messages.waitForDownload,
        severity: "success",
      });
      let reportDetails = await getAllRulesDownload({});
      downloadCsvFile(reportDetails, "Global Rules");
      updateAlertInfo({
        open: true,
        message: config.messages.successSummaryTableCSV,
        severity: "success",
      });
    } catch (error) {
      updateAlertInfo({
        open: true,
        message: config.messages.errorSummaryTableCSV,
        severity: "error",
      });
    }
  };

  let tabNames = [];
  if (tab === "globalRules") {
    tabNames = [{ name: "Global Rules" }];
  } else if (tab === "summaryTable") {
    tabNames = [{ name: "Summary Tables" }];
  }
  return (
    <>
      <div className={classes.root}>
        <div className="models-menu">
          {tabNames.map((tabName, index) => (
            <div
              key={tabName.name}
              className={`models-item`}
              onClick={(e) => {
                handleChange(e, index);
              }}
            >
              <div
                className={`models-item-div ${
                  index === value ? "active" : "not-active"
                }`}
              >
                <p>{tabName.name}</p>
              </div>
            </div>
          ))}
        </div>
        {tab === "globalRules" && (
          <>
            <div className="rules-manager-search">
              <Search
                placeholderText="Search Rule Name"
                user={user}
                updateSelections={updateSelections}
              />
              <div className="rules-manager-btns">
                <Button
                  variant="contained"
                  className={classes.contained}
                  onClick={handleDownloadGlobalRulesAsCSV}
                >
                  Download as CSV
                </Button>

                <Button
                  variant="contained"
                  className={classes.contained}
                  onClick={() => openManageRulesPopup()}
                >
                  Manage Rules
                </Button>
                <Button
                  variant="contained"
                  className={classes.contained}
                  onClick={openRequestNewRulePopup}
                >
                  Request New Rule
                </Button>
              </div>
            </div>
            {data.globalRulesList.status === "loading" && <Loader />}
            {data.globalRulesList.status === "success" && (
              <GlobalRulesListTable
                data={filteredData}
                allData={data.globalRulesList.result}
                paginationFlag={true}
                updateSelections={updateSelections}
              />
            )}
            {data.globalRulesList.status === "error" && (
              <div className="no-configs">
                {" "}
                <img src={noDataImage} alt="noDataImage" />
                <p className="no-data">
                  <b>{data.globalRulesList.message}</b>
                </p>
              </div>
            )}
            <Dialog
              fullWidth={true}
              maxWidth={"lg"}
              open={openRequestDialog}
              onClose={handleCloseRequestDialog}
              aria-labelledby="max-width-dialog-title"
              classes={{ paper: classes.paperDialog }}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {"Request New Rule"}
                  <i
                    className="material-icons-outlined"
                    onClick={handleCloseRequestDialog}
                  >
                    close
                  </i>
                </Grid>
              </DialogTitle>

              <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <div className="input-field-container">
                  <label>Rule Name:</label>
                  <div className="support-input-search">
                    <DQInput
                      name="ruleName"
                      value={requestNewRuleName}
                      placeholder="Enter Rule Name here"
                      isDisabled={false}
                      handleChange={handleRuleNameChange}
                    />
                  </div>
                </div>
                <div className="input-field-container">
                  <label>Rule Description:</label>
                  <div className="support-input-search">
                    <textarea
                      className="input-textarea"
                      value={requestNewRuleDescription}
                      onChange={(e) => handleRuleDescChange(e.target.value)}
                      placeholder="Enter rule logic, rule criteria and rule description here"
                    ></textarea>
                  </div>
                </div>
                <div className="requester-email-container">
                  <label>Requester Email:</label>
                  <span className="requester-email">{user.email || "NA"}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <DQButton
                  onclick={handleCloseRequestDialog}
                  title="Cancel"
                  variant="outlined"
                />
                <DQButton
                  onclick={requestNewRule}
                  title="Done"
                  variant="contained"
                  disabled={ruleAndDesCheck()}
                />
              </DialogActions>
            </Dialog>
            {/* <Dialog
              fullWidth={true}
              maxWidth="lg"
              open={openRulesManage}
              onClose={handleCloseRulesManage}
              aria-labelledby="max-width-dialog-title"
              classes={{ paper: classes.paperDialog }}
            >
              <DialogTitle>Manage Rules</DialogTitle>
              <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <ManageRules />
              </DialogContent>
              <DialogActions>
                <DQButton
                  onclick={handleCloseRulesManage}
                  title="Done"
                  variant="contained"
                />
              </DialogActions>{" "}
            </Dialog> */}
          </>
        )}

        {tab === "summaryTable" && (
          <>
            <div
              className="rules-manager-search"
              style={{
                height: "42px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* <Search
                placeholderText="Search Rule Name"
                user={user}
                updateSelections={updateSelections}
              /> */}

              <div className="rules-manager-btns">
                <Button
                  variant="contained"
                  className={classes.contained}
                  onClick={handleGoBackToGlobalRules}
                >
                  Go back to Global Rules
                </Button>
                <Button
                  variant="contained"
                  className={classes.contained}
                  onClick={handleDownloadAsCSV}
                >
                  Download as CSV
                </Button>
              </div>
            </div>
            {data.summaryTableList.status === "loading" && <Loader />}
            {data.summaryTableList.status === "success" && (
              <SummaryTable
                data={filteredSummaryData}
                allData={data.summaryTableList.result}
                paginationFlag={true}
                updateSelections={updateSelections}
              />
            )}
            {data.summaryTableList.status === "error" && (
              <div className="no-configs">
                {" "}
                <img src={noDataImage} alt="noDataImage" />
                <p className="no-data">
                  <b>{data.summaryTableList.message}</b>
                </p>
              </div>
            )}
            <Dialog
              fullWidth={true}
              maxWidth={"lg"}
              open={openRequestDialog}
              onClose={handleCloseRequestDialog}
              aria-labelledby="max-width-dialog-title"
              classes={{ paper: classes.paperDialog }}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {"Request New Rule"}
                  <i
                    className="material-icons-outlined"
                    onClick={handleCloseRequestDialog}
                  >
                    close
                  </i>
                </Grid>
              </DialogTitle>

              <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <div className="input-field-container">
                  <label>Rule Name:</label>
                  <div className="support-input-search">
                    <DQInput
                      name="ruleName"
                      value={requestNewRuleName}
                      placeholder="Enter Rule Name here"
                      isDisabled={false}
                      handleChange={handleRuleNameChange}
                    />
                  </div>
                </div>
                <div className="input-field-container">
                  <label>Rule Description:</label>
                  <div className="support-input-search">
                    <textarea
                      className="input-textarea"
                      value={requestNewRuleDescription}
                      onChange={(e) => handleRuleDescChange(e.target.value)}
                      placeholder="Enter rule logic, rule criteria and rule description here"
                    ></textarea>
                  </div>
                </div>
                <div className="requester-email-container">
                  <label>Requester Email:</label>
                  <span className="requester-email">{user.email || "NA"}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <DQButton
                  onclick={handleCloseRequestDialog}
                  title="Cancel"
                  variant="outlined"
                />
                <DQButton
                  onclick={requestNewRule}
                  title="Done"
                  variant="contained"
                  disabled={ruleAndDesCheck()}
                />
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth="lg"
              open={openRulesManage}
              onClose={handleCloseRulesManage}
              aria-labelledby="max-width-dialog-title"
              classes={{ paper: classes.paperDialog }}
            >
              <DialogTitle>Manage Rules</DialogTitle>
              <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <ManageRules />
              </DialogContent>
              <DialogActions>
                <DQButton
                  onclick={handleCloseRulesManage}
                  title="Done"
                  variant="contained"
                />
              </DialogActions>{" "}
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

GlobalRulesManager.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateSelections,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalRulesManager);
