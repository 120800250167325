//Import required libraies
import React from "react";

import CustomCheckbox from "./components/CustomCheckbox";
import CustomDropdown from "./components/CustomDropdown";
import CustomDropdownWithText from "./components/CustomDropdownWithText";
import MultipleRules from "./components/MultipleRules";
import CustomComponent from "./components/CustomComponent";
import FieldCompareComponent from "./components/FieldCompareComponent";
import CustomTypography from "./components/CustomTypography";
import CustomInputNumber from "./components/CustomInputNumber";
import CustomMultiSelect from "./components/CustomMultiSelect";
import CustomSelect from "./components/CustomSelect";
import IsExistingRule from "./components/IsExistingRule";

function RulesComponent(props) {
  const { display_type } = props;
  let Component;

  switch (display_type) {
    case "typography":
      Component = <CustomTypography {...props} />;
      break;
    case "input_number":
      Component = <CustomInputNumber {...props} />;
      break;
    case "select":
      Component = <CustomSelect {...props} />;
      break;
    case "multi_select":
      Component = <CustomMultiSelect {...props} />;
      break;
    case "isExisting_rule":
      Component = <IsExistingRule {...props} />;
      break;
    case "dropdown_with_text":
      Component = <CustomDropdownWithText {...props} />;
      break;
    case "checkbox":
      Component = <CustomCheckbox {...props} />;
      break;
    case "dropdown":
      Component = <CustomDropdown {...props} />;
      break;
    case "multiple_rules":
      Component = <MultipleRules {...props} />;
      break;
    case "field_compare_rule":
      Component = <FieldCompareComponent {...props} />;
      break;
    case "custom":
      Component = <CustomComponent {...props} />;
      break;
    default:
      Component = <p>{display_type}</p>;
  }
  return Component;
}

export default RulesComponent;
