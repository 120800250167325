// Import required libraies
import React from "react";
import { Box, LinearProgress, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#c5cfd9",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#4eafb3 !important",
    },
  },
});

function LinearProgressWithLabel(props) {
  const { value } = props;

  const classes = useStyles();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          className={classes.root}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="4eafb3">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;
