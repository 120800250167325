//Import required libraies
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "120px",
    height: "24px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
      borderRadius: "4px",
    },
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "4px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function CustomSelect(props) {
  let {
    onChange,
    id,
    columnName,
    columnDatatype,
    dataElem,
    name,
    optionsData,
    value,
    uniqueKey,
    valueObj,
    placeholder,
  } = props;
  const classes = useStyles();

  return (
    <div style={{ display: "flex", margin: "0px 5px" }}>
      {optionsData?.length ? (
        <Select
          value={
            dataElem.name === "condition" && value
              ? optionsData?.find((elem) => elem.id === value)?.name
              : value
          }
          onChange={(e) => {
            onChange(id, {
              isChecked: true,
              id,
              name,
              columnName,
              uniqueKey,
              groupdId: -1,
              columnDatatype: columnDatatype,
              value: { ...valueObj, [dataElem.name]: e.target.value },
            });
          }}
          displayEmpty
          renderValue={(selected) => {
            if (selected === "") {
              return placeholder || <p>Select option</p>;
            }
            return selected;
          }}
          className={classes.multiSelect}
          MenuProps={MenuProps}
          title={
            dataElem.name === "condition" && value
              ? optionsData?.find((elem) => elem.id === value)?.name
              : value
          }
        >
          {optionsData?.map((obj, index) => (
            <MenuItem
              key={index}
              value={dataElem.name === "condition" ? obj.id : obj.name}
              className={classes.singleMenuItem}
              title={dataElem.name === "condition" ? obj.id : obj.name}
            >
              {obj.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          value="No Options Available"
          renderValue={(selected) => {
            return selected;
          }}
          disabled
          className={classes.multiSelect}
        >
          <MenuItem disabled className={classes.menuItem}>
            No Options
          </MenuItem>
        </Select>
      )}
    </div>
  );
}

export default CustomSelect;
