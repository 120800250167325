import "./DiagnosticReport.scss";

import React, { useEffect, useRef, useState } from "react";
import {
  getTableDiagnosticReportDetails,
  getTableDiagnosticReportFilteredDetails,
  getTableDiagnosticReportFilters,
  syncDRFiltersApi,
} from "../../services/apis";
import {
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../redux/actions";

import DomainHierarchy from "./DomainHierarchy/DomainHierarchy";
import Grid from "@material-ui/core/Grid";
import ReportSideLeft from "./ReportSideLeft/ReportSideLeft";
import ReportSideRight from "./ReportSideRight/ReportSideRight";
import { addCsvFriendlySentenceForDiagnosticReport } from "./diagnosticReportUtils";
import config from "../../config/config";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useNonInitialEffect } from "../../hooks/useNonInitialEffect";
import { v4 } from "uuid";

// Import utils, data

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& h5": {
      fontWeight: "600",
      color: "#d11450",
    },
  },
}));

const DiagnosticReport = ({
  data,
  user,
  userInputs,
  updateData,
  currentScreen,
  updateUserInputs,
  updateSelections,
}) => {
  const classes = useStyles();
  const [runDateId, setRunDateId] = useState("");
  const [runDateObj, setRunDateObj] = useState({});
  const checkRef = useRef(0);
  const reportLogId = useRef(-1);
  const [reportDetailsApi, setReportDetailsApi] = useState();

  useEffect(() => {
    if (currentScreen) {
      updateUserInputs("diagReportCurrentScreen", currentScreen);
    }
    return () => {
      updateUserInputs("idForReportSelected", "");
      updateUserInputs("newidForReportSelected", "");
      updateData("tableHistoricalRunDetails", {
        status: "info",
        message: "",
        result: {},
      });
      updateData("tableDiagnosticReportDetails", {
        status: "info",
        message: "",
        result: {},
      });
      updateData("tableDiagnosticReportFilters", {
        status: "info",
        message: "",
        result: {},
      });
    };
    // eslint-disable-next-line
  }, []);

  const getRightSideReport = async (item) => {
    if (!item) {
      updateData("tableDiagnosticReportDetails", {
        status: "error",
        message: "No Data found! Please try to select another table!",
        result: [],
      });
    } else {
      if (userInputs.idForReportSelected && item.id) {
        setRunDateId(item.id);
        setRunDateObj(item);
        updateData("tableDiagnosticReportDetails", {
          status: "loading",
          message: "",
          result: [],
        });
        let mapped = Object.keys(user.selections.DRFilters)?.map((item) => ({
          [item]: Array.isArray(user.selections.DRFilters[item])
            ? user.selections.DRFilters[item].map((itm) => itm.name)
            : user.selections.DRFilters[item],
        }));
        let filters = Object.assign({}, ...mapped);

        let reqBody = {
          id: userInputs.idForReportSelected,
          diagnostic_report_id: item.id,
          filters: {
            ...filters,
          },
        };

        let reportDetails = {};
        if (reportLogId.current > 0) {
          var reportData = await getTableDiagnosticReportFilteredDetails(
            reportLogId.current
          );
          setReportDetailsApi(reportData);
          reportDetails = reportData.result;
          reportLogId.current = -1;
        } else {
          reportDetails = await getTableDiagnosticReportDetails(reqBody);
        }

        if ("report_log_id" in reportDetails) {
          reportLogId.current = reportDetails.report_log_id;

          const intervalId = setInterval(async () => {
            let reportFilteredCheck =
              await getTableDiagnosticReportFilteredDetails(
                reportDetails.report_log_id
              );
            if (reportFilteredCheck?.status) {
              getRightSideReport(runDateObj);
              clearInterval(checkRef.current);
            }
          }, 5000);

          checkRef.current = intervalId;

          return;
        } else {
          if (reportDetails && Object.keys(reportDetails).length > 0) {
            let reportDetailsWithUserInputs =
              await addCsvFriendlySentenceForDiagnosticReport(
                reportDetails?.report_details || []
              );
            let finalResult = {
              ...reportDetails,
              report_details: reportDetailsWithUserInputs,
            };
            updateData("tableDiagnosticReportDetails", {
              status: "success",
              message: "",
              result: JSON.parse(JSON.stringify(finalResult)),
            });
          } else {
            updateData("tableDiagnosticReportDetails", {
              status: "error",
              message: config.messages.noRecordFound,
              result: [],
            });
          }
        }
      }
    }
  };

  const getRightSideFilters = async (itm) => {
    if (!itm) {
      updateData("tableDiagnosticReportFilters", {
        status: "error",
        message: "No Data Found! PLease try to select another table!",
        result: {},
      });
    } else {
      setRunDateId(itm.id);
      setRunDateObj(itm);
      updateData("tableDiagnosticReportFilters", {
        status: "loading",
        message: "",
        result: {},
      });
      updateData("tableDiagnosticReportDetails", {
        status: "loading",
        message: "",
        result: [],
      });
      let reportFilters = await getTableDiagnosticReportFilters({
        id: userInputs.idForReportSelected,
        diagnostic_report_id: itm.id,
      });
      if (reportFilters && Object.keys(reportFilters).length > 0) {
        let updatedReportFiltersData = reportFilters.map((obj) => ({
          ...obj,
          key: v4(),
        }));
        updateData("tableDiagnosticReportFilters", {
          status: "success",
          message: "",
          result: updatedReportFiltersData,
        });
        let mapped = updatedReportFiltersData.map((item) => ({
          [item.name]: item.defaultValue
            ? item.defaultValue
            : item.type === "multiSelect"
            ? [{ id: "ALL", name: "ALL" }]
            : "ALL",
        }));
        let newObj = Object.assign({}, ...mapped);
        updateSelections("DRFilters", newObj);
        updateUserInputs("initialDRFilters", newObj);
      } else if (Object.keys(reportFilters).length === 0) {
        updateData("tableDiagnosticReportFilters", {
          status: "success",
          message: "",
          result: {},
        });
        getRightSideReport(itm);
      } else {
        updateData("tableDiagnosticReportFilters", {
          status: "error",
          message: config.messages.noRecordFound,
          result: {},
        });
        updateData("tableDiagnosticReportDetails", {
          status: "error",
          message: config.messages.noRecordFound,
          result: [],
        });
      }
    }
  };

  const syncDRFilters = async (runDateObj) => {
    updateData("tableDiagnosticReportFilters", {
      status: "loading",
      message: "",
      result: {},
    });
    let body = {
      id: userInputs.idForReportSelected,
      sync_columns: [],
    };
    let syncResponse = await syncDRFiltersApi(body);
    if (syncResponse && syncResponse.length) {
      getRightSideFilters(runDateObj);
    } else {
      updateData("tableDiagnosticReportFilters", {
        status: "error",
        message: config.messages.noRecordFound,
        result: {},
      });
    }
  };

  useNonInitialEffect(() => {
    clearInterval(checkRef.current);
    if (JSON.stringify(user.selections.DRFilters) !== "{}") {
      getRightSideReport(runDateObj);
    }
  }, [user.selections.DRFilters]);

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          alignContent="space-between"
          style={{ height: "calc(100vh - 150px)" }}
        >
          <Grid
            item
            xs={3}
            style={{
              padding: "10px",
              overflowY: "scroll",
              overflowX: "hidden",
              height: "inherit",
              maxWidth: "100%",
            }}
          >
            {userInputs.diagReportCurrentScreen === "reportData" ? (
              <ReportSideLeft
                getRightSideReport={getRightSideReport}
                getRightSideFilters={getRightSideFilters}
              />
            ) : (
              <DomainHierarchy />
            )}
          </Grid>
          <Grid
            item
            xs={9}
            style={{
              backgroundColor: "#f5f5f5",
              padding: "10px",
              height: "inherit",
              overflow: "scroll",
            }}
          >
            <ReportSideRight
              runDateId={runDateId}
              getRightSideFilters={getRightSideFilters}
              runDateObj={runDateObj}
              syncDRFilters={syncDRFilters}
              reportDetailsApi={reportDetailsApi}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateData,
  updateUserInputs,
  updateSelections,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticReport);
