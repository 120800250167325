import C from "../constants";
import { rulesValidations } from "../../components/RulesSelection/schema";

export default function rulesItAndBusiness(state = [], action) {
  // let {
  //   allRulesData = [], // ! Adding allRulesData here as it might be used in validation later
  // } = action;
  let newState = [...state];

  switch (action.type) {
    case C.UPDATE_RULES_IT_AND_BUSINESS_FORM_FIELD:
      newState = state.map((row) =>
        row.name !== action.name
          ? row
          : {
              ...row,
              value: {
                ...row.value,
                [action.formKey]: action.formValue,
                ...(action.formKey !== "isEnabled" ? { isEnabled: true } : {}),
              },
            }
      );
      break;
    case C.REPLACE_IT_AND_BUSINESS_FORM:
      newState = action.formSelections;
      break;
    default:
      return state;
  }

  // TODO : Add Validations Here
  newState = rulesValidations(newState);
  return newState;
}
