import { createTheme } from "@material-ui/core";

// * Define util func
function hexToRGBA(hex = "#ffffff", opacity = 1) {
  return (
    "rgba(" +
    (hex = hex.replace("#", ""))
      .match(new RegExp("(.{" + hex.length / 3 + "})", "g"))
      .map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16);
      })
      .concat(isFinite(opacity) ? opacity : 1)
      .join(",") +
    ")"
  );
}

var theme = createTheme({
  palette: {
    hexToRGBA,
    type: "light",
    primary: {
      main: "#fc007f",
    },
    secondary: {
      main: "#4EAFB3",
    },
    grey: {
      0: "#FFFFFF",
    },
    text: {
      primary: "#46596a",
      secondary: "#4EAFB3",
      tertiary: "#fc007f",
    },
    greenSuccess: {
      main: "#16A33D",
    },
  },
  typography: {
    fontFamily: "Hind Siliguri",
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    },
    MuiTooltip: {
      arrow: false,
    },
  },
  shape: {
    border: "1px solid rgba(70, 90, 105, 0.5)",
    borderRadius2: 2,
    borderRadius4: 4,
  },
  spacing: 8,
  transitions: { duration: { blazingFast: 100 } },
});

export default theme;
