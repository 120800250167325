//Import required libraies
import React from "react";
import { Route } from "react-router-dom";

//Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

function DQAppContainer(props) {
  const { match, children } = props;
  return (
    <Route>
      <LayoutTopSideBottom match={match}>
        <Breadcrumbs match={match} />
        {children}
      </LayoutTopSideBottom>
    </Route>
  );
}

export default DQAppContainer;
