/* eslint-disable indent */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell, Box } from "@material-ui/core";
import { useStyles } from "../../../../utils/style";
import Filters from "../../../../components/Filters/Filters";

import "./ColumnListGlobalRulesTable.scss";

const ColumnListGlobalRulesTableHeader = (props) => {
  const { user, fetchedData } = props;
  const classes = useStyles();
  const allDatasetNames = [
    ...new Set(fetchedData?.map((item) => item.dataset_name)),
  ];
  const allDatabasesNames = [
    ...new Set(fetchedData?.map((item) => item.database_name)),
  ];
  const allTableNames = [
    ...new Set(fetchedData?.map((item) => item.table_name)),
  ];
  const allColumnNames = [
    ...new Set(fetchedData?.map((item) => item.column_name)),
  ];
  return (
    <TableHead>
      <TableRow>
        {/* Remove this row on Aditiya Request */}
        {/* <TableCell
          className={classes.tablecell}
          style={{ padding: "10px" }}
          align="left"
        >
          <div className="header-with-filter">
            <Box>Dataset Name</Box>
            <Filters
              flag={
                user.selections.datasetNameForGRM.value?.length ? true : false
              }
              id="datasetNameForGRM"
              data={allDatasetNames}
              filterType="checkbox"
            />
          </div>
        </TableCell> */}
        <TableCell className={classes.tablecell}>
          <div className="header-with-filter">
            <Box>Database Name</Box>
            <Filters
              flag={
                user.selections.databaseNameForGRM.value?.length ? true : false
              }
              id="databaseNameForGRM"
              data={allDatabasesNames}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <div className="header-with-filter">
            <Box>Table Name</Box>
            <Filters
              flag={
                user.selections.tableNameForGRM.value?.length ? true : false
              }
              id="tableNameForGRM"
              data={allTableNames}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <div className="header-with-filter">
            <Box>Column Name</Box>
            <Filters
              flag={
                user.selections.columnNameForGRM.value?.length ? true : false
              }
              id="columnNameForGRM"
              data={allColumnNames}
              filterType="checkbox"
            />
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

ColumnListGlobalRulesTableHeader.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ColumnListGlobalRulesTableHeader);
