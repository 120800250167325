// Import required libraies

import "./MyDatasets.scss";

import React, { useEffect, useState } from "react";
import {
  getLayersData,
  getMyDataset,
  getPrimaryDomainOptionsWithoutId,
  getSecondaryDomainOptionsWithoutId,
  getSubDataDomainOptionsWithoutId,
} from "../../../services/apis";
import { updateData, updateSelections } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../components/Loader/Loader";
import Search from "../../../components/Search/Search";
import Table from "../../../components/MyDatasetTable/Table";
import { filterMyData } from "../../../utils/utils";
import noDataImage from "../../../../assets/images/undraw_void.svg";

// Import Custom Component

// Import data & utils

// Import actions

// Import styles

// # COMPONENT
const MyDatasets = (props) => {
  // # HOOKS
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.data);
  const changeFlag = useSelector((state) => state.changeFlag);
  const datasetsObj = data.myDataset.result;
  const [filteredData, setFilteredData] = useState(datasetsObj || []);

  // # SIDE EFFECTS
  useEffect(() => {
    return () => {
      dispatch(updateSelections("searchText", ""));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // fetch catalog first

    const fetchData = async () => {
      dispatch(
        updateData("myDataset", {
          status: "loading",
          message: "",
          result: [],
        })
      );
      let tableData = await getMyDataset();
      if (tableData && tableData?.length > 0) {
        dispatch(
          updateData("myDataset", {
            status: "success",
            message: "",
            result: tableData,
          })
        );
      } else {
        dispatch(
          updateData("myDataset", {
            status: "error",
            message: "No result found! Please try again Later!",
            result: [],
          })
        );
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [changeFlag.reloadData]);

  useEffect(() => {
    const fetchPrimaryOptionsData = async () => {
      dispatch(
        updateData("primaryDomainData", {
          status: "loading",
          message: "",
          result: [],
        })
      );
      let primaryDomainOptions = await getPrimaryDomainOptionsWithoutId();
      if (primaryDomainOptions && primaryDomainOptions?.length > 0) {
        dispatch(
          updateData("primaryDomainData", {
            status: "success",
            message: "",
            result: primaryDomainOptions,
          })
        );
      } else {
        dispatch(
          updateData("primaryDomainData", {
            status: "error",
            message: "No result found! Please try again Later!",
            result: [],
          })
        );
      }
    };
    fetchPrimaryOptionsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchSecondaryOptionsData = async () => {
      dispatch(
        updateData("secondaryDomainData", {
          status: "loading",
          message: "",
          result: [],
        })
      );
      let secondaryDomainOptions = await getSecondaryDomainOptionsWithoutId();
      if (secondaryDomainOptions && secondaryDomainOptions?.length > 0) {
        dispatch(
          updateData("secondaryDomainData", {
            status: "success",
            message: "",
            result: secondaryDomainOptions,
          })
        );
      } else {
        dispatch(
          updateData("secondaryDomainData", {
            status: "error",
            message: "No result found! Please try again Later!",
            result: [],
          })
        );
      }
    };
    fetchSecondaryOptionsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchSubOptionsData = async () => {
      dispatch(
        updateData("subDomainData", {
          status: "loading",
          message: "",
          result: [],
        })
      );
      let subDomainOptions = await getSubDataDomainOptionsWithoutId();
      if (subDomainOptions && subDomainOptions?.length > 0) {
        dispatch(
          updateData("subDomainData", {
            status: "success",
            message: "",
            result: subDomainOptions,
          })
        );
      } else {
        dispatch(
          updateData("subDomainData", {
            status: "error",
            message: "No result found! Please try again Later!",
            result: [],
          })
        );
      }
    };
    fetchSubOptionsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchDataLayerData = async () => {
      dispatch(
        updateData("dataLayerData", {
          status: "loading",
          message: "",
          result: [],
        })
      );
      let dataLayerData = await getLayersData();
      if (dataLayerData && dataLayerData?.length > 0) {
        dispatch(
          updateData("dataLayerData", {
            status: "success",
            message: "",
            result: dataLayerData,
          })
        );
      } else {
        dispatch(
          updateData("dataLayerData", {
            status: "error",
            message: "No result found! Please try again Later!",
            result: [],
          })
        );
      }
    };
    fetchDataLayerData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updatedData = filterMyData(data.myDataset.result, user.selections);
    setFilteredData(updatedData);
    // eslint-disable-next-line
  }, [user.selections, data.myDataset]);

  // # JSX
  return (
    <>
      <div className="standard-dataset-register-container">
        <Search
          placeholderText="Search Tables"
          user={user}
          updateSelections={(key, data) => {
            dispatch(updateSelections(key, data));
          }}
        />
      </div>
      {data.myDataset.status === "success" ? (
        <Table data={filteredData} paginationFlag={true} />
      ) : data.myDataset.status === "loading" ? (
        <div className="mydataset-loader">
          <Loader />
        </div>
      ) : (
        <div className="no-configs">
          {" "}
          <img src={noDataImage} alt="noDataImage" />
          <p className="no-data">
            <b>{data.myDataset.message}</b>
          </p>
        </div>
      )}
    </>
  );
};

// # EXPORTS
export default MyDatasets;
