import C from "./constants";

export const toggleSidenav = (data) => ({
  type: C.TOGGLE_SIDENAV,
});

export const updateData = (key, data) => ({
  type: C.UPDATE_DATA,
  key,
  data,
});

export const updateUserInputs = (key, data) => ({
  type: C.UPDATE_USER_INPUTS,
  key,
  data,
});

export const updateSelections = (key, data) => ({
  type: C.UPDATE_SELECTIONS,
  key,
  data,
});

export const resetSelections = (key) => ({
  type: C.RESET_SELECTIONS,
  key,
});

export const resetUserInputs = () => ({
  type: C.RESET_USER_INPUTS,
});

export const reloadData = () => ({
  type: C.RELOAD_DATA,
});

export const updateDialogInfo = (data) => ({
  type: C.UPDATE_DIALOG_INFO,
  data,
});

export const updateAlertInfo = (data) => ({
  type: C.UPDATE_ALERT_INFO,
  data,
});

export const updateUserInfo = (data) => ({
  type: C.UPDATE_USER_INFO,
  data,
});

export const resetFilters = (data) => ({
  type: C.RESET_FILTERS,
});

export const updateChangeFlagUiRules = (flag) => ({
  type: C.UPDATE_CHANGE_FLAG_UI_RULES,
  flag,
});

export const updateDRFilters = (key, data) => ({
  type: C.UPDATE_DR_FILTERS,
  key,
  data,
});
