// Import required libraries
import React, { useEffect } from "react";
import { TableBody, TableRow } from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
// import { Pagination } from "@material-ui/lab";

// Import data & utils
import config from "../../../config/config";
import useTable from "../../../utils/useTable";
import noDataImage from "../../../../assets/images/undraw_void.svg";

import tick from "../../../../assets/images/tick.png";
import cancel from "../../../../assets/images/cancel.png";
import warning from "../../../../assets/images/warning.png";
import not_applicable from "../../../../assets/images/not_applicable.png";

// Import action creators
import { updateUserInputs, resetSelections } from "../../../redux/actions";

// Import styles
import { StyledTableCell, useStyles } from "./style";
import "./RulesStatusTable.scss";
import RulesStatusTableHeader from "./RulesStatusTableHeader";

const headCells = [
  { id: "column_name", label: "Column Name", disableSorting: true },
  { id: "rules_applied", label: "Rules Applied", disableSorting: true },
  { id: "weightage", label: "Weightage", disableSorting: true },
  { id: "status", label: "Status", disableSorting: true },
  { id: "rules_passed", label: "Rules Passed", disableSorting: true },
];

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const RulesStatusTable = ({
  tableData = [],
  allData = [],
  resetSelections,
  reportDetailsApi,
}) => {
  const classes = useStyles();
  const filterFn = { fn: (items) => items };

  // reset header filter data on table unmount
  useEffect(() => {
    return () => {
      resetSelections("columnNameForReport");
      resetSelections("businessAttributeNameForReport");
      resetSelections("rulesAppliedForReport");
    };
    // eslint-disable-next-line
  }, []);

  const { TblContainer } = useTable(
    tableData,
    headCells,
    filterFn,
    {},
    () => {}
  );

  const getImageSourceForStatus = (status) => {
    let imgLink, altText, titleText;
    switch (status) {
      case "pass":
        imgLink = tick;
        altText = "Passed";
        break;
      case "fail":
        imgLink = cancel;
        altText = "Failed";
        break;
      case "warning":
        imgLink = warning;
        altText = "Warning";
        break;
      case "not_applicable":
        imgLink = not_applicable;
        altText = "NotApplicable";
        break;
      default:
        imgLink = "";
        altText = status;
        break;
    }
    return { link: imgLink, alt: altText, title: titleText };
  };

  const getTooltipContent = (itm) => {
    return (
      <div className="tooltip-content">
        <span>Records Passed: {itm.rules_passed}</span>
        <span>Warning Threshold: {itm.warning_threshold}</span>
      </div>
    );
  };

  return (
    <>
      <>
        {" "}
        <div className="dataset-details-container">
          <TblContainer>
            <RulesStatusTableHeader allData={allData} />
            {tableData?.length ? (
              <TableBody>
                {tableData.map((item, index) => {
                  const statusMessageLowerCase =
                    item.status_message.toLowerCase();
                  const isFail = statusMessageLowerCase === "fail";
                  const isPassOrWarning = [
                    "pass",
                    "warning",
                    "not_applicable",
                  ].includes(statusMessageLowerCase);
                  const imgDetails = getImageSourceForStatus(
                    statusMessageLowerCase
                  );
                  const imgProps = {
                    src: imgDetails.link,
                    alt: imgDetails.alt,
                    title: imgDetails.title,
                  };

                  return (
                    <TableRow className={classes.tableRow} key={index} hover>
                      <StyledTableCell
                        style={{
                          fontWeight: "600",
                        }}
                        align="left"
                      >
                        <div
                          className="rule-column-name"
                          title={item.column_name}
                          style={{
                            fontWeight: "600",
                            color: "#42596C",
                            maxWidth: 120,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.column_name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        title={item.business_attribute_name}
                        style={{
                          fontWeight: "600",
                          color: "#42596C",
                          maxWidth: 120,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.business_attribute_name}
                      </StyledTableCell>
                      <StyledTableCell style={{ maxWidth: "350px" }}>
                        {item.csvText}
                        {/* {item.description} */}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.weightage}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <>
                          {isPassOrWarning && (
                            <img {...imgProps} alt="noDataImage" />
                          )}
                          {isFail && (
                            <HtmlTooltip title={item.message} arrow>
                              <img {...imgProps} alt="noDataImage" />
                            </HtmlTooltip>
                          )}
                          {!isPassOrWarning && !isFail && (
                            <HtmlTooltip title={item.status_message} arrow>
                              {item.status_message}
                            </HtmlTooltip>
                          )}
                        </>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div className="pass-percentage-container">
                          <div title={item.rules_passed}>
                            {item.pass_percentage == "N/A"
                              ? item.pass_percentage
                              : item.pass_percentage + "%"}
                          </div>
                          <HtmlTooltip title={getTooltipContent(item)} arrow>
                            <i className="material-icons-outlined info-icon">
                              info
                            </i>
                          </HtmlTooltip>
                        </div>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody className="no-configs">
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <img src={noDataImage} alt="noDataImage" />
                    <p className="no-data">
                      {/* <b>{config.messages.noRecordsFound}</b> */}
                      <b>{reportDetailsApi?.message}</b>
                    </p>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            )}
          </TblContainer>
        </div>
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  userInputs: state.userInputs,
});

const mapDispatchToProps = { updateUserInputs, resetSelections };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(RulesStatusTable));
