// Import required libraies

import "./AdminPanel.scss";

import React, { useEffect } from "react";
import { resetUserInputs, updateSelections } from "../../redux/actions";

import AccessManagementTab from "./AccessManagmentTab/AccessManagementTab";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import TeamsTab from "./TeamsTab/TeamsTab";
import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Import Custom Component

// Import data & utils

// Import actions

// Import Styles

// # STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  rightAlign: {
    marginLeft: "auto",
  },
}));

// # HELPER COMPONENTS
function TabPanel(props) {
  // # PROPS
  const { children, value, index } = props;

  // # JSX
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabs-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div style={{ paddingTop: "10px" }}>{children}</div>}
    </div>
  );
}

// # COMPONENT
function AdminPanel({ match }) {
  const history = useHistory();

  // # HOOKS
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  // # SIDE EFFECTS
  useEffect(() => {
    return () => {
      dispatch(resetUserInputs());
    };
  }, []);

  // # HANDLERS
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      history.push("/home/adminpanel");
    } else {
      history.push("/home/adminpanel/teams");
    }
    dispatch(updateSelections("columnSelected", {}));
    dispatch(updateSelections("columnSelectionPage", false));
    setValue(newValue);
  };

  // # STATIC VARIABLES
  const tabNames = [{ name: "Access Management" }, { name: "Teams" }];

  useEffect(async () => {
    if (match?.params?.tab?.toLowerCase() === "teams") {
      handleChange({}, 1);
    }
  }, []);

  useEffect(async () => {
    if (match.path === "/home/adminpanel" && value === 1) {
      handleChange({}, 0);
    }
  }, [match]);

  // # JSX
  return (
    <LayoutTopSideBottom match={match}>
      <Breadcrumbs match={match} />
      <>
        <div className={classes.root}>
          <div className="models-menu">
            {tabNames.map((tabName, index) => (
              <div
                key={tabName.name}
                className={`models-item`}
                onClick={(e) => {
                  handleChange(e, index);
                }}
              >
                <div
                  className={`models-item-div ${
                    index === value ? "active" : "not-active"
                  }`}
                >
                  <p>{tabName.name}</p>
                </div>
              </div>
            ))}
          </div>

          <TabPanel value={value} index={0}>
            <AccessManagementTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TeamsTab />
          </TabPanel>
        </div>
      </>
    </LayoutTopSideBottom>
  );
}

// # EXPORTS
export default React.memo(AdminPanel);
