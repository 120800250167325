// Import Required Libraries

import { Redirect, Route, Switch } from "react-router-dom";

import AdminPanel from "../screens/AdminPanel/AdminPanel";
import CompareDatasets from "../screens/CompareDatasets/CompareDatasets";
import DQApp from "../screens/DQApp/DQApp";
import DataDomainTables from "../screens/DQApp/DataPipelines/DataDomainTables";
import Error from "../screens/Error/Error";
import ExploreDatasets from "../screens/DQApp/DataPipelines/ExploreDatasets";
import ExploreDatasetsTableColumns from "../screens/DQApp/DataPipelines/ExploreDatasetsTableColumns";
import GlobalRuleDetails from "../screens/GlobalRulesManager/GlobalRuleDetails/GlobalRuleDetails";
import GlobalRulesMangerContainer from "../screens/GlobalRulesManager/GlobalRulesMangerContainer";
import Login from "../screens/Login/Login";
import MyDataset from "../screens/MyDataset/MyDataset";
import Onboarding from "../screens/CompareDatasets/Onboarding";
import PageNotFound from "../screens/PageNotFound/PageNotFound";
import ProtectedRoute from "./ProtectedRoute";
import React from "react";
import Report from "./../screens/CompareDatasets/Report/index";
import SelectColumns from "../screens/CompareDatasets/SelectColumns";
import SelectCriteria from "../screens/CompareDatasets/SelectCriteria";
import Unauthorised from "../screens/Unauthorised/Unauthorised";

// Import Pages

// Import Custom Components

// Import data
function Routes() {
  return (
    <Switch>
      <Redirect from="/" to="/home" exact />
      <ProtectedRoute path="/error" component={Error} exact />

      <ProtectedRoute path="/home/adminpanel" component={AdminPanel} exact />
      <ProtectedRoute
        path="/home/adminpanel/:tab"
        component={AdminPanel}
        exact
      />

      <ProtectedRoute
        path="/home/globalRules"
        component={GlobalRulesMangerContainer}
        exact
      />
      <ProtectedRoute
        path="/home/globalRules/:ruleName"
        component={GlobalRuleDetails}
        exact
      />

      <ProtectedRoute path="/home" component={DQApp} exact />
      <ProtectedRoute path="/home/:tab" component={DQApp} exact />

      {
        // <ProtectedRoute
        //   path="/home/exploredatasets/:archetype/:primarydomain/:secondarydomain/:subdomain/:dataset/:layer"
        //   component={DataDomainTables}
        //   exact
        // />
        // <ProtectedRoute
        //   path="/home/exploredatasets/:archetype/:primarydomain/:secondarydomain/:subdomain/:dataset/:layer/:table"
        //   component={ExploreDatasetsTableColumns}
        //   exact
        // />
        // <ProtectedRoute
        //   path="/home/exploredatasets/:archetype?/:primarydomain?/:secondarydomain?/:subdomain?/:dataset?"
        //   component={ExploreDatasets}
        // />
      }

      <ProtectedRoute
        path="/home/comparedatasets"
        component={CompareDatasets}
        exact
      />

      <ProtectedRoute
        path="/home/comparedatasets/onboarding"
        component={Onboarding}
        exact
      />

      <Redirect
        from="/home/comparedatasets/report"
        to="/home/comparedatasets"
        exact
      />

      <ProtectedRoute
        path="/home/comparedatasets/report/:id"
        component={Report}
        exact
      />

      <ProtectedRoute
        path="/home/comparedatasets/onboarding/:reportName"
        component={Onboarding}
        exact
      />

      <ProtectedRoute
        path="/home/comparedatasets/onboarding/:reportName/selectcolumns/"
        component={SelectColumns}
        exact
      />

      <ProtectedRoute
        path="/home/comparedatasets/onboarding/:reportName/selectcriteria/"
        component={SelectCriteria}
        exact
      />

      <ProtectedRoute path="/home/mydataset" component={MyDataset} exact />
      <Route path="/login" component={Login} exact />
      <Route path="/unauthorised" component={Unauthorised} exact />
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default Routes;
