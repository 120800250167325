// Import required libraries
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  TableBody,
  TableRow,
  Grid,
  Select,
  Box,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pagination } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";

// Import data & utils
import config from "../../config/config";
import useTable from "../../utils/useTable";
import {
  getRatingColor,
  getCircleBarColor,
  getRoundOfScore,
} from "../../utils/utils";
import diagnosticReport from "../../../assets/images/diagnostic_report.png";
import noDataImage from "../../../assets/images/undraw_void.svg";

// Import action creators
import { updateUserInputs, updateSelections } from "../../redux/actions";

// Import styles
import {
  StyledTableCell,
  useStyles,
  customMenuItemStyle,
  CustomMenuItem,
} from "../../../styles/tblStyle";
import "./Table.scss";
import DiagnosticReport from "../DiagnosticReport/DiagnosticReport";
import DQButton from "../Common/DQButton";

// # HELPER COMPONENTS
const StyledRating = ({ value, color }) => {
  const classes = useStyles();
  const ratingBox = getRatingColor(value);

  // # JSX
  return (
    <div className={classes.ratingContainer}>
      {ratingBox.map((rate, i) => (
        <div className={classes.ratingBox} key={i}>
          <div
            className={classes.rating}
            style={{ width: `${rate}%`, backgroundColor: color }}
          />
          <div className={classes.empty} style={{ width: `${100 - rate}%` }} />
        </div>
      ))}
    </div>
  );
};

StyledRating.propTypes = {
  value: PropTypes.any.isRequired,
  color: PropTypes.any.isRequired,
};

// # COMPONENT
const Table = ({
  data = [],
  paginationFlag,
  criteria,
  tblHeaders,
  id,
  iconName,
  aggregateCellFlag,
}) => {
  // # HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState({
    tableId: "",
    isOpened: false,
  });
  const filterFn = { fn: (items) => items };
  const { TblContainer, TblHead } = useTable(
    data,
    tblHeaders,
    filterFn,
    {},
    () => {},
    criteria
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  //reset filters on screen change
  useEffect(() => {
    return () => {
      dispatch(updateSelections("searchText", ""));
      dispatch(
        updateSelections("primaryDomain", { searchText: "", value: [] })
      );
      dispatch(
        updateSelections("secondaryDomain", { searchText: "", value: [] })
      );
      dispatch(updateSelections("dataLayer", { searchText: "", value: [] }));
    };
    // eslint-disable-next-line
  }, []);

  // # HANDLERS
  const handleClickOpen = (tableData) => {
    setOpen({
      tableId: tableData.id,
      isOpened: true,
    });
    if (id === "tables") {
      dispatch(updateUserInputs("idForReportSelected", tableData.id));
      dispatch(
        updateUserInputs(
          "newidForReportSelected",
          `${tableData.id}#${tableData.schema_name}.${tableData.table_name}`
        )
      );
    }
  };

  const handleClose = (event, reason) => {
    if (id === "tables") {
      if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
        setOpen({ isOpened: false, tableId: "" });
        dispatch(updateUserInputs("idForReportSelected", ""));
        dispatch(updateUserInputs("newidForReportSelected", ""));
      }
    } else {
      setOpen({ isOpened: false, tableId: "" });
    }
  };

  const navigateToRow = (subtab) => {
    const modifiedSubTab = {
      ...subtab,
      name: subtab.name.includes("/")
        ? subtab.name.split("/").join("---")
        : subtab.name,
    };

    const path = window.location.pathname.endsWith("/")
      ? `${window.location.pathname}${modifiedSubTab.name}`
      : `${window.location.pathname}/${modifiedSubTab.name}`;
    history.push(path);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  // # STATIC VARIABLES
  const pageSizes = [...config.hardCoded.pageSizes];
  const displayRecords =
    data.length > page
      ? [...data].splice((page - 1) * pageSize, pageSize)
      : data;
  const noOfPages = Math.ceil(data.length / pageSize);

  // # JSX
  return (
    <>
      {" "}
      <div className="dataset-domains-container">
        <TblContainer>
          <TblHead />
          {data?.length ? (
            <TableBody>
              {displayRecords.map((item, index) => {
                const { color } =
                  item?.score === "NR"
                    ? { color: "NA", remarks: "" }
                    : item?.score?.includes("%")
                    ? getCircleBarColor(Number(item?.score?.slice(0, -1)))
                    : getCircleBarColor(Number(item?.score));
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableRow} hover>
                      <StyledTableCell
                        onClick={() => navigateToRow(item)}
                        style={{
                          cursor: "pointer",
                          fontWeight: "600",
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ width: "45px" }}>
                            <i
                              className={`material-icons-outlined ${
                                iconName ? "table-icon" : "filter-alt-icon"
                              }`}
                            >
                              {iconName ? iconName : "folder"}
                            </i>
                          </div>
                          <div
                            className="dataset-location-name"
                            title={item.name}
                            style={{
                              textDecoration: "underline",
                              maxWidth: "280px",
                            }}
                          >
                            {item.name}
                          </div>
                        </span>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "200px" }} align="right">
                        <Box display="flex" alignItems="center">
                          <StyledRating
                            value={getRoundOfScore(item?.score) / 10}
                            color={color}
                          />
                          <div className="dataset-score">
                            {item?.score ? (
                              item?.score === "NR" ? (
                                <span title="Not Rated">NR</span>
                              ) : (
                                <span>{`${getRoundOfScore(item.score)}%`}</span>
                              )
                            ) : (
                              <span title="Not Rated">NR</span>
                            )}
                          </div>
                        </Box>
                      </StyledTableCell>
                      {aggregateCellFlag && (
                        <StyledTableCell style={{ width: "180px" }}>
                          <button
                            type="button"
                            className="report-btn"
                            onClick={() => handleClickOpen(item)}
                          >
                            Diagnostic Report
                          </button>
                        </StyledTableCell>
                      )}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          ) : (
            <TableBody className="no-configs">
              <TableRow>
                <StyledTableCell colSpan={8}>
                  <img src={noDataImage} alt="noDataImage" />
                  <p className="no-data">
                    <b>{config.messages.noRecordsFound}</b>
                  </p>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </TblContainer>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={open.isOpened}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
          classes={{ paper: classes.paperDialog }}
        >
          {id === "tables" ? (
            <>
              <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <Box>
                  <DiagnosticReport currentScreen={"reportData"} />
                </Box>
              </DialogContent>
              <DialogActions>
                <DQButton
                  onclick={handleClose}
                  title="Close"
                  variant="contained"
                />
              </DialogActions>{" "}
            </>
          ) : (
            <Box>
              <img
                src={diagnosticReport}
                alt="img"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          )}
        </Dialog>
      </div>
      {paginationFlag && data.length > 10 && (
        <Grid container style={{ marginTop: "15px" }}>
          <Grid item>
            <Pagination
              count={noOfPages}
              page={page}
              shape="rounded"
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={2}
              className={classes.pagination}
            />
          </Grid>
          <Grid item xs={1}>
            <Select
              onChange={handlePageSizeChange}
              value={pageSize}
              disableUnderline
              className={classes.paginationSelect}
              IconComponent={ExpandMoreIcon}
            >
              {pageSizes.map((size) => (
                <CustomMenuItem
                  key={size}
                  value={size}
                  style={customMenuItemStyle}
                >
                  View {size}
                </CustomMenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      )}{" "}
    </>
  );
};

// # EXPORTS
export default React.memo(Table);
