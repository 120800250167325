import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { FormControlLabel, Switch } from "@mui/material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import DQButton from "../../../components/Common/DQButton";
import DQInput from "./../../../components/Common/DQInput";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "../../../kit/Checkbox";
import Typography from "../../../kit/Typography";
import PropTypes from "prop-types";

// Import Custom Component

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    opacity: "0.5",
  },
}));

const UpdateTeamDialog = (props) => {
  const { currentName, onSubmit, open, askForClose, currentUser } = props;
  const { users = [] } = currentUser;
  const [userList, setUserList] = useState([...users]);
  const [name, setName] = React.useState("");
  const clusters = currentUser.clusters;

  const classes = useStyles();

  const handleChange = (event, id) => {
    const updatedUserList = (userList.length ? userList : users).map((user) => {
      if (user.id === id) {
        user.send_mail = event.target.checked;
      }
      return user;
    });
    setUserList(updatedUserList);
  };

  const handleClose = () => {
    askForClose();
  };

  const handleOnSubmit = () => {
    onSubmit(name, userList);
    handleClose();
  };

  /* useEffect(() => {
    const getData = async () => {
      try {
        const response = await getAdminPanelTeamsList();
        console.log(response)

      }
      catch {
        console.log("Error")
      }
    };
    getData();
  },[]) */

  useEffect(() => {
    setName(currentName);
  }, [currentName, open]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle
        style={{ boxShadow: "0px 4px 10px rgba(221, 221, 221, 0.25)" }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          Team Details
          <i className="material-icons-outlined" onClick={handleClose}>
            close
          </i>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <div className="input-field-container">
          <Box className={classes.inputContainer}>
            <Box>
              <label>Team name: </label>
              <DQInput
                value={name}
                handleChange={() => {}}
                // onEnter={() => handleOnSubmit()}
              />
            </Box>
            <Box style={{ paddingLeft: "10px" }}>
              <label>This team has access to below clusters: </label>
              {clusters?.map((el) => {
                return (
                  <>
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={true}
                          value={"sonu"}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          disabled={true}
                          variant="secondary"
                          size="small"
                          onClick={() => {}}
                        />
                        <Typography>{`${el.name} - ${el.server}`}</Typography>
                      </Box>
                    </Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </div>
        <TableContainer component={Paper} sx={{ maxHeight: "300px" }}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Email Alert</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(userList.length ? userList : users).map((user, row, index) => {
                return (
                  <TableRow key={row.key}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={user.send_mail}
                            onChange={(event) => handleChange(event, user.id)}
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <DQButton onclick={handleClose} title="Cancel" variant="outlined" />
        <DQButton
          onclick={handleOnSubmit}
          title={"Update"}
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  );
};

UpdateTeamDialog.propTypes = {
  open: PropTypes.bool,
  currentName: PropTypes.string,
  currentUser: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default UpdateTeamDialog;
