// Import required libraies
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  MenuItem,
  Modal,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
  makeStyles,
} from "@material-ui/core";

import { StyledTableCell } from "../../screens/CompareDatasets/CompareDatasetsListTable/style.js";
import SummaryTable from "../../components/SummaryTableSelection/SummaryTableSelection.js";

// Import Custom Component
import Loader from "../Loader/Loader";

// Import data & utils
import { getSchemaHistory } from "../../../js/services/apis.js";

// # COMPONENT

// # HELPER COMPONENTS

export const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    display: "flex",
  },
  drawer: {
    width: "70vw",
    flexShrink: 0,
    display: "flex",
    alignSelf: "center",
    height: "60vh",
    alignItems: "center",
    marginTop: "15%",
  },
  drawerPaper: {
    width: "50vw",
  },
  rulesModalHeader: {
    height: "50px",
    display: "flex",
    width: "100%",
    padding: "0px 20px",
  },
  rulesModalBody: {
    height: "calc(100% - 100px)",
    width: "100%",
    padding: "10px 0px",
    boxSizing: "border-box",
    overflow: "auto",
  },
  rulesModalFooter: {
    // position: "relative",
    // bottom: 0,
    // textAlign: "center",
    // paddingBottom: 10,
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      fontFamily: "Hind Siliguri",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      color: "#46596a",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#EB367F",
      color: "#ffffff",
    },
  },
  paginationSelect: {
    paddingLeft: "10px",
    paddingRight: "10px",
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "23px",
    color: "#ffffff",
    backgroundColor: "#46596A",
    borderRadius: "5px",
    "& .MuiSvgIcon-root": {
      color: "#ffffff",
    },
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    minWidth: "100px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    borderBottom: "1px solid #E6E8ED",
    "&:last-child th": {
      borderBottom: 0,
    },
    backgroundColor: "white !important",
    "&:hover": {
      backgroundColor: "#4eafb342 !important",
    },
  },
  gridRoot: {
    flexGrow: 1,
    cursor: "default !important",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
    marginTop: "8px",
    marginBottom: "6px",
  },
  ratingBox: {
    width: "10px",
    height: "14px",
    marginRight: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rating: {
    height: "100%",
  },
  empty: {
    height: "100%",
    backgroundColor: "#ECECEC",
  },
  fontStyle: {
    paddingLeft: "10px",
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "19px",
    color: "#465A69",
    background: "#fff",
  },
  rafifyBtn: {
    border: "1.3px solid #4EAFB3",
    boxSizing: "border-box",
    borderRadius: "23px",
    color: "#4EAFB3",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "16px",
    textTransform: "none",
    // backgroundColor: 'transparent',
    "&:hover": {
      color: "#fff",
      backgroundColor: "#4EAFB3",
    },
  },
  reportBtn: {
    color: "#fff",
    border: "1px solid #4eafb3",
    background: "#4eafb3",
    fontSize: "12px",
    height: "24px",
    padding: "0px 10px",
    fontFamily: "Hind Siliguri",
    textTransform: "none",
    "&:hover": {
      background: "#4eafb3",
      color: "#ffffff",
    },
    "&.Mui-disabled": {
      color: "#ffffff",
      opacity: 0.7,
    },
  },
  cancelBtn: {
    color: "#4eafb3",
    border: "1px solid #4eafb3",
    fontSize: "12px",
    height: "24px",
    padding: "0px 10px",
    fontFamily: "Hind Siliguri",
    textTransform: "none",
    marginRight: "10px",
  },
  container: {
    minWidth: "400px",
    maxWidth: "600px",
    padding: "10px 0",
  },
  textWraping: {
    whiteSpace: "nowrap",
  },
  textAlignment: {
    textAlign: "center",
  },
  tableSubHead: {
    borderBottom: "none",
    fontFamily: "Hind Siliguri",
    backgroundColor: "#E6E8ED",
    color: "rgba(70, 89, 106, 1)",
    fontWeight: 600,
    fontSize: "11px",
    textTransform: "capitalize",
    marginTop: "2px",
  },
  tablecell: {
    padding: "5px 12px",
  },
  displayNone: {
    opacity: 0,
  },
  rulesModal: {},
  rulesSelectionWrapper: {
    backgroundColor: "#fafafa",
    borderRadius: "4px",
    width: "95%",
    height: "80%",
    boxSizing: "border-box",
    padding: "10px 10px 5px 10px",
  },
}));

const newResData = [
  {
    pipeline_table_id: 3,
    sync_at: "19/12/2023 08:55:37 UTC",
    columns: [
      {
        name: "country_id",
        datatype: "string",
        isChanged: "add",
      },
      {
        name: "iso_country_nm",
        datatype: "string",
      },
      {
        name: "iso_2_letter_country_cd",
        datatype: "string",
      },
      {
        name: "iso_3_letter_country_cd",
        datatype: "string",
      },
      {
        name: "iso_3_digit_country_nbr",
        datatype: "int",
      },
      {
        name: "create_run_id",
        datatype: "string",
      },
      {
        name: "create_run_ts",
        datatype: "timestamp",
      },
      {
        name: "update_run_id",
        datatype: "string",
      },
      {
        name: "update_run_ts",
        datatype: "timestamp",
      },
      {
        name: "dq_action",
        datatype: "string",
      },
      {
        name: "dq_message",
        datatype: "string",
      },
      {
        name: "syndct_data_prvd_nm",
        datatype: "string",
      },
    ],
    id: 1,
  },
  {
    pipeline_table_id: 3,
    sync_at: "19/12/2023 08:55:37 UTC",
    columns: [
      {
        name: "country_id",
        datatype: "string",
        isChanged: "add",
      },
      {
        name: "iso_country_nm",
        datatype: "string",
      },
      {
        name: "iso_2_letter_country_cd",
        datatype: "string",
      },
      {
        name: "iso_3_letter_country_cd",
        datatype: "string",
      },
      {
        name: "iso_3_digit_country_nbr",
        datatype: "int",
      },
      {
        name: "create_run_id",
        datatype: "string",
      },
      {
        name: "create_run_ts",
        datatype: "timestamp",
      },
      {
        name: "update_run_id",
        datatype: "string",
      },
      {
        name: "update_run_ts",
        datatype: "timestamp",
      },
      {
        name: "dq_action",
        datatype: "string",
      },
      {
        name: "dq_message",
        datatype: "string",
      },
      {
        name: "syndct_data_prvd_nm",
        datatype: "string",
      },
    ],
    id: 1,
  },
  {
    pipeline_table_id: 3,
    sync_at: "19/12/2023 08:55:37 UTC",
    columns: [
      {
        name: "country_id",
        datatype: "string",
        isChanged: "add",
      },
      {
        name: "iso_country_nm",
        datatype: "string",
      },
      {
        name: "iso_2_letter_country_cd",
        datatype: "string",
      },
      {
        name: "iso_3_letter_country_cd",
        datatype: "string",
      },
      {
        name: "iso_3_digit_country_nbr",
        datatype: "int",
      },
      {
        name: "create_run_id",
        datatype: "string",
      },
      {
        name: "create_run_ts",
        datatype: "timestamp",
      },
      {
        name: "update_run_id",
        datatype: "string",
      },
      {
        name: "update_run_ts",
        datatype: "timestamp",
      },
      {
        name: "dq_action",
        datatype: "string",
      },
      {
        name: "dq_message",
        datatype: "string",
      },
      {
        name: "syndct_data_prvd_nm",
        datatype: "string",
      },
    ],
    id: 1,
  },
];

const ColumnRulesTableHeader = (props = {}) => {
  // # PROPS
  //   const { allData } = props;

  // # HOOKS
  const classes = useStyles();
  const user = useSelector((state) => state.user);

  // # STATIC VARIABLES
  //   let columnsList = [...new Set(allData?.map((itm) => itm.column_name))];
  //   let datatypeList = [...new Set(allData?.map((item) => item.datatype))];

  // # JSX
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <div style={{ display: "flex", width: "150px" }}>
            <Box>Timestamp</Box>
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <div style={{ display: "flex", width: "120px" }}>
            <Box>Action</Box>
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Template</Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const HistoryInformation = (props) => {
  // # PROPS
  const [historyTableApi, setHistoryTableApi] = useState({
    status: "loading",
    message: "",
    data: {},
  });

  // # HOOKS
  const columnSelected = useSelector(
    (state) => state.user.selections.columnSelected
  );

  const classes = useStyles();

  const makeTableArray = (responseData) => {
    const temp = responseData.map((el) => el.sync_at);
    return temp;
  };

  useEffect(() => {
    let clusterDataPromise = getSchemaHistory({
      tableId: columnSelected.id,
    });
    clusterDataPromise
      .then((responseData) => {
        const dataArray = makeTableArray(responseData);
        setHistoryTableApi({
          status: "success",
          message: "",
          data: responseData,
          tableArray: dataArray,
        });
      })
      .catch((err) => {
        setHistoryTableApi({ status: "error", message: "", data: {} });
      });
  }, []);

  // # STATIC VARIABLES

  // # JSX
  return (
    <>
      {historyTableApi.status === "loading" && <Loader />}
      {historyTableApi.status === "success" && (
        <Box className={classes.root}>
          <SummaryTable rowData={historyTableApi} />
        </Box>
      )}
      {historyTableApi.status === "error" && <Loader />}
    </>
  );
};

// # EXPORTS
export default HistoryInformation;
