import groupBy from "lodash.groupby";
import { splitSemanticOptions } from "../../utils/utils";
import { v4 } from "uuid";

// ### FORM DATA SCHEMA - UI (currently hard coded in the UI; will come from backend later)
const allRulesData = [
  {
    categoryId: "it",
    groupId: "accuracy",
    name: "isSpace",
    ruleDescription:
      "The field should not contain empty space between words. Only 1 space allowed",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if all records in this column doesn't contain empty space between words (only 1 space allowed)",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "accuracy",
    name: "isNullCond",
    ruleDescription:
      "Mandatory field based on user provided condition (e.g.: Product description is mandatory if the product was created on or after 2016)",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          // "Check if all records in this column have values for corresponding records in ",
          "Check if all records in this column have values ",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      // {
      //   type: "select_single",
      //   formKey: "column",
      //   formLabel: "Enable Rule",
      //   disabled: false,
      //   metadata: {
      //     defaultValue: "",
      //     options: [],
      //     optionsFiller: {
      //       storeDataKey: "datasetTablesColumns",
      //       api: "/v1/catalogs/schemas/tables/columns",
      //     },
      //   },
      //   isVisibleOnUi: true,
      // },
      {
        type: "select_single",
        formKey: "condition",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: "greater_than",
          options: [
            {
              id: "greater_than",
              name: "Greater than",
            },
            {
              id: "less_than",
              name: "Less than",
            },
            {
              id: "greater_than_equal",
              name: "Greater than equal to",
            },
            {
              id: "less_than_equal",
              name: "Less than equal to",
            },
            {
              id: "equal_to",
              name: "Equal to",
            },
            {
              id: "not_equal",
              name: "Not equal to",
            },
          ],
        },
        isVisibleOnUi: true,
      },
      {
        type: "input_text",
        formKey: "conditionString",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          placeholder: "Type value",
          defaultValue: "",
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "accuracy",
    name: "isInList",
    ruleDescription:
      "Checks if the field value matches to a specified list of possible values provided by the user.",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check if these values",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "input_tags",
        formKey: "conditionString",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          placeholder: "Type value(s) and press enter",
          defaultValue: [],
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text2",
        formLabel: "are present in the current column",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },

  {
    categoryId: "business",
    groupId: "accuracy",
    name: "isInListWithCondition",
    ruleDescription:
      "Checks if the field value matches to a specified list of possible values provided by the user.",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: { defaultValue: false },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check if these values",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "input_tags",
        formKey: "listOfValues",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          placeholder: "Type value(s) and press enter",
          defaultValue: [],
        },
        isVisibleOnUi: true,
      },
      {
        type: "select_single",
        formKey: "columnName",
        formLabel: "Select Column",
        disabled: false,
        metadata: {
          defaultValue: [],
          options: [],
          optionsFiller: {
            storeDataKey: "datasetTablesColumns",
            api: "/v1/catalogs/schemas/tables/columns",
          },
        },
        isVisibleOnUi: true,
      },
      {
        type: "select_single",
        formKey: "condition",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          options: [
            {
              id: "greater_than",
              name: "Greater than",
            },
            {
              id: "less_than",
              name: "Less than",
            },
            {
              id: "greater_than_equal",
              name: "Greater than equal to",
            },
            {
              id: "less_than_equal",
              name: "Less than equal to",
            },
            {
              id: "equal_to",
              name: "Equal to",
            },
            {
              id: "not_equal",
              name: "Not equal to",
            },
          ],
          defaultValue: "greater_than",
        },
        isVisibleOnUi: true,
      },
      {
        type: "input_text",
        formKey: "conditionString",
        formLabel: "Condition String ",
        disabled: false,
        metadata: {
          defaultValue: "",
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text2",
        formLabel: "are present in the current column",
        disabled: false,
        metadata: {},
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "accuracy",
    name: "isNotInList",
    ruleDescription:
      "Checks if the field value matches to a specified list of invalid values provided by the user.",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check if these values",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "input_tags",
        formKey: "conditionString",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          placeholder: "Type value(s) and press enter",
          defaultValue: [],
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text2",
        formLabel: "are NOT present in the current column",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "accuracy",
    name: "leadingZero",
    ruleDescription: "Checks if the field value has leading zeroes.",
    supportedDataTypes: ["integer", "float"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check if this column has",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "input_number",
        formKey: "zerosCount",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          defaultValue: 0,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text2",
        formLabel: "leading zeros",
        disabled: false,
        options: [],
        validations: [],
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "accuracy",
    name: "lengthCheck",
    ruleDescription:
      "Checks if the field value is at least/not less or more than X characters long",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the length range for all records in this column is between",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "input_number",
        formKey: "minLen",
        formLabel: "Min Length",
        disabled: false,
        metadata: {
          defaultValue: 0,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text2",
        formLabel: "and",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "input_number",
        formKey: "maxLen",
        formLabel: "Max Length",
        disabled: false,
        metadata: {
          defaultValue: 10,
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "completeness",
    name: "isBlank",
    ruleDescription:
      "The field should not be blank after removing leading and trailing spaces",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if this column is not blank after removing leading and trailing spaces",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "completeness",
    name: "isNull",
    ruleDescription:
      "Checks if the field has a value after removing leading and trailing spaces",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if this column has a value after removing leading and trailing spaces",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "uniqueness",
    name: "isDuplicate",
    ruleDescription:
      "Checks if the record is not duplicated across the dataset.",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check is all the records in this column does not exist in ",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "select_autocomplete_multiple",
        formKey: "column",
        formLabel: "Select Columns",
        disabled: false,
        metadata: {
          defaultValue: [],
          options: [],
          optionsFiller: {
            storeDataKey: "datasetTablesColumns",
            api: "/v1/catalogs/schemas/tables/columns",
          },
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "validity",
    name: "isEmail",
    ruleDescription: "Checks if the field value is a valid email address.",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column is email",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "isASCII",
    ruleDescription: "Checks if the field value contains only ASCII characters",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column contains only ASCII characters",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "isBoolean",
    ruleDescription: "Checks if the field value is either 0/1 or True False",
    supportedDataTypes: ["string", "boolean"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column is boolean and the format is",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "select_multiple",
        formKey: "format",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          options: [
            {
              id: "0",
              name: "0",
              disabled: false,
            },
            {
              id: "1",
              name: "1",
              disabled: false,
            },
            {
              id: "true",
              name: "True",
              disabled: false,
            },
            {
              id: "false",
              name: "False",
              disabled: false,
            },
          ],
          defaultValue: [],
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "isAlphabet",
    ruleDescription:
      "Checks if the field value contains only Alphabets (only A-Z or a-z, no numbers or special characters).",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        position: 1,
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column is an alphabet (only A-Z or a-z, no numbers or special characters)",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "textToNumberCheck",
    ruleDescription:
      "Checks if the field value in text is a whole number (no decimals). Ex: 1,001, 001001",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column is a whole number (no decimals)",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "isURL",
    ruleDescription: "Checks if the field value is a valid URL.",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column is a valid URL",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "isDecimal",
    ruleDescription: "Checks if the field value is in decimal number format",
    supportedDataTypes: ["integer", "number", "decimal", "float", "string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column is in decimal format",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "isDecimalWithCondition",
    ruleDescription: "Checks if the field value is in decimal number format",
    supportedDataTypes: ["integer", "number", "decimal", "float", "string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: { defaultValue: false },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column is in decimal format",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "select_single",
        formKey: "condition",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          options: [
            {
              id: "greater_than",
              name: "Greater than",
            },
            {
              id: "less_than",
              name: "Less than",
            },
            {
              id: "greater_than_equal",
              name: "Greater than equal to",
            },
            {
              id: "less_than_equal",
              name: "Less than equal to",
            },
            {
              id: "equal_to",
              name: "Equal to",
            },
            {
              id: "not_equal",
              name: "Not equal to",
            },
          ],
          defaultValue: "greater_than",
        },
        isVisibleOnUi: true,
      },
      {
        type: "input_number",
        formKey: "conditionString",
        formLabel: "Condition String ",
        disabled: false,
        metadata: {
          defaultValue: 0,
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "isNumber",
    ruleDescription: "Checks if the field value is a whole number (no decimal)",
    supportedDataTypes: ["integer"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column is a whole number",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "validity",
    name: "isDate",
    ruleDescription:
      "Checks if the field value is a valid date (as per the format specified by user)",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column is a valid date and the format is",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "select_single",
        formKey: "format",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          options: [
            {
              id: "mmddYY",
              name: "mmddYY",
            },
            {
              id: "mm.dd.YY",
              name: "mm.dd.YY",
            },
            {
              id: "mm-dd-YY",
              name: "mm-dd-YY",
            },
            {
              id: "mm/dd/YY",
              name: "mm/dd/YY",
            },
            {
              id: "ddmmYY",
              name: "ddmmYY",
            },
            {
              id: "dd.mm.YY",
              name: "dd.mm.YY",
            },
            {
              id: "dd-mm-YY",
              name: "dd-mm-YY",
            },
            {
              id: "dd/mm/YY",
              name: "dd/mm/YY",
            },
            {
              id: "YYmmdd",
              name: "YYmmdd",
            },
            {
              id: "YY.mm.dd",
              name: "YY.mm.dd",
            },
            {
              id: "YY-mm-dd",
              name: "YY-mm-dd",
            },
            {
              id: "YY/mm/dd",
              name: "YY/mm/dd",
            },
          ],
          defaultValue: "mmddYY",
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "isTimestamp",
    ruleDescription:
      "Checks if the field value is a valid timestamp (as per the format specified by user)",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the data format for all records in this column is a valid timestamp",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "validity",
    name: "endsWith",
    ruleDescription: "Checks if the field value ends with a given string.",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check if the all records in this column ends with",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "input_text",
        formKey: "conditionString",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          placeholder: "Type value",
          defaultValue: "",
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "validity",
    name: "startsWith",
    ruleDescription:
      "Checks if the field value starts with a user specified string.",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check if the all records in this column starts with",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "input_text",
        formKey: "conditionString",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          placeholder: "Type value",
          defaultValue: "",
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "customPatternCheck",
    ruleDescription:
      "Checks if the field value matches the pattern specified by user Regex",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the all records in this column matches this regex pattern",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "input_text",
        formKey: "conditionString",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          placeholder: "Type value",
          defaultValue: "",
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "it",
    groupId: "validity",
    name: "isUpperCase",
    ruleDescription:
      "Checks if the field value contains only Alphabets in upper/capital case (only A-Z, no numbers or special characters, space allowed)",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check if the all records in this column are upper case",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "validity",
    name: "isPositiveNumber",
    ruleDescription:
      "Checks if the field value is a positive number (greater than or equals 0)",
    supportedDataTypes: ["number", "integer", "decimal"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the all records in this column are positive numbers",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "business",
    groupId: "validity",
    name: "isNegativeNumber",
    ruleDescription:
      "Checks if the field value is a negative number (less than 0)",
    supportedDataTypes: ["number", "integer", "decimal"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel:
          "Check if the all records in this column are negative numbers",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "semantic",
    name: "fieldCompare_zero",
    ruleDescription: "Specific number",
    groupId: "validity",
    supportedDataTypes: ["integer", "float"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check if the all records in this column are ",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "select_single",
        formKey: "condition",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          options: [
            {
              id: "greater_than",
              name: "Greater than",
            },
            {
              id: "less_than",
              name: "Less than",
            },
            {
              id: "greater_than_equal",
              name: "Greater than equal to",
            },
            {
              id: "less_than_equal",
              name: "Less than equal to",
            },
            {
              id: "equal_to",
              name: "Equal to",
            },
            {
              id: "not_equal",
              name: "Not equal to",
            },
          ],
          defaultValue: "greater_than",
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text2",
        formLabel: "Specific number ",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "input_number",
        formKey: "value1",
        formLabel: "Condition String ",
        disabled: false,
        metadata: {
          defaultValue: 0,
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "semantic",
    name: "fieldCompare_timestamp",
    ruleDescription: "Current Timestamp",
    groupId: "validity",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check if the all records in this column are ",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "select_single",
        formKey: "condition",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          options: [
            {
              id: "greater_than",
              name: "Greater than",
            },
            {
              id: "less_than",
              name: "Less than",
            },
          ],
          defaultValue: "greater_than",
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text2",
        formLabel: "current timestamp",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "semantic",
    name: "fieldCompare_date",
    ruleDescription: "Current Date",
    groupId: "validity",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text1",
        formLabel: "Check if the all records in this column are ",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
      {
        type: "select_single",
        formKey: "condition",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          options: [
            {
              id: "greater_than",
              name: "Greater than",
            },
            {
              id: "less_than",
              name: "Less than",
            },
          ],
          defaultValue: "greater_than",
        },
        isVisibleOnUi: true,
      },
      {
        type: "typography",
        formKey: "text2",
        formLabel: "current date",
        disabled: false,
        metadata: {},
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "semantic",
    name: "isExisting",
    ruleDescription: "Checks if the field value is available",
    groupId: "consistency",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "custom_multiple_rules",
        formKey: "value1",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          placeholder: "",
          defaultValue: [],
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    name: "fieldCompare_column",
    categoryId: "semantic",
    ruleDescription: "Checks if the field value is available",
    groupId: "validity",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: false,
      },
      {
        type: "custom_field_compare_rule",
        formKey: "value1",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          defaultValue: [
            {
              isChecked: false,
              value: {},
              threshold: 0,
              uniqueKey: v4(),
            },
          ],
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    name: "isMapped",
    categoryId: "semantic",
    ruleDescription: "",
    groupId: "consistency",
    supportedDataTypes: ["string"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: false,
      },
      {
        type: "custom_is_mapped_rule",
        formKey: "value1",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          defaultValue: [
            {
              isChecked: false,
              value: {},
              threshold: 0,
              uniqueKey: v4(),
            },
          ],
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "semantic",
    groupId: "consistency",
    name: "isExistingCond",
    ruleDescription:
      "The field should not contain empty space between words. Only 1 space allowed",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: false,
      },
      {
        type: "custom_isExisting_Cond",
        formKey: "value1",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          defaultValue: [
            {
              id: v4(),
              isEnabled: false,
              threshold: 0,
              tables: [
                {
                  id: v4(),
                  tableName: "",
                  columnName: "",
                  operator: "",
                },
              ],
              conditions: [
                {
                  id: v4(),
                  tableName: "",
                  columnName: "",
                  condition: "",
                  conditionString: "",
                  operator: "",
                },
              ],
            },
          ],
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "semantic",
    groupId: "consistency",
    name: "isExistingCondAdvanced",
    ruleDescription: "",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "custom_is_existing_cond_advanced",
        formKey: "value1",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          defaultValue: {
            primaryTableName: "",
            primaryColumnName: "",
            existFromTableName: "",
            existFromColumnName: "",
            existToTableName: "",
            existToColumnName: "",
            condTableName: "",
            condColumnName: "",
            condition: "",
            conditionString: "",
          },
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "semantic",
    groupId: "consistency",
    name: "fieldCompareWithMathematicalOperations",
    ruleDescription: "",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "custom_mathematical_operations_with_column",
        formKey: "value1",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          defaultValue: {
            columnName: "",
            conditions: [
              {
                condColumnName: "",
                operator: "",
                id: v4(),
              },
            ],
          },
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "semantic",
    groupId: "consistency",
    name: "mathematicalOperationsWithColumn",
    ruleDescription: "",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "custom_field_compare_with_mathematical_operations",
        formKey: "value1",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          defaultValue: {
            columnName: "",
            conditions: [
              {
                operator: "",
                operationsColumnName: "",
                id: v4(),
              },
            ],
            conditionString: "",
            inputString: "",
          },
        },
        isVisibleOnUi: true,
      },
    ],
  },
  {
    categoryId: "semantic",
    groupId: "validity",
    name: "fieldCompareWithCondition",
    ruleDescription: "",
    supportedDataTypes: ["all"],
    elementData: [
      {
        type: "checkbox",
        formKey: "isEnabled",
        formLabel: "Enable Rule",
        disabled: false,
        metadata: {
          defaultValue: false,
        },
        isVisibleOnUi: true,
      },
      {
        type: "custom_field_compare_with_condition",
        formKey: "value1",
        formLabel: "Condition String",
        disabled: false,
        metadata: {
          defaultValue: {
            conditionString: "greater_than",
            inputString: "",
            conditions: [
              {
                id: v4(),
                operationsColumnName: "",
                operationsConditionString: "",
                optionsInputString: "",
              },
            ],
          },
        },
        isVisibleOnUi: true,
      },
    ],
  },
];

// ### FORM SELECTIONS SCHEMA - UI
const rulesItAndBusiness = [
  {
    groupId: "accuracy",
    name: "isSpace",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "accuracy",
    name: "isNullCond",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      column: "select_column",
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "accuracy",
    name: "isInList",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      conditionString: "",
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "accuracy",
    name: "isNotInList",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      conditionString: "",
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "accuracy",
    name: "leadingZero",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      zerosCount: 0,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "accuracy",
    name: "lengthCheck",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      minLen: 0,
      maxLen: 10,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "completeness",
    name: "isBlank",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "completeness",
    name: "isNull",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "uniqueness",
    name: "isDuplicate",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      column: "select_column",
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isEmail",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isASCII",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isBoolean",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      format: [],
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isAlphabet",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "textToNumberCheck",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isURL",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isDecimal",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isNumber",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isDate",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      format: "mmddYY",
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isTimestamp",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      format: "yyyy-mm-dd hh:mm:ss",
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "endsWith",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      conditionString: "",
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "startsWith",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      conditionString: "",
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "customPatternCheck",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
      conditionString: "",
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isUpperCase",
    categoryId: "it",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isPositiveNumber",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
  {
    groupId: "validity",
    name: "isNegativeNumber",
    categoryId: "business",
    columnName: "MNAT",
    value: {
      isEnabled: false,
    },
    isValid: true,
    message: "",
  },
];

const masterGroupData = [
  {
    id: "validity",
    name: "Validity",
  },
  {
    id: "completeness",
    name: "Completeness",
  },
  {
    id: "accuracy",
    name: "Accuracy",
  },
  {
    id: "uniqueness",
    name: "Uniqueness",
  },
  {
    id: "consistency",
    name: "Consistency",
  },
  {
    id: "validity",
    name: "Validity",
  },
];

const rulesValidations = (selections) => {
  function isThresholdValid(threshold) {
    if (threshold === "" || threshold === undefined || threshold === null) {
      return { isValid: false, message: "Please fill threshold field" };
    }

    if (isNaN(threshold)) {
      return { isValid: false, message: "Please fill numeric threshold value" };
    } else {
      if (Number.isInteger(+threshold)) {
        if (+threshold < 0) {
          return {
            isValid: false,
            message: "Please fill min threshold value 0",
          };
        }
        if (+threshold > 100) {
          return {
            isValid: false,
            message: "Please fill threshold value less than 100",
          };
        }
      } else {
        return {
          isValid: false,
          message: "Please fill integer threshold value",
        };
      }
    }

    return { isValid: true, message: "" };
  }

  let validatedSelections = selections.map((row) => {
    const rowVal = row.value;
    let rowCondition = "";
    switch (row.name) {
      case "isSpace":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isNullCond":
        rowCondition =
          rowVal.condition?.length > 0 && rowVal.conditionString?.length > 0;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isInList":
        rowCondition = rowVal.conditionString.length;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isInListWithCondition":
        rowCondition =
          rowVal.conditionString &&
          rowVal.columnName.length &&
          rowVal.listOfValues.length;
        if (rowVal.isEnabled) {
          if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isNotInList":
        rowCondition = rowVal.conditionString.length;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "leadingZero":
        rowCondition =
          rowVal.zerosCount !== undefined || rowVal.zerosCount !== "";
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "lengthCheck":
        rowCondition =
          rowVal.minLen !== undefined ||
          rowVal.minLen !== "" ||
          rowVal.maxLen !== undefined ||
          rowVal.maxLen !== "";
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isBlank":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isNull":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isDuplicate":
        rowCondition = rowVal.column.length;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "validate_cas_number":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "validate_ec_number":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "validate_ecn_alt_bom":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isEmail":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isASCII":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isBoolean":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isAlphabet":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "textToNumberCheck":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isURL":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isDecimal":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isDecimalWithCondition":
        rowCondition =
          rowVal.conditionString !== undefined || rowVal.conditionString !== "";
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isNumber":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isDate":
        rowCondition = rowVal.format;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isTimestamp":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "endsWith":
        rowCondition = rowVal.conditionString;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "startsWith":
        rowCondition = rowVal.conditionString;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "customPatternCheck":
        rowCondition = rowVal.conditionString;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isUpperCase":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isPositiveNumber":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "isNegativeNumber":
        if (rowVal.isEnabled && !isThresholdValid(rowVal.threshold).isValid) {
          return { ...row, ...isThresholdValid(rowVal.threshold) };
        }
        return { ...row, isValid: true, message: "" };
      case "fieldCompare_zero":
        rowCondition =
          rowVal.condition &&
          (rowVal.value1 !== undefined || rowVal.value1 !== "");
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "fieldCompare_timestamp":
        rowCondition = rowVal.condition;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "fieldCompare_date":
        rowCondition = rowVal.condition;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isExistingCond": {
        let currEnabled = row.value.value1
          .map((a) => a.isEnabled)
          .some((a) => a);
        if (currEnabled) {
          const conditionKeys = [
            "columnName",
            "condition",
            "conditionString",
            "operator",
            "tableName",
          ];
          const tableKeys = ["columnName", "operator", "tableName"];
          let conditionResult = [],
            tableResult = [];
          let thresholdValidity = row.value.value1.reduce(
            (validityObj, currentValue) => {
              if (
                currentValue.isEnabled &&
                !isThresholdValid(currentValue.threshold).isValid
              ) {
                return {
                  ...validityObj,
                  ...isThresholdValid(currentValue.threshold),
                };
              } else return validityObj;
            },
            { isValid: true, message: "" }
          );
          row.value?.value1?.forEach((elem) => {
            conditionResult = elem.conditions.map((cond, index) =>
              conditionKeys.every((key) => {
                if (
                  index === 0 &&
                  cond["operator"] === "" &&
                  cond["columnName"] !== "" &&
                  cond["condition"] !== "" &&
                  cond["conditionString"] !== "" &&
                  cond["tableName"] !== ""
                ) {
                  return true;
                } else {
                  return Object.keys(cond).includes(key) && cond[key];
                }
              })
            );
            tableResult = elem.tables.map((cond, index) =>
              tableKeys.every((key) => {
                if (
                  index === 0 &&
                  cond["operator"] === "" &&
                  cond["columnName"] !== "" &&
                  cond["tableName"] !== ""
                ) {
                  return true;
                } else {
                  return Object.keys(cond).includes(key) && cond[key];
                }
              })
            );
          });
          let res = [...conditionResult, ...tableResult];

          if (res.length && res.some((val) => val === false)) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          } else {
            if (!thresholdValidity.isValid) {
              return { ...row, ...thresholdValidity };
            }
            return { ...row, isValid: true, message: "" };
          }
        } else {
          return { ...row, isValid: true, message: "" };
        }
      }
      case "isExisting":
        if (row.value.isEnabled) {
          if (row.value.value1?.length) {
            const tableKeys = ["columnName", "operator", "tableName"];
            let tableResult = row.value.value1.map((elem, index) => {
              return tableKeys.every((key) => {
                if (
                  index === 0 &&
                  elem["operator"] === "" &&
                  elem["columnName"] !== "" &&
                  elem["tableName"] !== ""
                ) {
                  return true;
                } else {
                  return Object.keys(elem).includes(key) && elem[key];
                }
              });
            });
            let res = [...tableResult];
            if (res.length && res.some((val) => val === false)) {
              return {
                ...row,
                isValid: false,
                message: "Please fill all the fields",
              };
            } else {
              if (!isThresholdValid(row.value.threshold).isValid) {
                return { ...row, ...isThresholdValid(row.value.threshold) };
              }
              return { ...row, isValid: true, message: "" };
            }
          } else {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isNotExisting":
        if (row.value.isEnabled) {
          if (row.value.value1?.length) {
            const tableKeys = ["columnName", "operator", "tableName"];
            let tableResult = row.value.value1.map((elem, index) => {
              return tableKeys.every((key) => {
                if (
                  index === 0 &&
                  elem["operator"] === "" &&
                  elem["columnName"] !== "" &&
                  elem["tableName"] !== ""
                ) {
                  return true;
                } else {
                  return Object.keys(elem).includes(key) && elem[key];
                }
              });
            });
            let res = [...tableResult];
            if (res.length && res.some((val) => val === false)) {
              return {
                ...row,
                isValid: false,
                message: "Please fill all the fields",
              };
            } else {
              if (!isThresholdValid(row.value.threshold).isValid) {
                return { ...row, ...isThresholdValid(row.value.threshold) };
              }
              return { ...row, isValid: true, message: "" };
            }
          } else {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isExistingCondAdvanced":
        rowCondition =
          rowVal.value1.primaryTableName.length > 0 &&
          rowVal.value1.primaryColumnName.length > 0 &&
          rowVal.value1.existFromTableName.length > 0 &&
          rowVal.value1.existFromColumnName.length > 0 &&
          rowVal.value1.existToTableName.length > 0 &&
          rowVal.value1.existToColumnName.length > 0 &&
          rowVal.value1.condTableName.length > 0 &&
          rowVal.value1.condColumnName.length > 0 &&
          rowVal.value1.condition.length > 0 &&
          rowVal.value1.conditionString.length > 0;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "fieldCompare_column":
        let currEnabled = row.value.value1
          .map((a) => a.isChecked)
          .some((a) => a);
        if (currEnabled) {
          const neededKeys = ["column", "condition", "table"];
          let res = row.value.value1.map((elem) =>
            neededKeys.every(
              (key) => Object.keys(elem.value).includes(key) && elem?.value[key]
            )
          );
          let thresholdValidity = row.value.value1.reduce(
            (validityObj, currentValue) => {
              if (
                currentValue.isChecked &&
                !isThresholdValid(currentValue.threshold).isValid
              ) {
                return {
                  ...validityObj,
                  ...isThresholdValid(currentValue.threshold),
                };
              } else return validityObj;
            },
            { isValid: true, message: "" }
          );
          if (res.length && res.some((val) => val === false)) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          } else {
            if (!thresholdValidity.isValid) {
              return { ...row, ...thresholdValidity };
            }
            return { ...row, isValid: true, message: "" };
          }
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "isMapped":
        let currEnabledVal = row.value.value1
          .map((a) => a.isChecked)
          .some((a) => a);
        if (currEnabledVal) {
          const neededKeys = ["column"];
          let res = row.value.value1.map((elem) =>
            neededKeys.every(
              (key) => Object.keys(elem.value).includes(key) && elem?.value[key]
            )
          );
          let thresholdValidity = row.value.value1.reduce(
            (validityObj, currentValue) => {
              if (
                currentValue.isChecked &&
                !isThresholdValid(currentValue.threshold).isValid
              ) {
                return {
                  ...validityObj,
                  ...isThresholdValid(currentValue.threshold),
                };
              } else return validityObj;
            },
            { isValid: true, message: "" }
          );
          if (res.length && res.some((val) => val === false)) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          } else {
            if (!thresholdValidity.isValid) {
              return { ...row, ...thresholdValidity };
            }
            return { ...row, isValid: true, message: "" };
          }
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "checkstring_sap_with_condition":
        return { ...row, isValid: true, message: "" };
      case "field_compare_with_condition_sap":
        return { ...row, isValid: true, message: "" };
      case "fieldCompareWithMathematicalOperations":
        let conditionResult = rowVal.value1.conditions.every(
          (elem) => elem.operator !== "" && elem.condColumnName !== ""
        );
        rowCondition =
          rowVal.value1.columnName &&
          rowVal.value1.conditions.length > 0 &&
          conditionResult;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "checkstring_with_condition":
        let checkstringconditionResult = rowVal.value1.conditions.every(
          (elem) =>
            elem.operationsConditionString !== "" &&
            elem.operationsColumnName !== ""
        );
        rowCondition =
          rowVal.value1.columnName &&
          rowVal.value1.conditions.length > 0 &&
          checkstringconditionResult;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      case "mathematicalOperationsWithColumn":
        let condResult = rowVal.value1.conditions.every(
          (elem) => elem.operator !== "" && elem.operationsColumnName !== ""
        );
        rowCondition =
          rowVal.value1.columnName &&
          rowVal.value1.conditionString &&
          rowVal.value1.inputString &&
          rowVal.value1.conditions.length > 0 &&
          condResult;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        }
      case "fieldCompareWithCondition":
        let compareWithConditionResult = rowVal.value1.conditions.every(
          (elem) =>
            elem.operationsColumnName !== "" &&
            elem.optionsInputString !== "" &&
            elem.operationsConditionString !== "" &&
            elem.columnOperator !== ""
        );
        rowCondition =
          rowVal.value1.conditionString &&
          rowVal.value1.inputString &&
          rowVal.value1.conditions.length > 0 &&
          compareWithConditionResult;
        if (rowVal.isEnabled) {
          if (!isThresholdValid(rowVal.threshold).isValid) {
            return { ...row, ...isThresholdValid(rowVal.threshold) };
          }
          if (!rowCondition) {
            return {
              ...row,
              isValid: false,
              message: "Please fill all the fields",
            };
          }
          return { ...row, isValid: true, message: "" };
        } else {
          return { ...row, isValid: true, message: "" };
        }
      default:
        return { ...row, isValid: true, message: "" };
    }
  });
  return validatedSelections;
};

const allRulesToSelections = (allRulesData, columnName) => {
  let selectionsUi = [];
  let selectionsBackend = [];
  const allowedValElementTypes = [
    "checkbox",
    "select_single",
    "select_multiple",
    "select_autocomplete_multiple",
    "input_number",
    "input_text",
    "input_tags",
    "custom_multiple_rules",
    "custom_is_not_existing_rule",
    "custom_field_compare_rule",
    "custom_isExisting_Cond",
    "custom_is_existing_cond_advanced",
    "custom_field_compare_with_condition_sap",
    "custom_checkstring_sap_with_condition",
    "custom_checkstring_with_condition",
    "custom_mathematical_operations_with_column",
    "custom_field_compare_with_mathematical_operations",
    "custom_field_compare_with_condition",
    "custom_is_mapped_rule",
  ];
  for (const ruleRow of allRulesData) {
    let value = {};
    for (const elDataRow of ruleRow.elementData) {
      if (allowedValElementTypes.includes(elDataRow.type)) {
        value[elDataRow.formKey] = elDataRow.metadata.defaultValue;
      }
    }
    value["threshold"] = 0;
    let selRowUi = {
      groupId: ruleRow.groupId,
      name: ruleRow.name,
      categoryId: ruleRow.categoryId,
      columnName: columnName,
      value: value,
      isValid: true,
      message: "",
    };
    let selRowBackend = {
      name: ruleRow.name,
      columnName: columnName,
      groupId: ruleRow.groupId,
      value: value,
    };
    selectionsUi.push(selRowUi);
    selectionsBackend.push(selRowBackend);
  }
  // console.log("BEFORE--->", selectionsUi);
  selectionsUi = rulesValidations(selectionsUi);
  // console.log("AFTER--->", selectionsUi);
  return selectionsUi;
};

const modifyAllRulesDataBasedOnColumns = (allRulesDataStatic, storeAllData) => {
  let allRulesDataMod = [];
  for (const ruleRow of allRulesDataStatic) {
    let newRow = { ...ruleRow };
    let newElementData = [];
    for (const elDataRow of ruleRow.elementData) {
      let newElDataRow = { ...elDataRow };
      if (elDataRow?.metadata?.optionsFiller) {
        const storeDataKey = elDataRow.metadata.optionsFiller.storeDataKey;
        let newOptions = [
          // { id: "select_column", name: "Select Column", disabled: true },
        ];
        newOptions = [...newOptions, ...storeAllData[storeDataKey].result]; // TODO : ORDER LEFT
        newElDataRow = {
          ...newElDataRow,
          metadata: {
            ...newElDataRow.metadata,
            options: newOptions,
          },
        };
      }
      newElementData.push(newElDataRow);
    }
    newRow = { ...newRow, elementData: newElementData };
    allRulesDataMod.push(newRow);
  }
  return allRulesDataMod;
};

const wrapperRulesItAndBusiness = (uiSchemaArr) => {
  // ! SCHEMA
  // let selRowUi = {
  //   groupId: ruleRow.groupId,
  //   name: ruleRow.name,
  //   categoryId: ruleRow.categoryId,
  //   columnName: columnName,
  //   value: value,
  //   isValid: true,
  //   message: "",
  // };
  // let selRowBackend = {
  //   name: ruleRow.name,
  //   columnName: columnName,
  //   groupId: ruleRow.groupId,
  //   value: value,
  // };
  let backendSchemaArr = [];

  for (const uiRow of uiSchemaArr) {
    // console.log("uiRow", uiRow);
    if (uiRow.name === "isExistingCond") {
      // ! HARD CODED
      let tblData = [],
        conditionData = [];
      let spreadData = uiRow.value.value1.map((r) => ({
        ...uiRow,
        value: r,
      }));
      spreadData = spreadData.filter((a) => a.value.isEnabled);
      let operatedSpreadData = spreadData.map((elem) => {
        tblData = elem.value.tables.map((tbl, tblIndex) => {
          if (tblIndex < elem.value.tables.length - 1) {
            return {
              ...tbl,
              operator: elem.value.tables[tblIndex + 1].operator,
            };
          } else {
            return { ...tbl, operator: "" };
          }
        });
        conditionData = elem.value.conditions.map((cond, condIndex) => {
          if (condIndex < elem.value.conditions.length - 1) {
            return {
              ...cond,
              operator: elem.value.conditions[condIndex + 1].operator,
            };
          } else {
            return { ...cond, operator: "" };
          }
        });
        return {
          ...elem,
          value: { ...elem.value, tables: tblData, conditions: conditionData },
        };
      });
      for (const s of operatedSpreadData) {
        let backendRow = {
          name: s.name,
          columnName: s.columnName,
          groupId: s.groupId,
          value: s.value, // ! HARD CODED
        };
        backendSchemaArr.push(backendRow);
      }
    } else if (uiRow.name === "checkstring_sap_with_condition") {
      // ! HARD CODED
      let spreadData = uiRow.value.value1.map((r) => ({
        ...uiRow,
        value: {
          ...uiRow.value,
          threshold: r.threshold,
          value1: r,
        },
      }));
      spreadData = spreadData.filter((a) => a.value.isEnabled);
      let operatedSpreadData = spreadData;
      for (const s of operatedSpreadData) {
        backendSchemaArr.push(s);
      }
    } else if (uiRow.name === "field_compare_with_condition_sap") {
      // ! HARD CODED
      let spreadData = uiRow.value.value1.map((r) => ({
        ...uiRow,
        value: {
          ...uiRow.value,
          threshold: r.threshold,
          value1: r,
        },
      }));
      spreadData = spreadData.filter((a) => a.value.isEnabled);
      let operatedSpreadData = spreadData;
      for (const s of operatedSpreadData) {
        backendSchemaArr.push(s);
      }
    } else if (uiRow.name === "fieldCompare_column") {
      // ! HARD CODED
      const spreadData = uiRow.value.value1
        .map((r) => ({
          ...uiRow,
          value: r,
        }))
        .filter((a) => Object.keys(a.value).length > 0)
        .filter((a) => a.value.isChecked);
      for (const s of spreadData) {
        let backendRow = {
          name: s.name,
          columnName: s.columnName,
          groupId: s.groupId,
          value: { ...s.value.value, threshold: s.value.threshold }, // ! HARD CODED
        };
        backendSchemaArr.push(backendRow);
      }
    } else if (uiRow.name === "isMapped") {
      // ! HARD CODED
      const spreadData = uiRow.value.value1
        .map((r) => ({
          ...uiRow,
          value: r,
        }))
        .filter((a) => Object.keys(a.value).length > 0)
        .filter((a) => a.value.isChecked);
      for (const s of spreadData) {
        let backendRow = {
          name: s.name,
          columnName: s.columnName,
          groupId: s.groupId,
          value: { ...s.value.value, threshold: s.value.threshold }, // ! HARD CODED
        };
        backendSchemaArr.push(backendRow);
      }
    } else if (uiRow.name === "isExisting") {
      // ! HARD CODED
      let optionsString = "";
      uiRow.value.value1.forEach((val) => {
        optionsString +=
          `${val.operator === "AND" ? "#AND#" : ""}` +
          `${val.operator === "OR" ? "%OR%" : ""}` +
          val.tableName +
          "." +
          val.columnName;
      });
      let backendRow = {
        name: uiRow.name,
        columnName: uiRow.columnName,
        groupId: uiRow.groupId,
        value: { value1: optionsString, threshold: uiRow.value.threshold }, // ! HARD CODED
      };
      backendSchemaArr.push(backendRow);
    } else if (uiRow.name === "isNotExisting") {
      // ! HARD CODED
      let optionsString = "";
      uiRow.value.value1.forEach((val) => {
        optionsString +=
          `${val.operator === "AND" ? "#AND#" : ""}` +
          `${val.operator === "OR" ? "%OR%" : ""}` +
          val.tableName +
          "." +
          val.columnName;
      });
      let backendRow = {
        name: uiRow.name,
        columnName: uiRow.columnName,
        groupId: uiRow.groupId,
        value: { value1: optionsString, threshold: uiRow.value.threshold }, // ! HARD CODED
      };
      backendSchemaArr.push(backendRow);
    } else {
      let backendRow = {
        name: uiRow.name,
        columnName: uiRow.columnName,
        groupId: uiRow.groupId,
        value: uiRow.value,
      };
      backendSchemaArr.push(backendRow);
    }
  }
  return backendSchemaArr;
};

const getRuleRowFromname = (name) =>
  allRulesData.find((row) => row.name === name) || {};

const unwrapperRulesItAndBusiness = (backendSchemaArr) => {
  // ! SCHEMA
  // let selRowUi = {
  //   groupId: ruleRow.groupId,
  //   name: ruleRow.name,
  //   categoryId: ruleRow.categoryId,
  //   columnName: columnName,
  //   value: value,
  //   isValid: true,
  //   message: "",
  // };
  // let selRowBackend = {
  //   name: ruleRow.name,
  //   columnName: columnName,
  //   groupId: ruleRow.groupId,
  //   value: value,
  // };
  // console.log("backendSchemaArr", backendSchemaArr);
  const groupedRules = groupBy(backendSchemaArr, (item) => item.name);
  let uiSchemaArr = [];

  for (const [ruleId, groupedData] of Object.entries(groupedRules)) {
    // console.log("ruleId", ruleId);
    if (ruleId === "isExistingCond") {
      // ! HARD CODED
      const firstRow = groupedData[0];
      // console.log("firstRow", firstRow);
      let uiRow = {
        categoryId: getRuleRowFromname(firstRow.name).categoryId,
        groupId: firstRow.groupId,
        name: firstRow.name,
        columnName: firstRow.columnName,
        value: {
          isEnabled: true,
          value1: groupedData.map((r) => ({
            ...r.value,
            threshold: r.value.threshold || 0, //Backward comptiblity
            tables: r.value.tables.map((tbl, tblIndex) => {
              if (tblIndex > 0) {
                return {
                  ...tbl,
                  operator: r.value.tables[tblIndex - 1].operator,
                };
              } else {
                return { ...tbl, operator: "" };
              }
            }),
            conditions: r.value.conditions.map((cond, condIndex) => {
              if (condIndex > 0) {
                return {
                  ...cond,
                  operator: r.value.conditions[condIndex - 1].operator,
                };
              } else {
                return { ...cond, operator: "" };
              }
            }),
            uniqueKey: v4(),
          })),
        },
        isValid: true,
        message: "",
      };
      // console.log("uiRow", uiRow);
      uiSchemaArr.push(uiRow);
    } else if (ruleId === "fieldCompare_column") {
      // ! HARD CODED
      const firstRow = groupedData[0];
      let uiRow = {
        categoryId: getRuleRowFromname(firstRow.name).categoryId,
        groupId: firstRow.groupId,
        name: firstRow.name,
        columnName: firstRow.columnName,
        value: {
          isEnabled: true,
          value1: groupedData.map((r) => ({
            isChecked: true,
            threshold: r.value.threshold || 0, //Backward comptiblity
            uniqueKey: v4(),
            value: r.value,
          })),
        },
        isValid: true,
        message: "",
      };
      // console.log("uiRow", uiRow);
      uiSchemaArr.push(uiRow);
    } else if (ruleId === "isMapped") {
      // ! HARD CODED
      const firstRow = groupedData[0];
      let uiRow = {
        categoryId: getRuleRowFromname(firstRow.name).categoryId,
        groupId: firstRow.groupId,
        name: firstRow.name,
        columnName: firstRow.columnName,
        value: {
          isEnabled: true,
          value1: groupedData.map((r) => ({
            isChecked: true,
            threshold: r.value.threshold || 0, //Backward comptiblity
            uniqueKey: v4(),
            value: r.value,
          })),
        },
        isValid: true,
        message: "",
      };
      // console.log("uiRow", uiRow);
      uiSchemaArr.push(uiRow);
    } else if (ruleId === "isExisting") {
      // ! HARD CODED
      const firstRow = groupedData[0];
      let uiRow = {
        categoryId: getRuleRowFromname(firstRow.name).categoryId,
        groupId: firstRow.groupId,
        name: firstRow.name,
        columnName: firstRow.columnName,
        value: {
          isEnabled: true,
          threshold: firstRow.value.threshold || 0, //Backward comptiblity
          value1: splitSemanticOptions(firstRow.value.value1).map((elem) => ({
            ...elem,
            id: v4(),
          })),
        },
        isValid: true,
        message: "",
      };
      uiSchemaArr.push(uiRow);
    } else if (ruleId === "isNotExisting") {
      // ! HARD CODED
      const firstRow = groupedData[0];
      let uiRow = {
        categoryId: getRuleRowFromname(firstRow.name).categoryId,
        groupId: firstRow.groupId,
        name: firstRow.name,
        columnName: firstRow.columnName,
        value: {
          isEnabled: true,
          threshold: firstRow.value.threshold || 0, //Backward comptiblity
          value1: splitSemanticOptions(firstRow.value.value1).map((elem) => ({
            ...elem,
            id: v4(),
          })),
        },
        isValid: true,
        message: "",
      };
      uiSchemaArr.push(uiRow);
    } else if (ruleId === "checkstring_sap_with_condition") {
      // ! HARD CODED
      const firstRow = groupedData[0];
      let uiRow = {
        categoryId: getRuleRowFromname(firstRow.name).categoryId,
        groupId: firstRow.groupId,
        name: firstRow.name,
        columnName: firstRow.columnName,
        value: {
          isEnabled: true,
          threshold: firstRow.value.threshold || 0, //Backward comptiblity
          value1: groupedData.map((r) => ({
            ...r.value.value1,
          })),
        },
        isValid: true,
        message: "",
      };
      // console.log("uiRow", uiRow);
      uiSchemaArr.push(uiRow);
    } else if (ruleId === "field_compare_with_condition_sap") {
      // ! HARD CODED
      const firstRow = groupedData[0];
      let uiRow = {
        categoryId: getRuleRowFromname(firstRow.name).categoryId,
        groupId: firstRow.groupId,
        name: firstRow.name,
        columnName: firstRow.columnName,
        value: {
          isEnabled: true,
          threshold: firstRow.value.threshold || 0, //Backward comptiblity
          value1: groupedData.map((r) => ({
            ...r.value.value1,
          })),
        },
        isValid: true,
        message: "",
      };
      // console.log("uiRow", uiRow);
      uiSchemaArr.push(uiRow);
    } else {
      for (const backendRow of groupedData) {
        let uiRow = {
          categoryId: getRuleRowFromname(backendRow.name).categoryId,
          groupId: backendRow.groupId,
          name: backendRow.name,
          columnName: backendRow.columnName,
          value: {
            ...backendRow.value,
            threshold: backendRow.value.threshold || 0,
          },
          isValid: true,
          message: "",
        };
        // console.log("uiRow", uiRow);
        uiSchemaArr.push(uiRow);
      }
    }
  }
  return uiSchemaArr;
};

const isRulesSelectionsInvalid = (selections) => {
  let enabledSelections = selections.filter((row) => row.value.isEnabled);
  let allValidFlags = enabledSelections.map((row) => row.isValid);
  let finalValidityFlag = allValidFlags.every((flag) => flag);
  return !finalValidityFlag;
};

const unwrapperTablesAddedForDatabase = (data) => {
  let data2 = JSON.parse(JSON.stringify(data));
  data2.forEach((item) => {
    item.it = {};
    item.business = {};
    item.semantic = {};
    item.table_columns.forEach((object) => {
      item.it[object.column_name] = unwrapperRulesItAndBusiness(
        object.rules[0].it
      );
      item.business[object.column_name] = unwrapperRulesItAndBusiness(
        object.rules[1].business
      );
      item.semantic[object.column_name] = unwrapperRulesItAndBusiness(
        object.rules[2].semantic
      );
    });
  });
  return data2;
};

const wrapperTableAddedForDatabase = (props) => {
  // Props
  const { userInputs, user, data } = props;

  // Create required variables
  const pipelinesArr = JSON.parse(
    JSON.stringify(userInputs.tablesAddedForDatabase)
  );
  const currTableId = user.selections.columnSelected.id;
  const currTableData = pipelinesArr.find((row) => row.id === currTableId);

  // Find list of columns to be added
  let columnNamesWhoseBANUpdated = data.datasetTablesColumns.result.filter(
    (backendRow) => {
      let uiRow = userInputs.datasetTablesColumns.find(
        (row) => row.column_name === backendRow.column_name
      );
      return (
        backendRow.business_attribute_name !== uiRow.business_attribute_name ||
        backendRow.filter_status !== uiRow.filter_status ||
        backendRow.filter_type !== uiRow.filter_type
      );
    }
  );
  columnNamesWhoseBANUpdated = columnNamesWhoseBANUpdated.map(
    (colNames) => colNames.column_name
  );
  let columnNamesAdded = [
    ...Object.keys(currTableData?.it),
    ...Object.keys(currTableData?.business),
    ...Object.keys(currTableData?.semantic),
    ...columnNamesWhoseBANUpdated,
  ];

  // Remove duplicates
  columnNamesAdded = [...new Set(columnNamesAdded)];

  // Table Columns
  const newTableColumns = columnNamesAdded.map((columnName) => {
    let columnObject =
      userInputs.datasetTablesColumns.find((a) => a.name === columnName) || {};
    let newColumnRow = {
      column_name: columnName,
      datatype: columnObject.datatype,
      business_attribute_name: columnObject.business_attribute_name,
      filter_type: columnObject.filter_type,
      filter_status: columnObject.filter_status,
      rules: [
        {
          it: wrapperRulesItAndBusiness(currTableData?.it?.[columnName] || []),
        },
        {
          business: wrapperRulesItAndBusiness(
            currTableData?.business?.[columnName] || []
          ),
        },
        {
          semantic: wrapperRulesItAndBusiness(
            currTableData?.semantic?.[columnName] || []
          ),
        },
      ],
    };
    return newColumnRow;
  });

  // Final Object
  let { it, business, semantic, schema_name, ...requiredKeysInCurrTableData } =
    currTableData;
  requiredKeysInCurrTableData = {
    ...requiredKeysInCurrTableData,
    db_name: currTableData?.schema_name,
    table_columns: newTableColumns,
  };

  return requiredKeysInCurrTableData;
};

export {
  allRulesData,
  masterGroupData,
  rulesItAndBusiness,
  allRulesToSelections,
  modifyAllRulesDataBasedOnColumns,
  wrapperRulesItAndBusiness,
  isRulesSelectionsInvalid,
  rulesValidations,
  unwrapperTablesAddedForDatabase,
  wrapperTableAddedForDatabase,
};
