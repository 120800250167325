// Import required libraies
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, TableBody, TableRow, makeStyles } from "@material-ui/core";

import { StyledTableCell } from "../../screens/CompareDatasets/CompareDatasetsListTable/style.js";
import runningImg from "../../../assets/images/running.svg";
import errorImg from "../../../assets/images/error.svg";
import resizingImg from "../../../assets/images/resizing.svg";
import restartingImg from "../../../assets/images/restarting.svg";

// Import Custom Component
import Loader from "../Loader/Loader";

// Import data & utils
import { clusterData, startCluster } from "../../../js/services/apis.js";
import DQButton from "../Common/DQButton.js";

import { updateAlertInfo } from "../../redux/actions";
// # COMPONENT
const ClusterInformation = (props) => {
  // # PROPS
  const [clusterApi, setClusterApi] = useState({
    status: "loading",
    message: "",
    data: {},
  });

  // # HOOKS
  const user = useSelector((state) => state.user);
  const userInfo = useSelector((state) => state.data.userInfo);
  const ReduxDispatcher = useDispatch();

  useEffect(() => {
    let clusterDataPromise = clusterData({
      cluster_id: user.selections.columnSelected.cluster_id,
    });
    clusterDataPromise
      .then((responseData) => {
        setClusterApi({ status: "success", message: "", data: responseData });
      })
      .catch((err) => {
        setClusterApi({ status: "error", message: "", data: {} });
      });
  }, []);

  const handleStartCluster = () => {
    let startClusterPromise = startCluster({
      cluster_id: user.selections.columnSelected.cluster_id,
    });
    startClusterPromise
      .then((responseData) => {
        if (responseData.status) {
          ReduxDispatcher(
            updateAlertInfo({
              open: true,
              message: responseData.message,
              severity: "success",
            })
          );
        } else {
          ReduxDispatcher(
            updateAlertInfo({
              open: true,
              message: responseData.message,
              severity: "success",
            })
          );
        }
      })
      .catch((err) => {
        ReduxDispatcher(
          updateAlertInfo({
            open: true,
            message: "Something went wrong",
            severity: "success",
          })
        );
      });
  };

  // # STATIC VARIABLES
  let conditionCheck = clusterApi.data.state;
  let backgroungColor = "";
  let image = "";
  // Color Palet
  switch (conditionCheck) {
    case "PENDING":
      backgroungColor = "#FFA500";
      image = resizingImg;
      break;
    case "RUNNING":
      backgroungColor = "#228B22";
      image = runningImg;
      break;
    case "RESTARTING":
      backgroungColor = "#9ACD32";
      image = restartingImg;
      break;
    case "RESIZING":
      backgroungColor = "#E0FFFF";
      image = resizingImg;
      break;
    case "TERMINATING":
      backgroungColor = "#A52A2A";
      image = resizingImg;
      break;
    case "TERMINATED":
      backgroungColor = "#800000";
      image = resizingImg;
      break;
    case "ERROR":
      backgroungColor = "#FF0000";
      image = errorImg;
      break;
    case "UNKNOWN":
      backgroungColor = "	#F08080";
      image = resizingImg;
      break;

    default:
      backgroungColor = "#FFD700";
      image = resizingImg;
      break;
  }

  let startClusterEnableButton = false;
  userInfo.result.map((el) => {
    if (el.label === "Cluster Setting") {
      startClusterEnableButton = true;
    }
  });

  // # JSX
  return (
    <>
      {clusterApi.status === "loading" && <Loader />}
      {clusterApi.status === "success" && (
        <Box style={{ display: "flex", width: "100%" }}>
          <Box style={{ width: "50%" }}>
            <TableBody>
              <TableBody>
                <TableRow>
                  <StyledTableCell style={{ width: "300px" }}>
                    {"Cluster Id"}
                  </StyledTableCell>

                  <StyledTableCell>
                    {clusterApi.data.cluster_id}
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell style={{ width: "300px" }}>
                    {"Cluster Name"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {clusterApi.data.cluster_name}
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell style={{ width: "300px" }}>
                    {"State"}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box
                      style={{
                        borderRadius: "5px",
                        textAlign: "center",
                        color: "white",
                        fontWeight: "bold",
                        background: backgroungColor,
                      }}
                    >
                      {clusterApi.data.state}
                    </Box>
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell style={{ width: "300px" }}>
                    {"State Message"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {clusterApi.data.state_message}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </TableBody>
            {clusterApi.data.state !== "RUNNING" && (
              <Box style={{ padding: "20px" }}>
                {startClusterEnableButton && (
                  <DQButton
                    title="Start Cluster"
                    disabled={false}
                    variant="contained"
                    onclick={handleStartCluster}
                  />
                )}
              </Box>
            )}
          </Box>
          <Box
            style={{
              width: "50%",
              height: "500px",
            }}
          >
            <img style={{ width: "100%", height: "100%" }} src={image} />
          </Box>
        </Box>
      )}
      {clusterApi.status === "error" && <Loader />}
    </>
  );
};

// # EXPORTS
export default ClusterInformation;
