import React, { useEffect, useRef, useState } from "react";
import {
  getTableDataProfiling,
  runTableDataProfiling,
} from "../../services/apis";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import DQButton from "./../Common/DQButton";
import DQMultiAutoCompleteWithCheckbox from "../Common/DQMultiAutoCompleteWithCheckbox";
import DQRadioGroup from "../Common/DQRadioGroup";
import { initialApiData } from "../../utils/utils";
import moment from "moment";
import { orderBy } from "lodash";
import { updateAlertInfo } from "../../redux/actions";
import noDataImg from "../../../assets/images/no-data.svg";
import running from "../../../assets/images/running.svg";
import LinearProgressBarWithLabel from "../../components/LinearProgressBarWithLabel/LinearProgressBarWithLabel.js";

export function DataProfiling(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.data);

  const [htmlFileString, setHtmlFileString] = useState(null);
  const [selectedDataProfiling, setSelectedDataProfiling] = useState({
    status: "loading",
    message: "",
    data: [],
  });

  const [progress, setProgress] = React.useState(10);

  const [tagsSelections, setTagsSelections] = useState([]); // selections
  const [tagsData, setTagsData] = useState(initialApiData);
  const autoC = useRef(null);

  async function fetchDataProfiling(id) {
    const listDataProf = await getTableDataProfiling({ id });

    if (listDataProf) {
      const orderedData = orderBy(
        listDataProf,
        [(obj) => moment(obj.created_at, "YYYY-MM-DD hh:mm:ss").toDate()],
        ["desc"]
      );

      const dataProf = orderedData[0];
      if (dataProf) {
        console.log(window.location.hostname);
        // setSelectedDataProfiling(dataProf);
        setSelectedDataProfiling({
          status: "loading",
          message: "",
          data: dataProf,
        });
        if (dataProf.status === "SUCCESS") {
          let url = `https://api.allorigins.win/raw?url=${encodeURIComponent(
            dataProf.url
          )}`;
          //          window.location.hostname === "localhost" ||
          //          window.location.hostname === "127.0.0.1"
          //            ? `https://api.allorigins.win/raw?url=${encodeURIComponent(
          //                dataProf.url
          //              )}`
          //            : dataProf.url;

          await setHtmlFileString(await (await fetch(url)).text());
          setSelectedDataProfiling({
            status: "success",
            message: "",
            data: dataProf,
          });
        } else {
          setSelectedDataProfiling({
            status: "running",
            message: "",
            data: dataProf,
          });
        }
      }
    }
    if (listDataProf.length === 0) {
      setSelectedDataProfiling({
        status: "nodata",
        message: "",
        data: [],
      });
    }
  }
  useEffect(() => {
    fetchDataProfiling(user.selections.columnSelected?.id);
  }, [user.selections.columnSelected?.id]);

  function downloadURI(uri, name = "") {
    var link = document.createElement("a");
    var isIE = /MSIE|Trident|Edge\//.test(window.navigator.userAgent);

    if (isIE) {
      link.target = "_blank";
    }

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const runType = [
    {
      id: "filteredRun",
      name: "Select Columns",
      disabled: false,
    },
    {
      id: "fullRun",
      name: "All Columns",
      disabled: false,
    },
  ];

  useEffect(() => {
    setTagsData({
      status: "success",
      message: "",
      result: data.datasetTablesColumns.result,
    });
  }, [data.datasetTablesColumns]);

  // Timmer UseEffect
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 90 ? prevProgress : prevProgress + 10
      );
    }, 1000);
    if (selectedDataProfiling.status === "success") clearInterval(timer);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleTagsChange = (newValue) => {
    setTagsSelections(newValue);
  };

  const deleteValues = (regValue) => {
    let valArr = tagsSelections.filter(
      (singleValue) => singleValue !== regValue
    );
    setTagsSelections(orderBy([...valArr]));
  };

  const [selectionRunType, setSelectionRunType] = useState({
    id: "filteredRun",
    name: "Select Columns",
    disabled: false,
  });

  const handleChangeRunType = (value) => {
    if (value === "filteredRun") {
      setSelectionRunType({
        id: "filteredRun",
        name: "Filtered Run",
        disabled: false,
      });
    } else {
      setSelectionRunType({
        id: "fullRun",
        name: "All Columns",
        disabled: false,
      });
    }
  };

  const handleSubmit = async (e) => {
    let reqObj = {
      id: user.selections.columnSelected?.id,
      columns:
        selectionRunType.id === "filteredRun"
          ? tagsSelections.map((c) => c.column_name)
          : tagsData.result.map((c) => c.column_name),
    };

    let responseData = await runTableDataProfiling(reqObj);

    if (!responseData) responseData = { status: false };

    if (responseData.status === true) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: `Run Data Profiling request successfully`,
          severity: "success",
        })
      );
    } else {
      dispatch(
        updateAlertInfo({
          open: true,
          message: "Something went wrong...",
          severity: "error",
        })
      );
    }
  };

  const handleDisableRun = () => {
    if (selectionRunType.id === "filteredRun") {
      if (tagsSelections.length > 0) return false;
    } else {
      return false;
    }
    return true;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          marginBottom: 10,
          flexDirection: "column",
          border: "1px solid #0000001f",
          borderRadius: 4,
          padding: 10,
        }}
      >
        <Box>
          <label className={"metadata-label"}>Run Type</label>
          <Box className>
            <Box css={{ paddingTop: "8px", paddingBottom: "10px" }}>
              <DQRadioGroup
                style={{ paddingTop: "10px !important" }}
                data={runType}
                onChange={handleChangeRunType}
                value={selectionRunType.id}
              />
            </Box>
          </Box>
        </Box>

        {selectionRunType.id === "filteredRun" ? (
          <Box sx={{ marginBottom: 20 }}>
            <label className={"metadata-label"}>
              Select Columns to filter:
            </label>
            <Box>
              <DQMultiAutoCompleteWithCheckbox
                value={tagsSelections}
                onChange={handleTagsChange}
                placeholderText="Enter Columns"
                disableFlag={false}
                autoC={autoC}
                deleteValues={deleteValues}
                data={tagsData.result || []}
                allOption={false}
              />
            </Box>
          </Box>
        ) : null}

        <Box>
          <DQButton
            onclick={handleSubmit}
            styles={{ padding: 16, alignSelf: "start" }}
            title="Run"
            disabled={handleDisableRun()}
            variant="contained"
          />
        </Box>
      </Box>

      {selectedDataProfiling.data ? (
        <Box
          sx={{
            display: "flex",
            marginBottom: 20,
            flexDirection: "column",
            padding: 5,
            paddingBottom: 15,
            borderBottom: "1px solid #e3e3e3",
          }}
        >
          <Box
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box>
              <DQButton
                onclick={() => {
                  if (selectedDataProfiling)
                    downloadURI(selectedDataProfiling.data.url);
                }}
                styles={{ padding: 16, alignSelf: "start" }}
                title="Download"
                disabled={false}
                variant="contained"
              />
            </Box>

            <Box>
              <DQButton
                onclick={() => {
                  fetchDataProfiling(user.selections.columnSelected?.id);
                }}
                styles={{
                  padding: 16,
                  alignSelf: "start",
                  backgroundColor: "#f3f3f3",
                }}
                title="Refresh"
                disabled={false}
              />
            </Box>
          </Box>
        </Box>
      ) : null}
      {selectedDataProfiling.status === "loading" && (
        <>
          <LinearProgressBarWithLabel value={progress} />
          <h3>Loading Data...</h3>
        </>
      )}

      {selectedDataProfiling.status === "nodata" && (
        <img style={{ width: "100%", height: "400px" }} src={noDataImg} />
      )}
      {selectedDataProfiling.status === "success" && (
        <Box>
          <iframe
            frameBorder="0"
            style={{ pointerEvents: "none", width: "100%", height: 600 }}
            srcDoc={htmlFileString}
          ></iframe>
        </Box>
      )}
      {selectedDataProfiling.status === "running" && (
        <img style={{ width: "100%", height: "400px" }} src={running} />
      )}
    </>
  );
}
