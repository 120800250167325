// Import required libraies
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

// Import Custom Component
import Loader from "../Loader/Loader";
import DQInput from "../Common/DQInput";
import DQButton from "../Common/DQButton";

// Import data & utils
import { saveLandingLayerFilePath } from "../../../js/services/apis.js";
import { updateAlertInfo, updateSelections } from "../../redux/actions";

// # CSS
export const useStyles = makeStyles((theme) => ({
  header: {
    // fontSize: "14px",
    fontFamily: "Hind Siliguri",
    color: "#4eafb3",
    fontWeight: "500",
    display: "flex",
    justifyContent: "center",
    marginBottom: "25px",
    backgroundColor: "#f5f4f3",
    fontSize: "16px",
    padding: "10px 0px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "5px",
  },
  headerLabel: {},
  inputContainerLabelSample: {
    fontSize: "14px",
    fontFamily: "Hind Siliguri",
    color: "#46596a9e",
    fontWeight: "550",
    width: "200px",
  },
  inputContainerLabel: {
    fontSize: "14px",
    fontFamily: "Hind Siliguri",
    color: "#465A69",
    fontWeight: "550",
    width: "200px",
  },
}));

// # COMPONENT
const LandinLayerInformation = (props) => {
  // # PROPS

  // # HOOKS
  const selections = useSelector((state) => state.user.selections);

  const landingLayerSelection = selections.landingLayerSelection;
  const columnSelected = selections.columnSelected;
  const dispatch = useDispatch();
  const classes = useStyles();

  // # HANDLER FUNCTION
  const handleActualFileLoaction = (value) => {
    const finalSelection = {
      ...landingLayerSelection,
      data: { ...landingLayerSelection.data, actual_file_location: value },
    };
    dispatch(updateSelections("landingLayerSelection", finalSelection));
  };

  const redirectToTableInformation = async () => {
    dispatch(updateSelections("columnSelectionPage", false));
  };
  const setNextTabOrSave = () => {
    let reqBody = {
      file: {
        sample_file_name: landingLayerSelection.data.sample_file_name,
        sample_file_type: landingLayerSelection.data.sample_file_type,
        sample_file_location: landingLayerSelection.data.sample_file_location,
        actual_file_location: landingLayerSelection.data.actual_file_location,
      },
    };

    const tableId = columnSelected.id;
    const filePath = landingLayerSelection.data.actual_file_location;
    const finalObj = { tableId, filePath, reqBody };
    console.log("monu finalObj", finalObj);
    let saveLandingLayerFilePathPromise = saveLandingLayerFilePath(finalObj);

    saveLandingLayerFilePathPromise.then(() => {
      dispatch(
        updateAlertInfo({
          open: true,
          message: `Location updated successfully`,
          severity: "success",
        })
      );
    }).catch(() => {
      dispatch(
        updateAlertInfo({
          open: true,
          message: `Error`,
          severity: "error",
        })
      );
    });
  };

  // # STATIC VARIABLES
  let saveButtonDisabled = true;
  if (landingLayerSelection.data.actual_file_location.length > 3) {
    saveButtonDisabled = false;
  }

  // # JSX
  return (
    <>
      {landingLayerSelection.status === "loading" && <Loader />}
      {landingLayerSelection.status === "success" && (
        <>
          <div className={classes.header}>
            <label className={classes.headerLabel}> Sample file</label>
          </div>
          <div className={classes.inputContainer}>
            <label className={classes.inputContainerLabelSample}>
              Sample File name:{" "}
            </label>
            <DQInput
              name="File name"
              value={landingLayerSelection.data.sample_file_name}
              placeholder="Report Name"
            />
          </div>

          <div className={classes.inputContainer}>
            <label className={classes.inputContainerLabelSample}>
              Sample File Type
            </label>
            <DQInput
              name="File type"
              value={landingLayerSelection.data.sample_file_type}
              placeholder="Report Name"
            />
          </div>

          <div className={classes.inputContainer}>
            <label className={classes.inputContainerLabelSample}>
              Sample File location
            </label>
            <DQInput
              name="File location"
              value={landingLayerSelection.data.sample_file_location}
              placeholder="Report Name"
            />
          </div>

          <div className={classes.header}>
            <label className={classes.headerLabel}> Actual file</label>
          </div>

          <div className={classes.inputContainer}>
            <label className={classes.inputContainerLabel}>
              {" "}
              Actual file location
            </label>
            <DQInput
              name="File location"
              value={landingLayerSelection.data.actual_file_location}
              placeholder="Actual file location"
              handleChange={(value) => handleActualFileLoaction(value)}
            />
          </div>

          <Box
            sx={{
              display: "flex",
              position: "fixed",
              bottom: 40,
              right: 0,
              mr: 2,
            }}
          >
            <Link className="disabled-style" to={"/home/mydataset"}>
              <DQButton
                title="Cancel"
                disabled={false}
                variant="outlined"
                onclick={() => {
                  redirectToTableInformation();
                }}
              />
            </Link>

            <DQButton
              title={"Save"}
              disabled={saveButtonDisabled}
              variant="contained"
              onclick={setNextTabOrSave}
            />
          </Box>
        </>
      )}
      {landingLayerSelection.status === "error" && <Loader />}
    </>
  );
};

// # EXPORTS
export default LandinLayerInformation;
