import "./common.scss";

import { Box, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import React from "react";

const useStyles = makeStyles({
  typographyStyle: {
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      color: "#46596a",
    },
  },
});

const CssTextField = withStyles({
  root: {
    backgroundColor: "#f3f3f3",
    padding: "10px",
    height: "24px",
    borderRadius: "6px",
  },
})(TextField);

const DQInput = ({
  name = "",
  handleChange,
  placeholder,
  value = "",
  isDisabled,
  className = "",
  styles,
  onEnter,
  maxWidth,
  onClickAway = () => {},
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        width: maxWidth ? maxWidth : 358,
        height: 46,
      }}
    >
      <CssTextField
        fullWidth
        style={{ ...(styles ? { ...styles } : {}) }}
        type="text"
        name={name}
        disabled={isDisabled}
        value={value}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onBlur={onClickAway}
        className={classes.typographyStyle}
        placeholder={placeholder}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            if (onEnter) onEnter(event, event.target.value);
          }
        }}
      />
    </Box>
  );
};

export default DQInput;
