import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { MenuItem, Tab } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& h5": {
      fontWeight: "600",
      color: "#d11450",
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "150px",
    },
    "& .MuiInputBase-root": {
      fontSize: "0.7em",
    },
    "& .MuiSelect - select.MuiSelect - select": {
      background: "#ededed",
    },
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },

  gridContainerClass: {
    // alignItems:'end'
  },
  gridItemClass: {
    textAlign: "start",
    marginLeft: "15px",
  },
  gridItemRight: {
    textAlign: "end",
  },
  selectiontypo: {
    fonFamily: "Hind Siliguri",
    fontSize: "18px",
    backgroundColor: "gray",
    margin: "10px",
    padding: "12px",
    borderRadius: "6px",
    color: "white",
  },
  monthVal: {
    color: "#465A69",
    fontSize: "12px !important",
    fontStyle: "normal !important",
    fontFamily: "Hind Siliguri !important",
    fontWeight: "600 !important",
    lineHeight: "19px !important",
    marginLeft: "30px !important",
    textTransform: "capitalize",
  },
  downloadButton: {
    fontFamily: "Hind Siliguri",
    border: "1px solid #4EAFB3",
    color: "#4EAFB3",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      background: "#4EAFB3",
      color: "#FFFFFF",
    },
  },
  runOnTextCSS: {
    fontFamily: "Hind Siliguri !important",
    fontStyle: "normal !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    lineHeight: "23px !important",
    color: "#465A69 !important",
    marginLeft: "30px !important",
    marginBottom: "15px !important",
  },
  doneButton: {
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "19px",
    color: "#FFFFFF",
    backgroundColor: "#4EAFB3",
    borderRadius: "5px",
    border: "none",
    textTransform: "none",
    // width: '75px',
    paddingLeft: "15px",
    paddingRight: "15px",
    height: "30px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#4EAFB3",
    "&:hover": {
      background: "none",
      color: "#4EAFB3",
    },
  },
  heading: {
    fontFamily: "Hind Siliguri",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "32px",
    letterSpacing: "-0.03em",
    marginLeft: "4px",
    paddingLeft: "4px",
    color: "#465A69",
    height: "50vh",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "15px",
  },
  statusIconStyle: {
    width: "22px",
    height: "22px",
    marginRight: "6px",
  },
  button: {
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    width: "50%",
    fontSize: "14px",
    fontWeight: 700,
    color: "#465A69",
    backgroundColor: "#f6f6f6",
    border: "none",
    textTransform: "none",
    height: "66px",
    boxShadow: "none",
    borderRadius: "none",
    padding: "12px 0px",
  },
  activeBtn: {
    fontFamily: "Hind Siliguri",
    fontStyle: "normal",
    fontSize: "14px",
    width: "50%",
    fontWeight: 700,
    color: "#4eafb3",
    backgroundColor: "#4eafb342 !important",
    border: "none",
    boxShadow: "none",
    textTransform: "none",
    height: "66px",
    borderRadius: "4px",
    padding: "12px 0px",
  },
  rulesTabGrid: {
    height: "10%",
    width: "100%",
  },
}));

export const CustomMenuItem = withStyles(() =>
  createStyles({
    // root: {
    // 	"&$selected": {
    // 		backgroundColor: "red",
    // 		"&:hover": {
    // 			backgroundColor: "green",
    // 		},
    // 	},
    // 	'&:hover': {
    // 		backgroundColor: 'blue',
    // 	},
    // },
    selected: {},
  })
)(MenuItem);

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#46596a",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "14px",
    fontFamily: "Hind Siliguri",
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
  selected: {
    color: "#EB367F",
    fontWeight: 700,
  },
}))((props) => <Tab disableRipple {...props} />);
