// Import required libraries

import "./Breadcrumbs.scss";

import { Link } from "react-router-dom";
import React from "react";
import allRulesStatic from "../../../assets/data/allRulesSearch.json";
import { splitUrlToBreadcrumbData } from "../../utils/utils";
import { v4 } from "uuid";

// Import Utils

// Import styles

function Breadcrumbs(props) {
  let { match } = props;

  let data = [];
  try {
    let data = splitUrlToBreadcrumbData(match.url);
  } catch (error) {}

  const getLabel = (label) => {
    if (match.path === "/home/globalRules/:ruleName") {
      let ruleFormattedName = allRulesStatic.find(
        (r) => match.params.ruleName === r.name
      )?.formattedName;
      return ruleFormattedName
        ? ruleFormattedName
        : label.replace(/./, (x) => x.toUpperCase());
    } else {
      return label.replace(/./, (x) => x.toUpperCase());
    }
  };

  return (
    <div className={`breadcrumb`}>
      {data.map((obj, index) => (
        <span key={v4()} className="breadcrumb-item-container">
          {index !== data.length - 1 ? (
            <>
              <Link
                className="breadcrumb-item disabled-style non-active"
                to={obj.path}
              >
                {obj.label}
              </Link>
              <span>&nbsp;/&nbsp;</span>
            </>
          ) : (
            <span className="breadcrumb-item active">
              {getLabel(obj.label)}
            </span>
          )}
        </span>
      ))}
    </div>
  );
}

export default Breadcrumbs;
