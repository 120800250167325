/* eslint-disable indent */

import "./TeamsListTable.scss";

import { Box, TableCell, TableHead, TableRow } from "@material-ui/core";

import Filters from "../../../../components/Filters/Filters";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useStyles } from "./style";

const TeamsListTableHeader = (props) => {
  const { user, allData } = props;
  const classes = useStyles();

  let nameList = [...new Set(allData?.map((item) => item.name))];

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <div className="header-with-filter">
            <Box>Name</Box>
            <Filters
              flag={
                user.selections.adminTeamsListNameSearch.value?.length
                  ? true
                  : false
              }
              id="adminTeamsListNameSearch"
              data={nameList}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <Box>Owner</Box>
        </TableCell>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <Box>Last Modified By</Box>
        </TableCell>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <Box>Created On</Box>
        </TableCell>
        <TableCell
          className={classes.tablecell}
          style={{ padding: "10px", width: 80 }}
        >
          {/**/}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

TeamsListTableHeader.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(TeamsListTableHeader);
