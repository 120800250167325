//Import required libraies
import React from "react";
import { connect } from "react-redux";

//Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import GlobalRulesManager from "./GlobalRulesManager";

function GlobalRulesManagerContainer(props) {
  let { match } = props;

  return (
    <LayoutTopSideBottom match={match}>
      <Breadcrumbs match={match} />
      <GlobalRulesManager />
    </LayoutTopSideBottom>
  );
}

// GlobalRulesManagerContainer.propTypes = {
//   match: PropTypes.object,
//   user: PropTypes.object,
//   allData: PropTypes.object,
// };

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalRulesManagerContainer);
