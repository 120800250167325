//Import required libraies

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import TextField from "@material-ui/core/TextField";

// Import Custom Component

// Import contexts

// Import action creators

// Import utils

// Import styles

const useStyles = makeStyles({
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      color: "#46596a",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      "& span": {
        "& svg": {
          "& path": {
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
          },
        },
      },
    },
  },
});

const StyledAutoComplete = withStyles({
  tag: {
    background: "#465a69",
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
})(Autocomplete);

const CssTextField = withStyles({
  root: {
    width: "96%",
    backgroundColor: "#f3f3f3",
    padding: "10px",
    height: "24px",
    borderRadius: "6px",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

function DQSingleAutocomplete(props) {
  const {
    value,
    optionsData,
    handleChange,
    placeholderText,
    isDisabled,
    newKey,
    styles,
    onInputChange,
    onEnter,
    customOptionLabel,
  } = props;
  const classes = useStyles();

  return (
    <StyledAutoComplete
      className={classes.typographyStyle}
      value={value}
      options={optionsData}
      // getOptionLabel={(option) => (option?.name ? option.name : option)}
      getOptionLabel={(option) => {
        if (customOptionLabel) return customOptionLabel(option);

        return option?.[newKey]
          ? option[newKey]
          : typeof option === "string" || option instanceof String
          ? option
          : "";
      }}
      onChange={(e, newValue) => {
        handleChange(newValue);
      }}
      disableClearable
      disabled={isDisabled}
      onInputChange={(event, newInputValue) => {
        if (onInputChange) onInputChange(newInputValue);
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          if (onEnter) onEnter(event);
        }
      }}
      renderInput={(params) => (
        <CssTextField
          {...params}
          className={classes.typographyStyle}
          fullWidth
          placeholder={placeholderText}
        />
      )}
      style={{
        width: "352px",
        height: "46px",
        ...(styles ? { ...styles } : {}),
      }}
    />
  );
}

export default DQSingleAutocomplete;
