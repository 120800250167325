import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Typography, Button, Grid, Box } from "@material-ui/core";

import noDataImage from "../../../../assets/images/undraw_void.svg";
import page_right from "../../../../assets/images/page_right.png";
import chooseOption from "../../../../assets/images/undraw_choose.svg";

import config from "../../../config/config";

import { useStyles } from "./ReportSideLeftStyles";
import "./ReportSideLeft.scss";
import HistoricalRunTable from "../HistoricalRunTable/HistoricalRunTable";
import HistoricalRunChart from "../HistoricalRunChart/HistoricalRunChart";
import { getTableHistoricalRunsDetails } from "../../../services/apis";
import Loader from "../../Loader/Loader";
import { updateData, updateUserInputs } from "../../../redux/actions";

const DateRangeBtns = [
  {
    labels: "Runs Table",
    value: "table",
    iconValue: "table_chart",
  },
  {
    labels: "Runs Chart",
    value: "chart",
    iconValue: "bar_chart",
  },
];

function ReportSideLeft({
  updateData,
  updateUserInputs,
  data,
  userInputs,
  getRightSideReport,
  getRightSideFilters,
}) {
  const classes = useStyles();
  const [activeRunsType, setActiveRunsType] = useState("table");

  const changeRunType = (typeValue) => {
    setActiveRunsType(typeValue);
  };

  useEffect(() => {
    const fetchTableHistoricalRunDetails = async (tId) => {
      updateData("tableHistoricalRunDetails", {
        status: "loading",
        message: "",
        result: {},
      });
      let reportDetails = await getTableHistoricalRunsDetails({
        tableId: tId,
      });
      if (reportDetails && Object.keys(reportDetails).length > 0) {
        updateData("tableHistoricalRunDetails", {
          status: "success",
          message: "",
          result: JSON.parse(JSON.stringify(reportDetails)),
        });
      } else {
        updateData("tableHistoricalRunDetails", {
          status: "error",
          message: config.messages.noRecordFound,
          result: {},
        });
        updateData("tableDiagnosticReportFilters", {
          status: "error",
          message: config.messages.noRecordFound,
          result: {},
        });
        updateData("tableDiagnosticReportDetails", {
          status: "error",
          message: config.messages.noRecordFound,
          result: [],
        });
      }
    };
    if (userInputs.idForReportSelected) {
      fetchTableHistoricalRunDetails(userInputs.idForReportSelected);
    }
    // eslint-disable-next-line
  }, [userInputs.idForReportSelected]);

  const changeScreenTabView = (viewName) => {
    updateUserInputs("diagReportCurrentScreen", viewName);
  };

  return (
    <Grid
      container
      spacing={0}
      justifyContent="space-between"
      className={classes.gridContainerClass}
      id="reportHeaderDetails"
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        direction="row"
      >
        {data.tableHistoricalRunDetails.status === "success" &&
        data.tableHistoricalRunDetails.result.table_name ? (
          <Typography className={classes.subsection}>
            <span>{data.tableHistoricalRunDetails.result?.dataset_name}/</span>
            <span>{data.tableHistoricalRunDetails.result.layer_name}/</span>
            <span
              onClick={() => changeScreenTabView("treeView")}
              className="table-name-span"
              title={data.tableHistoricalRunDetails.result.table_name}
            >
              {data.tableHistoricalRunDetails.result.table_name}
            </span>
          </Typography>
        ) : (
          <Typography className={classes.subsection}>
            {"No Table Selected"}
          </Typography>
        )}
        <Button>
          <img
            className=""
            src={page_right}
            alt={"Go"}
            onClick={() => changeScreenTabView("treeView")}
          />
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        lg={12}
        id="reportHeaderBody2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          marginTop: "5%",
        }}
      >
        <span className="runs-title">Historical Runs</span>
        <Box
          alignItems="center"
          boxShadow="none"
          border="1px sollid #ECEEF0"
          borderRadius={"2px"}
        >
          {DateRangeBtns.map((item) => (
            <Button
              key={item.value}
              className={
                activeRunsType === item.value
                  ? classes.activeBtn
                  : classes.button
              }
              onClick={() => changeRunType(item.value)}
            >
              <i className={`material-icons-outlined`}>{item.iconValue}</i>
            </Button>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {data.tableHistoricalRunDetails.status === "loading" && <Loader />}
        {data.tableHistoricalRunDetails.status === "success" && (
          <div>
            {activeRunsType === "table" && (
              <HistoricalRunTable
                tableData={
                  data.tableHistoricalRunDetails.result.historical_runs
                }
                getRightSideReport={getRightSideReport}
                getRightSideFilters={getRightSideFilters}
              />
            )}
            {activeRunsType === "chart" && (
              <HistoricalRunChart
                chartData={data.tableHistoricalRunDetails.result.chart}
              />
            )}
          </div>
        )}
        {data.tableHistoricalRunDetails.status === "error" && (
          <div className="no-historical-runs">
            <img
              src={noDataImage}
              alt="noDataImage"
              className="no-runs-image"
            />
            <p className="no-data">
              <b>{config.messages.noRecordFound}</b>
            </p>
          </div>
        )}
        {data.tableHistoricalRunDetails.status === "info" && (
          <div className="no-historical-runs">
            <img
              src={chooseOption}
              alt="No Option Selected"
              className="no-runs-image"
            />
            <p className="no-data">
              <b>Please select a table!</b>
            </p>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

// ReportSideLeft.propTypes = {
//   aggregateScore: PropTypes.any.isRequired,
//   lastRunOn: PropTypes.any.isRequired,
//   runHistoryObj: PropTypes.any.isRequired,
//   onDateChange: PropTypes.any.isRequired,
//   latestSucessRun: PropTypes.any.isRequired,
// };
const mapStateToProps = (state) => ({
  data: state.data,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateData,
  updateUserInputs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSideLeft);
