/* eslint-disable indent */

import "./CompareDatasetsListTable.scss";

import { Box, TableCell, TableHead, TableRow } from "@material-ui/core";

import Filters from "../../../components/Filters/Filters";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useStyles } from "./style";

const CompareDatasetsListTableHeader = (props) => {
  const { user, allData } = props;
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <div className="header-with-filter">
            <Box>Report Name</Box>
          </div>
        </TableCell>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <Box>Team</Box>
        </TableCell>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <Box>Run On</Box>
        </TableCell>
        <TableCell
          className={classes.tablecell}
          style={{ padding: "10px" }}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};

CompareDatasetsListTableHeader.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(CompareDatasetsListTableHeader);
