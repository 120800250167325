// Import required libraies
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./ColumnSelection.scss";

// Import Custom Component
import DQButton from "../../Common/DQButton";
import ColumnRulesTable from "../../ColumnRulesTable/ColumnRulesTable";
import Loader from "../../Loader/Loader";

// Import data & utils
import dqMaster from "../../../../assets/data/dqMaster.json";
import { filterColumnsForSelections } from "../../../utils/utils";
import { unwrapperTablesAddedForDatabase } from "../../RulesSelection/schema";
import allRulesSearchStatic from "../../../../assets/data/allRulesSearch.json";

// Import actions
import {
  updateData,
  updateSelections,
  updateUserInputs,
  updateAlertInfo,
} from "../../../redux/actions";

// Import API call
import {
  getSchemaSync,
  allColumnsData,
  getAllRulesData,
  getColumnsForDatasetTables,
  getDataTableDetails,
  getMyDatasetColumns,
} from "../../../../js/services/apis.js";

// Import styles

// # COMPONENT
const ColumnSelection = (props) => {
  // # PROPS
  const { datasetDetailsData } = props;

  // # HOOKS
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.data);
  const [tableSelected, setTableSelected] = useState({});
  const orgArray = data.datasetTablesColumns.result;
  const userInputs = useSelector((state) => state.userInputs);

  const [filteredColumnsData, setFilteredColumnsData] = useState(
    orgArray || []
  );
  const ReduxDispatcher = useDispatch();

  // # SIDE EFFECTS
  useEffect(() => {
    // fetch columns and rules and combine the responses
    const fetchAllColumnsData = async () => {
      dispatch(
        updateData("allColumns", {
          status: "loading",
          message: "",
          result: [],
        })
      );

      let reqObj = {
        cluster_id: user.selections.columnSelected?.cluster_id,
        layer_id: user.selections.columnSelected?.layer_id,
        db_name: user.selections.columnSelected?.schema_name,
      };
      let columns = await allColumnsData(reqObj);
      if (columns && columns?.length > 0) {
        dispatch(
          updateData("allColumns", {
            status: "prefetch",
            message: "",
            result: columns,
          })
        );
      } else {
        dispatch(
          updateData("allColumns", {
            status: "error",
            message: "No result found! Please try again Later!",
            result: [],
          })
        );
      }
    };
    const fetchColumnsData = async () => {
      dispatch(
        updateData("datasetTablesColumns", {
          status: "loading",
          message: "",
          result: [],
        })
      );
      let reqObj = {
        cluster_id: user.selections.columnSelected?.cluster_id,
        meta_catalog_id: user.selections.columnSelected?.catalog_id,
        layer_id: user.selections.columnSelected?.layer_id,
        db_name: user.selections.columnSelected?.schema_name,
        table_name: user.selections.columnSelected?.table_name,
      };
      let columns = await getColumnsForDatasetTables(reqObj);
      if (columns && columns?.length > 0) {
        dispatch(
          updateData("datasetTablesColumns", {
            status: "success",
            message: "",
            result: columns,
          })
        );
        dispatch(updateUserInputs("datasetTablesColumns", columns));
      } else {
        dispatch(
          updateData("datasetTablesColumns", {
            status: "error",
            message: "No result found! Please try again Later!",
            result: [],
          })
        );
      }
    };
    const fetchRulesData = async () => {
      dispatch(
        updateData("allRules", {
          status: "loading",
          message: "",
          result: [],
        })
      );
      let rules = dqMaster;
      // let rules = await getAllRules();
      if (rules && rules?.length > 0) {
        dispatch(
          updateData("allRules", {
            status: "success",
            message: "",
            result: rules,
          })
        );
      } else {
        dispatch(
          updateData("allRules", {
            status: "error",
            message: "No result found! Please try again Later!",
            result: [],
          })
        );
      }
    };
    const fetchAllRulesData = async () => {
      dispatch(
        updateData("allRulesData", {
          status: "loading",
          message: "",
          result: [],
        })
      );
      let allRulesData = await getAllRulesData();
      // ! Add search text on UI
      // TODO : Move to dq_master backend when stable
      allRulesData = allRulesData.map((row) => ({
        ...row,
        searchText:
          allRulesSearchStatic.find((r) => row.name === r.name)?.searchText ||
          "",
      }));
      if (allRulesData && allRulesData?.length > 0) {
        dispatch(
          updateData("allRulesData", {
            status: "success",
            message: "",
            result: allRulesData,
          })
        );
      } else {
        dispatch(
          updateData("allRulesData", {
            status: "error",
            message: "No result found! Please try again Later!",
            result: [],
          })
        );
      }
    };

    const fetchTableSelected = async () => {
      let tbs = await getDataTableDetails({
        tableId: user.selections.columnSelected.id,
      });

      dispatch(updateSelections("columnSelected", tbs));

      const idx = userInputs.tablesAddedForDatabase.findIndex(
        (t) => t.id === user.selections.columnSelected.id
      );

      let tables = [
        ...userInputs.tablesAddedForDatabase.slice(0, idx),
        tbs,
        ...userInputs.tablesAddedForDatabase.slice(idx + 1),
      ];

      // This action is based on landing layer file
      if (tbs.file) {
        const finalSelection = {
          status: "success",
          message: "",

          data: tbs.file,
        };
        dispatch(updateSelections("landingLayerSelection", finalSelection));
      }
      dispatch(
        updateUserInputs(
          "tablesAddedForDatabase",
          unwrapperTablesAddedForDatabase(tables)
        )
      );

      setTableSelected(tbs);
    };
    fetchTableSelected();
    fetchRulesData();
    fetchAllRulesData();
    fetchColumnsData();
    fetchAllColumnsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updatedData = filterColumnsForSelections(
      data.datasetTablesColumns.result,
      user.selections
    );

    tableSelected?.table_columns?.forEach((item) => {
      let columnRef = updatedData.find(
        (c) => c.column_name === item.column_name
      );
      if (typeof columnRef !== "undefined") {
        columnRef.business_attribute_name = item.business_attribute_name;
        columnRef.filter_type = item.filter_type;
        columnRef.filter_status = item.filter_status;
      }
    });

    setFilteredColumnsData(updatedData);
    // eslint-disable-next-line
  }, [user.selections, data.datasetTablesColumns, tableSelected]);

  // # HANDLERS
  const redirectToTableInformation = () => {
    dispatch(updateSelections("columnSelectionPage", false));
  };

  const getTableName = () => {
    return (
      <div>
        <span className="dataset-name" onClick={redirectToTableInformation}>
          Back
          {" > "}
        </span>
        <span>
          {tableSelected.catalog_name}.{tableSelected.schema_name}.
          {tableSelected.table_name}
        </span>
      </div>
    );
  };

  useEffect(async () => {
    if (data.allColumns.status === "prefetch") {
      let columns = data.allColumns.result;

      for (let i = 0; i < columns[0].children.length; i++) {
        var c = columns[0].children[i];
        var d = await getMyDatasetColumns({
          db_name: columns[0].name,
          table_name: columns[0].children[i].name,
        });

        c.children = d.map((c) => {
          return { id: c, name: c };
        });
      }

      dispatch(
        updateData("allColumns", {
          status: "success",
          message: "",
          result: columns,
        })
      );
    }
  }, [data.allColumns]);

  // Handler Function
  const handleSync = () => {
    ReduxDispatcher(
      updateAlertInfo({
        open: true,
        message: "Please wait while the columns are being synchronized.",
        severity: "success",
      })
    );
    let syncColumnPromise = getSchemaSync({
      tableId: user.selections.columnSelected.id,
    });
    syncColumnPromise
      .then((responseData) => {
        if (responseData.status) {
          ReduxDispatcher(
            updateAlertInfo({
              open: true,
              message: responseData.message,
              severity: "success",
            })
          );
        } else {
          ReduxDispatcher(
            updateAlertInfo({
              open: true,
              message: "Something went wrong! Please try again...",
              severity: "success",
            })
          );
        }
      })
      .catch((err) => {
        ReduxDispatcher(
          updateAlertInfo({
            open: true,
            message: "Something went wrong! Please try again...",
            severity: "success",
          })
        );
      });
  };

  // # STATIC VARIABLES
  const loadingCondition =
    data.datasetTablesColumns.status === "loading" ||
    data.allColumns.status === "loading" ||
    data.allRulesData.status === "loading";
  const successConsition =
    data.datasetTablesColumns.status !== "loading" &&
    data.allColumns.status !== "loading" &&
    data.allRulesData.status !== "loading";

  // # JSX
  return (
    <>
      <div className="table-selection-container">
        {loadingCondition && <Loader />}
        {successConsition && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="table_name">{getTableName()}</div>
              <DQButton
                title="Sync"
                disabled={false}
                variant="outlined"
                onclick={handleSync}
              />
            </div>

            <ColumnRulesTable
              allRulesDataStatic={data.allRulesData.result}
              data={filteredColumnsData}
              allData={orgArray} // pass for header filter
              rulesData={data.allRules.result}
              paginationFlag={true}
              isCreatePage={true}
              currentTable={tableSelected.table_name}
            />
          </>
        )}
      </div>
    </>
  );
};

// # EXPORTS
export default ColumnSelection;
