const user = {
  isAuth: false,
  theme: "light",
  name: "",
  preferred_username: "",
  email: "",
  role: "",
  token: "",
  error: null,
  errorCode: null,
  screen: {
    isSidenavBarExpanded: true,
  },
  selections: {
    searchText: "",
    selectedSearchTags: [],
    DRFilters: {},
    primaryDomain: { searchText: "", value: [] },
    secondaryDomain: { searchText: "", value: [] },
    subDomain: { searchText: "", value: [] },
    dataLayer: { searchText: "", value: [] },
    useCase: { searchText: "", value: [] },
    connection_info: {
      clusterName: "",
      // userName: "",
      // token: "",
    },
    columnName: { searchText: "", value: [] },
    datatype: { searchText: "", value: [] },
    columnSelectionPage: false,
    columnSelected: {}, // column selected is the table selected for update
    columnNameForReport: { searchText: "", value: [] },
    businessAttributeNameForReport: { searchText: "", value: [] },
    rulesAppliedForReport: { searchText: "", value: [] },
    weightageForReport: { searchText: "", value: [] },
    statusForReport: { searchText: "", value: [] },
    leftTableStatusForReport: { searchText: "", value: [] },
    ruleDimensionForGlobalRuleManager: { searchText: "", value: [] },
    supportedDatatypesForGRM: { searchText: "", value: [] },
    statusForGlobalRulesManager: { searchText: "", value: [] },
    summaryUseCase: { searchText: "", value: [] },
    summaryTable: { searchText: "", value: [] },
    summaryColumn: { searchText: "", value: [] },
    summaryAllRule: { searchText: "", value: [] },
    summaryCategory: { searchText: "", value: [] },
    summaryDimension: { searchText: "", value: [] },
    datasetNameForGRM: { searchText: "", value: [] },
    databaseNameForGRM: { searchText: "", value: [] },
    tableNameForGRM: { searchText: "", value: [] },
    columnNameForGRM: { searchText: "", value: [] },
    adminUsersListEmailSearch: { searchText: "", value: [] },
    adminUsersListNameSearch: { searchText: "", value: [] },
    adminUsersListTeamsSearch: { searchText: "", value: [] },
    adminTeamsListNameSearch: { searchText: "", value: [] },
    landingLayerSelection: {
      status: "loading",
      message: "",
      data: {
        sample_file_name: "sample",
        sample_file_type: "csv",
        sample_file_location: "sample.csv",
        actual_file_location: "",
      },
    },
  },
  dialogInfo: {
    title: "",
    message: "",
    open: false,
    handleYes: () => {},
    handleNo: () => {},
  },
  alertInfo: {
    message: "",
    open: false,
    severity: "success",
  },
};

const data = {
  tableDetails: { status: "loading", message: "", result: {} },
  activePage: "", //unused
  connection_status: { status: "loading", message: "", result: {} }, //unused
  tableColumns: { status: "loading", message: "", result: {} },
  allRules: { status: "loading", message: "", result: [] },
  allRulesData: { status: "loading", message: "", result: [] },
  catalogs: { status: "loading", message: "", result: [] },
  databases: { status: "loading", message: "", result: [] },
  tables: { status: "loading", message: "", result: [] },
  dataTables: { status: "loading", message: "", result: [] }, // only use in DataDomainTables
  categoryTreeData: { status: "loading", message: "", result: [] },
  myDataset: { status: "loading", message: "", result: [] },
  primaryDomainData: { status: "loading", message: "", result: [] },
  secondaryDomainData: { status: "loading", message: "", result: [] },
  subDomainData: { status: "loading", message: "", result: [] },
  datasetName: { status: "loading", message: "", result: [] },
  dataLayerData: { status: "loading", message: "", result: [] },
  clusterData: { status: "loading", message: "", result: [] }, //unused
  dataTableDetails: { status: "loading", message: "", result: {} },
  datasetDetails: { status: "loading", message: "", result: [] },
  datasetTablesColumns: { status: "loading", message: "", result: [] },
  allColumns: { status: "loading", message: "", result: [] },
  tableDiagnosticReportDetails: { status: "info", message: "", result: {} },
  tableDiagnosticReportFilters: { status: "info", message: "", result: {} },
  tableHistoricalRunDetails: { status: "info", message: "", result: {} },
  tableColumnsAndRulesDetailsForReport: {
    status: "loading",
    message: "",
    result: {},
  }, //unused
  reportHierarchyData: {
    status: "loading",
    message: "",
    result: [],
  },
  globalRulesList: { status: "loading", message: "", result: [] },
  globalRuleDetails: { status: "loading", message: "", result: {} },
  manageRulesList: { status: "loading", message: "", result: [] },
  adminPanelUserList: { status: "loading", message: "", result: [] },
  adminPanelTeamList: { status: "loading", message: "", result: [] },
  compareDatasetList: { status: "loading", message: "", result: [] },
  summaryTableList: { status: "loading", message: "", result: [] },
  userInfo: { status: "loading", message: "", result: [] },
};

const userInputs = {
  adminReckittUserSelected: "",
  categoryName: "",
  primaryDomain: "",
  secondaryDomain: "",
  subDataDomain: "",
  datasetName: "",
  environment: "",
  dataLayer: "",
  hierarchy: {},
  clusterName: {},
  clusters: {},
  // userName: "",
  // token: "",
  catalogName: {},
  databaseName: {},
  tableName: {},
  tableNameAppended: "",
  isConnectionTested: false,
  tablesAddedForDatabase: [],
  tags: [],
  diagReportCurrentScreen: "reportData",
  idForReportSelected: "",
  newidForReportSelected: "",
  tableSelections: {
    archetype: null,
    primaryDomain: null,
    secondaryDomain: null,
    subDomain: null,
    datasets: null,
    dataLayers: null,
    tables: null,
  },
  datasetTablesColumns: [],
  openDRFilters: false,
  initialDRFilters: {},
  compareDataset: {
    reportName: null,
    teamName: null,
    selectedCluster: null,
    selectedSourceDatabase: null,
    selectedDestinationDatabase: null,
    selectedSourceTable: null,
    selectedDestinationTable: null,
    sourceFileUpload: "table",
    destinationFileUpload: "table",
    sourceFile: {
      sourceSelectedFile: null,
      sourceSelectedFileName: "",
    },
    destinationFile: {
      destinationSelectedFile: null,
      destinationSelectedFileName: "",
    },
    sourceLandingLayerFile: "",
    destinationLandingLayerFile: "",
    currentLandingLayer: "",
  },
  compareDatasetColumns: {
    map: {},
    fromTableSource: null,
    fromTableDestination: null,
    selectedSource: [],
    selectedDestination: [],
  },
  compareDatasetCriteria: {
    selectedCriteria: [],
  },
  landingLayer: { status: "loading", message: "", result: [] },
  pathForLandingLayerBlob: "",
};

const changeFlag = {
  reloadData: false,
  uiRules: false,
};

export const stateData = {
  user,
  data,
  userInputs,
  changeFlag,
};
