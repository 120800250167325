// Import required libraies

import "./GlobalRuleDetails.scss";

import React, { useEffect, useRef, useState } from "react";
import { Route, useHistory } from "react-router";
import {
  filterColumnsForGlobalRulesEdit,
  getRuleFormattedName,
} from "../../../utils/utils";
import {
  getGlobalRuleDetails,
  updateglobalRuleDetails,
} from "../../../services/apis";
import {
  updateAlertInfo,
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";

import { Box } from "@material-ui/core";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import ColumnsListGlobalRulesTable from "./ColumnsListGlobalRulesTable/ColumnListGlobalRulesTable";
import DQButton from "../../../components/Common/DQButton";
import DQInput from "../../../components/Common/DQInput";
import DQMultiAutoComplete from "../../../components/Common/DQMutliAutocomplete";
import DQSingleAutocomplete from "../../../components/Common/DQSingleAutocomplete";
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import PropTypes from "prop-types";
import config from "../../../config/config";
import { connect } from "react-redux";
import noDataImage from "../../../../assets/images/undraw_void.svg";

// Import Custom Component

const GlobalRuleDetails = (props) => {
  const { user, data, updateData, match, updateAlertInfo } = props;
  const autoC = useRef(null);
  const history = useHistory();
  const columnsList = data.globalRuleDetails.result?.columns;
  const [filteredData, setFilteredData] = useState(columnsList || []);
  const previousInputValue = useRef("");
  const fetchGlobalRuleDetails = async () => {
    updateData("globalRuleDetails", {
      status: "loading",
      result: {},
      message: "",
    });
    updateUserInputs("globalRuleDetails");
    let reqObj = {
      ruleName: match.params.ruleName,
    };
    let globalRules = await getGlobalRuleDetails(reqObj);
    if (globalRules && Object.keys(globalRules).length > 0) {
      updateData("globalRuleDetails", {
        status: "success",
        result: globalRules,
        message: "",
      });
    } else {
      updateData("globalRuleDetails", {
        status: "error",
        result: {},
        message: "Could not load details! Please try again later!",
      });
    }
    previousInputValue.current = globalRules;
  };

  useEffect(() => {
    fetchGlobalRuleDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updatedData = filterColumnsForGlobalRulesEdit(
      data.globalRuleDetails.result?.columns,
      user.selections
    );
    setFilteredData(updatedData);
    // eslint-disable-next-line
  }, [user.selections, data.globalRuleDetails]);

  const changeRuleValue = (fieldValue, value) => {
    let ruleDetails = data.globalRuleDetails.result;
    updateData("globalRuleDetails", {
      status: "success",
      message: "",
      result: {
        ...ruleDetails,
        [fieldValue]: value,
      },
    });
  };

  const changeRuleStatus = (value) => {
    changeRuleValue("status", value);
  };
  const changeRuleDimension = (value) => {
    changeRuleValue("dimension", value);
  };

  const changeRuleCategory = (value) => {
    changeRuleValue("category", value);
  };

  const handleSupportedDatatypes = (event, newValue) => {
    const columns = JSON.parse(JSON.stringify(data.globalRuleDetails.result));
    if (columns.supported_datatypes !== newValue) {
      updateData("globalRuleDetails", {
        status: "success",
        message: "",
        result: {
          ...columns,
          supported_datatypes: [...newValue],
        },
      });
    }
  };

  const deleteValues = (regValue) => {
    const columns = JSON.parse(JSON.stringify(data.globalRuleDetails.result));
    let valArr = columns.supported_datatypes.filter(
      (singleValue) => singleValue !== regValue
    );
    updateData("globalRuleDetails", {
      status: "success",
      message: "",
      result: {
        ...columns,
        supported_datatypes: [...valArr],
      },
    });
  };

  const handleUpdateGlobalRule = async () => {
    updateAlertInfo({
      open: true,
      message: config.messages.updateGlobalRuleRequestMessage,
      severity: "info",
    });
    let body = JSON.parse(JSON.stringify(data.globalRuleDetails.result));
    let reqBody = {
      id: body.id,
      name: body.name,
      category: body.category,
      dimension: body.dimension,
      status: body.status,
      supported_datatypes: body.supported_datatypes,
      rule_description: body.rule_description,
      example: body.example,
      logic: body.logic,
    };
    let respData = await updateglobalRuleDetails(reqBody);
    if (respData) {
      history.push("/home/globalRules");
      updateAlertInfo({
        open: true,
        message: config.messages.successGlobalRuleUpdateMessage,
        severity: "success",
      });
    } else {
      updateAlertInfo({
        open: true,
        message: config.messages.failureGlobalRuleUpdateMessage,
        severity: "error",
      });
    }
  };
  let userInputChanges = previousInputValue.current
    ? JSON.stringify(previousInputValue.current) ===
      JSON.stringify(data.globalRuleDetails.result)
    : true;
  return (
    <Route>
      <LayoutTopSideBottom match={match}>
        <Breadcrumbs match={match} />
        <>
          {data.globalRuleDetails.status === "loading" && <Loader />}
          {data.globalRuleDetails.status === "success" && (
            <div className="rule-details-container">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div className="rule-name">
                  <label className="metadata-label">Rule Name</label>
                  <DQInput
                    name="ruleName"
                    value={getRuleFormattedName(
                      data.globalRuleDetails.result.name
                    )}
                    placeholder="Rule Name"
                    isDisabled={true}
                  />
                </div>
                <div className="rule-logic">
                  <label className="metadata-label">Rule Logic</label>
                  <DQInput
                    name="ruleLogic"
                    value={data.globalRuleDetails.result.logic}
                    placeholder="Rule Logic"
                    isDisabled={true}
                    className="subsection-name-field"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  mt: 3,
                }}
              >
                <div className="rule-status">
                  <label className="metadata-label">Status</label>
                  <DQSingleAutocomplete
                    value={data.globalRuleDetails.result.status}
                    optionsData={
                      data.globalRuleDetails.result.status_options || []
                    }
                    handleChange={changeRuleStatus}
                    placeholderText="Rule Status"
                    isDisabled={false}
                    newKey="name"
                  />
                </div>
                <div className="rule-category">
                  <label className="metadata-label">Rule Category</label>
                  <DQSingleAutocomplete
                    value={data.globalRuleDetails.result.category}
                    optionsData={
                      data.globalRuleDetails.result.category_options || []
                    }
                    handleChange={changeRuleCategory}
                    placeholderText="Rule Category"
                    isDisabled={false}
                    newKey="name"
                  />
                </div>
                <div className="rule-dimension">
                  <label className="metadata-label">Rule Dimension</label>
                  <DQSingleAutocomplete
                    value={data.globalRuleDetails.result.dimension}
                    optionsData={
                      data.globalRuleDetails.result.dimension_options || []
                    }
                    handleChange={changeRuleDimension}
                    placeholderText="Rule Dimension"
                    isDisabled={false}
                    newKey="name"
                  />
                </div>
              </Box>
              {/* Commented this as this will be introduced in future */}
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 4,
                  flexWrap: "wrap",
                }}
              >
                <div className="rule-example">
                  <label className="metadata-label">Rule Example</label>
                  <DQTextarea
                    name="ruleExample"
                    value={data.globalRuleDetails.result.example}
                    placeholder="Rule Example"
                    isDisabled={true}
                    className="subsection-name-field-width-50"
                  />
                </div>
              </Box> */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 3,
                }}
              >
                <div className="supported-datatypes">
                  <label className="metadata-label">Supported Datatypes</label>
                  <DQMultiAutoComplete
                    value={data.globalRuleDetails.result.supported_datatypes}
                    handleChange={handleSupportedDatatypes}
                    placeholderText="Enter Supported Datatypes"
                    isDisabled={false}
                    autoC={autoC}
                    deleteValues={deleteValues}
                    optionsData={
                      data.globalRuleDetails.result
                        .supported_datatypes_options || []
                    }
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 3,
                  flexDirection: "column",
                }}
              >
                <label className="metadata-label">
                  List of columns rule is applied on:
                </label>
                <div className="columns-list-table">
                  <ColumnsListGlobalRulesTable
                    data={filteredData}
                    allData={data.globalRuleDetails.result?.columns}
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  position: "fixed",
                  bottom: 40,
                  right: 0,
                  mr: 2,
                }}
              >
                <Link className="disabled-style" to={"/home/globalRules"}>
                  <DQButton
                    title="Cancel"
                    disabled={false}
                    variant="outlined"
                  />
                </Link>
                <Box sx={{ alignSelf: "center" }}>
                  <DQButton
                    title="Update"
                    disabled={userInputChanges}
                    variant="contained"
                    onclick={handleUpdateGlobalRule}
                  />
                </Box>
              </Box>
            </div>
          )}
          {data.globalRuleDetails.status === "error" && (
            <div className="no-configs">
              {" "}
              <img src={noDataImage} alt="noDataImage" />
              <p className="no-data">
                <b>{data.globalRuleDetails.message}</b>
              </p>
            </div>
          )}
        </>
      </LayoutTopSideBottom>
    </Route>
  );
};

GlobalRuleDetails.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateSelections,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalRuleDetails);
