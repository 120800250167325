// ### Imports
// * Import lib
import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core";
import groupBy from "lodash.groupby";
import { Box } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { WithContext as ReactTags } from "react-tag-input";

// * Import Kit
import Typography from "../../kit/Typography";
import TextField from "../../kit/TextField";
import Select from "../../kit/Select";
import MenuItem from "../../kit/MenuItem";
import Checkbox from "../../kit/Checkbox";
import AutoComplete from "../../kit/AutoComplete";

// * Import custom components
import Icon from "../Icon/Icon";
import MultipleRules from "./components/MultipleRules";
import FieldCompareComponent from "./components/FieldCompareComponent";
import IsExistingRule from "./components/IsExistingRule";
import IsNotExistingRule from "./components/IsNotExistingRule";
import CustomIsExistingCondAdvanced from "./components/CustomIsExistingCondAdvanced";
import CustomFieldCompareWithConditionSAP from "./components/CustomFieldCompareWithConditionSAP";
import CustomMathematicalOperationsWithColumn from "./components/CustomMathematicalOperationsWithColumn";
import SearchTableSelection from "../SearchTableSelection/SearchTableSelection";
import IsMappedRuleComponent from "./components/IsMappedRuleComponent";

// * Import utils
import { masterGroupData } from "./schema";
import C from "../../redux/constants";
import config from "../../config/config";
import { getTotalSelectedRules } from "../../utils/utils";
import CustomFieldCompareWithMathematicalOperations from "./components/CustomFieldCompareWithMathematicalOperations";
import CustomCheckStringSAPWithCondition from "./components/CustomCheckStringSAPWithCondition";
import CustomCheckStringWithCondition from "./components/CustomCheckStringWithCondition";
import CustomFieldCompareWithCondition from "./components/CustomFieldCompareWithCondition";
import noDataImage from "../../../assets/images/undraw_void.svg";

// # UTILITY FUNCTIONS
// TODO : Move to the right folder
const selectAllReduxData = (store) => store.data;
const selectRulesItAndBusinessRulesCurrentFormSelection = (
  formSelections,
  name,
  groupId
) => {
  let el = formSelections.find((row) => row.name === name);
  if (!el) {
    el = {
      id: "1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed", // ! ONLY FOR UI
      columnId: 199,
      columnName: "MANDT",
      columnDatatype: "string",
      name: "startsWith",
      groupId: 2,
      value: {
        isEnabled: true,
        condition: "startsWith",
        conditionString: "Uni",
      },
      isValid: true,
      message: "",
    };
  }
  return el;
};
const findGroupNameFromGroupId = (groupId, groupData) =>
  groupData.find((row) => row.id === groupId)?.name || "undefined";

// # STYLES
const useStyles = makeStyles((theme) => ({
  autoComplete: {
    fontSize: "12px",
  },
  formControl: { margin: theme.spacing(1), minWidth: 120, maxWidth: 300 },
  autoCompleteRule: {
    width: 300,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  groupsRoot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  groupsRootText: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    border: "1px solid transparent",
  },
  groupsRootTextLeft: { gridColumn: "1 / span 11" },
  groupsRootTextRight: { gridColumn: "12 / span 1" },
  groupsRootTextRightWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "7px",
  },
  rulesRowRootContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
  },
  rulesAllRow: {
    display: "flex",
    alignItems: "center",
    gridColumn: "1 / span 12",
  },
  rulesLeftRow: {
    display: "flex",
    alignItems: "center",
    gridColumn: "1 / span 11",
  },
  rulesRightRow: { gridColumn: "12" },
  ruleRowWrapper: {
    display: "flex",
    alignItems: "center",
  },
  ruleRowItem: {
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    "&.input_tags": { width: "200px" },
    "&.custom_field_compare_rule": { marginRight: "0px", width: "100%" },
    "&.custom_isExisting_Cond": { marginRight: "0px", width: "100%" },
    "&.custom_is_mapped_rule": { marginRight: "0px", width: "100%" },
  },
  noDataWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
  },
  rulesMenuWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  accordionMargin: {
    marginBottom: "10px",
  },
}));
// # HELPER COMPONENTS
function RulesRow(props = {}) {
  // # PROPS
  const {
    formSelections,
    dispatchFormSelections,
    columnDetails,
    showErrorOnUi,
    currentTable,
    isLastRow,
  } = props;

  // # SELECTORS
  // const formSelections = useSelector(selectRulesItAndBusinessRules);
  const currFormSelections = useSelector((store) =>
    selectRulesItAndBusinessRulesCurrentFormSelection(
      formSelections,
      props.name,
      props.groupId
    )
  );

  // # HANDLERS
  const handleChange = (e, elementType, formKey, formValue) => {
    // ! NOT WORKING (e.target.getAttribute not a function)
    // const formKey = e.target.getAttribute("data-form-key");
    // const elementType = e.target.getAttribute("data-element-type");
    // console.log("e", e);
    // console.log("formKey", formKey);
    // console.log(`elementType`, elementType);
    // let formValue = "";
    // switch ("elementType") {
    //   case "checkbox":
    //     formValue = e.target.checked;
    //     break;
    //   case "text":
    //   case "select_single":
    //     formValue = e.target.value;
    //     break;
    // }
    const action = {
      type: C.UPDATE_RULES_IT_AND_BUSINESS_FORM_FIELD,
      name: props.name,
      formKey: formKey,
      formValue: formValue,
    };
    dispatchFormSelections(action);
  };

  // # STATIC VARIABLES
  const classes = useStyles();
  const allReduxData = useSelector(selectAllReduxData);
  const theme = useTheme();
  const isRowInvalid = !currFormSelections.isValid;
  const errorMessage = currFormSelections.message;
  const showErrorFinalFlag = isRowInvalid && showErrorOnUi;
  const inlineDynamicStyle = {
    border: showErrorFinalFlag
      ? `2px solid ${theme.palette.error.main}`
      : "2px solid transparent",
    borderRadius: "4px",
    // margin: "2px 0",
    // alignItems: "stretch",
    // padding: "5px",
    // marginBottom: "5px",
  };
  const errorInlineStyle = {
    fontSize: "13px",
    color: theme.palette.error.main,
    padding: "0px 2px 15px 2px",
  };
  const renderThresholdNumber = ![
    "fieldCompare_column",
    "isExistingCond",
    "isMapped",
    "checkstring_sap_with_condition",
    "field_compare_with_condition_sap"
  ].includes(props.name);
  // console.groupCollapsed(`${currFormSelections.name}`);
  // console.log("currFormSelections", currFormSelections);
  // console.log("isRowInvalid", isRowInvalid);
  // console.log("showErrorOnUi", showErrorOnUi);
  // console.log("showErrorFinalFlag", showErrorFinalFlag);
  // console.groupEnd();

  // # DEBUGGER
  // console.group("RulesRow");
  // console.log("formSelections", formSelections);
  // console.log("currFormSelections", currFormSelections);
  // console.log("props", props);
  // console.groupEnd();

  // # JSX
  return (
    <>
      <Box className={classes.rulesRowRootContainer} style={inlineDynamicStyle}>
        {/* <Typography>{props.name}</Typography> */}
        <Box
          className={
            renderThresholdNumber ? classes.rulesLeftRow : classes.rulesAllRow
          }
        >
          {props.elementData.map((ruleDataRow) => {
            const OLD_PROPS_FOR_CUSTOM_MULTIPLE_RULES = {
              columnName: columnDetails.columnName,
              id: 1, // ! NOT NEEDED ANYMORE; HENCE ADDING A DEFAULT VALUE
              name: props.name,
              onChange: (...args) => {
                // console.log("args: ", args);
                const [id, value] = args;
                // console.log("id: ", id);
                // console.log("value: ", value);
                handleChange(null, null, "value1", value.value.value1); // ! HARD CODED
              },
              record: currFormSelections.value["value1"] || undefined,
              stateData: allReduxData,
            };
            const OLD_PROPS_FOR_CUSTOM_FIELD_COMPARE_RULE = {
              columnName: columnDetails.columnName,
              id: 1, // ! NOT NEEDED ANYMORE; HENCE ADDING A DEFAULT VALUE
              name: props.name,
              onChange: (value) => {
                // console.log("value", value);
                handleChange(null, null, "value1", value); // ! HARD CODED
              },
              selections: currFormSelections.value["value1"] || [],
              stateData: allReduxData,
            };
            const OLD_PROPS_FOR_CUSTOM_IS_MAPPED_RULE = {
              columnName: columnDetails.columnName,
              id: 1, // ! NOT NEEDED ANYMORE; HENCE ADDING A DEFAULT VALUE
              name: props.name,
              onChange: (value) => {
                handleChange(null, null, "value1", value); // ! HARD CODED
              },
              selections: currFormSelections.value["value1"] || [],
              stateData: allReduxData,
            };
            const OLD_PROPS_FOR_CUSTOM_ISEXISTING_COND = {
              id: 1, // ! NOT NEEDED ANYMORE; HENCE ADDING A DEFAULT VALUE
              // name: props.name,
              onChange: (value) => {
                handleChange(null, null, "value1", value); // ! HARD CODED
              },
              selections: currFormSelections.value["value1"] || [],
              stateData: allReduxData,
              currentTable: currentTable,
            };
            const OLD_PROPS_FOR_CUSTOM_CHECK_STRING_SAP_WITH_CONDITION = {
              columnName: columnDetails.columnName,
              id: 1, // ! NOT NEEDED ANYMORE; HENCE ADDING A DEFAULT VALUE
              name: props.name,
              onChange: (value) => {
                handleChange(null, null, "value1", value); // ! HARD CODED
              },
              selections: currFormSelections.value["value1"] || [],
              stateData: allReduxData,
            };
            const OLD_PROPS_FOR_CUSTOM_FIELD_COMPARE_WITH_CONDITION_SAP = {
              columnName: columnDetails.columnName,
              id: 1, // ! NOT NEEDED ANYMORE; HENCE ADDING A DEFAULT VALUE
              name: props.name,
              onChange: (value) => {
                handleChange(null, null, "value1", value); // ! HARD CODED
              },
              selections: currFormSelections.value["value1"] || [],
              stateData: allReduxData,
            };

            return (
              <Box
                className={`${classes.ruleRowItem} ${ruleDataRow.type}`}
                key={ruleDataRow.formKey}
              >
                {ruleDataRow.type === "checkbox" &&
                  ruleDataRow.isVisibleOnUi && (
                    <Checkbox
                      checked={currFormSelections.value[ruleDataRow.formKey]}
                      value={ruleDataRow.formKey}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      disabled={ruleDataRow.disabled}
                      variant="secondary"
                      size="small"
                      // data-element-type={ruleDataRow.type}
                      // data-form-key={ruleDataRow.formKey}
                      onClick={(e) =>
                        handleChange(
                          e,
                          ruleDataRow.type,
                          ruleDataRow.formKey,
                          e.target.checked
                        )
                      }
                    />
                  )}
                {ruleDataRow.type === "typography" && (
                  <Typography>{ruleDataRow.formLabel}</Typography>
                )}
                {ruleDataRow.type === "select_single" && (
                  <Select
                    value={currFormSelections.value[ruleDataRow.formKey]}
                    title={currFormSelections.value[ruleDataRow.formKey]}
                    displayEmpty
                    inputProps={{ "aria-label": "Secondary Color" }}
                    color="secondary"
                    // data-element-type={ruleDataRow.type}
                    // data-form-key={ruleDataRow.formKey}
                    onChange={(e) =>
                      handleChange(
                        e,
                        ruleDataRow.type,
                        ruleDataRow.formKey,
                        e.target.value
                      )
                    }
                    MenuProps={config.hardCoded.MenuProps}
                    renderValue={(selected) => {
                      // console.log("selected", selected);
                      if (["", undefined, null].includes(selected)) {
                        // return <p>{ruleDataRow.metadata.placeholder}</p>;
                        return ruleDataRow?.placeholder || <p>Select option</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
                      }
                      let label = ruleDataRow.metadata.options.find(
                        (row) => row.id === selected
                      )?.name;
                      return label;
                    }}
                  >
                    {ruleDataRow.metadata.options.length ? (
                      ruleDataRow.metadata.options.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          title={option.name}
                        >
                          {option.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No Options
                      </MenuItem>
                    )}
                  </Select>
                )}
                {ruleDataRow.type === "select_autocomplete_multiple" && (
                  <Box className={classes.autoCompleteRule}>
                    <AutoComplete
                      // open={true}
                      classes={{
                        input: classes.autoComplete,
                        option: classes.autoComplete,
                      }}
                      multiple
                      value={currFormSelections.value[ruleDataRow.formKey].map(
                        (val) =>
                          ruleDataRow.metadata.options.find(
                            (row) => row.id === val
                          )
                      )}
                      options={ruleDataRow.metadata.options}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        // console.log("value", value);
                        handleChange(
                          e,
                          ruleDataRow.type,
                          ruleDataRow.formKey,
                          value.map((row) => row.id)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={ruleDataRow.formLabel}
                          placeholder={ruleDataRow.formLabel}
                        />
                      )}
                    />
                  </Box>
                )}
                {ruleDataRow.type === "input_text" && (
                  <TextField
                    id="standard-required"
                    value={currFormSelections.value[ruleDataRow.formKey]}
                    // data-element-type={ruleDataRow.type}
                    // data-form-key={ruleDataRow.formKey}
                    placeholder={ruleDataRow.metadata.placeholder}
                    onChange={(e) =>
                      handleChange(
                        e,
                        ruleDataRow.type,
                        ruleDataRow.formKey,
                        e.target.value
                      )
                    }
                  />
                )}
                {ruleDataRow.type === "input_number" && (
                  <TextField
                    id="standard-required"
                    type="number"
                    value={currFormSelections.value[ruleDataRow.formKey]}
                    // data-element-type={ruleDataRow.type}
                    // data-form-key={ruleDataRow.formKey}
                    onChange={(e) =>
                      handleChange(
                        e,
                        ruleDataRow.type,
                        ruleDataRow.formKey,
                        e.target.value
                      )
                    }
                  />
                )}
                {ruleDataRow.type === "input_tags" && (
                  <ReactTags
                    tags={currFormSelections.value[ruleDataRow.formKey].map(
                      (val) => ({ id: val, text: val })
                    )}
                    handleDelete={(i) =>
                      handleChange(
                        null,
                        ruleDataRow.type,
                        ruleDataRow.formKey,
                        currFormSelections.value[ruleDataRow.formKey].filter(
                          (tag, index) => index !== i
                        )
                      )
                    }
                    handleAddition={(tag) =>
                      handleChange(
                        null,
                        ruleDataRow.type,
                        ruleDataRow.formKey,
                        [
                          ...currFormSelections.value[ruleDataRow.formKey],
                          tag.text,
                        ]
                      )
                    }
                    allowDragDrop={false}
                    placeholder={ruleDataRow.metadata.placeholder}
                    autofocus={false}
                    handleInputBlur={(value) => {
                      if (value !== "") {
                        handleChange(
                          null,
                          ruleDataRow.type,
                          ruleDataRow.formKey,
                          [
                            ...currFormSelections.value[ruleDataRow.formKey],
                            value,
                          ]
                        );
                      }
                    }}
                  />
                )}
                {ruleDataRow.type === "custom_multiple_rules" && (
                  <MultipleRules {...OLD_PROPS_FOR_CUSTOM_MULTIPLE_RULES} />
                )}
                {ruleDataRow.type === "custom_is_not_existing_rule" && (
                  <IsNotExistingRule {...OLD_PROPS_FOR_CUSTOM_MULTIPLE_RULES} />
                )}
                {ruleDataRow.type === "custom_field_compare_rule" && (
                  <FieldCompareComponent
                    {...OLD_PROPS_FOR_CUSTOM_FIELD_COMPARE_RULE}
                  />
                )}
                {ruleDataRow.type === "custom_is_mapped_rule" && (
                  <IsMappedRuleComponent
                    {...OLD_PROPS_FOR_CUSTOM_IS_MAPPED_RULE}
                  />
                )}
                {ruleDataRow.type === "custom_isExisting_Cond" && (
                  <IsExistingRule {...OLD_PROPS_FOR_CUSTOM_ISEXISTING_COND} />
                )}
                {ruleDataRow.type ===
                  "custom_checkstring_sap_with_condition" && (
                  <CustomCheckStringSAPWithCondition
                    {...OLD_PROPS_FOR_CUSTOM_CHECK_STRING_SAP_WITH_CONDITION}
                  />
                )}
                {ruleDataRow.type === "custom_is_existing_cond_advanced" && (
                  <CustomIsExistingCondAdvanced
                    selections={currFormSelections.value["value1"]}
                    onChange={(value) => {
                      handleChange(null, null, "value1", value); // ! HARD CODED
                    }}
                  />
                )}
                {ruleDataRow.type ===
                  "custom_field_compare_with_condition_sap" && (
                  <CustomFieldCompareWithConditionSAP
                    {...OLD_PROPS_FOR_CUSTOM_FIELD_COMPARE_WITH_CONDITION_SAP}
                  />
                )}
                {ruleDataRow.type ===
                  "custom_mathematical_operations_with_column" && (
                  <CustomMathematicalOperationsWithColumn
                    columnName={columnDetails.columnName}
                    selections={currFormSelections.value["value1"]}
                    onChange={(value) => {
                      handleChange(null, null, "value1", value); // ! HARD CODED
                    }}
                  />
                )}
                {ruleDataRow.type ===
                  "custom_field_compare_with_mathematical_operations" && (
                  <CustomFieldCompareWithMathematicalOperations
                    columnName={columnDetails.columnName}
                    selections={currFormSelections.value["value1"]}
                    onChange={(value) => {
                      handleChange(null, null, "value1", value); // ! HARD CODED
                    }}
                  />
                )}
                {ruleDataRow.type === "custom_checkstring_with_condition" && (
                  <CustomCheckStringWithCondition
                    columnName={columnDetails.columnName}
                    selections={currFormSelections.value["value1"]}
                    onChange={(value) => {
                      handleChange(null, null, "value1", value); // ! HARD CODED
                    }}
                  />
                )}
                {ruleDataRow.type === "custom_field_compare_with_condition" && (
                  <CustomFieldCompareWithCondition
                    columnName={columnDetails.columnName}
                    selections={currFormSelections.value["value1"]}
                    onChange={(value) => {
                      handleChange(null, null, "value1", value); // ! HARD CODED
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
        {renderThresholdNumber && (
          <Box className={classes.rulesRightRow}>
            <TextField
              id="standard-required"
              type="number"
              value={currFormSelections.value["threshold"]}
              // data-element-type={ruleDataRow.type}
              // data-form-key={ruleDataRow.formKey}
              onChange={(e) =>
                handleChange(e, null, "threshold", e.target.value)
              }
              style={{ width: "75px" }}
            />
          </Box>
        )}
      </Box>
      {showErrorFinalFlag && (
        <Typography style={errorInlineStyle}>{errorMessage}</Typography>
      )}
      {renderThresholdNumber && !isLastRow && (
        <hr style={{ width: "100%", borderColor: "#ffffff40" }} />
      )}
    </>
  );
}

// # COMPONENT
function ItAndBusinessRules(props = {}) {
  const {
    rulesItAndBusinessSelections: formSelections,
    dispatchRulesItAndBusinessSelections: dispatchFormSelections,
    allRulesData,
    columnDetails,
    showErrorOnUi,
    currentTable,
    categoryType,
    searchText,
    handleSearchTextChange,
  } = props;

  // # STATIC VARIABLES
  // ! Filter allRulesData
  let filteredAllRulesData = allRulesData.filter(
    (row) =>
      row.searchText
        .toLowerCase()
        .includes(searchText.searchText.toLowerCase()) ||
      row.name.toLowerCase().includes(searchText.searchText.toLowerCase())
  );
  const groupedRules = groupBy(filteredAllRulesData, (item) => item.groupId);
  let groupedRulesMod = Object.entries(groupedRules).map(
    ([groupId, groupedData]) => ({
      id: groupId,
      name: findGroupNameFromGroupId(groupId, masterGroupData),
      data: groupedData,
    })
  );
  // .sort((a, b) => (a.id > b.id ? 1 : -1)); // to arrage accordions of rules alphabetically
  const classes = useStyles();
  const theme = useTheme();
  const iconProps = {
    className: "material-icons-outlined",
    iconName: "info",
    style: {
      fontSize: "12px",
      marginLeft: "5px",
    },
    tooltipProps: {
      flag: true,
      title:
        "Show the status as Warning in the diagnostic report if the given failure percentage is greater than given threshold percentage",
    },
  };

  // # DEBUGGER
  // console.group("ItAndBusinessRules");
  // console.log("groupedRules", groupedRules);
  // console.log("groupedRulesMod", groupedRulesMod);
  // console.log("masterGroupData", masterGroupData);
  // console.groupEnd();

  // # JSX
  return (
    <div>
      <Box className={classes.rulesMenuWrapper}>
        <Typography
          style={{
            color: theme.palette.text.primary,
            fontSize: "12px !important",
            // paddingBottom: "5px",
          }}
        >
          Applying rules for{" "}
          <span style={{ color: theme.palette.text.secondary }}>
            {columnDetails.columnName}
          </span>{" "}
          whose data type is{" "}
          <span style={{ color: theme.palette.text.secondary }}>
            {columnDetails.columnDatatype}
          </span>
        </Typography>
        <Box style={{ width: "600px" }}>
          <SearchTableSelection
            criteria={searchText}
            changeCriteria={handleSearchTextChange}
            placeholderText="Search rule"
            width={"100%"}
            className="rules-search"
          />
        </Box>
      </Box>

      {groupedRulesMod.length === 0 && (
        <Box className={classes.noDataWrapper}>
          <img src={noDataImage} alt="noDataImage" width="200" />
          <p
            className="no-data"
            style={{ color: theme.palette.text.primary, fontSize: "14px" }}
          >
            No matching rules
          </p>
        </Box>
      )}

      {groupedRulesMod.length > 0 &&
        groupedRulesMod.map((group) => {
          // let totalRules = group.data.length;
          let enabledRulesForThisGroup = getTotalSelectedRules(
            formSelections.filter(
              (row) =>
                row.groupId === group.id &&
                categoryType.includes(row.categoryId)
            )
          );
          let accordionDetailsTitle = `(${enabledRulesForThisGroup} rule(s) selected)`;

          return (
            <Accordion
              key={group.id}
              defaultExpanded={true}
              className={classes.accordionMargin}
            >
              <AccordionSummary
                expandIcon={
                  <i className={`material-icons-outlined`}>expand_more</i>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  {group.name} Checks{" "}
                  <span style={{ color: theme.palette.text.secondary }}>
                    {accordionDetailsTitle}
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.groupsRoot}>
                  <Box className={classes.groupsRootText}>
                    <Box className={classes.groupsRootTextLeft}></Box>
                    <Box className={classes.groupsRootTextRight}>
                      <Box className={classes.groupsRootTextRightWrapper}>
                        <Typography style={{ fontSize: "14px" }}>
                          Threshold %
                        </Typography>
                        <Icon {...iconProps}></Icon>
                      </Box>
                    </Box>
                  </Box>
                  {group.data.map((ruleRow, index) => (
                    <RulesRow
                      key={ruleRow.name}
                      groupId={group.id}
                      {...ruleRow}
                      formSelections={formSelections}
                      dispatchFormSelections={dispatchFormSelections}
                      columnDetails={columnDetails}
                      showErrorOnUi={showErrorOnUi}
                      currentTable={currentTable}
                      isLastRow={index === group.data.length - 1}
                    />
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}

      {/* <Typography>Hello World</Typography>
      <TextField
        required
        id="standard-required"
        label="Required"
        defaultValue="Hello World"
      />
      <Select
        value={10}
        displayEmpty
        inputProps={{ "aria-label": "Secondary Color" }}
        color="secondary"
      >
        <MenuItem value="">None</MenuItem>
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select>
      <Select
        multiple
        value={[10, 20]}
        displayEmpty
        inputProps={{ "aria-label": "Secondary Color" }}
        color="secondary"
      >
        <MenuItem value="">None</MenuItem>
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select> */}
    </div>
  );
}

// # EXPORTS
export default ItAndBusinessRules;
