// Import required libraries
import React from "react";
import { Box, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";

import DQButton from "./components/Common/DQButton";
// import LayoutTopBottom from "./layouts/LayoutTop/LayoutTopBottom";
import LayoutTopBottom from "./layouts/LayoutTopBottom/LayoutTopBottom";
import Typography from "./../js/kit/Typography";
import Alert from "./../js/kit/AlertSnackbar";

// Import styles

// Import routes

// Import action creators

// Import utils
import Maintenance from "../assets/images/Maintenance.svg";
import { updateData, updateAlertInfo } from "../js/redux/actions";

// Import Configuration
import config from "./config/config";

// Import utils
import { userTeamInfo } from "../js/services/apis";

function MaintainancePage(props) {
  const { setShowMaintenancePage, match } = props;

  const ReduxDispatcher = useDispatch();
  const theme = useTheme();
  const user = useSelector((state) => state.user);

  function checkMaintenance(responseData) {
    for (let el of responseData) {
      if (el.label === "Maintenance") {
        return true;
      }
    }
    return false;
  }

  const handleCheck = () => {
    ReduxDispatcher(
      updateAlertInfo({
        open: true,
        message:
          "Please bear with us as we conduct maintenance checks. We may need to modify certain aspects for improved performance. Thank you for your patience.",
        severity: "info",
      })
    );
    let userTeamInfoPromise = userTeamInfo({});
    userTeamInfoPromise
      .then((responseData) => {
        ReduxDispatcher(
          updateData("userInfo", {
            status: "success",
            message: "",
            result: responseData,
          })
        );
        let check = checkMaintenance(responseData);
        if (check) {
          ReduxDispatcher(
            updateAlertInfo({
              open: true,
              message: "This site is still under maintenance",
              severity: "info",
            })
          );
        }
        setShowMaintenancePage(check);
      })
      .catch(() => {
        ReduxDispatcher(
          updateData("userInfo", {
            status: "error",
            message: "Something went wrong",
            result: [],
          })
        );
      });
  };

  const handleClose = () => {
    ReduxDispatcher(updateAlertInfo({ ...user.alertInfo, open: false }));
  };

  return (
    <>
      <LayoutTopBottom match={match}>
        <Box style={{ width: "100%", height: "100%" }}>
          <Box style={{ width: "100%", height: "calc(100% - 200px)" }}>
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={Maintenance}
              />
            </Box>
          </Box>
          <Box style={{ width: "100%", height: "200px" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  color: theme.palette.text.primary,
                  paddingBottom: "5px",
                }}
                fontSize="20px"
              >
                The site is under maintenance
              </Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DQButton
                title="Click to check"
                onclick={handleCheck}
                disabled={false}
                variant="contained"
              />
            </Box>
          </Box>
        </Box>
        <Snackbar
          open={user.alertInfo.open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={config.autoHideDuration}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            icon={false}
            severity={user.alertInfo.severity}
          >
            {user.alertInfo.message}
          </Alert>
        </Snackbar>
      </LayoutTopBottom>
    </>
  );
}

export default MaintainancePage;
