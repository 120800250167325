// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree-view-icon {
  font-size: 14px;
  font-family: "Hind Siliguri";
}

.hierarchy-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hierarchy-label .name {
  text-overflow: ellipsis;
  max-width: 65%;
  min-width: 50%;
  overflow-x: clip;
  overflow-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/js/components/DiagnosticReport/DomainHierarchy/DomainHierarchy.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,4BAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,uBAAA;EACA,cAAA;EACA,cAAA;EACA,gBAAA;EACA,yBAAA;AAEJ","sourcesContent":[".tree-view-icon {\n  font-size: 14px;\n  font-family: \"Hind Siliguri\";\n}\n\n.hierarchy-label {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  .name {\n    text-overflow: ellipsis;\n    max-width: 65%;\n    min-width: 50%;\n    overflow-x: clip;\n    overflow-wrap: break-word;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
