// Import required libraies

import { Card, CardContent, Paper, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Popover } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  compareDatasetDownloadJson,
  compareDatasetdownloadReportCSV,
  getAdminPanelTeamsList,
  getAllRulesData,
  getCompareDatasetById,
  getCompareDatasetCriteria,
  runCompareDataset,
  getCatalogsColumns,
} from "../../../services/apis";
import {
  resetUserInputs,
  updateAlertInfo,
  updateChangeFlagUiRules,
  updateData,
  updateDialogInfo,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import { BorderColor } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import DQButton from "../../../components/Common/DQButton";
import DQInput from "../../../components/Common/DQInput";
import DQSingleAutocomplete from "../../../components/Common/DQSingleAutocomplete";
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import PropTypes from "prop-types";
import cancel from "../../../../assets/images/cancel.png";
import { connect } from "react-redux";
import { initialApiData } from "../../../utils/utils";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import tick from "../../../../assets/images/tick.png";
import FilterCriteriaCondition from "../../../components/FilterCriteriaCondition";

// # STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  rightAlign: {
    marginLeft: "auto",
  },
  asterik: {
    color: "#f10000",
    marginLeft: "2px",
  },
  p: { margin: 2, color: "#46596a" },
  pvalue: {
    margin: 2,
    fontWeight: 600,
    fontSize: 15,
    color: "#4eafb3",
  },
  ptable: {
    textAlign: "center",
    margin: 10,
    fontWeight: 600,
    fontSize: 15,
    color: "#4eafb3",
  },
}));

function FilterIteamContainer(props) {
  const {
    filterRow,
    rowIndex,
    isReadOnly = false,
    disabledItemsList = [],
    handleDelete = () => {},
    filterType = "",
  } = props;

  //Defining required states
  const [anchorEl, setAnchorEl] = useState(null);

  //Defining Styles
  const useStyles = makeStyles(() => ({
    popover: {
      pointerEvents: "none",
      zIndex: "99999999999 !important",
      "& .MuiPaper-root": {
        backgroundColor: " #ffffff",
        padding: "5px",
      },
    },
  }));

  //Defining required functions
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //Defining required variables
  const open = Boolean(anchorEl);
  const popoverId = open ? `selectedFilter_${rowIndex}` : undefined;

  const classes = useStyles();

  //Transform advanced filters data for UI
  //HARD CODED

  const isReadOnlyFinal =
    disabledItemsList.includes(filterRow.id) || isReadOnly;
  const isReadOnlyHelperText = isReadOnlyFinal ? "(Read Only)" : "";

  return (
    <>
      <div
        className={`selected-filter-item-container `}
        style={{ display: "flex" }}
      >
        <div
          className="selected-filter-item-title"
          title={filterRow.columnName}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {filterRow.columnName}
        </div>
        {/* {!isReadOnlyFinal && (
          <div
            className="material-icons-outlined selected-filter-item-delete-icon"
            onClick={(e) => {
              e.stopPropagation(); //To prevent the onclick function of the parent div
              handleDelete(filterRow, filterType);
            }}
          >
            close
          </div>
        )} */}
      </div>
      <Popover
        id={popoverId}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="selected-filter-item-popover-container">
          <p className="selected-filter-item-popover-filter-type">
            {"condition:"} {filterRow.condition} {isReadOnlyHelperText}
          </p>
          <p className="selected-filter-item-popover-filter-type">
            {"InputValue:"} {filterRow.inputValue} {isReadOnlyHelperText}
          </p>
          <p className="selected-filter-item-popover-filter-type">
            {"Operator:"} {filterRow.operator} {isReadOnlyHelperText}
          </p>
        </div>
      </Popover>
    </>
  );
}

function FilterContainer(props) {
  const { filterList = [], handleDelete = () => {}, filterType = "" } = props;

  return (
    <>
      {filterList.map((filterRow, filterIndex) => {
        return (
          <FilterIteamContainer
            filterRow={filterRow}
            filterIndex={filterIndex}
            handleDelete={handleDelete}
            filterType={filterType}
          />
        );
      })}
    </>
  );
}

// # COMPONENT
function Report(props) {
  const {
    user,
    data,
    updateData,
    match,
    updateAlertInfo,
    userInputs,
    updateDialogInfo,
  } = props;

  // # HOOKS
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [compareDatasetData, setCompareDatasetData] = useState(initialApiData);
  const [criteriaData, setCriteriaData] = useState(initialApiData);
  const [allRulesData, setAllRulesData] = useState([]);
  const [dataSource, setDataSource] = useState({ flag: false, data: [] });
  const [dataDestination, setDataDestination] = useState({
    flag: false,
    data: [],
  });

  // Defining the AlertCriteria reducer

  const fetchCompareDatasetReport = async (id) => {
    let data = await getCompareDatasetById(id);
    if (data) {
      setCompareDatasetData({
        status: "success",
        message: "",
        result: data,
      });
    } else {
      setCompareDatasetData({
        status: "error",
        message: "No result found! Please try again Later!",
        result: [],
      });
    }
  };

  useEffect(() => {
    fetchCompareDatasetReport(match.params.id);
  }, [match]);

  const fetchCriteriaData = async () => {
    let data = await getCompareDatasetCriteria();
    if (data && data?.length > 0) {
      setCriteriaData({
        status: "success",
        message: "",
        result: data,
      });
    } else {
      setCriteriaData({
        status: "error",
        message: "No result found! Please try again Later!",
        result: [],
      });
    }
  };

  const fetchAllRulesData = async () => {
    let data = await getAllRulesData();
    if (data && data?.length > 0) {
      setAllRulesData({
        status: "success",
        message: "",
        result: data,
      });
    } else {
      setAllRulesData({
        status: "error",
        message: "No result found! Please try again Later!",
        result: [],
      });
    }
  };

  useEffect(() => {
    fetchCriteriaData();
    fetchAllRulesData();
  }, []);

  const downloadCsvFile = (csvData = "", fileName = "csvFile") => {
    var blob = new Blob([csvData]);
    const url = window.URL.createObjectURL(blob, {
      type: "text/csv",
    });
    const hiddenElement = document.createElement("a");
    hiddenElement.href = url;
    hiddenElement.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(hiddenElement);
    hiddenElement.click();

    // Clean up and remove the hiddenElement
    hiddenElement.parentNode.removeChild(hiddenElement);
  };

  function downloadURI(uri, name) {
    var link = document.createElement("a");
    var isIE = /MSIE|Trident|Edge\//.test(window.navigator.userAgent);

    if (isIE) {
      link.target = "_blank";
    }

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const handleDownloadAsCSV = async () => {
    let fetchProps = {
      id: compareDatasetData.result.id,
    };
    let reportDetails = await compareDatasetdownloadReportCSV(fetchProps);
    downloadCsvFile(reportDetails, compareDatasetData.result.report_name);
  };

  const handleDownloadFailedValues = async (criteria, direction) => {
    let fetchProps = {
      prefix:
        direction === "source"
          ? "source_failed_values"
          : direction === "target"
          ? "target_failed_values"
          : "failed_values",
      id: compareDatasetData.result.id,
      criteria,
    };
    let reportDetails = await compareDatasetDownloadJson(fetchProps);
    if (reportDetails.status) {
      downloadURI(reportDetails.url, reportDetails.filename);
    } else {
      dispatch(
        updateAlertInfo({
          open: true,
          message: `Failed to get file: ${reportDetails.message}`,
          severity: "error",
        })
      );
    }
  };

  const getCriteriaName = (criteria) => {
    try {
      return criteriaData.result.find((c) => c.function === criteria)
        ? criteriaData.result.find((c) => c.function === criteria).description
        : allRulesData.result.find((c) => c.name === criteria)
        ? allRulesData.result.find((c) => c.name === criteria).ruleDescription
        : criteria;
    } catch (error) {
      return criteria;
    }
  };
  const handleDelete = (filterRow, filterType) => {
    setCompareDatasetData({
      ...compareDatasetData,
      result: {
        ...compareDatasetData.result,
        [filterType]: {
          ...compareDatasetData.result[filterType],
          filter: compareDatasetData.result[filterType].filter.filter(
            (el) => el.columnName !== filterRow.columnName
          ),
        },
      },
    });
    compareDatasetData.result.source.filter.filter((el) => {});
  };

  const handleClearAll = (filterType) => {
    setCompareDatasetData({
      ...compareDatasetData,
      result: {
        ...compareDatasetData.result,
        [filterType]: {
          ...compareDatasetData.result[filterType],
          filter: [],
        },
      },
    });
  };

  const handleAddNewFilters = async () => {
    let fetchPropsSource = {
      cluster: compareDatasetData.result.team,
      schema_name: compareDatasetData.result.source.database,
      table_name: compareDatasetData.result.source.table,
    };
    let fetchPropsDestination = {
      cluster: compareDatasetData.result.team,
      schema_name: compareDatasetData.result.destination.database,
      table_name: compareDatasetData.result.destination.table,
    };
    let columnData = await Promise.all([
      getCatalogsColumns(fetchPropsSource),
      getCatalogsColumns(fetchPropsDestination),
    ]);

    if (columnData[0] === undefined) {
      setDataSource({ flag: "error", data: [] });
    } else {
      let newDataSource = columnData[0];
      setDataSource({ flag: "success", data: newDataSource });
    }
    if (columnData[1] === undefined) {
      setDataDestination({ flag: "error", data: [] });
    } else {
      let newDataDestination = columnData[1];
      setDataDestination({ flag: "success", data: newDataDestination });
    }
  };

  return (
    <LayoutTopSideBottom match={match}>
      <Breadcrumbs match={match} />
      {compareDatasetData.status === "success" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 4,
          }}
        >
          <Box css={{ display: "flex", flexDirection: "row-reverse" }}>
            <a
              className="disabled-style"
              style={{ alignSelf: "end", position: "relative" }}
              title={"Run/Rerun"}
              onClick={async () => {
                dispatch(
                  updateDialogInfo({
                    title: `Do you want re|run Compare Dataset Report ?`,
                    message: `for Report Name: ${compareDatasetData.result.report_name}`,
                    open: true,
                    handleYes: async () => {
                      dispatch(
                        updateDialogInfo({
                          ...user.dialogInfo,
                          open: false,
                        })
                      );

                      let responseData = await runCompareDataset({
                        id: compareDatasetData.result.id,
                      });

                      if (responseData.status) {
                        dispatch(
                          updateAlertInfo({
                            open: true,
                            message:
                              "Requested Run for Compare Dataset Id: " +
                              compareDatasetData.result.id,
                            severity: "success",
                          })
                        );
                        window.location.reload();
                      }
                    },
                    handleNo: () => {
                      dispatch(
                        updateDialogInfo({
                          ...user.dialogInfo,
                          open: false,
                        })
                      );
                    },
                  })
                );
              }}
            >
              {/* <i
              className={`material-icons-outlined`}
              style={{ fontSize: 36, color: "#46596a" }}
            >
              play_arrow
            </i> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <i
                  className={`material-icons-outlined`}
                  style={{ fontSize: 36, color: "#46596a" }}
                >
                  play_arrow
                </i>
                <span style={{ fontSize: 14, color: "#46596a", marginTop: 8 }}>
                  Rerun
                </span>
              </div>
            </a>

            <a
              className="disabled-style"
              style={{ alignSelf: "end", position: "relative" }}
              title={"download"}
              onClick={handleDownloadAsCSV}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <i
                  className={`material-icons-outlined`}
                  style={{ fontSize: 32, color: "#46596a" }}
                >
                  download
                </i>
                <span style={{ fontSize: 14, color: "#46596a", marginTop: 8 }}>
                  Download
                </span>
              </div>
            </a>
          </Box>

          <p
            style={{
              textAlign: "center",
              color: "#4eafb3",
              marginBottom: 0,
            }}
          >
            Report Name
          </p>
          <h4
            style={{
              textAlign: "center",
              fontWeight: 600,
              textTransform: "uppercase",
              color: "#46596a",
              margin: 0,
            }}
          >
            {compareDatasetData.result.team} -{" "}
            {compareDatasetData.result.report_name}
          </h4>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              padding: 10,
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                textAlign: "center",
                borderRight: "3px dashed #4eafb3",
              }}
            >
              <p
                style={{
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 15,
                  textTransform: "uppercase",
                  color: "#4eafb3",
                  marginBottom: 8,
                }}
              >
                Source
              </p>
              {/* <p className={classes.p}>Name</p>
              <p className={classes.pvalue}>
                {compareDatasetData.result.source.table}
              </p>
              <p className={classes.p}>Database</p>
              <p className={classes.pvalue}>
                {compareDatasetData.result.source.database}
              </p>
              <p className={classes.p}>Number of Columns</p>
              <p className={classes.pvalue}>
                {compareDatasetData.result.source.columns.length}
              </p> */}
              <p className={classes.p}>
                <span>Name: </span>
                <span className={classes.pvalue}>
                  {compareDatasetData.result.source.table}
                </span>
              </p>
              <p className={classes.p}>
                <span>Database: </span>
                <span className={classes.pvalue}>
                  {compareDatasetData.result.source.database}
                </span>
              </p>
              <p className={classes.p}>
                <span>Number of Columns: </span>
                <span className={classes.pvalue}>
                  {compareDatasetData.result.source.columns.length}
                </span>
              </p>
              <p className={classes.p}>
                <span>Number of Filters: </span>
                <span className={classes.pvalue}>
                  {compareDatasetData.result.source.filter.length}
                </span>
              </p>
              <Box
                css={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FilterContainer
                  filterList={compareDatasetData.result.source.filter}
                  handleDelete={handleDelete}
                  filterType="source"
                />
                {/* {compareDatasetData.result.source.filter.length > 0 && (
                  <button
                    className="apply-filter-btn"
                    onClick={() => handleClearAll("source")}
                  >
                    Clear All
                  </button>
                )}

                <button
                  className="apply-filter-btn"
                  onClick={handleAddNewFilters}
                >
                  Click to add filters
                </button> */}
              </Box>
              {/* {dataSource.flag === "success" && (
                <Box>
                  <FilterCriteriaCondition
                    dataTable={dataSource.data}
                    selection={compareDatasetData.result.source.filter}
                  />
                </Box>
              )} */}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,

                textAlign: "center",
              }}
            >
              <p
                style={{
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 15,
                  textTransform: "uppercase",
                  color: "#4eafb3",
                  marginBottom: 8,
                }}
              >
                Destination
              </p>
              {/* <p className={classes.p}>Name</p>
              <p className={classes.pvalue}>
                {compareDatasetData.result.destination.table}
              </p>
              <p className={classes.p}>Database</p>
              <p className={classes.pvalue}>
                {compareDatasetData.result.destination.database}
              </p>

              <p className={classes.p}>Number of Columns</p>
              <p className={classes.pvalue}>
                {compareDatasetData.result.destination.columns.length}
              </p> */}
              <p className={classes.p}>
                <span>Name: </span>
                <span className={classes.pvalue}>
                  {compareDatasetData.result.destination.table}
                </span>
              </p>
              <p className={classes.p}>
                <span>Database: </span>
                <span className={classes.pvalue}>
                  {compareDatasetData.result.destination.database}
                </span>
              </p>
              <p className={classes.p}>
                <span>Number of Columns: </span>
                <span className={classes.pvalue}>
                  {compareDatasetData.result.destination.columns.length}
                </span>
              </p>
              <p className={classes.p}>
                <span>Number of Filters: </span>
                <span className={classes.pvalue}>
                  {compareDatasetData.result.destination.filter.length}
                </span>
              </p>
              <Box
                css={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FilterContainer
                  filterList={compareDatasetData.result.destination.filter}
                  handleDelete={handleDelete}
                  filterType="destination"
                />
                {/* {compareDatasetData.result.destination.filter.length > 0 && (
                  <button
                    className="apply-filter-btn"
                    onClick={() => handleClearAll("destination")}
                  >
                    Clear All
                  </button>
                )}

                <button
                  className="apply-filter-btn"
                  onClick={handleAddNewFilters}
                >
                  Click to add filters
                </button> */}
              </Box>
              {/* {dataDestination.flag === "success" && (
                <Box>
                  <FilterCriteriaCondition dataTable={dataDestination.data} />
                </Box>
              )} */}
            </Box>
          </Box>

          <p
            style={{
              textAlign: "center",
              fontWeight: 600,
              color: "#4eafb3",
              marginBottom: 0,
            }}
          >
            Run on: {compareDatasetData.result.run_on}
          </p>

          <h1
            style={{
              textAlign: "center",
              fontWeight: 600,
              textTransform: "uppercase",
              color: "#46596a",
              marginBottom: 10,
            }}
          >
            Result
          </h1>

          {compareDatasetData.result.result.map((a) => {
            return (
              <Card variant="outlined" style={{ margin: 10, padding: 10 }}>
                <CardContent>
                  <p
                    style={{
                      fontWeight: 600,
                      color: "#4eafb3",
                      marginBottom: 0,
                    }}
                  >
                    {getCriteriaName(a.criteria)}
                  </p>

                  {"destination" in a && "source" in a ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <p className={classes.ptable}>Source</p>

                        <p className={classes.p} style={{ margin: 10 }}>
                          {a.source}
                        </p>
                        <p className={classes.ptable}>Destination</p>

                        <p className={classes.p} style={{ margin: 10 }}>
                          {a.destination}
                        </p>
                      </Box>
                      {Object.entries(a.input.value).some(
                        (e) => e[0] != "isEnabled"
                      ) ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 600,
                              color: "#4eafb3",
                              margin: 0,
                            }}
                          >
                            User custom inputs
                          </p>
                          {Object.entries(a.input.value)
                            .filter((e) => e[0] != "isEnabled")
                            .map((e) => {
                              return (
                                <Box>
                                  <p className={classes.p}>
                                    <span>{e[0]}: </span>
                                    <span>{e[1].toString()}</span>
                                  </p>
                                </Box>
                              );
                            })}
                        </Box>
                      ) : null}
                    </>
                  ) : null}

                  {"source_schema" in a && "target_schema" in a ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 10,
                      }}
                    >
                      <TableContainer sx={{ marginRight: 5 }}>
                        <p className={classes.ptable}>Source</p>

                        <Table aria-label="simple table" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Column</TableCell>
                              <TableCell>Score</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(a.source_schema).map((p, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>{p[0]}</TableCell>
                                  <TableCell>{p[1]}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <TableContainer sx={{ marginLeft: 5 }}>
                        <p className={classes.ptable}>Destination</p>

                        <Table aria-label="simple table" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Column</TableCell>
                              <TableCell>Score</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(a.target_schema).map((p, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>{p[0]}</TableCell>
                                  <TableCell>{p[1]}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        fontWeight: 600,
                        textTransform: "uppercase",
                        color: "#46596a",
                        padding: 15,
                        fontSize: 14,
                      }}
                    >
                      No Result yet
                    </Box>
                  )}
                </CardContent>

                {"source_schema" in a && "target_schema" in a ? (
                  <>
                    {a.success === "False" &&
                      (getCriteriaName(a.criteria) === "Check values" ||
                        a.input !== undefined) && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {getCriteriaName(a.criteria) !== "Check values" ? (
                            <a
                              className="disabled-style"
                              title={"download"}
                              onClick={() =>
                                handleDownloadFailedValues(a.criteria, "source")
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <i
                                  className={`material-icons-outlined`}
                                  style={{ fontSize: 24, color: "#46596a" }}
                                >
                                  download
                                </i>
                                <span
                                  style={{
                                    fontSize: 11,
                                    color: "#46596a",
                                    marginTop: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  Download Failed Values
                                </span>
                              </div>
                            </a>
                          ) : null}
                          <a
                            style={{ alignSelf: "flex-end" }}
                            className="disabled-style"
                            title={"download"}
                            onClick={() => {
                              let direction =
                                getCriteriaName(a.criteria) !== "Check values"
                                  ? "target"
                                  : "";
                              handleDownloadFailedValues(a.criteria, direction);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <i
                                className={`material-icons-outlined`}
                                style={{ fontSize: 24, color: "#46596a" }}
                              >
                                download
                              </i>
                              <span
                                style={{
                                  fontSize: 11,
                                  color: "#46596a",
                                  marginTop: 2,
                                  cursor: "pointer",
                                }}
                              >
                                Download Failed Values
                              </span>
                            </div>
                          </a>
                        </Box>
                      )}
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={a.success === "True" ? tick : cancel}
                        alt={"status"}
                        title={a.status === "True"}
                      />
                    </div>
                  </>
                ) : null}
              </Card>
            );
          })}
          {/*<pre> {JSON.stringify(compareDatasetData.result, null, 2)}</pre> */}
        </Box>
      ) : (
        <Loader />
      )}
    </LayoutTopSideBottom>
  );
}

Report.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateData,
  updateUserInputs,
  updateSelections,
  updateAlertInfo,
  updateDialogInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
