// Import required libraries
import React, { useEffect, useState } from "react";
import { TableBody, TableRow } from "@material-ui/core";
import { connect } from "react-redux";
// import { Pagination } from "@material-ui/lab";

// Import data & utils
import config from "../../../config/config";
import useTable from "../../../utils/useTable";
import noDataImage from "../../../../assets/images/undraw_void.svg";

import tick from "../../../../assets/images/tick.png";
import cancel from "../../../../assets/images/cancel.png";
// Import action creators

// Import styles
import { StyledTableCell, useStyles } from "./style";
import "./HistoricalRunTable.scss";
import HistoricalRunTableHeader from "./HistoricalRunTableHeader";
import {
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";

const headCells = [
  { id: "status", label: "Run Status", disableSorting: true },
  { id: "date", label: "Date", disableSorting: true },
  { id: "score", label: "Score", disableSorting: true },
];

const HistoricalRunTable = ({
  data,
  tableData = [],
  getRightSideReport,
  getRightSideFilters,
  updateData,
  updateSelections,
  updateUserInputs,
  // activeDate,
  // changeActiveDate,
}) => {
  const classes = useStyles();
  const filterFn = { fn: (items) => items };

  const { TblContainer } = useTable(
    tableData,
    headCells,
    filterFn,
    {},
    () => {}
  );

  const [activeDate, setActiveDate] = useState({});

  const changeActiveDate = (date, index) => {
    if (activeDate.id !== date.id) {
      setActiveDate(date);
      updateUserInputs("openDRFilters", false);
      updateSelections("DRFilters", {});
      updateSelections("initialDRFilters", {});
      if (index === 0) {
        getRightSideFilters(date);
      } else {
        getRightSideReport(date);
        updateData("tableDiagnosticReportFilters", {
          status: "invalid",
          message: "",
          result: {},
        });
      }
    }
  };

  useEffect(() => {
    if (
      data.tableHistoricalRunDetails.result?.historical_runs?.length > 0 &&
      data.tableHistoricalRunDetails.status !== "loading"
    ) {
      changeActiveDate(
        data.tableHistoricalRunDetails.result.historical_runs[0],
        0
      );
    } else {
      if (data.tableHistoricalRunDetails.status !== "loading") {
        updateData("tableDiagnosticReportFilters", {
          status: "error",
          message: config.messages.noRecordFound,
          result: {},
        });
        updateData("tableDiagnosticReportDetails", {
          status: "error",
          message: config.messages.noRecordFound,
          result: [],
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {" "}
      <div className="historical-run-container">
        <TblContainer>
          <HistoricalRunTableHeader fetchedData={tableData} />
          {tableData?.length ? (
            <TableBody>
              {tableData.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow
                      className={
                        activeDate.id === item.id
                          ? classes.activeTableRow
                          : classes.tableRow
                      }
                      hover
                      onClick={() => changeActiveDate(item, index)}
                    >
                      <StyledTableCell
                        style={{
                          fontWeight: "600",
                        }}
                        width="30%"
                      >
                        <img
                          src={item.status === true ? tick : cancel}
                          alt={"status"}
                          title={item.status === true ? "Pass" : "Fail"}
                        />
                      </StyledTableCell>
                      <StyledTableCell width="50%">{item.date}</StyledTableCell>
                      <StyledTableCell align="center" width="20%">
                        {item.score ? item.score : "NR"}
                        {item.score !== "NR" && " %"}
                      </StyledTableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          ) : (
            <TableBody className="no-runs">
              <TableRow>
                <StyledTableCell colSpan={8} style={{ padding: "20%" }}>
                  <img src={noDataImage} alt="noDataImage" />
                  <p className="no-data">
                    <b>{config.messages.noRecordsFound}</b>
                  </p>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </TblContainer>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userInputs: state.userInputs,
  data: state.data,
});

const mapDispatchToProps = { updateData, updateSelections, updateUserInputs };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(HistoricalRunTable));
