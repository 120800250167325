import C from "../constants";

export default function changeFlag(state = {}, payload) {
  let { type, flag } = payload;
  switch (type) {
    case C.RELOAD_DATA:
      return { ...state, reloadData: !state.reloadData };
    case C.UPDATE_CHANGE_FLAG_UI_RULES:
      return { ...state, uiRules: flag };
    default:
      return state;
  }
}
