//Import required libraies
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import { v4 } from "uuid";

// import { splitSemanticOptions } from "../../../utils/utils";

const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#4eafb3 !important",
    paddingLeft: "0px",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function CustomComponent(props) {
  let { id, name, isChecked, onChange, columnName, record, ruleElement } =
    props;

  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <div
        style={{
          width: "fit-content",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        <StyledCheckbox
          disableRipple
          checked={isChecked}
          onChange={() => {
            onChange(id, {
              ...record,
              isChecked: !isChecked,
              id,
              name,
              columnName,
            });
          }}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        <label className="light-text">{name}</label>
      </div>
      <div style={{ display: "flex" }}>
        {ruleElement?.mandatory.map((elem) => {
          const handleChange = (key, value) => {
            let updatedVal = [];
            if (elem.type === "list") {
              updatedVal = [...value.split(",")];
            }
            const obj = {
              ...record,
              isChecked: true,
              id,
              name,
              columnName,
              [key]: elem.type === "list" ? updatedVal : value,
            };
            onChange(id, obj);
          };
          return (
            <div
              key={elem.id}
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                <input
                  className="custom-inputbox"
                  style={{ margin: "0px", width: "220px" }}
                  type="text"
                  value={record?.[elem.id] ? record[elem.id] : ""}
                  placeholder={
                    elem?.placeholder ? elem.placeholder : "Enter value"
                  }
                  onChange={(e) => {
                    const inputVal =
                      elem.type === "number" ? +e.target.value : e.target.value;
                    handleChange(elem.id, inputVal);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CustomComponent;
