// Import required libraries
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TableBody, TableRow, Grid, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pagination } from "@material-ui/lab";

// Import data & utils
import config from "../../../config/config";
import useTable from "../../../utils/useTable";
import noDataImage from "../../../../assets/images/undraw_void.svg";

// Import action creators
import {
  updateDialogInfo,
  updateAlertInfo,
  reloadData,
  resetSelections,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";

// Import styles
import {
  StyledTableCell,
  useStyles,
  customMenuItemStyle,
  CustomMenuItem,
} from "./style";
import "./GlobalRulesListTable";
import SummaryTableHeader from "./SummaryTableHeader";

const SummaryTable = ({
  data = [],
  paginationFlag,
  criteria,
  allData = [],
  resetSelections,
}) => {
  const classes = useStyles();
  const filterFn = { fn: (items) => items };

  const { TblContainer } = useTable(data, filterFn, {}, () => {}, criteria);

  const pageSizes = [...config.hardCoded.pageSizes];

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  //reset filters on screen change
  useEffect(() => {
    return () => {
      resetSelections("summaryUseCase");
      resetSelections("summaryTable");
      resetSelections("summaryColumn");
      resetSelections("summaryAllRule");
      resetSelections("summaryCategory");
      resetSelections("summaryDimension");
    };
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const displayRecords =
    data.length > page
      ? [...data].splice((page - 1) * pageSize, pageSize)
      : data;
  const noOfPages = Math.ceil(data.length / pageSize);

  return (
    <>
      <>
        {" "}
        <div className="global-rules-table">
          <TblContainer>
            <SummaryTableHeader fetchedData={allData} />
            {data?.length ? (
              <TableBody>
                {displayRecords.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow className={classes.tableRow} key={index} hover>
                        {/* <StyledTableCell>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "underline",
                              maxWidth: "280px",
                            }}
                          >
                            {item["Use Case"]}
                          </span>
                        </StyledTableCell> */}
                        <StyledTableCell>{item["Use Case"]}</StyledTableCell>
                        <StyledTableCell>{item.Table}</StyledTableCell>
                        <StyledTableCell>{item.Column}</StyledTableCell>
                        <StyledTableCell>{item.Rule}</StyledTableCell>
                        <StyledTableCell>{item.Description}</StyledTableCell>
                        <StyledTableCell>{item.Category}</StyledTableCell>
                        <StyledTableCell>{item.Dimension}</StyledTableCell>

                        {/* <StyledTableCell title={item.Category}>
                          {item.supported_datatypes.length
                            ? item.supported_datatypes.map(
                                (itemValue, index) => (
                                  <span key={itemValue}>
                                    {" "}
                                    {itemValue}
                                    {index !==
                                    item.supported_datatypes.length - 1
                                      ? ","
                                      : ""}
                                  </span>
                                )
                              )
                            : "-"}
                        </StyledTableCell> */}

                        {/* <StyledTableCell>
                          <div
                            className={
                              item.status.toLowerCase() === "active"
                                ? "status-active"
                                : "status-inactive"
                            }
                          >
                            {item.status}
                          </div>
                        </StyledTableCell> */}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody className="no-configs">
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <img
                      src={noDataImage}
                      alt="noDataImage"
                      className="no-data-image"
                    />
                    <p className="no-data">
                      <b>{config.messages.noRecordsFound}</b>
                    </p>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            )}
          </TblContainer>
        </div>
        {paginationFlag && data.length > 10 && (
          <Grid container style={{ marginTop: "15px" }}>
            <Grid item>
              <Pagination
                count={noOfPages}
                page={page}
                shape="rounded"
                onChange={handleChangePage}
                siblingCount={0}
                boundaryCount={2}
                className={classes.pagination}
              />
            </Grid>
            <Grid item xs={1}>
              <Select
                onChange={handlePageSizeChange}
                value={pageSize}
                disableUnderline
                className={classes.paginationSelect}
                IconComponent={ExpandMoreIcon}
              >
                {pageSizes.map((size) => (
                  <CustomMenuItem
                    key={size}
                    value={size}
                    style={customMenuItemStyle}
                  >
                    View {size}
                  </CustomMenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}{" "}
      </>
    </>
  );
};

SummaryTable.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  reloadData,
  resetSelections,
  updateDialogInfo,
  updateAlertInfo,
  updateSelections,
  updateUserInputs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SummaryTable));
