const HierarchyDefault = {
  archetype: null,
  primaryDomain: null,
  secondaryDomain: null,
  subDomain: null,
  datasets: null,
  dataLayers: null,
  tables: null,
};

export const convertToLinearArray = (
  data,
  hierarchyDetails = { ...HierarchyDefault },
  linearArray = []
) => {
  if (Array.isArray(data)) {
    for (const element of data) {
      let { children, ...updateElement } = { ...element };
      if (element.elementName === "Archetype") {
        hierarchyDetails.archetype = updateElement;
        hierarchyDetails.primaryDomain = null;
        hierarchyDetails.secondaryDomain = null;
        hierarchyDetails.subDomain = null;
        hierarchyDetails.datasets = null;
        hierarchyDetails.dataLayers = null;
        hierarchyDetails.tables = null;
      }
      if (element.elementName === "Primary Domain") {
        hierarchyDetails.primaryDomain = updateElement;
        hierarchyDetails.secondaryDomain = null;
        hierarchyDetails.subDomain = null;
        hierarchyDetails.datasets = null;
        hierarchyDetails.dataLayers = null;
        hierarchyDetails.tables = null;
      }
      if (element.elementName === "Secondary Domain") {
        hierarchyDetails.secondaryDomain = updateElement;
        hierarchyDetails.subDomain = null;
        hierarchyDetails.datasets = null;
        hierarchyDetails.dataLayers = null;
        hierarchyDetails.tables = null;
      }
      if (element.elementName === "Sub Domain") {
        hierarchyDetails.subDomain = updateElement;
        hierarchyDetails.datasets = null;
        hierarchyDetails.dataLayers = null;
        hierarchyDetails.tables = null;
      }
      if (element.elementName === "Dataset") {
        hierarchyDetails.datasets = updateElement;
        hierarchyDetails.dataLayers = null;
        hierarchyDetails.tables = null;
      }
      if (element.elementName === "Layer") {
        hierarchyDetails.dataLayers = updateElement;
        hierarchyDetails.tables = null;
      }
      if (element.elementName === "Table") {
        hierarchyDetails.tables = updateElement;
      }
      linearArray.push({ metaData: { ...hierarchyDetails }, ...updateElement });
      if (children) {
        convertToLinearArray(children, hierarchyDetails, linearArray);
      }
    }
  }

  return linearArray;
};

export const filterDataWithSearchText = (data = [], searchText) => {
  const lowerSearchText = searchText.toLowerCase().trim();
  return data.filter((elem) =>
    elem.name.toLowerCase().includes(lowerSearchText)
  );
};

const isElementInHierarchy = (elem, params = {}) => {
  const updatedParams = convertParamsNameToOriginName(params);

  if (
    updatedParams.archetype &&
    (!elem.metaData.archetype ||
      elem.metaData.archetype.name !== updatedParams.archetype)
  ) {
    return false;
  }

  if (
    updatedParams.primarydomain &&
    (!elem.metaData.primaryDomain ||
      elem.metaData.primaryDomain.name !== updatedParams.primarydomain)
  ) {
    return false;
  }

  if (
    updatedParams.secondarydomain &&
    (!elem.metaData.secondaryDomain ||
      elem.metaData.secondaryDomain.name !== updatedParams.secondarydomain)
  ) {
    return false;
  }

  if (
    updatedParams.subdomain &&
    (!elem.metaData.subDomain ||
      elem.metaData.subDomain.name !== updatedParams.subdomain)
  ) {
    return false;
  }

  if (
    updatedParams.dataset &&
    (!elem.metaData.datasets ||
      elem.metaData.datasets.name !== updatedParams.dataset)
  ) {
    return false;
  }

  if (
    updatedParams.layer &&
    (!elem.metaData.dataLayers ||
      elem.metaData.dataLayers.name !== updatedParams.layer)
  ) {
    return false;
  }

  if (
    updatedParams.table &&
    (!elem.metaData.tables || elem.metaData.tables.name !== updatedParams.table)
  ) {
    return false;
  }

  return true;
};

export const filterDataWithSearchTextAndTags = (
  data = [],
  searchText,
  selectedTags = [],
  params
) => {
  const lowerSearchText = searchText.toLowerCase().trim();

  return data.filter((elem) => {
    if (isElementInHierarchy(elem, params)) {
      if (selectedTags.length) {
        let isTagPresent = false;
        if (elem.tags) {
          isTagPresent = elem.tags.some((item) => selectedTags.includes(item));
        }
        return (
          elem.name.toLowerCase().includes(lowerSearchText) && isTagPresent
        );
      } else {
        return elem.name.toLowerCase().includes(lowerSearchText);
      }
    } else return false;
  });
};

//  convert to origin name that get changed during pathname formating
export const convertParamsNameToOriginName = (params = {}) => {
  let newParams = {};
  for (const key of Object.keys(params)) {
    if (params[key] && params[key].includes("---")) {
      newParams[key] = params[key].split("---").join("/");
    } else {
      newParams[key] = params[key];
    }
  }
  return newParams;
};

const getTreeChildren = (data = [], searchParamName = null, params = {}) => {
  if (searchParamName === null || searchParamName === undefined) {
    return data;
  }

  const matchElement = data.find((element) => element.name === searchParamName);
  if (matchElement) {
    if (matchElement.elementName === "Archetype") {
      return getTreeChildren(
        matchElement.children,
        params.primarydomain,
        params
      );
    }
    if (matchElement.elementName === "Primary Domain") {
      return getTreeChildren(
        matchElement.children,
        params.secondarydomain,
        params
      );
    }
    if (matchElement.elementName === "Secondary Domain") {
      return getTreeChildren(matchElement.children, params.subdomain, params);
    }
    if (matchElement.elementName === "Sub Domain") {
      return getTreeChildren(matchElement.children, params.dataset, params);
    }
    if (matchElement.elementName === "Dataset") {
      return getTreeChildren(matchElement.children, params.layer, params);
    }
    if (matchElement.elementName === "Layer") {
      return getTreeChildren(matchElement.children, params.table, params);
    }
    if (matchElement.elementName === "Table") {
      return getTreeChildren(matchElement.children, null, params);
    }
  } else {
    return getTreeChildren([], null, params);
  }
};

export const getCategoryTreeChildrenByParams = (treeData = [], params = {}) => {
  if (params) {
    const updatedParams = convertParamsNameToOriginName(params);
    return getTreeChildren(treeData, updatedParams.archetype, updatedParams);
  }
  return treeData;
};

const getHierarchyDetails = (
  data = [],
  params = {},
  searchParamName,
  hierarchyData = { ...HierarchyDefault }
) => {
  if (searchParamName === null || searchParamName === undefined) {
    return hierarchyData;
  }

  const matchElement = data.find((element) => element.name === searchParamName);
  if (matchElement) {
    const { children, ...restMatchElement } = matchElement;
    if (matchElement.elementName === "Archetype") {
      hierarchyData.archetype = restMatchElement;
      return getHierarchyDetails(
        matchElement.children,
        params,
        params.primarydomain,
        hierarchyData
      );
    }
    if (matchElement.elementName === "Primary Domain") {
      hierarchyData.primaryDomain = restMatchElement;
      return getHierarchyDetails(
        matchElement.children,
        params,
        params.secondarydomain,
        hierarchyData
      );
    }
    if (matchElement.elementName === "Secondary Domain") {
      hierarchyData.secondaryDomain = restMatchElement;
      return getHierarchyDetails(
        matchElement.children,
        params,
        params.subdomain,
        hierarchyData
      );
    }
    if (matchElement.elementName === "Sub Domain") {
      hierarchyData.subDomain = restMatchElement;
      return getHierarchyDetails(
        matchElement.children,
        params,
        params.dataset,
        hierarchyData
      );
    }
    if (matchElement.elementName === "Dataset") {
      hierarchyData.datasets = restMatchElement;
      return getHierarchyDetails(
        matchElement.children,
        params,
        params.layer,
        hierarchyData
      );
    }
    if (matchElement.elementName === "Layer") {
      hierarchyData.dataLayers = restMatchElement;
      return getHierarchyDetails(
        matchElement.children,
        params,
        params.table,
        hierarchyData
      );
    }
    if (matchElement.elementName === "Table") {
      hierarchyData.tables = restMatchElement;
      return getHierarchyDetails(
        matchElement.children,
        params,
        null,
        hierarchyData
      );
    }
  } else {
    return getHierarchyDetails(data, params, null, hierarchyData);
  }
};

export const getCategoryChildrenHierarchyByParams = (
  treeData = [],
  params = {}
) => {
  if (params) {
    const updatedParams = convertParamsNameToOriginName(params);
    return getHierarchyDetails(
      treeData,
      updatedParams,
      updatedParams.archetype
    );
  }
  return { ...HierarchyDefault };
};

export const getTblHeaders = (filteringFlag, params) => {
  let header = [];
  if (filteringFlag) {
    header.push({ id: "archetypes", label: "Search Result" });
  } else {
    // data in table is one label behind params
    header.push({ id: "", label: "Archetypes" });
    if (params.archetype) {
      header.pop();
      header.push({ id: "primary_domains", label: "L0 Domains" });
    }
    if (params.primarydomain) {
      header.pop();
      header.push({ id: "secondary_domains", label: "L1 Domains" });
    }
    if (params.secondarydomain) {
      header.pop();
      header.push({ id: "sub_domains", label: "L2 Subdomains" });
    }
    if (params.subdomain) {
      header.pop();
      header.push({ id: "datasets", label: "Datasets" });
    }
    if (params.dataset) {
      header.pop();
      header.push({ id: "data_layers", label: "Data Layers" });
    }
    if (params.layer) {
      header.pop();
      header.push({ id: "table_name", label: "Table Name" });
    }
  }

  header.push({ id: "aggregated_score", label: "Aggregated Score" });
  return header;
};

export const validateParamsWithTableSelections = (
  params = {},
  tableSelections = {}
) => {
  // TODO : make generic logic when params key and tableSelection key is same

  if (!(params.archetype && tableSelections.archetype)) {
    return false;
  }
  if (!(params.primarydomain && tableSelections.primaryDomain)) {
    return false;
  }
  if (!(params.secondarydomain && tableSelections.secondaryDomain)) {
    return false;
  }
  if (!(params.subdomain && tableSelections.subDomain)) {
    return false;
  }
  if (!(params.dataset && tableSelections.datasets)) {
    return false;
  }
  if (!(params.layer && tableSelections.dataLayers)) {
    return false;
  }
  if (!(params.table && tableSelections.tables)) {
    return false;
  }

  return true;
};

export const getTagsFromCategoryLineaData = (data = []) => {
  let tags = [];

  for (const element of data) {
    if (element.tags) {
      tags = [...new Set([...tags, ...element.tags])];
    }
  }
  return tags;
};

export const getHierarchyName = (item) => {
  let hierarchyNameArray = [];
  if (!item.metaData) {
    return "";
  }

  if (item.metaData.archetype) {
    hierarchyNameArray.push(item.metaData.archetype.name);
  }
  if (item.metaData.primaryDomain) {
    hierarchyNameArray.push(item.metaData.primaryDomain.name);
  }
  if (item.metaData.secondaryDomain) {
    hierarchyNameArray.push(item.metaData.secondaryDomain.name);
  }
  if (item.metaData.subDomain) {
    hierarchyNameArray.push(item.metaData.subDomain.name);
  }
  if (item.metaData.datasets) {
    hierarchyNameArray.push(item.metaData.datasets.name);
  }
  if (item.metaData.dataLayers) {
    hierarchyNameArray.push(item.metaData.dataLayers.name);
  }
  if (item.metaData.tables) {
    hierarchyNameArray.push(item.metaData.tables.name);
  }
  // Pop last name and join
  hierarchyNameArray.pop();

  return hierarchyNameArray.length
    ? hierarchyNameArray.join(" > ").concat(" > ")
    : "";
};

export const getSubPathName = (item) => {
  let hierarchyNameArray = [];
  if (!item.metaData) {
    return "";
  }

  if (item.metaData.archetype) {
    hierarchyNameArray.push(item.metaData.archetype.name);
  }
  if (item.metaData.primaryDomain) {
    hierarchyNameArray.push(item.metaData.primaryDomain.name);
  }
  if (item.metaData.secondaryDomain) {
    hierarchyNameArray.push(item.metaData.secondaryDomain.name);
  }
  if (item.metaData.subDomain) {
    hierarchyNameArray.push(item.metaData.subDomain.name);
  }
  if (item.metaData.datasets) {
    hierarchyNameArray.push(item.metaData.datasets.name);
  }
  if (item.metaData.dataLayers) {
    hierarchyNameArray.push(item.metaData.dataLayers.name);
  }
  if (item.metaData.tables) {
    hierarchyNameArray.push(item.metaData.tables.name);
  }

  //   replace name value already containing '/' with '---'
  return hierarchyNameArray.length
    ? hierarchyNameArray
        .map((name) =>
          name.includes("/") ? name.split("/").join("---") : name
        )
        .join("/")
    : "";
};
