/* eslint-disable indent */

import { Box, TableCell, TableHead, TableRow } from "@material-ui/core";

import Filters from "../../../components/Filters/Filters";
import React from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";

// Import Custom Component

// Import styles

// # COMPONENT
const DatasetTableHeader = (props) => {
  // # HOOKS
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.data);
  const classes = useStyles();

  // # STATIC VARIABLES
  let datatypeList = data.datasetDetails.result.map((item) => item.layer_name);
  let uniqueData = [...new Set(datatypeList)];
  console.log(uniqueData);

  let useCaseList = data.datasetDetails.result.map((item) => item.use_case);
  let uniqueUseCase = [...new Set(useCaseList)];
  console.log(uniqueUseCase);

  // # JSX
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <Box style={{ marginLeft: "45px" }}>Table Name</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <div style={{ display: "flex", alignItems: "center" }}>
          <Box>Use Case</Box>
          <Filters
            flag={user.selections.useCase.value?.length ? true : false}
            id="useCase"
            data={uniqueUseCase}
            filterType="checkbox"
          />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box>Data Layer</Box>
            <Filters
              flag={user.selections.dataLayer.value?.length ? true : false}
              id="dataLayer"
              data={uniqueData}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Last Run On</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Latest Score</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Execute</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Last Execution</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Report</Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

// # EXPORTS
export default DatasetTableHeader;
