// ### Imports
// * Import lib

import React, { useState } from "react";
import {
  selectActiveDatabase,
  selectAllReduxData,
} from "../../../redux/selectors";

import Grid from "@material-ui/core/Grid";
import MenuItem from "../../../kit/MenuItem";
import Select from "../../../kit/Select";
import TextField from "../../../kit/TextField";
import Typography from "../../../kit/Typography";
import config from "../../../config/config";
import { makeStyles } from "@material-ui/core/styles";
import { useNonInitialEffect } from "../../../hooks/useNonInitialEffect";
import { useSelector } from "react-redux";

// * Import Kit

// * Import utils

// # UTILITY FUNCTIONS
const modifyDataForSelect = (data) =>
  data.map((row) => ({ id: row.name, name: row.name }));

// # STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  leftMiddle: { display: "flex", alignItems: "center" },
  centerMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  select: { width: "100% !important" },
}));

// # HELPER COMPONENTS
const CommonSelect = (props = {}) => {
  // # PROPS
  const {
    value,
    options,
    onChange,
    formKey,
    disabled = false,
    placeholder,
  } = props;

  // # HANDLERS
  const handleChange = (e) => {
    onChange(formKey, e.target.value);
  };

  // # STATIC VARIABLES
  const classes = useStyles();

  // # JSX
  return (
    <Select
      className={classes.select}
      value={value}
      displayEmpty
      inputProps={{ "aria-label": "Secondary Color" }}
      color="secondary"
      onChange={handleChange}
      MenuProps={config.hardCoded.MenuProps}
      renderValue={(selected) => {
        if (["", undefined, null].includes(selected)) {
          return placeholder || <p>Select option</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
        }
        let label = options.find((row) => row.id === selected)?.name;
        return label;
      }}
      disabled={disabled}
      title={value}
    >
      {options.length ? (
        options.map((option) => (
          <MenuItem key={option.id} value={option.id} title={option.name}>
            {option.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="" disabled>
          No Options
        </MenuItem>
      )}
    </Select>
  );
};

// # COMPONENT
function CustomIsExistingCondAdvanced(props = {}) {
  // # PROPS
  const { onChange, selections } = props;

  // # STATES
  const [formSelections, setFormSelections] = useState(selections);

  useNonInitialEffect(() => {
    onChange(formSelections);
  }, [formSelections]);

  // # SELECTORS
  const allReduxData = useSelector(selectAllReduxData);
  const activeDatabase = useSelector(selectActiveDatabase);

  // # HANDLERS

  const handleChange = (formKey, formValue) => {
    setFormSelections((prevState) => {
      switch (formKey) {
        case "primaryTableName":
          return { ...prevState, [formKey]: formValue, primaryColumnName: "" };
        case "existFromTableName":
          return {
            ...prevState,
            [formKey]: formValue,
            existFromColumnName: "",
          };
        case "existToTableName":
          return { ...prevState, [formKey]: formValue, existToColumnName: "" };
        case "condTableName":
          return { ...prevState, [formKey]: formValue, condColumnName: "" };
        default:
          return { ...prevState, [formKey]: formValue };
      }
    });
  };

  // # STATIC VARIABLES
  const classes = useStyles();
  const allTablesData =
    allReduxData.allColumns.result.find((row) => row.name === activeDatabase)
      ?.children || [];
  const allTablesOptions = modifyDataForSelect(
    allReduxData.allColumns.result.find((row) => row.name === activeDatabase)
      ?.children || []
  );
  const primaryColumnNameOptions = modifyDataForSelect(
    allTablesData.find((row) => row.name === formSelections.primaryTableName)
      ?.children || []
  );
  const existFromColumnNameOptions = modifyDataForSelect(
    allTablesData.find((row) => row.name === formSelections.existFromTableName)
      ?.children || []
  );
  const existToColumnNameOptions = modifyDataForSelect(
    allTablesData.find((row) => row.name === formSelections.existToTableName)
      ?.children || []
  );
  const condColumnNameOptions = modifyDataForSelect(
    allTablesData.find((row) => row.name === formSelections.condTableName)
      ?.children || []
  );

  // # DEBUGGER
  // console.log("formSelections", formSelections);
  // console.log("allTablesOptions", allTablesOptions);
  // console.log("primaryColumnNameOptions", primaryColumnNameOptions);

  // # JSX
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={2} className={classes.rightMiddle}>
          <Typography>Check if all records exist in</Typography>
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <CommonSelect
            formKey="primaryTableName"
            value={formSelections["primaryTableName"]}
            options={allTablesOptions}
            onChange={handleChange}
            disabled={false}
            placeholder="Select table"
          />
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <CommonSelect
            formKey={"primaryColumnName"}
            value={formSelections["primaryColumnName"]}
            options={primaryColumnNameOptions}
            onChange={handleChange}
            disabled={formSelections["primaryTableName"] === ""}
            placeholder="Select column"
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={2} className={classes.rightMiddle}>
          <Typography>Provided that</Typography>
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <CommonSelect
            formKey="existFromTableName"
            value={formSelections["existFromTableName"]}
            options={allTablesOptions}
            onChange={handleChange}
            disabled={false}
            placeholder="Select table"
          />
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <CommonSelect
            formKey={"existFromColumnName"}
            value={formSelections["existFromColumnName"]}
            options={existFromColumnNameOptions}
            onChange={handleChange}
            disabled={formSelections["existFromTableName"] === ""}
            placeholder="Select column"
          />
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <Typography>should exist in</Typography>
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <CommonSelect
            formKey="existToTableName"
            value={formSelections["existToTableName"]}
            options={allTablesOptions}
            onChange={handleChange}
            disabled={false}
            placeholder="Select table"
          />
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <CommonSelect
            formKey={"existToColumnName"}
            value={formSelections["existToColumnName"]}
            options={existToColumnNameOptions}
            onChange={handleChange}
            disabled={formSelections["existToTableName"] === ""}
            placeholder="Select column"
          />
        </Grid>
        <Grid item xs={2} className={classes.rightMiddle}>
          <Typography>And</Typography>
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <CommonSelect
            formKey="condTableName"
            value={formSelections["condTableName"]}
            options={allTablesOptions}
            onChange={handleChange}
            disabled={false}
            placeholder="Select table"
          />
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <CommonSelect
            formKey={"condColumnName"}
            value={formSelections["condColumnName"]}
            options={condColumnNameOptions}
            onChange={handleChange}
            disabled={formSelections["condTableName"] === ""}
            placeholder="Select column"
          />
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <CommonSelect
            formKey={"condition"}
            value={formSelections["condition"]}
            options={config.hardCoded.conditionOptions}
            onChange={handleChange}
            disabled={false}
            placeholder="Select condition"
          />
        </Grid>
        <Grid item xs={2} className={classes.centerMiddle}>
          <TextField
            value={formSelections["conditionString"]}
            placeholder="Enter value"
            onChange={(e) => handleChange("conditionString", e.target.value)}
          />
        </Grid>
      </Grid>
    </div>
  );
}

// # EXPORTS
export default CustomIsExistingCondAdvanced;
