// Import required libraries

import "./Sidenav.scss";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {
  toggleSidenav,
  updateAlertInfo,
  updateDialogInfo,
} from "../../redux/actions";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiAlert from "@material-ui/lab/Alert";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import config from "../../config/config";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import sidebarAppData from "../../../assets/data/sidebarApp.json";

// Import custom components

// Import action creators

// Import data & utils

// Import styles

const useStyles = makeStyles(() => ({
  list: {
    width: 500,
  },
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    minWidth: 400,
    maxWidth: 600,
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

const alertTheme = createTheme({
  palette: {
    success: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
    info: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
    error: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
  },
  "& .MuiAlert-filledSuccess": {
    color: "#46596a",
    //font-weight: 1000,
    backgroundColor: "#f0f2f4",
    width: "800px",
  },
});

function Alert(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={alertTheme}>
      <MuiAlert
        className={classes.typographyStyle}
        elevation={6}
        variant="filled"
        {...props}
      />
    </ThemeProvider>
  );
}

function Sidenav(props) {
  const { user, toggleSidenav, updateAlertInfo, updateDialogInfo } = props;
  const classes = useStyles();

  const handleMenuClose = () => {
    toggleSidenav();
  };

  const handleDialogClose = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const handleClose = () => {
    updateAlertInfo({ ...user.alertInfo, open: false });
  };

  return (
    <>
      <aside
        className={`light ${
          user.screen.isSidenavBarExpanded
            ? "sidenav-expanded"
            : "sidenav-collapsed"
        }`}
      >
        <nav>
          <div>
            <div
              className="nav-item-menu disabled-style"
              onClick={handleMenuClose}
            >
              <i className={`material-icons-outlined nav-icon-menu`}>reorder</i>
              <p
                className={`${
                  user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                } nav-text-menu`}
              >
                Menu
              </p>
            </div>
            {sidebarAppData.map((obj) => {
              return (
                <NavLink
                  to={`/home/${obj.name}`}
                  className={`nav-item disabled-style`}
                  key={obj.key}
                  title={obj.label}
                >
                  <i className={`${obj.materialUiClassName} nav-icon`}>
                    {obj.materialUiIconName}
                  </i>
                  <p
                    className={`${
                      user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                    } nav-text`}
                  >
                    {obj.label}
                  </p>
                </NavLink>
              );
            })}
          </div>
        </nav>
        <div className="sidenav-bottom-container">
          <nav className="admin-panel-container">
            <div>
              <NavLink
                to={`/home/globalRules`}
                className={`nav-item disabled-style `}
                title={"globalRules"}
              >
                <i className={`material-icons-outlined nav-icon`}>article</i>
                <p
                  className={`${
                    user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                  } nav-text`}
                >
                  Global Rules
                </p>
              </NavLink>
            </div>
            <div>
              <NavLink
                to={`/home/adminpanel`}
                className={`nav-item disabled-style `}
                title={"admin-panel"}
              >
                <i className={`material-icons-outlined nav-icon`}>person</i>
                <p
                  className={`${
                    user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                  } nav-text`}
                >
                  Admin Panel
                </p>
              </NavLink>
            </div>
          </nav>
        </div>
      </aside>
      <ThemeProvider theme={alertTheme}>
        <Snackbar
          open={user.alertInfo.open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={config.autoHideDuration}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            icon={false}
            severity={user.alertInfo.severity}
          >
            {user.alertInfo.message}
          </Alert>
        </Snackbar>
      </ThemeProvider>
      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="simple-dialog-title"
        open={user.dialogInfo.open}
      >
        <div className="dialog-popup-title">{user.dialogInfo.title}</div>
        <div className="dialog-popup-body">{user.dialogInfo.message}</div>
        <div className="dialog-popup-btn">
          <Button
            variant="outlined"
            className={classes.outlined}
            onClick={() => {
              user.dialogInfo.handleNo();
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            className={classes.contained}
            onClick={() => {
              user.dialogInfo.handleYes();
            }}
          >
            Yes
          </Button>
        </div>
      </Dialog>
    </>
  );
}

Sidenav.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  toggleSidenav,
  updateAlertInfo,
  updateDialogInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
