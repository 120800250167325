// Import required libraies

import "./DataDomainTables.scss";

import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import {
  allColumnsData,
  getAllRulesData,
  getColumnsForDatasetTables,
  getDataTableDetails,
  getTableStructure,
  updateTable,
} from "../../../services/apis";
import {
  filterColumnsForSelections,
  getStatusMaster,
} from "../../../utils/utils";
import {
  getCategoryChildrenHierarchyByParams,
  validateParamsWithTableSelections,
} from "./utils";
import {
  updateAlertInfo,
  updateChangeFlagUiRules,
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import ColumnRulesTable from "../../../components/ColumnRulesTable/ColumnRulesTable";
import DQAppContainer from "../../DQAppContainer/DQAppContainer";
import DQButton from "../../../components/Common/DQButton";
import Loader from "../../../components/Loader/Loader";
import allRulesSearchStatic from "../../../../assets/data/allRulesSearch.json";
import config from "../../../config/config";
import dqMaster from "../../../../assets/data/dqMaster.json";
import noDataImage from "../../../../assets/images/undraw_void.svg";
import { unwrapperTablesAddedForDatabase } from "../../../components/RulesSelection/schema";
import useFetchCategoryTreeData from "./hooks/useFetchCategoryTreeData";
import { wrapperTableAddedForDatabase } from "../../../components/RulesSelection/schema";

// Import Custom Component & hooks

// Import utils

// # COMPONENT
const ExploreDatasetsTableColumns = ({ match }) => {
  // # HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.data);
  const userInputs = useSelector((state) => state.userInputs);
  const changeFlag = useSelector((state) => state.changeFlag);
  const orgArray = data.datasetTablesColumns.result;

  if (!userInputs.tableSelections) {
    history.push("/home/exploredatasets");
  }

  // # HANDLERS
  const fetchColumnsData = async () => {
    dispatch(
      updateData("datasetTablesColumns", {
        status: "loading",
        message: "",
        result: [],
      })
    );
    let reqObj = {
      cluster_id: userInputs?.tableSelections?.tables?.cluster_id,
      meta_catalog_id: userInputs?.tableSelections?.tables?.catalog_id,
      layer_id: userInputs?.tableSelections?.dataLayers?.id,
      db_name: userInputs?.tableSelections?.tables?.schema_name,
      table_name: userInputs?.tableSelections?.tables?.table_name,
      dataset_name: userInputs?.tableSelections?.datasets?.name,
    };
    let columns = await getColumnsForDatasetTables(reqObj);
    if (columns && columns?.length > 0) {
      dispatch(
        updateData("datasetTablesColumns", {
          status: "success",
          message: "",
          result: columns,
        })
      );
      dispatch(updateUserInputs("datasetTablesColumns", columns));
    } else {
      dispatch(
        updateData("datasetTablesColumns", {
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        })
      );
    }
  };
  const fetchRulesData = async () => {
    dispatch(
      updateData("allRules", {
        status: "loading",
        message: "",
        result: [],
      })
    );

    let rules = dqMaster;
    // let rules = await getAllRules();
    if (rules && rules?.length > 0) {
      dispatch(
        updateData("allRules", {
          status: "success",
          message: "",
          result: rules,
        })
      );
    } else {
      dispatch(
        updateData("allRules", {
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        })
      );
    }
  };
  // fetch columns and rules and combine the responses
  const fetchAllColumnsData = async () => {
    dispatch(
      updateData("allColumns", {
        status: "loading",
        message: "",
        result: [],
      })
    );

    let reqObj = {
      cluster_id: userInputs?.tableSelections?.tables?.cluster_id,
      layer_id: userInputs?.tableSelections?.dataLayers?.id,
      dataset_name: userInputs?.tableSelections?.datasets?.name,
    };
    let columns = await allColumnsData(reqObj);
    if (columns && columns?.length > 0) {
      dispatch(
        updateData("allColumns", {
          status: "success",
          message: "",
          result: columns,
        })
      );
    } else {
      dispatch(
        updateData("allColumns", {
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        })
      );
    }
  };
  const fetchTableStructure = async () => {
    dispatch(
      updateData("semanticTableStructure", {
        status: "loading",
        message: "",
        result: [],
      })
    );

    let reqObj = {
      cluster_id: userInputs?.tableSelections?.tables?.cluster_id,
      layer_id: userInputs?.tableSelections?.dataLayers?.id,
      dataset_name: userInputs?.tableSelections?.datasets?.name,
      db_name: userInputs?.tableSelections?.tables?.schema_name,
    };
    let columns = await getTableStructure(reqObj);
    if (columns && columns?.length > 0) {
      dispatch(
        updateData("semanticTableStructure", {
          status: "success",
          message: "",
          result: columns,
        })
      );
    } else {
      dispatch(
        updateData("semanticTableStructure", {
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        })
      );
    }
  };
  const fetchAllRulesData = async () => {
    dispatch(
      updateData("allRulesData", {
        status: "loading",
        message: "",
        result: [],
      })
    );
    let allRulesData = await getAllRulesData();
    // ! Add search text on UI
    // TODO : Move to dq_master backend when stable
    allRulesData = allRulesData.map((row) => ({
      ...row,
      searchText:
        allRulesSearchStatic.find((r) => row.name === r.name)?.searchText || "",
    }));
    if (allRulesData && allRulesData?.length > 0) {
      dispatch(
        updateData("allRulesData", {
          status: "success",
          message: "",
          result: allRulesData,
        })
      );
    } else {
      dispatch(
        updateData("allRulesData", {
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        })
      );
    }
  };

  // # SIDE EFFECTS
  useFetchCategoryTreeData();

  // Update tableSelections based on current route
  useEffect(() => {
    const runFlag = data.categoryTreeData.status === "success";
    if (runFlag) {
      // Using a function, get tableSelections from match.params
      const tableSelections = getCategoryChildrenHierarchyByParams(
        data.categoryTreeData.result,
        match.params
      );

      // Update required userInputs based on route
      dispatch(
        updateUserInputs("tableSelections", {
          ...tableSelections,
        })
      );

      const isParamsValidWithTableSelections =
        validateParamsWithTableSelections(match.params, tableSelections);
      if (!isParamsValidWithTableSelections) {
        dispatch(
          updateData("datasetTablesColumns", {
            status: "error",
            result: [],
            message: "No result found! Please try again Later!",
          })
        );
      } else {
        const tables = tableSelections.tables;
        const tablesAddedForDatabase = unwrapperTablesAddedForDatabase([
          tables,
        ]);
        dispatch(
          updateUserInputs("tablesAddedForDatabase", tablesAddedForDatabase)
        );
        dispatch(updateSelections("columnSelected", tables));
        dispatch(updateSelections("columnSelectionPage", true));
      }
    }
    // eslint-disable-next-line
  }, [data.categoryTreeData.status, match.params.table]);

  const fetchTableSelected = async () => {
    if (!userInputs.tableSelections.tables) return;
    let tbs = await getDataTableDetails({
      tableId: userInputs.tableSelections.tables?.id,
    });

    const idx = userInputs.tablesAddedForDatabase.findIndex(
      (t) => t.id === user.selections.columnSelected.id
    );

    let tables = [
      ...userInputs.tablesAddedForDatabase.slice(0, idx),
      tbs,
      ...userInputs.tablesAddedForDatabase.slice(idx + 1),
    ];

    dispatch(
      updateUserInputs(
        "tablesAddedForDatabase",
        unwrapperTablesAddedForDatabase(tables)
      )
    );

    dispatch(
      updateUserInputs("tableSelections", {
        ...userInputs.tableSelections,
        tables: {
          ...userInputs.tableSelections.tables,
          ...tbs,
          _fetch: true,
        },
      })
    );
  };

  // Fetch All Required APIs
  useEffect(() => {
    const runFlag =
      userInputs.tableSelections.tables &&
      userInputs.tableSelections.dataLayers &&
      userInputs.tableSelections.datasets;
    if (runFlag) {
      fetchRulesData();
      fetchColumnsData();
      fetchTableStructure();
      fetchAllRulesData();
      fetchAllColumnsData();
    }

    // eslint-disable-next-line
  }, [userInputs.tableSelections]);

  useEffect(() => {
    if (!userInputs.tableSelections.tables?._fetch) fetchTableSelected();
  }, [userInputs.tableSelections.tables]);

  const setNextTabOrSave = async () => {
    // Show a snackbar on the UI
    dispatch(
      updateAlertInfo({
        open: true,
        message: config.messages.datasetSaveRequestMessage,
        severity: "info",
      })
    );

    try {
      const updateTablePayload = wrapperTableAddedForDatabase({
        user,
        userInputs,
        data,
      });

      let respData = await updateTable(updateTablePayload);
      if (respData && respData.status && respData.status) {
        history.go(-1);
        dispatch(updateChangeFlagUiRules(false));
        dispatch(
          updateAlertInfo({
            open: true,
            message: config.messages.datasetUpdationResponseMessage,
            severity: "success",
          })
        );

        // trigger categoryTreeData reload
        dispatch(
          updateData("categoryTreeData", {
            status: "loading",
            message: "",
            result: [],
          })
        );
      } else {
        dispatch(
          updateAlertInfo({
            open: true,
            message: config.messages.datasetUpdationFailedMessage,
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: config.messages.datasetUpdationFailedMessage,
          severity: "error",
        })
      );

      console.error(
        "error in ExploreDatasetsTableColumns.js > setNextTabOrSave"
      );
      console.log(error);
    }
  };

  // # STATIC VARIABLES
  const status = getStatusMaster([
    data.allRulesData,
    data.datasetTablesColumns,
    data.semanticTableStructure,
    data.allColumns,
  ]);

  const filteredColumnsData = filterColumnsForSelections(
    data.datasetTablesColumns.result,
    user.selections
  );

  // # JSX
  return (
    <DQAppContainer match={match}>
      {status.partialLoading && <Loader />}
      {status.anyError && !status.partialLoading && (
        <div className="no-configs">
          <img src={noDataImage} alt="noDataImage" />
          <p className="no-data">
            <b>{data.datasetTablesColumns.message}</b>
          </p>
        </div>
      )}
      {status.isNoData && !status.partialLoading && (
        <div className="no-configs">
          <img src={noDataImage} alt="noDataImage" />
          <p className="no-data">
            <b>{data.datasetTablesColumns.message}</b>
          </p>
        </div>
      )}
      {status.fullSuccess && !status.isNoData && (
        <>
          <ColumnRulesTable
            data={filteredColumnsData}
            allData={orgArray} // pass for header filter
            allRulesDataStatic={data.allRulesData.result}
            rulesData={data.allRules.result}
            paginationFlag={false}
            isDataDomainPage={true}
            isCreatePage={true}
            currentTable={userInputs?.tableSelections?.tables?.name}
          />
          <Box
            sx={{
              display: "flex",
              position: "fixed",
              bottom: 40,
              right: 0,
              mr: 2,
            }}
          >
            <Link className="disabled-style" to={"/home/exploredatasets"}>
              <DQButton title="Cancel" disabled={false} variant="outlined" />
            </Link>
            <Box sx={{ alignSelf: "center" }}>
              <DQButton
                title={"Update"}
                disabled={!changeFlag.uiRules}
                variant="contained"
                onclick={setNextTabOrSave}
              />
            </Box>
          </Box>
        </>
      )}
    </DQAppContainer>
  );
};

// # EXPORTS
export default ExploreDatasetsTableColumns;
