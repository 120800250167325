import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {
  getAdminPanelAccessManagmentUsersList,
  getAdminPanelTeamsList,
  getReckittUsersList,
  postAdminPanelAccessManagmentUser,
} from "../../../services/apis";

import DQButton from "../../../components/Common/DQButton";
import DQMultiAutoComplete from "../../../components/Common/DQMutliAutocomplete";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import { initialApiData } from "../../../utils/utils";
import orderBy from "lodash/orderBy";

// Import Custom Component

const AlterUserDialog = (props) => {
  const { teamsSelected, onSubmit, userEmail, open, askForClose } = props;

  const [isNew, setIsNew] = React.useState(true);

  const [tagsSelections, setTagsSelections] = useState([]); // selections
  const [tagsData, setTagsData] = useState(initialApiData);
  const autoC = useRef(null);

  useEffect(() => {
    setTagsSelections(teamsSelected);
    if (teamsSelected.length > 0) {
      setIsNew(false);
    } else {
      setIsNew(true);
    }
  }, [teamsSelected, userEmail, open]);

  const handleClose = () => {
    setTagsSelections([]);
    askForClose();
  };

  const handleOnSubmit = () => {
    onSubmit(tagsSelections);
    handleClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      let teamsOptions = await getAdminPanelTeamsList();
      if (teamsOptions && teamsOptions?.length > 0) {
        setTagsData({
          status: "success",
          message: "",
          result: teamsOptions.map((c) => c.name),
        });
      } else {
        setTagsData({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      }
    };

    fetchData();
  }, []);

  const handleTagsChange = (event, newValue) => {
    let newOptions = tagsData.result.filter((x) => newValue.includes(x));

    setTagsSelections(newOptions);
  };

  const deleteValues = (regValue) => {
    let valArr = tagsSelections.filter(
      (singleValue) => singleValue !== regValue
    );
    setTagsSelections(orderBy([...valArr]));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {isNew ? "Add New User" : "Update Selected User"}
          <i className="material-icons-outlined" onClick={handleClose}>
            close
          </i>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <div className="input-field-container">
          <label>User: </label>
          <p style={{ margin: 0 }}>{userEmail}</p>
        </div>
        <div className="input-field-container">
          <label>Select Teams: </label>
          <div className="support-input-search">
            <DQMultiAutoComplete
              value={tagsSelections}
              handleChange={handleTagsChange}
              placeholderText="Enter desirable Teams"
              isDisabled={false}
              autoC={autoC}
              deleteValues={deleteValues}
              optionsData={tagsData.result || []}
              styles={{ height: 70, width: "94%" }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <DQButton onclick={handleClose} title="Cancel" variant="outlined" />
        <DQButton
          onclick={handleOnSubmit}
          title={isNew ? "Add" : "Update"}
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  );
};

AlterUserDialog.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  teamsSelected: PropTypes.array,
  userEmail: PropTypes.string,
};

export default AlterUserDialog;
