import { MenuItem } from "@material-ui/core";
import { styled } from "@material-ui/core";

const StyledMenuItem = styled(
  MenuItem,
  {}
)(({ theme, customProps }) => ({
  "&.MuiButtonBase-root": {
    fontSize: "12px",
  },
  //   '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected': {
  //     backgroundColor: 'blue',
  //   },
  //   textTransform: 'none',
  //   fontSize: '16px',
  //   padding: '0',
  //   '&.Mui-selected': {
  //     color: '#fff',
  //   },
  //   '&.Mui-focusVisible': {
  //     backgroundColor: 'rgba(100, 95, 228, 0.32)',
  //   },
}));

export default StyledMenuItem;
