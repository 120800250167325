// Import required libraies

import "./CompareDatasets.scss";

import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  resetUserInputs,
  updateAlertInfo,
  updateChangeFlagUiRules,
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CompareDatasetsListTable from "./CompareDatasetsListTable/CompareDatasetsListTable";
import DQButton from "../../components/Common/DQButton";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCompareDatasetList } from "../../services/apis";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { orderBy } from "lodash";

// # STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  rightAlign: {
    marginLeft: "auto",
  },
}));

// # COMPONENT
function CompareDatasets(props) {
  // # HOOKS
  const { user, data, updateData, match, updateAlertInfo } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const datasetsObj = data.compareDatasetList.result;
  const [filteredData, setFilteredData] = useState(datasetsObj || []);

  const fetchList = async () => {
    updateData("compareDatasetList", {
      status: "loading",
      result: [],
      message: "",
    });
    let data = await getCompareDatasetList();

    const orderedData = orderBy(
      data,
      [(obj) => moment(obj.run_on, "DD/MM/YYYY hh:mm:ss").toDate()],
      ["desc"]
    );

    if (data && data.length > 0) {
      updateData("compareDatasetList", {
        status: "success",
        result: orderedData,
        // status: "loading",
        // result: [],
        message: "",
      });
    } else {
      updateData("compareDatasetList", {
        status: "error",
        result: [],
        message: "Could not load Users! Please try again later!",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchList();
    };

    fetchData();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div
          style={{
            padding: 30,
            paddingLeft: 0,
            paddingTop: 0,
            display: "flex",
          }}
        >
          <Link
            className="disabled-style"
            onClick={() => {
              dispatch(
                updateUserInputs("compareDataset", {
                  reportName: null,
                  teamName: null,
                  selectedSource: null,
                  selectedDestination: null,
                  selectedSourceDatabase: null,
                  selectedDestinationDatabase: null,
                  selectedSourceTable: null,
                  selectedDestinationTable: null,
                })
              );
              dispatch(
                updateUserInputs("compareDatasetColumns", {
                  fromTableSource: null,
                  fromTableDestination: null,
                  selectedSource: [],
                  selectedDestination: [],
                })
              );
              dispatch(
                updateUserInputs("compareDatasetCriteria", {
                  selectedCriteria: [],
                })
              );
            }}
            to={"/home/comparedatasets/onboarding"}
          >
            <DQButton
              styles={{ padding: 21 }}
              title="Create New Report"
              disabled={false}
              variant="contained"
            />
          </Link>
          <br></br>

          {/*<Link className="disabled-style" to={"/home/mydataset"}>
            <DQButton
              title="Edit Existing Report"
              disabled={false}
              variant="contained"
            />
  </Link> */}
        </div>

        <label className="amt-label">List of Last Runs:</label>

        <CompareDatasetsListTable
          data={data.compareDatasetList.result}
          allData={data.compareDatasetList.result}
          paginationFlag={true}
          loading={data.compareDatasetList.status === "loading"}
          refreshList={fetchList}
        />
      </div>
    </>
  );
}

CompareDatasets.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateUserInputs,
  updateSelections,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareDatasets);
