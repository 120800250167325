//Import required libraies

import AddDataTable from "./AddDataTable/AddDataTable";
import AdminPanel from "../AdminPanel/AdminPanel";
import CompareDatasets from "../CompareDatasets/CompareDatasets";
import DQAppContainer from "../DQAppContainer/DQAppContainer";
import DiagnosticReport from "../../components/DiagnosticReport/DiagnosticReport";
import ExploreDatasetsSearch from "./DataPipelines/ExploreDatasetsSearch";
import MyDataset from "../MyDataset/MyDataset";
import MyDatasets from "./MyDatasets/MyDatasets";
import Onboarding from "../CompareDatasets/Onboarding";
import React from "react";
import { Redirect } from "react-router-dom";
import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";

//Import Custom Component

// # COMPONENT
function DQApp(props) {
  const { match } = props;

  // # STATIC VARIABLES
  const tabMapping = {
    managedatasets: {
      component: <AddDataTable match={match} />,
    },
    exploredatasets: {
      component: <ExploreDatasetsSearch match={match} />,
    },
    mydataset: {
      component: <MyDataset match={match} />,
    },
    comparedatasets: {
      component: <CompareDatasets />,
    },
    diagnosticReport: {
      component: <DiagnosticReport currentScreen={"viewName"} />,
    },
    adminpanel: {
      component: <AdminPanel />,
    },
    onboarding: {
      component: <Onboarding />,
    },
  };

  // # JSX
  return (
    <DQAppContainer match={match}>
      {match.params.tab ? (
        <>
          {tabMapping[match.params.tab] ? (
            tabMapping[match.params.tab].component
          ) : (
            <WorkInProgress />
          )}
        </>
      ) : (
        <Redirect
          to={
            match.url.endsWith("/")
              ? `${match.url}mydataset`
              : `${match.url}/mydataset`
          }
        />
      )}
    </DQAppContainer>
  );
}

// # EXPORTS
export default DQApp;
