//Import required libraies
import React from "react";

import CustomDropdown from "./CustomDropdown";

function CustomDropdownWithText(props) {
  let { id, name, onChange, columnName, record } = props;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CustomDropdown {...props} />
      <input
        className="custom-inputbox"
        type="text"
        value={record?.textValue ? record.textValue : ""}
        placeholder="Enter value"
        onChange={(e) => {
          onChange(id, {
            ...record,
            isChecked: true,
            id,
            name,
            columnName,
            textValue: e.target.value,
          });
        }}
      />
    </div>
  );
}

export default CustomDropdownWithText;
