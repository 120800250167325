// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.models-menu {
  display: flex;
}
.models-menu .models-item {
  padding: 8px 35px 10px 0px;
  text-align: center;
  cursor: pointer;
}
.models-menu .models-item .models-item-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
}
.models-menu .models-item .models-item-div p {
  text-transform: uppercase;
  margin: 0px;
  font-size: 13px;
}
.models-menu .models-item .models-item-div.active {
  background-color: white;
  color: #eb367f !important;
  border-bottom: 3px solid #eb367f;
  font-weight: bolder;
}
.models-menu .models-item .models-item-div.not-active {
  color: #46596a !important;
}`, "",{"version":3,"sources":["webpack://./src/js/components/DatasetTableInformation/ColumnContainer.scss"],"names":[],"mappings":"AACA;EACI,aAAA;AAAJ;AAEI;EACI,0BAAA;EACA,kBAAA;EACA,eAAA;AAAR;AAEQ;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AAAZ;AAEY;EACI,yBAAA;EACA,WAAA;EACA,eAAA;AAAhB;AAGY;EACI,uBAAA;EACA,yBAAA;EACA,gCAAA;EACA,mBAAA;AADhB;AAIY;EACI,yBAAA;AAFhB","sourcesContent":["@import \"../../../styles/sass//NewThemeVariables.scss\";\n.models-menu {\n    display: flex;\n\n    .models-item {\n        padding: 8px 35px 10px 0px;\n        text-align: center;\n        cursor: pointer;\n\n        .models-item-div {\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            padding-bottom: 8px;\n\n            p {\n                text-transform: uppercase;\n                margin: 0px;\n                font-size: 13px;\n            }\n\n            &.active {\n                background-color: white;\n                color: $secondaryColor !important;\n                border-bottom: 3px solid $secondaryColor;\n                font-weight: bolder;\n            }\n\n            &.not-active {\n                color: $tertiaryColor !important;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
