//Import required libraies
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#4eafb3 !important",
    paddingLeft: "0px",
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function CustomCheckbox(props) {
  let {
    id,
    name,
    isChecked,
    onChange,
    columnName,
    columnDatatype,
    uniqueKey,
    showName,
  } = props;

  return (
    <div style={{ width: "fit-content", marginRight: "10px" }}>
      <StyledCheckbox
        disableRipple
        checked={isChecked}
        onChange={() => {
          onChange(id, {
            isChecked: !isChecked,
            id,
            name,
            columnName,
            columnDatatype: columnDatatype,
            value: {},
            uniqueKey: uniqueKey,
          });
        }}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      {!showName && <label className="light-text">{name}</label>}
    </div>
  );
}

export default CustomCheckbox;
