/* eslint-disable indent */

import "./AdminUsersListTable.scss";

import { Box, TableCell, TableHead, TableRow } from "@material-ui/core";

import Filters from "../../../../components/Filters/Filters";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useStyles } from "./style";

const AdminUsersListTableHeader = (props) => {
  const { user, allData, allTeams } = props;
  const classes = useStyles();

  let emailList = [...new Set(allData?.map((itm) => itm.email))];
  let nameList = [...new Set(allData?.map((item) => item.name))];
  let teamsList = [...new Set(allTeams?.map((item) => item.name))];

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <div className="header-with-filter">
            <Box>Email</Box>
            <Filters
              flag={
                user.selections.adminUsersListEmailSearch.value?.length
                  ? true
                  : false
              }
              id="adminUsersListEmailSearch"
              data={emailList}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <div className="header-with-filter">
            <Box>Name</Box>
            <Filters
              flag={
                user.selections.adminUsersListNameSearch.value?.length
                  ? true
                  : false
              }
              id="adminUsersListNameSearch"
              data={nameList}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <div className="header-with-filter">
            <Box>Teams</Box>
            <Filters
              flag={
                user.selections.adminUsersListTeamsSearch.value?.length
                  ? true
                  : false
              }
              id="adminUsersListTeamsSearch"
              data={teamsList}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <Box>Access Granted By</Box>
        </TableCell>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <Box>Access Granted On</Box>
        </TableCell>
        <TableCell
          className={classes.tablecell}
          style={{ padding: "10px", width: 80 }}
        >
          {/**/}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

AdminUsersListTableHeader.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AdminUsersListTableHeader);
