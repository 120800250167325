/* eslint-disable indent */

import { Box, TableCell, TableHead, TableRow } from "@material-ui/core";

import Filters from "../../components/Filters/Filters";
import React from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";

// Import Custom Component

// Import styles

// # COMPONENT
const TableHeader = (props) => {
  // # HOOKS
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.data);

  // # STATIC VARIABLES
  const flag = user.selections.primaryDomain.value?.length ? true : false;
  const primaryDomainDataList = [
    ...new Set(data.primaryDomainData.result?.map((item) => item.name)),
  ];
  const secondaryDomainDataList = [
    ...new Set(data.secondaryDomainData.result?.map((item) => item.name)),
  ];
  const subDomainDataList = [
    ...new Set(data.subDomainData.result?.map((item) => item.name)),
  ];
  const classes = useStyles();

  // # JSX
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <Box style={{ marginLeft: "45px" }}>Datasets</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Aggregated Score</Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

// # EXPORTS
export default TableHeader;
