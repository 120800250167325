// Import required libraies

import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";

import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import DQButton from "../../../components/Common/DQButton";
import DQInput from "../../../components/Common/DQInput";
import DQRadioGroup from "../../../components/Common/DQRadioGroup";
import DQSingleAutocomplete from "../../../components/Common/DQSingleAutocomplete";
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import PropTypes from "prop-types";
import Loader from "../../../components/Loader/Loader";
import LandingLayerTableSelection from "../../../components/CompareTableTableSelection/LandingLayerTableSelection";
import Icon from "../../../components/Icon/Icon";
import running from "../../../../assets/images/checked.png";
import resize from "../../../../assets/images/resize.png";
import error from "../../../../assets/images/close.png";
import restart from "../../../../assets/images/restart.png";

import { initialApiData } from "../../../utils/utils";

import {
  checkReportName,
  getAdminPanelTeamsList,
  getCatalogsDatabase,
  getCatalogsTable,
  getCatalogs,
  getCompareDatasetSource,
  getMyDatasetDetails,
  clusterData,
  fileUpload,
  readBlobData,
} from "../../../services/apis";
import {
  resetUserInputs,
  updateAlertInfo,
  updateChangeFlagUiRules,
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";

// # STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  rightAlign: {
    marginLeft: "auto",
  },
  asterik: {
    color: "#f10000",
    marginLeft: "2px",
  },
  compareDatasetFieldCustom: {
    // padding: "0px 30px",
  },
  paperDialog: {
    minWidth: "90vw",
    minHeight: "50vh",
  },
  runDialog: {
    maxWidth: "700px !important",
  },
  dialogContentRoot: {
    padding: "8px 16px !important",
  },
}));

// # COMPONENT

const sourceConfigurationData = [
  {
    id: "table",
    name: "Source table",
    disabled: false,
  },
  {
    id: "file",
    name: "Upload file",
    disabled: false,
  },
  {
    id: "landingLayer",
    name: "Landing Layer",
    disabled: false,
  },
];
const destinationConfigurationData = [
  {
    id: "table",
    name: "Destination table",
    disabled: false,
  },
  {
    id: "file",
    name: "Upload file",
    disabled: false,
  },
  {
    id: "landingLayer",
    name: "Landing Layer",
    disabled: false,
  },
];
function Onboarding(props) {
  const { user, data, match, userInputs } = props;

  // # HOOKS
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [compareDatasetState, setCompareDatasetState] = useState(
    userInputs.compareDataset
  );

  const [teamsData, setTeamsData] = useState(initialApiData);
  const [clusterApi, setClusterApi] = useState({
    status: "initial",
    message: "",
    result: [],
  });

  const [sourceAndDestinationData, setSourceAndDestinationData] = useState(initialApiData);

  const [tableSourceData, setTableSourceData] = useState(initialApiData);
  const [tableDestinationData, setTableDestinationData] = useState(initialApiData);

  const [databaseSourceData, setDatabaseSourceData] = useState(initialApiData);
  const [databaseDestinationData, setDatabaseDestinationData] = useState(initialApiData);

  const [catalogSourceData, setCatalogSourceData] = useState(initialApiData);
  const [catalogDestinationData, setCatalogDestinationData] = useState(initialApiData);

  const [SubmitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [
    SubmitLandingLayerButtonDisabled,
    setSubmitLandingLayerButtonDisabled,
  ] = useState(true);
  const [landingLayerOpen, setLandingLayerOpen] = useState(false);

  const fetchTeamsData = async () => {
    let teamsOptions = await getAdminPanelTeamsList();
    if (teamsOptions && teamsOptions?.length > 0) {
      setTeamsData({
        status: "success",
        message: "",
        result: teamsOptions.map((c) => c.name),
      });
    } else {
      setTeamsData({
        status: "error",
        message: "No result found! Please try again Later!",
        result: [],
      });
    }
  };

  const fetchSourcesAndDestinationsData = async () => {
    let data = await getCompareDatasetSource();
    if (data && data?.length > 0) {
      setSourceAndDestinationData({
        status: "success",
        message: "",
        result: data.filter((c) => c.status),
      });
    } else {
      setSourceAndDestinationData({
        status: "error",
        message: "No result found! Please try again Later!",
        result: [],
      });
    }
  };

  const fetchTableSourceData = async (cluster, catalog, schemaName, isSource) => {
    let data = await getCatalogsTable({ cluster, catalog: catalog, schema_name: schemaName });
    if (data && data?.length > 0) {
      if (isSource) {
        setTableSourceData({
          status: "success",
          message: "",
          result: data,
        });
      } else {
        setTableDestinationData({
          status: "success",
          message: "",
          result: data,
        });
      }
    } else {
      if (isSource) {
        setTableSourceData({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      } else {
        setTableDestinationData({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      }
    }
  };

  const fetchDatabaseSourceData = async (cluster, catalog, isSource) => {
    let data = await getCatalogsDatabase({ cluster, catalog: catalog });
    if (data && data?.length > 0) {
      if (isSource) {
        setDatabaseSourceData({
          status: "success",
          message: "",
          result: data.map((c) => c.name),
        });
      } else {
        setDatabaseDestinationData({
          status: "success",
          message: "",
          result: data.map((c) => c.name),
        });
      }
    } else {
      if (isSource) {
        setDatabaseSourceData({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      } else {
        setDatabaseDestinationData({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      }
    }
  };

  const fetchCatalogSourceData = async (cluster, isSource) => {
    console.log("fetchCatalogSourceData");
    let data = await getCatalogs({ cluster });
    console.log(data);
    if (data && data?.length > 0) {
      if (isSource) {
        setCatalogSourceData({
          status: "success",
          message: "",
          result: data.map((c) => c.name),
        });
      } else {
        setCatalogDestinationData({
          status: "success",
          message: "",
          result: data.map((c) => c.name),
        });
      }
    } else {
      if (isSource) {
        setCatalogSourceData({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      } else {
        setCatalogDestinationData({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      }
    }
  };

  useEffect(() => {
    fetchTeamsData();
  }, []);

  useEffect(() => {
    fetchSourcesAndDestinationsData(compareDatasetState.teamName);
  }, []);

  useEffect(() => {
    if (compareDatasetState.selectedCluster != null) {
      fetchCatalogSourceData(
        compareDatasetState.selectedCluster.cluster,
        true
      );
      fetchCatalogSourceData(
        compareDatasetState.selectedCluster.cluster,
        false
      );
    }
  }, [compareDatasetState.selectedCluster]);

  useEffect(() => {
    if (compareDatasetState.selectedSourceCatalog != null) {
      fetchDatabaseSourceData(
        compareDatasetState.selectedCluster.cluster,
        compareDatasetState.selectedSourceCatalog,
        true
      );
    }
  }, [compareDatasetState.selectedSourceCatalog]);

  useEffect(() => {
    if (compareDatasetState.selectedDestinationCatalog != null) {
      fetchDatabaseSourceData(
        compareDatasetState.selectedCluster.cluster,
        compareDatasetState.selectedDestinationCatalog,
        false
      );
    }
  }, [compareDatasetState.selectedDestinationCatalog]);

  useEffect(() => {
    if (compareDatasetState.selectedSourceDatabase != null) {
      fetchTableSourceData(
        compareDatasetState.selectedCluster.cluster,
        compareDatasetState.selectedSourceCatalog,
        compareDatasetState.selectedSourceDatabase,
        true
      );
    }
  }, [compareDatasetState.selectedSourceDatabase]);

  useEffect(() => {
    if (compareDatasetState.selectedDestinationDatabase != null) {
      fetchTableSourceData(
        compareDatasetState.selectedCluster.cluster,
        compareDatasetState.selectedDestinationCatalog,
        compareDatasetState.selectedDestinationDatabase,
        false
      );
    }
  }, [compareDatasetState.selectedDestinationDatabase]);

  // Landing Layer useEffect
  useEffect(() => {
    if (userInputs.tableName.length > 0) {
      setSubmitLandingLayerButtonDisabled(false);
    }
  }, [userInputs.tableName]);

  const apiCallForBothSourceAndDestination = async () => {
    let sourceReqBody = new FormData();
    let destinationReqBody = new FormData();
    let sourceReqParams = {};
    let destinationReqParams = {};
    sourceReqBody.append(
      "file",
      compareDatasetState.sourceFile.sourceSelectedFile,
      compareDatasetState.sourceFile.sourceSelectedFileName
    );

    sourceReqParams = {
      cluster_id: compareDatasetState.selectedCluster.cluster_id,
      report_name: compareDatasetState.reportName,
      database: compareDatasetState.selectedSourceDatabase,
    };

    destinationReqBody.append(
      "file",
      compareDatasetState.destinationFile.destinationSelectedFile,
      compareDatasetState.destinationFile.destinationSelectedFileName
    );
    destinationReqParams = {
      cluster_id: compareDatasetState.selectedCluster.cluster_id,
      report_name: compareDatasetState.reportName,
      database: compareDatasetState.selectedDestinationDatabase,
    };
    dispatch(
      updateAlertInfo({
        open: true,
        message: "Please wait while we process your file",
        severity: "info",
      })
    );

    let fileUploadPromise = await Promise.all([
      fileUpload(sourceReqBody, sourceReqParams),
      fileUpload(destinationReqBody, destinationReqParams),
    ]);

    if (
      fileUploadPromise[0] === undefined ||
      fileUploadPromise[1] === undefined
    ) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: "Something went wrong  please try again",
          severity: "info",
        })
      );
      setSubmitButtonDisabled(false);
    } else {
      dispatch(
        updateUserInputs("compareDataset", {
          ...compareDatasetState,
          selectedSourceTable: {
            name: fileUploadPromise[0].table_name,
          },
          selectedSourceDatabase: fileUploadPromise[0].schema_name,
          selectedDestinationTable: {
            name: fileUploadPromise[1].table_name,
          },
          selectedDestinationDatabase: fileUploadPromise[1].schema_name,
        })
      );

      dispatch(
        updateUserInputs("compareDatasetColumns", {
          map: {},
          fromTableDestination: null,
          selectedDestination: [],
          fromTableSource: null,
          selectedSource: [],
        })
      );
      history.push(
        `/home/comparedatasets/onboarding/${compareDatasetState.reportName}/selectColumns`
      );
    }
  };

  const handleSubmit = () => {
    const runFlag =
      isEmpty(compareDatasetState.reportName) ||
      compareDatasetState.sourceFileUpload === "file"
        ? compareDatasetState.sourceFile.sourceSelectedFile === null
          ? true
          : false
        : compareDatasetState.selectedSourceTable == null ||
          compareDatasetState.destinationFileUpload === "file"
        ? compareDatasetState.destinationFile.destinationSelectedFile === null
          ? true
          : false
        : compareDatasetState.selectedDestinationTable == null;

    if (runFlag) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: "All fields is required",
          severity: "info",
        })
      );
    } else {
      let fetchProps = {
        report_name: compareDatasetState.reportName,
      };
      const reportNamePromise = checkReportName(fetchProps);
      reportNamePromise
        .then((responseData) => {
          if (!responseData.status) {
            dispatch(
              updateAlertInfo({
                open: true,
                message: `${responseData.message}`,
                severity: "info",
              })
            );
          } else if (responseData.status) {
            // First check if there is file in source and destination table new flow
            if (
              compareDatasetState.sourceFileUpload === "file" ||
              compareDatasetState.destinationFileUpload === "file"
            ) {
              setSubmitButtonDisabled(true);

              // Check if in both source and destination there is file
              if (
                compareDatasetState.sourceFileUpload === "file" &&
                compareDatasetState.destinationFileUpload === "file"
              ) {
                apiCallForBothSourceAndDestination();
              }
              // If either source and destination has file
              else {
                let reqBody = new FormData();
                let reqParams = {};
                if (compareDatasetState.sourceFileUpload === "file") {
                  reqBody.append(
                    "file",
                    compareDatasetState.sourceFile.sourceSelectedFile,
                    compareDatasetState.sourceFile.sourceSelectedFileName
                  );

                  reqParams = {
                    cluster_id: compareDatasetState.selectedCluster.cluster_id,
                    report_name: compareDatasetState.reportName,
                    // database: compareDatasetState.selectedSourceDatabase,
                  };
                } else {
                  reqBody.append(
                    "file",
                    compareDatasetState.destinationFile.destinationSelectedFile,
                    compareDatasetState.destinationFile
                      .destinationSelectedFileName
                  );
                  reqParams = {
                    cluster_id: compareDatasetState.selectedCluster.cluster_id,
                    report_name: compareDatasetState.reportName,
                    // database: compareDatasetState.selectedDestinationDatabase,
                  };
                }
                dispatch(
                  updateAlertInfo({
                    open: true,
                    message: "Please wait while we process your file",
                    severity: "info",
                  })
                );

                const fileUploadPromise = fileUpload(reqBody, reqParams);
                fileUploadPromise
                  .then((data) => {
                    const selectKey =
                      compareDatasetState.sourceFileUpload === "file"
                        ? "selectedSourceTable"
                        : "selectedDestinationTable";
                    const selectDatabaseKey =
                      compareDatasetState.sourceFileUpload === "file"
                        ? "selectedSourceDatabase"
                        : "selectedDestinationDatabase";
                    dispatch(
                      updateUserInputs("compareDataset", {
                        ...compareDatasetState,
                        [selectKey]: { name: data.table_name },
                        [selectDatabaseKey]: data.schema_name,
                      })
                    );

                    dispatch(
                      updateUserInputs("compareDatasetColumns", {
                        map: {},
                        fromTableDestination: null,
                        selectedDestination: [],
                        fromTableSource: null,
                        selectedSource: [],
                      })
                    );
                    history.push(
                      `/home/comparedatasets/onboarding/${compareDatasetState.reportName}/selectColumns`
                    );
                  })
                  .catch((err) => {
                    dispatch(
                      updateAlertInfo({
                        open: true,
                        message: "Something went worng  please try again",
                        severity: "info",
                      })
                    );
                    setSubmitButtonDisabled(false);
                  });
              }
            }
            // Existing flow
            else {
              dispatch(updateUserInputs("compareDataset", compareDatasetState));
              if (
                compareDatasetState.selectedSourceTable.name !=
                  userInputs.compareDatasetColumns.fromTableSource ||
                compareDatasetState.selectedDestinationTable.name !=
                  userInputs.compareDatasetColumns.fromTableDestination
              )
                dispatch(
                  updateUserInputs("compareDatasetColumns", {
                    map: {},
                    fromTableDestination: null,
                    selectedDestination: [],
                    fromTableSource: null,
                    selectedSource: [],
                  })
                );

              history.push(
                `/home/comparedatasets/onboarding/${compareDatasetState.reportName}/selectColumns`
              );
            }
          }
        })
        .catch(() => {
          dispatch(
            updateAlertInfo({
              open: true,
              message: `Something went wrong`,
              severity: "info",
            })
          );
        });
    }
  };

  const handlefileUpload = (location, event) => {
    if (location === "source") {
      setCompareDatasetState({
        ...compareDatasetState,
        sourceFile: {
          sourceSelectedFile: event.target.files[0],
          sourceSelectedFileName: event.target.files[0].name,
        },
      });
    } else {
      setCompareDatasetState({
        ...compareDatasetState,
        destinationFile: {
          destinationSelectedFile: event.target.files[0],
          destinationSelectedFileName: event.target.files[0].name,
        },
      });
    }
  };

  const handleFileRemove = (location) => {
    if (location === "source") {
      setCompareDatasetState({
        ...compareDatasetState,
        sourceFile: {
          sourceSelectedFile: null,
          sourceSelectedFileName: "",
        },
      });
    } else {
      setCompareDatasetState({
        ...compareDatasetState,
        destinationFile: {
          destinationSelectedFile: null,
          destinationSelectedFileName: "",
        },
      });
    }
  };

  const handleClose = () => {
    setLandingLayerOpen(false);
    if (compareDatasetState.currentLandingLayer === "source") {
      handleConfigurationChange("table", "source");
    } else {
      handleConfigurationChange("table", "destination");
    }
  };

  const handleConfigurationChange = (newValue, location) => {
    if (location === "source") {
      setCompareDatasetState({
        ...compareDatasetState,
        sourceFileUpload: newValue,
        selectedSourceTable: null,
        currentLandingLayer: "source",
        sourceFile: {
          sourceSelectedFile: null,
          sourceSelectedFileName: "",
        },
      });
    } else {
      setCompareDatasetState({
        ...compareDatasetState,
        destinationFileUpload: newValue,
        selectedDestinationTable: null,
        currentLandingLayer: "destination",
        destinationFile: {
          destinationSelectedFile: null,
          destinationSelectedFileName: "",
        },
      });
    }
    if (newValue === "landingLayer") {
      setLandingLayerOpen(true);
    }
  };
  const handleClusterChange = (value) => {
    setClusterApi({ status: "loading", message: "", data: {} });
    setCompareDatasetState({
      ...compareDatasetState,
      selectedCluster: value,
      selectedSourceCatalog: "hive_metastore",
      selectedSourceDatabase: null,
      selectedSourceTable: null,
    });
    setCatalogSourceData(initialApiData);
    setDatabaseSourceData(initialApiData);
    setTableSourceData(initialApiData);
    let clusterDataPromise = clusterData({
      cluster_id: value.cluster_id,
    });
    clusterDataPromise
      .then((responseData) => {
        setClusterApi({ status: "success", message: "", data: responseData });
      })
      .catch((err) => {
        setClusterApi({ status: "error", message: "", data: {} });
      });
  };

  const handleLandingLayerSelection = () => {
    let path = userInputs.tableName[0].path;
    // path = `${path}/${userInputs.tableName[0].metadata.db_name}/${userInputs.tableName[0].metadata.table_name}`;
    dispatch(
      updateAlertInfo({
        open: true,
        message: "Reading file, please wait for some time.",
        severity: "success",
      })
    );
    setSubmitLandingLayerButtonDisabled(true);
    if (compareDatasetState.currentLandingLayer === "source") {
      setCompareDatasetState({
        ...compareDatasetState,
        sourceLandingLayerFile: path,
      });
    } else {
      setCompareDatasetState({
        ...compareDatasetState,
        destinationLandingLayerFile: path,
      });
    }

    let newPath = userInputs.tableName[0].path;
    newPath = newPath.slice(0, newPath.lastIndexOf("/"));

    let reqObj = {
      schema_name: userInputs.tableName[0].metadata.db_name,
      table_name: userInputs.tableName[0].metadata.table_name,
      cluster_id: compareDatasetState.selectedCluster?.cluster_id,
      path: newPath,
    };

    const readBlobPromise = readBlobData(reqObj);
    readBlobPromise
      .then((responseData) => {
        if (responseData.status) {
          setLandingLayerOpen(false);

          // ! For the column api call
          let sourceTableName = userInputs.tableName[0].metadata.table_name;
          // sourceTableName = sourceTableName.slice(
          //   0,
          //   sourceTableName.lastIndexOf(".")
          // );
          compareDatasetState.currentLandingLayer === "source"
            ? setCompareDatasetState((latestState) => {
                return {
                  ...latestState,
                  selectedSourceDatabase:
                    userInputs.tableName[0].metadata.db_name,
                  selectedSourceTable: {
                    id: sourceTableName,
                    isTemporary: false,
                    name: sourceTableName,
                  },
                };
              })
            : setCompareDatasetState((latestState) => {
                return {
                  ...latestState,
                  selectedDestinationDatabase:
                    userInputs.tableName[0].metadata.db_name,
                  selectedDestinationTable: {
                    id: sourceTableName,
                    isTemporary: false,
                    name: sourceTableName,
                  },
                };
              });
        } else {
          setSubmitLandingLayerButtonDisabled(true);
          dispatch(
            updateAlertInfo({
              open: true,
              message: "something went wrong ",
              severity: "success",
            })
          );
        }
      })
      .catch((err) => {
        setSubmitLandingLayerButtonDisabled(true);
        dispatch(
          updateAlertInfo({
            open: true,
            message: err,
            severity: "success",
          })
        );
      });
  };
  let conditionCheck = clusterApi?.data?.state;
  let backgroungColor = "";
  // Color Palet
  switch (conditionCheck) {
    case "PENDING":
      backgroungColor = "#FFA500";
      break;
    case "RUNNING":
      backgroungColor = "#228B22";
      break;
    case "RESTARTING":
      backgroungColor = "#9ACD32";
      break;
    case "RESIZING":
      backgroungColor = "#E0FFFF";
      break;
    case "TERMINATING":
      backgroungColor = "#A52A2A";
      break;
    case "TERMINATED":
      backgroungColor = "#800000";
      break;
    case "ERROR":
      backgroungColor = "#FF0000";
      break;
    case "UNKNOWN":
      backgroungColor = "	#F08080";
      break;

    default:
      backgroungColor = "#FFD700";
      break;
  }
  const disabledClassConfigurationLevel =
    clusterApi?.data?.state === "RUNNING" ? false : true;

  const iconProps = {
    className: "material-icons-outlined",
    iconName: "info",
    style: {
      fontSize: "12px",
      marginLeft: "5px",
    },
    tooltipProps: {
      flag: true,
      title:
        "Select only one file and ensure it is either a CSV or Parquet file to prevent errors.",
    },
  };

  return (
    <LayoutTopSideBottom match={match}>
      <Breadcrumbs match={match} />
      <>
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              alignItems: "center",
              mt: 4,
            }}
          >
            <div className="compare-dataset-div-field-input">
              <label className="metadata-label">
                Report Name <span className={classes.asterik}>*</span>
              </label>

              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DQInput
                  name="reportName"
                  value={compareDatasetState.reportName}
                  placeholder="Report Name"
                  // onClickAway={handleClickAway}
                  handleChange={async (value) => {
                    setCompareDatasetState({
                      ...compareDatasetState,
                      reportName: value,
                    });
                  }}
                />
                <Box style={{ width: "30px" }}></Box>
              </Box>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              alignItems: "center",
              mt: 4,
            }}
          >
            <div className="compare-dataset-div-field-input">
              <label className="metadata-label">
                Cluster <span className={classes.asterik}>*</span>
              </label>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DQSingleAutocomplete
                  value={compareDatasetState.selectedCluster}
                  optionsData={sourceAndDestinationData.result || []}
                  handleChange={(value) => {
                    handleClusterChange(value);
                  }}
                  placeholderText="Select Cluster"
                  isDisabled={false}
                  customOptionLabel={(option) => {
                    return `${option.cluster
                      .replace(/[0-9]/g, "")
                      .toUpperCase()} - ${option.name} - ${option.server}`;
                  }}
                />
                <Box style={{ width: "30px" }}>
                  {clusterApi.status === "loading" && (
                    <Tooltip title={"Loading"}>
                      <Box style={{ paddingLeft: "10px" }}>
                        <Loader />
                      </Box>
                    </Tooltip>
                  )}
                  {clusterApi.status === "success" && (
                    <Box style={{ paddingLeft: "10px", cursor: "pointer" }}>
                      {conditionCheck === "RUNNING" && (
                        <Tooltip title={conditionCheck}>
                         <img style={{ width: "100%", height: "100%" }} src={running} />
                        </Tooltip>
                      )}
                      {conditionCheck === "TERMINATING" ||
                        (conditionCheck === "TERMINATED" && (
                          <Tooltip title={conditionCheck}>
                           <img style={{ width: "100%", height: "100%" }} src={error} />
                          </Tooltip>
                        ))}
                      {!(
                        conditionCheck === "TERMINATING" ||
                        conditionCheck === "TERMINATED" ||
                        conditionCheck === "RUNNING"
                      ) && (
                          <Tooltip title={conditionCheck}>
                           <img style={{ width: "100%", height: "100%" }} src={resize} />
                          </Tooltip>
                      )}
                    </Box>
                  )}
                  {clusterApi.status === "error" && (
                    <Box style={{ paddingLeft: "10px", cursor: "pointer" }}>
                      <Tooltip title={"Error"}>
                       <img style={{ width: "100%", height: "100%" }} src={error} />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Box>
            </div>
          </Box>
          {/* // ! Remove this code for future */}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              alignItems: "center",
              mt: 4,
            }}
          >
            <div className="compare-dataset-div-field-input">
              {clusterApi.status === "loading" && <Loader />}
              {clusterApi.status === "success" && (
                <Box
                  style={{
                    width: "200px",
                    borderRadius: "5px",
                    textAlign: "center",
                    color: "white",
                    fontWeight: "bold",
                    background: backgroungColor,
                  }}
                >
                  {clusterApi.data.state}
                </Box>
              )}
              {clusterApi.status === "error" && <Loader />}
            </div>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              alignItems: "center",
              mt: 4,
            }}
          >
            <div className={classes.compareDatasetFieldCustom}>
              <label
                className="metadata-label"
                style={{
                  opacity: disabledClassConfigurationLevel ? "0.5" : "",
                }}
              >
                Select Source Configuration
                <span className={classes.asterik}>*</span>
              </label>
              <DQRadioGroup
                value={compareDatasetState.sourceFileUpload}
                data={sourceConfigurationData}
                onChange={(newValue) => {
                  handleConfigurationChange(newValue, "source");
                }}
                disabled={disabledClassConfigurationLevel}
              />
            </div>
            <div className={classes.compareDatasetFieldCustom}>
              <label
                className="metadata-label"
                style={{
                  opacity: disabledClassConfigurationLevel ? "0.5" : "",
                }}
              >
                Select Destination Configuration
                <span className={classes.asterik}>*</span>
              </label>
              <DQRadioGroup
                value={compareDatasetState.destinationFileUpload}
                data={destinationConfigurationData}
                onChange={(newValue) => {
                  handleConfigurationChange(newValue, "destination");
                }}
                disabled={disabledClassConfigurationLevel}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              alignItems: "center",
              mt: 4,
            }}
          >
            <div className={classes.compareDatasetFieldCustom}>
              {compareDatasetState.sourceFileUpload === "table" && (
                <>
                  <label className="metadata-label">
                    Source Catalog <span className={classes.asterik}>*</span>
                  </label>
                  <DQSingleAutocomplete
                    value={compareDatasetState.selectedSourceCatalog}
                    optionsData={catalogSourceData.result || []}
                    handleChange={(value) => {
                      setCompareDatasetState({
                        ...compareDatasetState,
                        selectedSourceCatalog: value,
                        selectedSourceDatabase: null,
                        selectedSourceTable: null,
                      });
                      setTableSourceData(initialApiData);
                    }}
                    placeholderText="Select Source Catalog"
                    isDisabled={false}
                    newKey="name"
                  />
                </>
              )}
              {compareDatasetState.sourceFileUpload === "table" && (
                <>
                  <label className="metadata-label">
                    Source Database <span className={classes.asterik}>*</span>
                  </label>
                  <DQSingleAutocomplete
                    value={compareDatasetState.selectedSourceDatabase}
                    optionsData={databaseSourceData.result || []}
                    handleChange={(value) => {
                      setCompareDatasetState({
                        ...compareDatasetState,
                        selectedSourceDatabase: value,
                        selectedSourceTable: null,
                      });
                      setTableSourceData(initialApiData);
                    }}
                    placeholderText="Select Source Database"
                    isDisabled={false}
                    newKey="name"
                  />
                </>
              )}
              {compareDatasetState.sourceFileUpload === "file" && (
                <>
                  <Box>
                    <input
                      type="file"
                      name={
                        compareDatasetState.sourceFile.sourceSelectedFileName
                      }
                      id=""
                      onChange={(e) => handlefileUpload("source", e)}
                      style={{ width: "352px", height: "46px" }}
                    />
                    {/* {compareDatasetState.sourceFile.sourceSelectedFile && (
                    <i
                      className="material-icons-outlined advanced-filters-item-icon af-delete-icon"
                      onClick={() => handleFileRemove("source")}
                    >
                      cancel
                    </i>
                  )} */}
                    <Box
                      style={{
                        fontSize: "11px",
                        fontFamily: "Hind Siliguri",
                        color: "#465A69",
                        fontWeight: "550",
                        opacity: "0.7",
                      }}
                    >
                      <span className={classes.asterik}>*</span>
                      <span>
                        Only csv, excel, parquet and txt file are allowed
                      </span>
                    </Box>
                  </Box>
                </>
              )}
              {compareDatasetState.sourceFileUpload === "landingLayer" && (
                <>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        width: "300px",
                        // height: "46px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "max-content",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        style={{ cursor: "pointer" }}
                        title={compareDatasetState.destinationLandingLayerFile}
                      >
                        <span>
                          {compareDatasetState.sourceLandingLayerFile}
                        </span>
                      </Tooltip>
                    </Box>
                    <span
                      style={{
                        color: "#465a69",
                        fontWeight: "550",
                        cursor: "pointer",
                      }}
                      class="material-symbols-outlined"
                      onClick={() => {
                        handleConfigurationChange("landingLayer", "source");
                      }}
                    >
                      close
                    </span>
                  </Box>
                </>
              )}
            </div>

            <div className={classes.compareDatasetFieldCustom}>
              {compareDatasetState.destinationFileUpload === "table" && (
                <>
                  <label className="metadata-label">
                    Destination Catalog <span className={classes.asterik}>*</span>
                  </label>
                  <DQSingleAutocomplete
                    value={compareDatasetState.selectedDestinationCatalog}
                    optionsData={catalogDestinationData.result || []}
                    handleChange={(value) => {
                      setCompareDatasetState({
                        ...compareDatasetState,
                        selectedDestinationCatalog: value,
                        selectedDestinationDatabase: null,
                        selectedDestinationTable: null,
                      });
                      setTableDestinationData(initialApiData);
                    }}
                    placeholderText="Select Destination Catalog"
                    isDisabled={false}
                    newKey="name"
                  />
                </>
              )}
              {compareDatasetState.destinationFileUpload === "table" && (
                <>
                  <label className="metadata-label">
                    Destination Database
                    <span className={classes.asterik}>*</span>
                  </label>
                  <DQSingleAutocomplete
                    value={compareDatasetState.selectedDestinationDatabase}
                    optionsData={databaseDestinationData.result || []}
                    handleChange={(value) => {
                      setCompareDatasetState({
                        ...compareDatasetState,
                        selectedDestinationDatabase: value,
                        selectedDestinationTable: null,
                      });
                      setTableDestinationData(initialApiData);
                    }}
                    placeholderText="Select Destination Database"
                    isDisabled={false}
                    newKey="name"
                  />
                </>
              )}
              {compareDatasetState.destinationFileUpload === "file" && (
                <Box>
                  <input
                    type="file"
                    name=""
                    id=""
                    onChange={(e) => handlefileUpload("destination", e)}
                    style={{ width: "352px", height: "46px" }}
                  />
                  <Box
                    style={{
                      fontSize: "11px",
                      fontFamily: "Hind Siliguri",
                      color: "#465A69",
                      fontWeight: "550",
                      opacity: "0.7",
                    }}
                  >
                    <span className={classes.asterik}>*</span>
                    <span>
                      Only csv, excel, parquet and txt file are allowed
                    </span>
                  </Box>
                </Box>
              )}

              {compareDatasetState.destinationFileUpload === "landingLayer" && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      width: "352px",
                      height: "46px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip
                      style={{ cursor: "pointer" }}
                      title={compareDatasetState.destinationLandingLayerFile}
                    >
                      <span>
                        {compareDatasetState.destinationLandingLayerFile}
                      </span>
                    </Tooltip>
                  </Box>
                  <span
                    style={{
                      color: "#465a69",
                      fontWeight: "550",
                      cursor: "pointer",
                    }}
                    class="material-symbols-outlined"
                    onClick={() => {
                      handleConfigurationChange("landingLayer", "destination");
                    }}
                  >
                    close
                  </span>
                </Box>
              )}
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              alignItems: "center",
              mt: 4,
            }}
          >
            <div className={classes.compareDatasetFieldCustom}>
              {compareDatasetState.sourceFileUpload === "table" && (
                <>
                  <label className="metadata-label">
                    Source Table <span className={classes.asterik}>*</span>
                  </label>

                  <DQSingleAutocomplete
                    value={compareDatasetState.selectedSourceTable}
                    optionsData={tableSourceData.result || []}
                    handleChange={(value) => {
                      setCompareDatasetState({
                        ...compareDatasetState,
                        selectedSourceTable: value,
                      });
                    }}
                    placeholderText="Select Source Table"
                    isDisabled={false}
                    newKey="name"
                  />
                </>
              )}
              {compareDatasetState.sourceFileUpload === "file" && (
                // Dummy box for the styels not to break
                <Box style={{ width: "352px", height: "46px" }}></Box>
              )}
              {compareDatasetState.sourceFileUpload === "landingLayer" && (
                // Dummy box for the styels not to break
                <Box style={{ width: "352px", height: "46px" }}></Box>
              )}
            </div>
            <div className={classes.compareDatasetFieldCustom}>
              {compareDatasetState.destinationFileUpload === "table" && (
                <>
                  <label className="metadata-label">
                    Destination Table <span className={classes.asterik}>*</span>
                  </label>

                  <DQSingleAutocomplete
                    value={compareDatasetState.selectedDestinationTable}
                    optionsData={tableDestinationData.result || []}
                    handleChange={(value) => {
                      setCompareDatasetState({
                        ...compareDatasetState,
                        selectedDestinationTable: value,
                      });
                    }}
                    placeholderText="Select Destination Table"
                    isDisabled={false}
                    newKey="name"
                  />
                </>
              )}
              {compareDatasetState.destinationFileUpload === "file" && (
                // Dummy box for the styels not to break
                <Box style={{ width: "352px", height: "46px" }}></Box>
              )}
              {compareDatasetState.destinationFileUpload === "landingLayer" && (
                // Dummy box for the styels not to break
                <Box style={{ width: "352px", height: "46px" }}></Box>
              )}
            </div>
          </Box>
        </>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            bottom: 40,
            right: 0,
            mr: 2,
          }}
        >
          <Link className="disabled-style" to={"/home/comparedatasets/"}>
            <DQButton title="Cancel" disabled={false} variant="outlined" />
          </Link>
          <Box className="disabled-style">
            <DQButton
              title="Continue"
              onclick={handleSubmit}
              // disabled={SubmitButtonDisabled}
              disabled={false}
              variant="contained"
            />
          </Box>
        </Box>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={
            (compareDatasetState.sourceFileUpload === "landingLayer" ||
              compareDatasetState.destinationFileUpload === "landingLayer") &&
            landingLayerOpen
          }
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
          classes={{ paper: classes.paperDialog }}
        >
          <DialogContent classes={{ root: classes.dialogContentRoot }}>
            <Box style={{ height: "100%", width: "100%" }}>
              <Box
                style={{
                  height: "10%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    fontSize: "14px",
                    fontFamily: "Hind Siliguri",
                    color: "#465a69",
                    fontWeight: "550",
                  }}
                >
                  <Box style={{ display: "flex" }}>
                    Choose a File
                    <Icon {...iconProps}></Icon>
                  </Box>
                </Box>
                {/* <Box onClick={handleClose}> */}
                <span
                  style={{
                    color: "#465a69",
                    fontWeight: "550",
                    cursor: "pointer",
                  }}
                  class="material-symbols-outlined"
                  onClick={handleClose}
                >
                  close
                </span>
              </Box>

              {/* <DiagnosticReport currentScreen={"reportData"} /> */}
              <Box style={{ height: "calc(100% - 20%)" }}>
                <LandingLayerTableSelection
                  clusterId={compareDatasetState.selectedCluster?.cluster_id}
                />
              </Box>

              <Box style={{ height: "10%" }}></Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box className="disabled-style">
              <DQButton
                title="Cancel"
                onclick={handleClose}
                disabled={false}
                variant="outlined"
              />
              <DQButton
                title="Continue"
                onclick={handleLandingLayerSelection}
                disabled={SubmitLandingLayerButtonDisabled}
                variant="contained"
              />
            </Box>
          </DialogActions>
        </Dialog>
      </>
    </LayoutTopSideBottom>
  );
}

Onboarding.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  userInputs: state.userInputs,
});

export default connect(mapStateToProps)(Onboarding);
