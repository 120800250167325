import React, { useEffect, useRef, useState, useReducer } from "react";
import { Box, DialogActions } from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@material-ui/core/Grid";

import DQButton from "../Common/DQButton";

import ReportFiltersPopup from "./ReportFiltersPopup/ReportFiltersPopup";
import {
  getTableDiagnosticReportFilters,
  syncDRFiltersApi,
  runMyDatasetCondition,
} from "../../services/apis";
import config from "../../config/config";

import {
  updateData,
  updateSelections,
  updateUserInputs,
  updateAlertInfo,
} from "../../redux/actions";
import { useDispatch } from "react-redux";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";
import DQRadioGroup from "../Common/DQRadioGroup";

// Import utils, data

const useStyles = makeStyles((theme) => ({
  root: {
    height: "40vh",
  },
  buttonContainer: {
    height: "calc(100% - 40vh)",
  },
  title: {
    height: "5vh",
    borderBottom: "1px solid #eee9e5",
    display: "flex",
    alignItems: "center",
  },
  typeContainer: {
    height: "35vh",
    overflowY: "auto",
    overflowX: "none",
    paddingLeft: "12px",
  },
  filterContainer: {
    height: "30vh",
    width: "100%",
  },
  metricCriteriaRowContainer: {
    display: "flex",
    alignItems: "center",
    // flexWrap: "wrap",
    width: "100%",
  },
  metricCriteriaRow: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flexDirection: "column",
    marginRight: "10px",
  },
  alertMeInputContainer: {
    display: "flex",
    alignItems: "center",
    // flexWrap: "wrap",
    backgroundColor: "#fff",
    // padding: "13px 11px 0px 11px !important",
    borderRadius: "3px",
    boxSizing: "border-box",
    // border: "1px solid #edf1ff",
    width: "100%",
  },
}));

const runType = [
  {
    id: "filteredRun",
    name: "Filtered Run",
    disabled: false,
  },
  {
    id: "fullRun",
    name: "Full Run",
    disabled: false,
  },
];

const dateRangeData = [
  {
    id: "All",
    name: "All",
    value: "All",
    disabled: true,
  },
  {
    id: "Incremental",
    name: "Incremental",
    value: "Incremental",
    disabled: false,
  },
  {
    id: "1 day",
    name: "1 Day",
    value: "1 day",
    disabled: false,
  },
  {
    id: "1 week",
    name: "1 Week",
    value: "1 week",
    disabled: false,
  },
  {
    id: "1 month",
    name: "1 Month",
    value: "1 month",
    disabled: false,
  },
  {
    id: "1 year",
    name: "1 Year",
    value: "1 year",
    disabled: false,
  },
];

const DiagnosticReportRun = (props) => {
  const { tableId, handleClose = () => {}, userInputs, user } = props;

  // Defining the AlertCriteria reducer
  const [selectionrunType, setSelectionRunType] = useState({
    id: "filteredRun",
    name: "Filtered Run",
    disabled: false,
  });
  // const [runDateId, setRunDateId] = useState("");
  // const [runDateObj, setRunDateObj] = useState({});
  const [updateReportFiltersPopup, setUpdateReportFiltersPopup] =
    useState(false);
  const [apiCallForFilterRun, setApiCallForFilterRun] = useState(false);
  const [dateRangeSelection, setDateRangeSelection] = useState({
    id: "All",
    name: "All",
    value: "All",
    disabled: false,
  });
  const [apiCallSuccessForTable, setApiCallSuccessForTable] = useState({
    status: "loading",
  });

  const ReduxDispatcher = useDispatch();

  // * Define states
  const initilaMount = useRef(true);

  useEffect(() => {
    let reportFiltersPromise = getTableDiagnosticReportFilters({
      id: tableId,
      diagnostic_report_id: 0,
    });
    reportFiltersPromise
      .then((responseData) => {
        if (responseData && Object.keys(responseData).length > 0) {
          let updatedReportFiltersData = responseData.map((obj) => ({
            ...obj,
            key: v4(),
          }));
          ReduxDispatcher(
            updateData("tableDiagnosticReportFilters", {
              status: "success",
              message: "",
              result: updatedReportFiltersData,
            })
          );
          let mapped = updatedReportFiltersData.map((item) => ({
            [item.name]: item.defaultValue
              ? item.defaultValue
              : item.type === "multiSelect"
              ? [{ id: "ALL", name: "ALL" }]
              : "ALL",
          }));
          let newObj = Object.assign({}, ...mapped);
          ReduxDispatcher(updateSelections("DRFilters", newObj));
          ReduxDispatcher(updateUserInputs("initialDRFilters", newObj));
          setApiCallSuccessForTable({ status: "success" });
        } else if (Object.keys(responseData).length === 0) {
          ReduxDispatcher(
            updateData("tableDiagnosticReportFilters", {
              status: "success",
              message: "",
              result: {},
            })
          );
          setApiCallSuccessForTable({ status: "success" });
        } else {
          setApiCallSuccessForTable({ status: "error" });
          ReduxDispatcher(
            updateData("tableDiagnosticReportFilters", {
              status: "error",
              message: config.messages.noRecordFound,
              result: {},
            })
          );
          ReduxDispatcher(
            updateData("tableDiagnosticReportDetails", {
              status: "error",
              message: config.messages.noRecordFound,
              result: [],
            })
          );
        }
      })
      .catch(() => {
        ReduxDispatcher(
          updateData("tableDiagnosticReportDetails", {
            status: "error",
            message: config.messages.noRecordFound,
            result: [],
          })
        );
      });

    return () => {
      ReduxDispatcher(updateSelections("DRFilters", {}));
      ReduxDispatcher(updateUserInputs("initialDRFilters", {}));
    };
  }, []);

  useEffect(() => {
    if (apiCallForFilterRun) {
      let mapped = Object.keys(user.selections.DRFilters)?.map((item) => ({
        [item]: Array.isArray(user.selections.DRFilters[item])
          ? user.selections.DRFilters[item].map((itm) => itm.name)
          : user.selections.DRFilters[item],
      }));
      let filters = Object.assign({}, ...mapped);

      let fetchProps = {
        id: tableId,
        type: "filtered_run",
        filters: {
          ...filters,
        },
        date_range:
          dateRangeSelection.value === "All" ? "" : dateRangeSelection.value,
      };

      const runMyDataSetPromise = runMyDatasetCondition(fetchProps);
      runMyDataSetPromise
        .then((responseData) => {
          setApiCallForFilterRun(false);
          setUpdateReportFiltersPopup(false);
          handleClose();
          ReduxDispatcher(
            updateAlertInfo({
              open: true,
              message:
                "Successfully placed your run request. Please check again after some time. Click on Refresh to get the status.",
              severity: "success",
            })
          );
        })
        .catch(() => {
          setApiCallForFilterRun(false);
          setUpdateReportFiltersPopup(false);
          ReduxDispatcher(
            updateAlertInfo({
              open: true,
              message: "Something went wrong",
              severity: "success",
            })
          );
        });
    }
  }, [user.selections.DRFilters]);

  useEffect(() => {
    if (selectionrunType.id === "filteredRun") {
      ReduxDispatcher(updateUserInputs("openDRFilters", true));
    }
  }, [selectionrunType]);

  const handleCloseDialouge = () => {
    handleClose();
  };
  const handleRun = async () => {
    let fetchProps = {};
    ReduxDispatcher(
      updateAlertInfo({
        open: true,
        message: "Processing your run request",
        severity: "success",
      })
    );
    if (selectionrunType.id === "fullRun") {
      fetchProps = { id: tableId, type: "full_run", filters: {} };
      const runMyDataSetPromise = runMyDatasetCondition(fetchProps);
      runMyDataSetPromise
        .then((responseData) => {
          handleClose();
          ReduxDispatcher(
            updateAlertInfo({
              open: true,
              message:
                "Successfully placed your run request. Please check again after some time. Click on Refresh to get the status.",
              severity: "success",
            })
          );
        })
        .catch(() => {
          ReduxDispatcher(
            updateAlertInfo({
              open: true,
              message: "Something went wrong",
              severity: "success",
            })
          );
        });
    } else {
      setUpdateReportFiltersPopup(true);
      setApiCallForFilterRun(true);
    }
  };

  const handleChangeRunType = (value) => {
    if (value === "filteredRun") {
      ReduxDispatcher(updateUserInputs("openDRFilters", true));
      setSelectionRunType({
        id: "filteredRun",
        name: "Filtered Run",
        disabled: false,
      });
    } else {
      ReduxDispatcher(updateUserInputs("openDRFilters", false));
      setSelectionRunType({
        id: "fullRun",
        name: "Full Run",
        disabled: false,
      });
    }
  };

  const getRightSideFilters = async (itm) => {
    if (!itm) {
      ReduxDispatcher(
        updateData("tableDiagnosticReportFilters", {
          status: "error",
          message: "No Data Found! PLease try to select another table!",
          result: {},
        })
      );
    } else {
      // setRunDateId(itm.id);
      // setRunDateObj(itm);
      ReduxDispatcher(
        updateData("tableDiagnosticReportFilters", {
          status: "loading",
          message: "",
          result: {},
        })
      );
      ReduxDispatcher(
        updateData("tableDiagnosticReportDetails", {
          status: "loading",
          message: "",
          result: [],
        })
      );
      let reportFilters = await getTableDiagnosticReportFilters({
        id: tableId,
        diagnostic_report_id: 0,
      });
      if (reportFilters && Object.keys(reportFilters).length > 0) {
        let updatedReportFiltersData = reportFilters.map((obj) => ({
          ...obj,
          key: v4(),
        }));
        ReduxDispatcher(
          updateData("tableDiagnosticReportFilters", {
            status: "success",
            message: "",
            result: updatedReportFiltersData,
          })
        );
        let mapped = updatedReportFiltersData.map((item) => ({
          [item.name]: item.defaultValue
            ? item.defaultValue
            : item.type === "multiSelect"
            ? [{ id: "ALL", name: "ALL" }]
            : "ALL",
        }));
        let newObj = Object.assign({}, ...mapped);
        ReduxDispatcher(updateSelections("DRFilters", newObj));
        ReduxDispatcher(updateUserInputs("initialDRFilters", newObj));
      } else if (Object.keys(reportFilters).length === 0) {
        ReduxDispatcher(
          updateData("tableDiagnosticReportFilters", {
            status: "success",
            message: "",
            result: {},
          })
        );
      } else {
        ReduxDispatcher(
          updateData("tableDiagnosticReportFilters", {
            status: "error",
            message: config.messages.noRecordFound,
            result: {},
          })
        );
        ReduxDispatcher(
          updateData("tableDiagnosticReportDetails", {
            status: "error",
            message: config.messages.noRecordFound,
            result: [],
          })
        );
      }
    }
  };

  const syncDRFilters = async (runDateObj) => {
    ReduxDispatcher(
      updateData("tableDiagnosticReportFilters", {
        status: "loading",
        message: "",
        result: {},
      })
    );
    let body = {
      id: tableId,
      sync_columns: [],
    };
    let syncResponse = await syncDRFiltersApi(body);
    if (syncResponse && syncResponse.length) {
      getRightSideFilters(runDateObj);
    } else {
      ReduxDispatcher(
        updateData("tableDiagnosticReportFilters", {
          status: "error",
          message: config.messages.noRecordFound,
          result: {},
        })
      );
    }
  };

  const classes = useStyles();
  const reportFiltersPopupOpen =
    userInputs.openDRFilters && selectionrunType.id === "filteredRun";

  return (
    <>
      <Box className={classes.title}>Run Type</Box>
      <Box className={classes.typeContainer}>
        <Box css={{ paddingTop: "8px", paddingBottom: "10px" }}>
          <DQRadioGroup
            style={{ paddingTop: "10px !important" }}
            data={runType}
            onChange={(newValue) => {
              handleChangeRunType(newValue);
            }}
            value={selectionrunType.id}
          />
        </Box>

        {reportFiltersPopupOpen &&
          apiCallSuccessForTable.status === "loading" && (
            <Box className={classes.filterContainer}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  border: "1px solid #FFFFFF",
                  boxSizing: "border-box",
                  padding: "5px",
                  marginBottom: "2%",
                }}
              >
                <Box>
                  <Skeleton variant="rounded" width={210} height={60} />
                </Box>
              </Grid>
            </Box>
          )}
        {reportFiltersPopupOpen &&
          apiCallSuccessForTable.status === "success" && (
            <Box className={classes.filterContainer}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  border: "1px solid #FFFFFF",
                  boxSizing: "border-box",
                  padding: "5px",
                  marginBottom: "2%",
                }}
              >
                <ReportFiltersPopup
                  runDateObj={tableId}
                  syncDRFilters={syncDRFilters}
                  updateReportFiltersPopup={updateReportFiltersPopup}
                  filterOpen="filteredRun"
                  dateRangeData={dateRangeData}
                  dateRangeSelection={dateRangeSelection}
                  setDateRangeSelection={setDateRangeSelection}
                />
              </Grid>
            </Box>
          )}
      </Box>

      <DialogActions className={classes.buttonContainer}>
        <DQButton
          onclick={handleCloseDialouge}
          title="Close"
          variant="contained"
        />
        <DQButton onclick={handleRun} title="Run" variant="contained" />
      </DialogActions>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateData,
  updateUserInputs,
  updateSelections,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiagnosticReportRun);
