import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCategoryTreeData } from "../../../../services/apis";
import { updateData } from "../../../../redux/actions";

const useFetchCategoryTreeData = () => {
  const dispatch = useDispatch();
  const reloadData = useSelector((state) => state.changeFlag.reloadData);
  const data = useSelector((state) => state.data);

  // Update categoryTreeData if the status is not success
  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        updateData("categoryTreeData", {
          status: "loading",
          message: "",
          result: [],
        })
      );
      let categoryTreeData = await getCategoryTreeData();
      if (categoryTreeData && categoryTreeData.length > 0) {
        dispatch(
          updateData("categoryTreeData", {
            status: "success",
            message: "",
            result: categoryTreeData,
          })
        );
      } else {
        dispatch(
          updateData("categoryTreeData", {
            status: "error",
            message: "No result found! Please try again Later!",
            result: [],
          })
        );
      }
    };

    const runFlag = data.categoryTreeData.status === "loading";
    if (runFlag) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [data.categoryTreeData.status, reloadData]);
};

export default useFetchCategoryTreeData;
