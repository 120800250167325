// * Import 3rd party
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core";

const StyledTypography = styled(
  Typography,
  {}
)(({ theme, customProps }) => ({
  fontSize: "14px",
  color: "#46596a",
}));

function CustomTypography(props) {
  const { dataElem } = props;

  return <StyledTypography>{dataElem.value}</StyledTypography>;
}

export default CustomTypography;
