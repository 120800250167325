//Import required libraies
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import CustomCheckbox from "./CustomCheckbox";

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "200px",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
      borderRadius: "4px",
    },
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "4px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function CustomDropdown(props) {
  let { id, name, onChange, columnName, record, ruleElement, placeholder } =
    props;
  const classes = useStyles();

  return (
    <div style={{ display: "flex" }}>
      <CustomCheckbox {...props} />
      {ruleElement?.mandatory.map((elem) => {
        return (
          <Select
            key={elem.id}
            value={record?.[elem.id] ? record?.[elem.id] : ""}
            onChange={(e) => {
              onChange(id, {
                ...record,
                isChecked: true,
                id,
                name,
                columnName,
                [elem.id]: e.target.value,
              });
            }}
            displayEmpty
            renderValue={(selected) => {
              if (selected === "") {
                return placeholder || <p>Select option</p>;
              }
              return selected;
            }}
            className={classes.multiSelect}
            MenuProps={MenuProps}
            title={record?.[elem.id] ? record?.[elem.id] : ""}
          >
            {elem?.options?.length ? (
              elem.options.map((obj, index) => (
                <MenuItem
                  key={index}
                  value={obj}
                  className={classes.singleMenuItem}
                  title={obj}
                >
                  {obj}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled className={classes.singleMenuItem}>
                No Options
              </MenuItem>
            )}
          </Select>
        );
      })}
    </div>
  );
}

export default CustomDropdown;
