import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import RbLogo from "../RBLogo/RbLogo";
import UserInfo from "../UserInfo/UserInfo";

import "./Header.scss";

function Header(props) {
  const { user, match } = props;
  return (
    <header className={user.theme}>
      <RbLogo match={match} />
      <section className="header-menu">
        <UserInfo {...props} />
      </section>
    </header>
  );
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Header);
