//Import required libraies
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "200px",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
      borderRadius: "4px",
    },
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  listItem: {
    "& span.MuiTypography-body1": {
      fontSize: "12px",
      color: "#46596a",
      fontFamily: "Hind Siliguri",
      paddingRight: "5px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "4px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function CustomMultiSelect(props) {
  let {
    onChange,
    id,
    columnName,
    columnDatatype,
    dataElem,
    name,
    optionsData,
    uniqueKey,
    valueObj,
    placeholder,
  } = props;
  const classes = useStyles();
  return (
    <>
      {optionsData?.length ? (
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={valueObj?.column || []}
          placeholder="please select values"
          onChange={(e) => {
            onChange(id, {
              uniqueKey,
              groupdId: -1,
              isChecked: true,
              id,
              name,
              columnName,
              columnDatatype: columnDatatype,
              value: { ...valueObj, [dataElem.name]: e.target.value },
            });
            e.stopPropagation();
          }}
          displayEmpty
          className={classes.multiSelect}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return placeholder || <p>Select option</p>;
            }
            return selected.join(", ");
          }}
          input={<Input />}
          MenuProps={MenuProps}
          title={valueObj?.column}
        >
          {optionsData?.map((item) => (
            <MenuItem
              key={item.name}
              value={item.name}
              className={classes.menuItem}
              title={item.name}
            >
              <StyledCheckbox
                checked={valueObj?.[dataElem.name]?.indexOf(item.name) > -1}
              />
              <ListItemText primary={item.name} className={classes.listItem} />
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          value="No Options Available"
          renderValue={(selected) => {
            return selected;
          }}
          disabled
          className={classes.multiSelect}
        >
          <MenuItem disabled className={classes.menuItem}>
            No Options
          </MenuItem>
        </Select>
      )}
    </>
  );
}

export default CustomMultiSelect;
