// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metadata-label {
  font-size: 14px;
  font-family: "Hind Siliguri";
  color: #465A69;
  font-weight: 550;
}

.supported-datatypes {
  flex: 1 1;
}

.columns-list-table {
  max-height: 40vh;
  overflow: scroll;
}

.rule-details-container {
  margin-top: 10px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/js/screens/GlobalRulesManager/GlobalRuleDetails/GlobalRuleDetails.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,4BAAA;EACA,cAAA;EACA,gBAAA;AACJ;;AAEA;EACI,SAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,2BAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":[".metadata-label {\n    font-size: 14px;\n    font-family: \"Hind Siliguri\";\n    color: #465A69;\n    font-weight: 550;\n}\n\n.supported-datatypes  {\n    flex: 1;\n}\n\n.columns-list-table {\n    max-height: 40vh;\n    overflow: scroll;\n}\n\n.rule-details-container {\n    margin-top: 10px;\n    height: calc(100vh - 200px);\n    overflow-y: auto;\n    overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
