//Import required libraies

import React, { useState } from "react";

import { Box } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "../../../kit/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { v4 } from "uuid";

function FilterRun(props) {
  let {
    id,
    onChange = () => {},
    record,
    placeholder,
    title,
    columns = [],
  } = props;
  const initialState =
    record?.length && Array.isArray(record)
      ? [...record]
      : [
          {
            condition: "",
            columnName: "",
            operator: "",
            inputValue: "",
            id: v4(),
          },
        ];
  const [localState, setLocalState] = useState(initialState);

  const [semanticColumnData, setSemanticColumnData] = useState(columns);
  const [error, setError] = useState("");
  const classes = useStyles();

  useEffect(() => {
    setSemanticColumnData(columns);
  }, [columns]);

  const data = [
    { id: "OR", name: "OR" },
    { id: "AND", name: "AND" },
  ];

  let semanticConditionData = [
    {
      id: "greater_than",
      name: "Greater than",
    },
    {
      id: "less_than",
      name: "Less than",
    },
    {
      id: "greater_than_equal",
      name: "Greater than equal to",
    },
    {
      id: "less_than_equal",
      name: "Less than equal to",
    },
    {
      id: "equal_to",
      name: "Equal to",
    },
    {
      id: "not_equal",
      name: "Not equal to",
    },
    {
      id: "in",
      name: "In",
    },
    {
      id: "not_in",
      name: "Not in",
    },
  ];

  // Add empty test row
  const addNewRow = () => {
    const newData = [
      ...localState,
      {
        condition: "",
        columnName: "",
        operator: "OR",
        inputValue: "",
        id: v4(),
      },
    ];
    setLocalState(newData);
    setError("");
  };

  const handleDelete = (id, index) => {
    if (index)
      if (localState.length > 1) {
        let newData = localState.filter((item) => item.id !== id);
        setLocalState(newData);
        setError("");
      } else {
        setError("Cannot delete last selection");
      }
  };

  const getConditionName = (elem) => {
    if (elem?.condition) {
      return semanticConditionData.find((c) => c.id == elem?.condition).name;
    } else {
      return "";
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          marginBottom: "10px",
          flexDirection: "column",
          border: "1px solid #dedede",
          borderRadius: 4,
          padding: 10,
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            fontWeight: 600,
            textTransform: "uppercase",
            color: "#4eafb3",
            marginBottom: 0,
          }}
        >
          {title}
        </Box>

        <div>
          {localState.map((elem, index) => {
            const handleChange = (key, value) => {
              const newData = localState.map((item) =>
                item.id !== elem.id
                  ? item
                  : {
                      ...item,
                      [key]: value,
                    }
              );
              setLocalState([...newData]);
              onChange([...newData]);
            };
            return (
              <div
                key={elem.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {elem.operator && (
                    <Select
                      value={elem.operator}
                      onChange={(e) => {
                        handleChange("operator", e.target.value);
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === "") {
                          return placeholder || <p>Select option</p>;
                        }
                        return selected;
                      }}
                      className={classes.multiSelect}
                      style={{
                        width: "60px",
                        marginRight: "10px",
                      }}
                      MenuProps={MenuProps}
                      title={elem.operator}
                    >
                      {data.length ? (
                        data.map((obj) => (
                          <MenuItem
                            key={obj.id}
                            value={obj.id}
                            className={classes.singleMenuItem}
                            title={obj.name}
                          >
                            {obj.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          value=""
                          disabled
                          className={classes.singleMenuItem}
                        >
                          No Options
                        </MenuItem>
                      )}
                    </Select>
                  )}
                  {index === 0 && (
                    <div
                      style={{
                        width: "60px",
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                        fontSize: "14px",
                      }}
                    >
                      <label></label>
                    </div>
                  )}
                  <Select
                    value={elem?.columnName || ""}
                    onChange={(e) => {
                      handleChange("columnName", e.target.value);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <p>Select column</p>;
                      }
                      return selected;
                    }}
                    className={classes.multiSelect}
                    style={{ margin: "0px", width: "140px" }}
                    MenuProps={MenuProps}
                    title={elem?.columnName || ""}
                  >
                    {semanticColumnData.length ? (
                      semanticColumnData.map((obj) => {
                        return (
                          <MenuItem
                            key={v4()}
                            value={obj.name}
                            className={classes.singleMenuItem}
                            title={obj.name}
                          >
                            {obj.name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        value=""
                        disabled
                        className={classes.singleMenuItem}
                      >
                        No Options
                      </MenuItem>
                    )}
                  </Select>
                  <p className="light-text" style={{ margin: "0px 5px" }}>
                    .
                  </p>
                  <Select
                    value={getConditionName(elem)}
                    onChange={(e) => {
                      handleChange("condition", e.target.value);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <p>Select condition</p>;
                      }
                      return selected;
                    }}
                    className={classes.multiSelect}
                    style={{ margin: "0px", width: "140px" }}
                    MenuProps={MenuProps}
                    title={elem?.condition || ""}
                  >
                    {semanticConditionData.length ? (
                      semanticConditionData.map((obj) => (
                        <MenuItem
                          key={v4()}
                          value={obj.id}
                          className={classes.singleMenuItem}
                          title={obj.name}
                        >
                          {obj.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        value=""
                        disabled
                        className={classes.singleMenuItem}
                      >
                        No Options
                      </MenuItem>
                    )}
                  </Select>
                  <p className="light-text" style={{ margin: "0px 5px" }}>
                    .
                  </p>
                  <TextField
                    id="standard-required"
                    type="any"
                    value={elem?.inputValue || ""}
                    onChange={(e) => {
                      handleChange("inputValue", e.target.value);
                    }}
                    placeholder="Enter value"
                  />
                  {index !== 0 ? (
                    <p
                      style={{
                        width: 30,
                        fontSize: "14px",
                        padding: "0px 20px 0px 0px",
                        color: "#4eafb3",
                        cursor: "pointer",
                        margin: "0px 0px 0px 10px",
                      }}
                      onClick={() => handleDelete(elem.id, index)}
                    >
                      Delete
                    </p>
                  ) : (
                    <Box sx={{ width: 60 }}></Box>
                  )}
                  {index === localState.length - 1 && (
                    <p
                      style={{
                        fontSize: "14px",
                        padding: "0px 20px 0px 0px",
                        color: "#4eafb3",
                        cursor: "pointer",
                        margin: "0px 0px 0px 10px",
                      }}
                      onClick={() => addNewRow()}
                    >
                      Add New Condition
                    </p>
                  )}
                  {error && <p className="error-msg">{error}</p>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default FilterRun;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "200px",
    height: "24px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
      borderRadius: "4px",
    },
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "4px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
});
