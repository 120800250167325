// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.run-chart-container {
  margin-top: 20px;
  overflow-x: auto;
  height: calc(100vh - 280px);
}`, "",{"version":3,"sources":["webpack://./src/js/components/DiagnosticReport/HistoricalRunChart/HistoricalRunChart.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,2BAAA;AACJ","sourcesContent":[".run-chart-container {\n    margin-top: 20px;\n    overflow-x: auto;\n    height: calc(100vh - 280px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
