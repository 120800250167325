// Import required libraries
import React from "react";
import { connect } from "react-redux";
import BarChart from "./BarChart";

import "./HistoricalRunChart.scss";

const HistoricalRunChart = ({ chartData = [] }) => {
  return (
    <div className="run-chart-container">
      <BarChart
        labels={chartData.map((item) => item.date)}
        chartData={chartData}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(HistoricalRunChart));
