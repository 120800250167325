import { createStore } from "redux";
// import { applyMiddleware } from "redux"; // uncomment this to get state console logs
// import logger from "redux-logger"; // uncomment this to get state console logs
import rootReducer from "./reducers/index";
import { stateData } from "./stateData";

const initialState = stateData;

// const store = applyMiddleware(logger)(createStore)(rootReducer, initialState); // uncomment this to get state console logs
const store = createStore(rootReducer, initialState); // comment this to get state console logs

export default store;
