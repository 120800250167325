/* eslint-disable indent */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell, Box } from "@material-ui/core";
import { useStyles } from "./style";

const HistoricalRunTableHeader = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
          <div style={{ display: "flex", width: "30%" }}>
            <Box>Run Status</Box>
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <div style={{ display: "flex", width: "50%" }}>
            <Box>Date</Box>
          </div>
        </TableCell>
        <TableCell className={classes.tablecell} align="center">
          <Box>Score</Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

HistoricalRunTableHeader.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(HistoricalRunTableHeader);
