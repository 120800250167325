//Import required libraies
import React from "react";

//Import Custom Component

//Import Custom Hooks

//Import utils/data

//Import styles

function LayoutNone(props) {
  let { children } = props;
  return <div className={`light page`}>{children}</div>;
}

export default LayoutNone;
