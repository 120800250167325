/*-------------------------Actual APIs-------------------------*/

import { env } from "../utils/env";

const backendApi = env("BACKEND");
const signInUrl = `/auth/sign_in?post_sign_in_url=https%3A%2F%2F`;
const signOutUrl = `/auth/sign_out`;
const testConnectionUrl = `${backendApi}v1/connection/test`;
const catalogsUrl = `${backendApi}v1/catalogs`;
const primaryDomainUrl = `${backendApi}v1/primary_data_domain`;
const secondaryDomainUrl = `${backendApi}v1/secondary_data_domain`;
const subDataDomainUrl = `${backendApi}v1/sub_data_domain`;
const columnsUrl = `${backendApi}v1/table_column`;
const allRulesUrl = `${backendApi}v1/dq_master/`;
const allRulesDownload = `${backendApi}v1/dq_master/download`;
//const allRulesUrl = "https://run.mocky.io/v3/23cfc118-3967-47a1-b206-e1f4ab30f0ba";
//const allRulesDataUrl = `https://run.mocky.io/v3/aacc7af1-8f8f-4360-ad44-e674cbc4dde1`;
const allRulesDataUrl = `${backendApi}v1/dq_master/all`;
const layersUrl = `${backendApi}v1/layer`;
const detailedDatasetUrl = `${backendApi}v1/detailed_dataset`;
const dataLayerUrl = `${backendApi}v1/layer/dataset`;
const authUrl = `${backendApi}v1/users/login`;
const userDetailsUrl = `${backendApi}v1/users/self`;
const categoryUrl = `${backendApi}v1/category`;
const environmentUrl = `${backendApi}v1/server_name`;
const datasetUrl = `${backendApi}v1/data_set`;
const myDatasetUrl = `${backendApi}v1/detailed_dataset`;
const myDatasetColumns = `${backendApi}v1/table/columns`;
const myDatasetCondition = `${backendApi}v1/table/conditions`;
const runMyDatasetCondition = `${backendApi}v1/table/run`;
const checkForDuplicateDataset = `${backendApi}v1/detailed_dataset/check`;
const suggestedRules = `${backendApi}v1/table/suggest_rules`;

const tableUrl = `${backendApi}v1/table`;
const reportUrl = `${backendApi}v1/table`;
const reportHierarchyUrl = `${backendApi}v1/category/tree_structure`;
const getTableStructureUrl = `${backendApi}v1/catalogs/table_structure`;
const allColumnsUrl = `${backendApi}v1/catalogs/all_columns`;
const clusterData = `${backendApi}v1/catalogs/cluster_data`;
const readBlob = `${backendApi}v1/catalogs/read_blob`;
const listDirectory = `${backendApi}v1/catalogs/list_directory`;
const globalRulesUrl = `${backendApi}v1/global_rule`;
const globalRulesDownloadUrl = `${backendApi}v1/table/summary/download`;
const getTableSummary = `${backendApi}v1/table/summary`;
const requestOrManageRuleUrl = `${backendApi}v1/dq_master_request`;
const reportFiltersUrl = `${backendApi}v1/table/diagnostic_report_filters`;
const diagnosticReportDetailsUrl = `${backendApi}v1/table/diagnostic_report`;
const diagnosticReportDownloadUrl = `${backendApi}v1/table/diagnostic_report/download`;
const categoryTreeDataUrl = `${backendApi}v1/category/tree_structure_with_layer`;
const syncFiltersUrl = `${backendApi}v1/table/sync_diagnostic_report_filters`;
const userTeamUrl = `${backendApi}v1/user_team`;
const userTeamReckittUsersUrl = `${backendApi}v1/user_team/users`;
const userTeamInfo = `${backendApi}v1/user_team/info`;
const teamUrl = `${backendApi}v1/team`;
const compareDatasetUrl = `${backendApi}v1/compare_dataset`;
const deletecompareDatasetUrl = `${backendApi}v1/compare_dataset`;
const compareDatasetReportDownloadUrl = `${backendApi}v1/compare_dataset/download`;
const diagnosticReportGetStatusUrl = `${backendApi}v1/table/get_report`;

const messages = {
  loginPrimaryText: "Trinity Quality",
  loginSecondaryText_1: "A Portal to manage all your Data Quality Rules.",
  loginSecondaryText_2: "Log-in to find out more!",
  noAccessMessage: "You don't have access to this app. Please contact support.",
  UnauthorisedMessage: "User does not have access to this Application",
  UnauthorisedMessageUser: "User does not have access to this Module",
  authenticatingUserMessage: "Authenticating user",
  noDatasetsFound: "No datasets found!",
  noViewSchemaFound: "View Schema not found!",
  noColumnsFound: "Column Entered Not found!",
  noTablesFound: "No Tables found!",
  noRecordsFound: "No Records found!",
  confirmDelete: "Are you sure you want to delete?",
  confirmRemove: "Are you sure you want to remove?",
  duplicateTagFoundMessage: "Tag is already added.",
  tableSaveRequestMessage: "Requesting to save the table...",
  datasetSaveRequestMessage: "Requesting to save the dataset...",
  tableCreationResponseMessage: "Table Added successfully.",
  datasetUpdationResponseMessage: "Dataset Saved successfully.",
  datasetUpdationFailedMessage:
    "Dataset updation is unsuccessful! Please try again later!",
  tableCreationFailedMessage:
    "Dataset creation is unsuccessful! Please try again later!",
  noRecordFound: "No Record Found! Please try again Later!",
  errorDownloadReportCSV:
    "Error occurred in downloading report! Please try again later!",
  errorSummaryTableCSV:
    "Error occurred in downloading Summary Table! Please try again later!",
  successDownloadReportCSV: "Diagnostic Report has been downloaded",
  successSummaryTableCSV: "Summary Table has been downloaded",
  requestRuleMessage: "Requesting new rule...",
  errorRequestRuleMessage:
    "New Rule could not be requested!Please try again later!",
  successRequestRuleMessage: "Rule requested successfully.",
  updateGlobalRuleRequestMessage: "Requesting to update the global Rule...",
  successGlobalRuleUpdateMessage: "Global Rule updated successfully!",
  failureGlobalRuleUpdateMessage:
    "Global Rule updation is unsuccessful! Please try again later!",
  ruleApprovalRequestMessage: "Requesting to approve the rule...",
  ruleRejectionRequestMessage: "Requesting to reject the rule...",
  ruleApprovalSuccessMessage: "Rule Approved Successfully!",
  ruleRejectionSuccessMessage: "Rule Rejected Successfully!",
  ruleApprovalFailureMessage:
    "Rule could not be approved! Please try again later!",
  ruleRejectionFailureMessage:
    "Rule could not be rejected! Please try again later!",
  ruleNotAppliedMessage: "Rule not applied to any column!",
  duplicateDatasetInformation:
    " already exists. Please review it in Explore Datasets",
  loadingMessage: "Validating the requesting data",
  waitForDownload: "Download is in progress, please wait for a while...",
};

const hardCoded = {
  appName: "Trinity Quality",
  homeLocation: "/home",
  pageSizes: [10, 15, 20, 30, 50],
  allSelection: "ALL",
  conditionOptions: [
    {
      id: "greater_than",
      name: "Greater than",
    },
    {
      id: "less_than",
      name: "Less than",
    },
    {
      id: "greater_than_equal",
      name: "Greater than equal to",
    },
    {
      id: "less_than_equal",
      name: "Less than equal to",
    },
    {
      id: "equal_to",
      name: "Equal to",
    },
    {
      id: "not_equal",
      name: "Not equal to",
    },
  ],
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 150,
        width: "auto",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    variant: "menu",
    getContentAnchorEl: null,
  },
};

const api = {
  signInUrl,
  signOutUrl,
  testConnectionUrl,
  catalogsUrl,
  primaryDomainUrl,
  secondaryDomainUrl,
  subDataDomainUrl,
  columnsUrl,
  allRulesUrl,
  allRulesDownload,
  allRulesDataUrl,
  layersUrl,
  detailedDatasetUrl,
  dataLayerUrl,
  authUrl,
  userDetailsUrl,
  categoryUrl,
  environmentUrl,
  datasetUrl,
  myDatasetUrl,
  tableUrl,
  reportUrl,
  reportHierarchyUrl,
  getTableStructureUrl,
  allColumnsUrl,
  clusterData,
  readBlob,
  listDirectory,
  globalRulesUrl,
  requestOrManageRuleUrl,
  reportFiltersUrl,
  diagnosticReportDetailsUrl,
  diagnosticReportGetStatusUrl,
  diagnosticReportDownloadUrl,
  checkForDuplicateDataset,
  categoryTreeDataUrl,
  syncFiltersUrl,
  userTeamUrl,
  userTeamReckittUsersUrl,
  userTeamInfo,
  teamUrl,
  compareDatasetUrl,
  deletecompareDatasetUrl,
  compareDatasetReportDownloadUrl,
  myDatasetColumns,
  myDatasetCondition,
  runMyDatasetCondition,
  getTableSummary,
  globalRulesDownloadUrl,
  suggestedRules,
};

const config = {
  footerText: "Powered by RBOne",
  autoHideDuration: 6000,
  configSuccessDeleteMessage: "Record deleted successfully",
  configSuccessRemoveMessage: "Record removed successfully",
  configDeleteRequestMessage: "Requesting to delete the record...",
  configRemoveRequestMessage: "Requesting to remove the record...",
  api,
  messages,
  hardCoded,
};

export default config;
