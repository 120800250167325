//Import required libraies
import React from "react";

//Import Custom Component
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

//Import Custom Hooks

//Import utils/data

//Import styles

function LayoutTopBottom(props) {
  let { children } = props;
  return (
    <div className={`light page`}>
      <Header />
      <section className="body">
        {/* <main className="content main-expanded">{children}</main> */}
        {/* Changed above to This because of the content getting overflow to the page */}
        <main className="content" style={{ width: "100%" }}>
          {children}
        </main>
      </section>
      <Footer />
    </div>
  );
}

export default LayoutTopBottom;
