// Import required libraries
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

// Import custom components
import App from "./js/App";

import theme from "./js/theme";

// Import styles
import "./index.scss";

// Import store
import store from "./js/redux/storeFactory";

window.React = React;
window.store = store;

const msalInstance = new PublicClientApplication(msalConfig);

// console.log("STORE -> ", store.getState());
// console.log("THEME ->", theme);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
