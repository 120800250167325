import "./ReportSideRight.scss";

import React, { useEffect, useRef, useState } from "react";
import {
  downloadReportCSV,
  getDiagnosticReportStatusDetails,
} from "../../../services/apis";
import { updateAlertInfo, updateUserInputs } from "../../../redux/actions";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CustomizedLinearProgressBar from "../utils/CustomizedLinearProgressBar";
import Grid from "@material-ui/core/Grid";
import Loader from "../../Loader/Loader";
import { Parser } from "json2csv";
import ReportFiltersPopup from "../ReportFiltersPopup/ReportFiltersPopup";
import RulesStatusTable from "../RulesStatusTable/RulesStatusTable";
import chooseOption from "../../../../assets/images/undraw_choose.svg";
import config from "../../../config/config";
import { connect } from "react-redux";
import { filterDiagReportDetails } from "../../../utils/utils";
import { makeDiagnosticReportJsonForCsvDownload } from "../diagnosticReportUtils";
import noDataImage from "../../../../assets/images/undraw_void.svg";
import { useDispatch } from "react-redux";
import { useStyles } from "./ReportSideRightStyles";

// styles

const rulesTabValues = [
  {
    label: "IT Validations & Business Rules",
    value: "itBusiness",
  },
  {
    label: "Contextual Rules",
    value: "semantic",
  },
];

function ReportSideRight({
  data = [],
  user,
  updateAlertInfo,
  runDateId,
  userInputs,
  getRightSideFilters,
  runDateObj,
  syncDRFilters,
  updateUserInputs,
  reportDetailsApi,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  let dataResult = data.tableDiagnosticReportDetails.result;
  const [filteredReportData, setFilteredReportData] = useState(
    dataResult.report_details || []
  );
  const [rulesTabvalue, setRulesTabValue] = useState("itBusiness");
  const checkRef = useRef(0);
  const reportLogId = useRef(-1);

  const changeRuleTabValue = (newValue) => {
    setRulesTabValue(newValue);
  };

  const getProgressBarColor = (percent) => {
    const colorObj = {
      terrible: { color: "#FF2442", remarks: "Terrible" },
      bad: { color: "#FF6464", remarks: "Bad" },
      moderate: { color: "#FFbd67", remarks: "Moderate" },
      good: { color: "#96cd39", remarks: "Good" },
      excellent: { color: "#50cd93", remarks: "Excellent" },
    };
    if (percent < 20) {
      return colorObj.terrible;
    }
    if (percent >= 21 && percent <= 40) {
      return colorObj.bad;
    }
    if (percent >= 41 && percent <= 70) {
      return colorObj.moderate;
    }
    if (percent >= 71 && percent <= 90) {
      return colorObj.good;
    }
    if (percent >= 91 && percent <= 100) {
      return colorObj.excellent;
    }
    return colorObj.terrible;
  };

  function downloadURI(uri, name = "") {
    var link = document.createElement("a");
    var isIE = /MSIE|Trident|Edge\//.test(window.navigator.userAgent);

    if (isIE) {
      link.target = "_blank";
    }

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const downloadCSVForReport = async () => {
    try {
      let reqBody = {
        id: userInputs.idForReportSelected,
        diagnostic_report_id: runDateId,
        filters: user.selections.DRFilters,
      };

      updateAlertInfo({
        open: true,
        message: config.messages.waitForDownload,
        severity: "success",
      });

      let reportDetails = await downloadReportCSV(reqBody);
      reportLogId.current = reportDetails.report_log_id;

      const intervalId = setInterval(async () => {
        let reportStatusCheck = await getDiagnosticReportStatusDetails(
          reportLogId.current
        );

        if (reportStatusCheck?.status) {
          downloadURI(reportStatusCheck.result.url);

          updateAlertInfo({
            open: true,
            message: config.messages.successDownloadReportCSV,
            severity: "success",
          });

          clearInterval(checkRef.current);
        }
      }, 5000);

      checkRef.current = intervalId;
    } catch (error) {
      updateAlertInfo({
        open: true,
        message: config.messages.errorDownloadReportCSV,
        severity: "error",
      });
    }
  };

  // const downloadCsvFile = (csvData = "", fileName = "csvFile") => {
  //   var blob = new Blob([csvData]);
  //   const url = window.URL.createObjectURL(blob, {
  //     type: "text/csv",
  //   });
  //   const hiddenElement = document.createElement("a");
  //   hiddenElement.href = url;
  //   hiddenElement.setAttribute("download", `${fileName}.csv`);
  //   document.body.appendChild(hiddenElement);
  //   hiddenElement.click();

  //   // Clean up and remove the hiddenElement
  //   hiddenElement.parentNode.removeChild(hiddenElement);
  // };

  // const downloadExcelFile = (csvData = "", fileName = "excelFile") => {
  //   var blob = new Blob([csvData]);
  //   const url = window.URL.createObjectURL(blob);
  //   const hiddenElement = document.createElement("a");
  //   hiddenElement.href = url;
  //   hiddenElement.setAttribute("download", `${fileName}.xlsx`);
  //   document.body.appendChild(hiddenElement);
  //   hiddenElement.click();

  //   // Clean up and remove the hiddenElement
  //   hiddenElement.parentNode.removeChild(hiddenElement);
  // };

  useEffect(() => {
    const updatedData = filterDiagReportDetails(
      data.tableDiagnosticReportDetails.result.report_details,
      user.selections
    );
    setFilteredReportData(updatedData);
    // eslint-disable-next-line
  }, [user.selections, data.tableDiagnosticReportDetails]);

  const getFilterButtonClasses = () => {
    Object.keys(user.selections.DRFilters).map((key, index) => {
      if (Array.isArray(user.selections.DRFilters[key])) {
        if (
          user.selections.DRFilters[key].filter((e) => e.name !== "ALL")
            .length !== 0
        ) {
          return true;
        }
      } else {
        if (user.selections.DRFilters[key] !== "ALL") {
          return true;
        }
      }
    });
    return false;
  };

  const openCloseFilterPopup = () => {
    updateUserInputs("openDRFilters", !userInputs.openDRFilters);
  };

  return (
    <>
      {userInputs.openDRFilters && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            border: "1px solid #FFFFFF",
            boxSizing: "border-box",
            padding: "5px",
            marginBottom: "2%",
          }}
        >
          <ReportFiltersPopup
            getRightSideFilters={getRightSideFilters}
            runDateObj={runDateObj}
            syncDRFilters={syncDRFilters}
          />
        </Grid>
      )}

      {data.tableDiagnosticReportDetails.status === "loading" && <Loader />}
      {data.tableDiagnosticReportDetails.status === "success" && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              paddingBottom: "2%",
            }}
          >
            <Box
              style={{ flexGrow: 0, flexShrink: 0, flexBasis: "25%" }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <i className={`material-icons-outlined schedule-icon`}>speed</i>
              <div className="run-on-div">
                <label className="right-score-title">
                  <span>Current Score : </span>
                  <span> {dataResult.current_score}%</span>
                </label>
                <CustomizedLinearProgressBar
                  percentage={parseInt(dataResult.current_score, 10)}
                  color={
                    getProgressBarColor(parseInt(dataResult.current_score, 10))
                      .color
                  }
                />
              </div>
            </Box>
            <Box display="flex" alignItems="center">
              <i className={`material-icons-outlined schedule-icon`}>
                schedule
              </i>
              <div className="run-on-div">
                <label className="last-scored-on-title">Last Run On</label>
                <div className="last-scored-on-date">
                  {dataResult.last_score_on}
                </div>
              </div>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  downloadCSVForReport();
                }}
                className={classes.downloadButton}
              >
                <span
                  className="material-icons-outlined"
                  style={{ fontSize: "16px", marginRight: "8px" }}
                >
                  file_download
                </span>
                Download as CSV
              </Button>
            </Box>
            {data.tableDiagnosticReportFilters.status === "loading" && (
              <Loader />
            )}
            {data.tableDiagnosticReportFilters.status === "success" && (
              <div className="filter-btn">
                <button
                  onClick={() => openCloseFilterPopup()}
                  className={
                    getFilterButtonClasses()
                      ? "filter-btn-applied"
                      : "filter-btn-not-applied"
                  }
                >
                  Filters
                  <i className="material-icons-outlined filter-alt-icon">
                    keyboard_double_arrow_down
                  </i>
                </button>
              </div>
            )}
          </Grid>
          <div
            style={{
              background: "antiquewhite",
              padding: "10px",
              width: "100%",
              margin: "10px",
            }}
          >
            <label
              className="right-score-title"
              style={{ justifyContent: "flex-start", paddingBottom: "0px" }}
            >
              {dataResult.filter === "" ? (
                <span>No filter used for DQ execution</span>
              ) : (
                <span>Filter used for DQ execution: {dataResult.filter}</span>
              )}
            </label>
          </div>
          <Grid item xs={12} lg={12} className={classes.rulesTabGrid}>
            {rulesTabValues.map((item) => (
              <Button
                key={item.value}
                className={
                  rulesTabvalue === item.value
                    ? classes.activeBtn
                    : classes.button
                }
                onClick={() => changeRuleTabValue(item.value)}
              >
                <Box style={{ width: "100%", padding: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 0px",
                    }}
                  >
                    <span>{item.label}</span>
                    <span style={{ color: "#465A69" }}>
                      {item.value === "itBusiness"
                        ? dataResult.it_and_business_score
                        : dataResult.semantic_score}
                      {"%"}
                    </span>
                  </div>
                  <CustomizedLinearProgressBar
                    percentage={
                      item.value === "itBusiness"
                        ? parseInt(dataResult.it_and_business_score, 10)
                        : parseInt(dataResult.semantic_score, 10)
                    }
                    color={rulesTabvalue === item.value ? "#4eafb3" : "#415A6C"}
                  />
                </Box>
              </Button>
            ))}
          </Grid>
          <Grid item xs={12} style={{ width: "100%", overflowX: "scroll" }}>
            {rulesTabvalue === "itBusiness" && (
              <RulesStatusTable
                tableData={filteredReportData.filter(
                  (item) =>
                    item.type.toLowerCase() === "it" ||
                    item.type.toLowerCase() === "business"
                )}
                allData={data.tableDiagnosticReportDetails.result?.report_details?.filter(
                  (item) =>
                    item.type.toLowerCase() === "it" ||
                    item.type.toLowerCase() === "business"
                )}
                reportDetailsApi={reportDetailsApi}
              />
            )}
            {rulesTabvalue === "semantic" && (
              <RulesStatusTable
                tableData={filteredReportData.filter(
                  (item) => item.type.toLowerCase() === "semantic"
                )}
                allData={data.tableDiagnosticReportDetails.result?.report_details?.filter(
                  (item) => item.type.toLowerCase() === "semantic"
                )}
                reportDetailsApi={reportDetailsApi}
              />
            )}
          </Grid>
        </Grid>
      )}
      {data.tableDiagnosticReportDetails.status === "error" && (
        <div className="no-rules-data-diag">
          <img src={noDataImage} alt="noDataImage" />
          <p className="no-data">
            <b>{data.tableDiagnosticReportDetails.message}</b>
          </p>
        </div>
      )}
      {data.tableDiagnosticReportDetails.status === "info" && (
        <div className="no-rules-data-diag">
          <img src={chooseOption} alt="No Options Selected" />
          <p className="no-data">
            <b>Please select a table to view the report!</b>
          </p>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInputs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSideRight);
