// * Import 3rd party
import Autocomplete from "@material-ui/lab/Autocomplete";
import { styled } from "@material-ui/core";

const StyledAutoComplete = styled(
  Autocomplete,
  {}
)(({ theme, customProps }) => ({
  // height: "2em !important",
  "& .MuiAutocomplete-endAdornment": {
    top: "calc(50% - 11px)",
  },
  "& .MuiInputLabel-formControl": {
    fontSize: "12px",
    padding: "1px 8px 0px 8px",
    color: "#46596A",
  },
  "& .MuiAutocomplete-popper": {
    background: "red !important",
  },
  "& .MuiAutocomplete-inputRoot": {
    minWidth: "150px",
    width: "max-content",
    height: "unset",
    maxWidth: "600px",
  },
  "& .MuiChip-label": {
    color: "#46596A",
    fontSize: "12px",
  },
  "& .MuiAutocomplete-option": {
    fontSize: "10px",
  },
}));

export default StyledAutoComplete;
