import React, { useState, useRef } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import noChange from "../../../assets/images/change-management.png";
import databaseAdd from "../../../assets/images/database-add.png";
import databaseDelete from "../../../assets/images/database-delete.png";

// * Making styles - Common style of all  Table
export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "0px 22px",
    height: "30px",
    display: "flex",
    alignItems: "center",
  },
  table: {
    width: "100% !important",
  },

  tableMain: {
    // margin: " 50px 0px 20px 20px",
  },
  tableHead: {
    width: "100%",
  },
  tableHeadTitle: {
    // width: `calc(100% - 50px)`,
    boxSizing: "border-box",
  },
  tableHeadIcon: {
    // width: "10px !important",
  },
  tableBody: {
    width: "100% !important",
  },
  tablerow: {
    color: "green",
    padding: "20px",
    backgroundColor: "#b1b1b1 !important",
  },
  tableRowHeader: {
    paddingTop: "500px !important",
  },

  tableCellHeader: {
    // color: "#46596a",
    // backgroundColor: "#eff2f5",
    // borderRightStyle: "solid",
    // borderRightColor: "#dfdfdf",
    // display: "tableRowGroup",
    // fontFamily: "Fira Sans",
    // fontWeight: "500 !important",
    // fontSize: "12px !important",
    // padding: "5px 11px 5px 11px",
    // // maxWidth: "70px !important",
    // // minWidth: "70px !important",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    color: "rgba(70, 89, 106, 1)",
    fontSize: "13px",
    fontFamily: "Hind Siliguri",
    fontWeight: "600",
    borderBottom: "none",
    textTransform: "capitalize",
    backgroundColor: "#E6E8ED",
    textTransform: "capitalize !important",
  },
  totaltableCellHeader: {
    backgroundColor: "#ddeeff !important",
    borderRightStyle: "solid",
    borderRightColor: "#dfdfdf",
    fontFamily: "Fira Sans",
    fontWeight: "400",
    fontSize: "11px !important",
    padding: "5px 11px 5px 11px",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tableCellHeaderRow2: {
    // color: themeColors["secondaryColor"],
    // backgroundColor: themeColors["headerBgColor"],
    borderRightStyle: "solid",
    borderRightColor: "#dfdfdf",
    display: "tableRowGroup",
    fontFamily: "Fira Sans",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    minWidth: "90px", // Date wrap in table CSS
    padding: "3px !important",
    textAlign: "center !important",
  },
  upperTableRow: {
    width: "100% !important",
    backgroundColor: "#eaf3ff",
  },
  innerTableRow: {
    // paddingLeft: "100px",
  },

  sticky: {
    position: "sticky",
    left: 0,
  },

  tableCell: {
    borderRightStyle: "solid",
    borderRightColor: "#dfdfdf",
    fontFamily: "Fira Sans",
    fontSize: "11px !important",
    textAlign: "center",
    padding: "3px 11px 3px 11px",
    whiteSpace: "nowrap",
  },
  tableData: {},
  elementActive: {
    // color: themeColors["primaryColor"],
    cursor: "pointer",
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  elementDisabled: {
    // color: themeColors["secondaryColor"],
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  tableColumns: {
    border: "10px solid white",
    background: "grey",
  },
  totalRow: {
    backgroundColor: "#C4C9CC !important",
  },
  arrowRow: {
    width: "1%",
  },
  upperTableRowName: {
    width: "30%",
    textTransform: "capitalize !important",
  },
  remaningUpperTableRow: {
    width: "calc(100% - 21%)",
  },
  tableRow: {
    width: "100%",
  },
}));

function Row(props) {
  const { row, index, rowData, classes } = props;
  const [open, setOpen] = useState(false);
  const autoC = useRef(null);

  return (
    <>
      <TableRow className={classes.upperTableRow}>
        <TableCell className={classes.arrowRow}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <span class="material-symbols-outlined">keyboard_arrow_up</span>
            ) : (
              <span class="material-symbols-outlined">keyboard_arrow_down</span>
            )}
          </IconButton>
        </TableCell>
        <TableCell className={classes.upperTableRowName}>{row}</TableCell>
        <TableCell style={{ width: "10%" }}>{}</TableCell>
        <TableCell style={{ width: "10%" }}>{}</TableCell>
        <TableCell style={{ width: "29%" }}>{}</TableCell>
        <TableCell style={{ width: "40%" }}>{}</TableCell>
        {/* <TableCell className={classes.upperTableRowName}>{}</TableCell>
        <TableCell className={classes.upperTableRowName}>{}</TableCell>
        <TableCell className={classes.upperTableRowName}>{}</TableCell> */}
      </TableRow>
      {/* <TableRow> */}
      {/* <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingRight: 0,
            paddingLeft: 0,
          }}
          colSpan={6}
        > */}
      {open &&
        // <Table size="small">
        //   <TableBody style={{ paddingLeft: "0px" }}>
        rowData.data[index].columns.map((el) => (
          <TableRow key={el.column_name} className={classes.innerTableRow}>
            <>
              <TableCell style={{ width: "1% !important" }}></TableCell>
              <TableCell style={{ width: "10% !important" }}></TableCell>
              <TableCell
                style={{
                  width: "10%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {el.name}
              </TableCell>
              <TableCell
                style={{
                  width: "10%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {el.datatype}
              </TableCell>
              <TableCell style={{ width: "29%" }}>
                {el.isChanged === "0" && (
                  <img
                    className=""
                    src={noChange}
                    alt={"No Change"}
                    style={{ height: "25px", width: "25px" }}
                  />
                )}
                {el.isChanged === "1" && (
                  <img
                    className=""
                    src={databaseAdd}
                    alt={"Database Add"}
                    style={{ height: "25px", width: "25px" }}
                  />
                )}
                {el.isChanged === "-1" && (
                  <img
                    className=""
                    src={databaseDelete}
                    alt={"Database Delete"}
                    style={{ height: "25px", width: "25px" }}
                  />
                )}
              </TableCell>

              <TableCell style={{ width: "40%" }}>
                {el.isChanged === "0" && <div>No Change</div>}
                {el.isChanged === "1" && <div>Column Added</div>}
                {el.isChanged === "-1" && <div>Column Deleted</div>}
              </TableCell>
            </>
          </TableRow>
        ))}
    </>
  );
}

const SummaryTableSelection = (props) => {
  const { rowData } = props;

  const classes = useStyles();
  let headCells = [
    {
      id: "icon",
      label: "",
      width: "1%",
      disableSorting: "disable",
    },
    {
      id: "icon",
      label: "Synced on",
      width: "10%",
      disableSorting: "disable",
    },
    {
      id: "icon",
      label: "Columns",
      width: "10%",
      disableSorting: "disable",
    },
    {
      id: "icon",
      label: "Data Type",
      width: "10%",
      disableSorting: "disable",
    },
    {
      id: "icon",
      label: "Is Changed",
      width: "29%",
      disableSorting: "disable",
    },
    {
      id: "icon",
      label: "Status",
      width: "40%",
      disableSorting: "disable",
    },
  ];

  return (
    <Table stickyHeader className={classes.table}>
      <TableHead className={classes.tableHead}>
        <TableRow className={classes.tableRow}>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              width={headCell?.width}
              align={headCell?.align}
              className={classes.tableCellHeader}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        {rowData.tableArray.map((row, index) => (
          <Row
            key={row}
            row={row}
            index={index}
            rowData={rowData}
            classes={classes}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default SummaryTableSelection;
