// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataset-name {
  color: #4EAFB3;
  cursor: pointer;
  font-family: "Hind Siliguri" !important;
  font-style: bold;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 0px;
}

.table_name {
  color: #465A69;
  font-family: "Hind Siliguri" !important;
  font-style: bold;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 0px;
}`, "",{"version":3,"sources":["webpack://./src/js/components/DatasetTableInformation/ColumnSelection/ColumnSelection.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,uCAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,cAAA;EACA,uCAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".dataset-name {\n    color: #4EAFB3;\n    cursor: pointer;\n    font-family: 'Hind Siliguri' !important;\n    font-style: bold;\n    font-weight: 700;\n    font-size: 14px;\n    padding: 10px 0px;\n}\n\n.table_name {\n    color: #465A69;\n    font-family: 'Hind Siliguri' !important;\n    font-style: bold;\n    font-weight: 700;\n    font-size: 14px;\n    padding: 10px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
