// Creating axios instance here export the same
import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "secret"
    )}`;
    // config.headers["Content-Type"] = `application/x-www-form-urlencoded`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        window.location.href = "/login";
      }
    }
    return error;
  }
);

export default axios;
