// ### Imports
// * Import lib
import React, { useState } from "react";
import { Box } from "@material-ui/core";

// * Import styles
import "./TextFieldWithIcons.scss";

// # COMPONENT
const TextFieldWithIcons = (props) => {
  // # PROPS
  const { value = "abc", onChange = () => {} } = props;
  // # STATES
  const [localValue, SetLocalValue] = useState(value);
  const [showInput, setShowInput] = useState(false);

  // # HANDLERS
  const handleOnChange = (event) => {
    SetLocalValue(event.target.value);
  };
  const handleSubmit = () => {
    setShowInput(false);
    onChange(localValue);
  };
  const handleCancel = () => {
    setShowInput(false);
    SetLocalValue(value);
  };

  // # JSX
  return (
    <Box className="text-field-with-icons-container">
      {showInput ? (
        <form onSubmit={handleSubmit}>
          <Box className="input-container">
            <input
              className="input"
              value={localValue}
              onChange={handleOnChange}
              onBlur={handleSubmit}
              autoFocus
            />
          </Box>
          <Box className="button-container">
            <i
              className="material-icons-outlined text-field-with-icons-icon"
              onClick={handleSubmit}
            >
              done
            </i>
            <i
              className="material-icons-outlined text-field-with-icons-icon"
              onClick={handleCancel}
            >
              close
            </i>
          </Box>
        </form>
      ) : (
        <Box
          className="column-text-container"
          onClick={() => setShowInput(true)}
        >
          <span>{localValue}</span>
        </Box>
      )}
    </Box>
  );
};

// # EXPORTS
export default TextFieldWithIcons;
