// Import required libraies

import "./MyDataset.scss";

import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  getMyDatasetDetails,
  getTablesList,
  updateTable,
} from "../../services/apis";
import {
  resetUserInputs,
  updateAlertInfo,
  updateChangeFlagUiRules,
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../redux/actions";
import {
  unwrapperTablesAddedForDatabase,
  wrapperTableAddedForDatabase,
} from "../../components/RulesSelection/schema";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Button } from "@material-ui/core";
import DQButton from "../../components/Common/DQButton";
import { DataProfiling } from "../../components/DataProfiling";
import ColumnContainer from "../../components/DatasetTableInformation/ColumnContainer";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../components/Loader/Loader";
import SearchTableSelection from "../../components/SearchTableSelection/SearchTableSelection";
import TableInformation from "../../components/TableInformation/TableInformation";
import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";
import config from "../../config/config";
import { makeStyles } from "@material-ui/core/styles";
import noDataImage from "../../../assets/images/undraw_void.svg";

// Import Custom Component
import DatasetTable from "../../../js/components/DatasetTableInformation/DatasetTable/DatasetTable.js";

// Import data & utils
import { filterMyData } from "../../utils/utils";

// Import actions

// Import Styles

// # STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  rightAlign: {
    marginLeft: "auto",
  },
}));

// # COMPONENT
function MyDataset({ match }) {
  // # HOOKS
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  const userInputs = useSelector((state) => state.userInputs);
  const user = useSelector((state) => state.user);
  const changeFlag = useSelector((state) => state.changeFlag);
  const classes = useStyles();
  const history = useHistory();
  const [dataSetDetails, setDataSetDetails] = useState(
    data.datasetDetails.result
  );
  const [criteriaHierarchy, setCriteriaHierarchy] = useState({
    searchText: "",
  });
  const [runGetTablesList, setRunGetTablesList] = useState(true);

  // # STATIC VARIABLES
  const filteredData = filterMyData(dataSetDetails || [], user.selections);

  useEffect(() => {
    // fetch Table Details
    if (runGetTablesList) {
      const fetchDatasetDetails = async () => {
        dispatch(
          updateData("datasetDetails", {
            status: "loading",
            result: {},
            message: "",
          })
        );
        let myDatasetDetails = await getTablesList();
        if (myDatasetDetails && Object.keys(myDatasetDetails).length > 0) {
          setDataSetDetails(myDatasetDetails);
          setRunGetTablesList(false);
          dispatch(
            updateUserInputs(
              "tablesAddedForDatabase",
              unwrapperTablesAddedForDatabase(myDatasetDetails)
            )
          );
          dispatch(
            updateData("datasetDetails", {
              status: "success",
              result: myDatasetDetails,
              message: "",
            })
          );
        } else {
          setRunGetTablesList(false);
          dispatch(
            updateData("datasetDetails", {
              status: "error",
              result: [],
              message: "Could not load details! Please try again later!",
            })
          );
        }
      };
      fetchDatasetDetails();
    }

    // dispatch(
    //   updateData("datasetDetails", {
    //     status: "loading",
    //     result: [],
    //     message: "",
    //   })
    // );
    dispatch(resetUserInputs());

    // eslint-disable-next-line
  }, [runGetTablesList]);

  // # HANDLERS

  const changeCriteriaHierarchy = (param, value) => {
    criteriaHierarchy[param] = value;
    setCriteriaHierarchy({ ...criteriaHierarchy });
    if (param === "searchText") {
      const filteredDataSetDetails = data.datasetDetails.result.filter((el) =>
        `${el.catalog_name}.${el.schema_name}.${el.table_name}`
          .toLowerCase()
          .includes(value.toLowerCase())
      );
      setDataSetDetails(filteredDataSetDetails);
    }
  };

  const handleRefresh = () => {
    setRunGetTablesList(true);
  };

  // # JSX
  return (
    <>
      {data.datasetDetails.status === "loading" && <Loader />}
      {data.datasetDetails.status === "success" && (
        <>
          <div className={classes.root}>
            {!user.selections.columnSelectionPage && (
              <Box css={{ display: "flex" }}>
                <SearchTableSelection
                  criteria={criteriaHierarchy}
                  changeCriteria={changeCriteriaHierarchy}
                  placeholderText="Search Tables"
                  width={"100%"}
                  className="search-for-table-information"
                  isDisabled={false}
                />
                <Button
                  className={`nav-item disabled-style`}
                  onClick={handleRefresh}
                >
                  <i className={`material-icons-outlined nav-icon`}>
                    {"refresh"}
                  </i>

                  <p
                    className={`${
                      user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                    } nav-text`}
                  >
                    {"Refresh"}
                  </p>
                </Button>
              </Box>
            )}

            {user.selections.columnSelectionPage ? (
              <ColumnContainer
                tableDetails={filteredData}
                datasetDetailsData={dataSetDetails}
              />
            ) : (
              <DatasetTable data={filteredData} paginationFlag={false} />
            )}
          </div>
        </>
      )}
      {data.datasetDetails.status === "error" && (
        <div className="no-tables">
          {" "}
          <img src={noDataImage} alt="noDataImage" />
          <p className="no-data">
            <b>{data.datasetDetails.message}</b>
          </p>
        </div>
      )}
    </>
  );
}

// # EXPORTS
export default React.memo(MyDataset);
