const constants = {
  TOGGLE_SIDENAV: "TOGGLE_SIDENAV",
  UPDATE_DATA: "UPDATE_DATA",
  UPDATE_USER_INPUTS: "UPDATE_USER_INPUTS",
  UPDATE_SELECTIONS: "UPDATE_SELECTIONS",
  RESET_SELECTIONS: "RESET_SELECTIONS",
  RELOAD_DATA: "RELOAD_DATA",
  UPDATE_DIALOG_INFO: "UPDATE_DIALOG_INFO",
  UPDATE_ALERT_INFO: "UPDATE_ALERT_INFO",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  UPDATE_CHANGE_FLAG_UI_RULES: "UPDATE_CHANGE_FLAG_UI_RULES",
  RESET_FILTERS: "RESET_FILTERS",
  RESET_USER_INPUTS: "RESET_USER_INPUTS",
  UPDATE_DR_FILTERS: "UPDATE_DR_FILTERS",

  // RULES IT AND BUSINESS
  UPDATE_RULES_IT_AND_BUSINESS_FORM_FIELD:
    "UPDATE_RULES_IT_AND_BUSINESS_FORM_FIELD",
  REPLACE_IT_AND_BUSINESS_FORM: "REPLACE_IT_AND_BUSINESS_FORM",
};

export default constants;
