import allRulesStatic from "../../assets/data/allRulesSearch.json";
import breadcrumbsMapping from "../../assets/data/BreadcumbsMapping.json";
import groupBy from "lodash.groupby";
import moment from "moment";
import { v4 } from "uuid";

export const initialApiData = {
  status: "loading",
  message: "",
  result: [],
};

export const initialApiDataInfo = {
  status: "info",
  message: "",
  result: [],
};

export const initialApiDataInfoObj = {
  status: "info",
  message: "",
  result: [],
};

// Get current year
export const getCurrentYear = () => moment().year();

export const splitUrlToBreadcrumbData = (url) => {
  const routes = url.replace(/^\//g, "").replace(/\/$/g, "").split("/");
  const data = routes.map((name, index) => ({
    name,
    label: breadcrumbsMapping[name] ? breadcrumbsMapping[name] : name,
    path: routes
      .slice(0, index + 1)
      .reduce((finalPath, route) => `${finalPath + route}/`, "/"),
  }));
  return data;
};

export const getRuleFormattedName = (ruleName) => {
  let ruleFormattedName = allRulesStatic.find(
    (r) => ruleName === r.name
  )?.formattedName;
  return ruleFormattedName ? ruleFormattedName : ruleName;
};

export const formatDate = (date, format = "MMM-DD-YYYY H:mm:ss") => {
  const momentDate = moment(+date * 1000);
  return momentDate.format(format);
};

export const getRoundOfScore = (score) => {
  if (score === "NR") {
    return "";
  } else if (score === "") {
    return score;
  } else if (score?.includes("%")) {
    return Number(score.slice(0, -1));
  } else {
    return Number(score);
  }
};

export const getRatingColor = (value) => {
  let ratingBox = [];
  if (value > 0 && value <= 10) {
    let newValue = value;
    for (let i = 0; i < 10; i++) {
      newValue = value - i;
      if (newValue >= 1) {
        ratingBox.push(100);
      } else if (newValue > 0 && newValue < 1) {
        ratingBox.push(newValue * 100);
      } else {
        ratingBox.push(0);
      }
    }
  } else {
    ratingBox = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  }
  return ratingBox;
};

export const getCircleBarColor = (percent) => {
  const colorObj = {
    terrible: { color: "#FF2442", remarks: "Terrible" },
    bad: { color: "#FF6464", remarks: "Bad" },
    moderate: { color: "#FFbd67", remarks: "Moderate" },
    good: { color: "#96cd39", remarks: "Good" },
    excellent: { color: "#50cd93", remarks: "Excellent" },
  };

  if (percent === 100) {
    return colorObj.good;
  } else if (percent >= 80 && percent < 100) {
    return colorObj.moderate;
  }
  return colorObj.terrible;
};

export const getColorFromPercentage = (percent) => {
  const colorObj = {
    terrible: { color: "#FF2442", remarks: "Terrible" },
    bad: { color: "#FF6464", remarks: "Bad" },
    moderate: { color: "#FFBD67", remarks: "Moderate" },
    good: { color: "#96CD39", remarks: "Good" },
    excellent: { color: "#50CD93", remarks: "Excellent" },
  };
  if (percent < 20) {
    return colorObj.terrible;
  }
  if (percent >= 21 && percent <= 40) {
    return colorObj.bad;
  }
  if (percent >= 41 && percent <= 70) {
    return colorObj.moderate;
  }
  if (percent >= 71 && percent <= 90) {
    return colorObj.good;
  }
  if (percent >= 91 && percent <= 100) {
    return colorObj.excellent;
  }
  return colorObj.terrible;
};

//filter records based on user selections
export const filterMyData = (arr = [], selections) => {
  let filteredArr = arr;
  if (selections.primaryDomain.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.primaryDomain.value.includes(elem.primary_data_domain_name)
    );
  }
  if (selections.secondaryDomain.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.secondaryDomain.value.includes(elem.secondary_data_domain_name)
    );
  }
  if (selections.dataLayer.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.dataLayer.value.includes(elem.layer_name)
    );
  }
  if (selections.useCase.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.useCase.value.includes(elem.use_case)
    );
  }
  if (selections.searchText) {
    filteredArr = filteredArr.filter((elem) => {
      if (elem.name) {
        return elem.name
          ?.toLowerCase()
          .includes(selections.searchText.toLowerCase());
      } else {
        return true;
      }
    });
  }
  return filteredArr;
};

export const filterColumnsForSelections = (arr = [], selections) => {
  let filteredArr = arr;
  if (selections.columnName.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.columnName.value.includes(elem.column_name)
    );
  }
  if (selections.datatype.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.datatype.value.includes(elem.datatype)
    );
  }
  return filteredArr;
};

export const filterColumnsForSelectionsAdminPanel = (arr = [], selections) => {
  let filteredArr = arr;
  if (selections.adminUsersListEmailSearch.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.adminUsersListEmailSearch.value.includes(elem.email)
    );
  }
  if (selections.adminUsersListNameSearch.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.adminUsersListNameSearch.value.includes(elem.name)
    );
  }
  if (selections.adminUsersListTeamsSearch.value.length) {
    filteredArr = filteredArr.filter((elem) => {
      return elem.team
        .join()
        .includes(selections.adminUsersListTeamsSearch.value.join());
    });
  }
  return filteredArr;
};

export const filterColumnsForSelectionsAdminPanelTeams = (
  arr = [],
  selections
) => {
  let filteredArr = arr;
  if (selections.adminTeamsListNameSearch.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.adminTeamsListNameSearch.value.includes(elem.name)
    );
  }
  return filteredArr;
};

export const filterDiagReportDetails = (arr = [], selections) => {
  let filteredArr = arr;
  if (selections.columnNameForReport.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.columnNameForReport.value.includes(elem.column_name)
    );
  }
  if (selections.businessAttributeNameForReport.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.businessAttributeNameForReport.value.includes(elem.column_name)
    );
  }
  if (selections.rulesAppliedForReport.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.rulesAppliedForReport.value.includes(elem.description)
    );
  }
  return filteredArr;
};

export const filterGlobalRules = (arr = [], selections) => {
  let filteredArr = arr;
  if (selections.ruleDimensionForGlobalRuleManager.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.ruleDimensionForGlobalRuleManager.value.includes(
        elem.dimension
      )
    );
  }
  if (selections.supportedDatatypesForGRM.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      elem.supported_datatypes?.find((obj) =>
        selections.supportedDatatypesForGRM.value.some((data) => data === obj)
      )
    );
  }
  if (selections.statusForGlobalRulesManager.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.statusForGlobalRulesManager.value.includes(elem.status)
    );
  }
  if (selections.searchText) {
    filteredArr = filteredArr.filter((elem) => {
      if (elem.name) {
        return elem.name
          ?.toLowerCase()
          .includes(selections.searchText.toLowerCase());
      } else {
        return true;
      }
    });
  }
  return filteredArr;
};
export const filterSummaryRules = (arr = [], selections) => {
  let filteredArr = arr;
  if (selections.summaryUseCase.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.summaryUseCase.value.includes(elem["Use Case"])
    );
  }

  if (selections.summaryTable.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.summaryTable.value.includes(elem.Table)
    );
  }
  if (selections.summaryColumn.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.summaryColumn.value.includes(elem.Column)
    );
  }
  if (selections.summaryAllRule.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.summaryAllRule.value.includes(elem.Rule)
    );
  }
  if (selections.summaryCategory.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.summaryCategory.value.includes(elem.Category)
    );
  }
  if (selections.summaryDimension.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.summaryDimension.value.includes(elem.Dimension)
    );
  }

  return filteredArr;
};

export const filterColumnsForGlobalRulesEdit = (arr = [], selections) => {
  let filteredArr = arr;
  if (selections.datasetNameForGRM.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.datasetNameForGRM.value.includes(elem.dataset_name)
    );
  }
  if (selections.databaseNameForGRM.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.databaseNameForGRM.value.includes(elem.database_name)
    );
  }
  if (selections.tableNameForGRM.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.tableNameForGRM.value.includes(elem.table_name.toLowerCase())
    );
  }
  if (selections.columnNameForGRM.value.length) {
    filteredArr = filteredArr.filter((elem) =>
      selections.columnNameForGRM.value.includes(elem.column_name.toLowerCase())
    );
  }
  // if (selections.searchText) {
  //   filteredArr = filteredArr.filter((elem) => {
  //     // const name = elem.name;
  //     return elem.name
  //       ?.toLowerCase()
  //       .includes(selections.searchText.toLowerCase());
  //   });
  // }
  return filteredArr;
};

export const getCountOfRules = (rulesData, category) => {
  let count;
  if (category === "IT/Business") {
    count = rulesData?.filter(
      (item) =>
        item.category?.toLowerCase() === "it" ||
        item.category?.toLowerCase() === "business"
    ).length;
  } else {
    count = rulesData?.filter(
      (item) => item.category?.toLowerCase() === category?.toLowerCase()
    ).length;
  }
  return count;
};

export const divideRulesIntoArrays = (rulesData) => {
  let obj = {
    Business: rulesData.filter(
      (item) => item.category.toLowerCase() === "business"
    ),
    IT: rulesData.filter((item) => item.category.toLowerCase() === "it"),
    Semantic: rulesData.filter(
      (item) => item.category.toLowerCase() === "semantic"
    ),
  };
  return obj;
};

export const splitSemanticOptions = (inputText) => {
  let result = inputText?.split("%OR%");
  result = result
    .map((elem, index1) => {
      if (elem.includes("#AND#")) {
        let dummy1 = elem.split("#AND#");
        return dummy1.map((item, index2) => {
          return index2 !== 0
            ? {
                operator: "AND",
                tableName: item.split(".")[0],
                columnName: item.split(".")[1],
                id: v4(),
              }
            : index2 === 0 && index1 === 0
            ? {
                operator: "",
                tableName: item.split(".")[0],
                columnName: item.split(".")[1],
                id: v4(),
              }
            : {
                operator: "OR",
                tableName: item.split(".")[0],
                columnName: item.split(".")[1],
                id: v4(),
              };
        });
      } else {
        return index1 !== 0
          ? {
              operator: "OR",
              tableName: elem.split(".")[0],
              columnName: elem.split(".")[1],
              id: v4(),
            }
          : {
              operator: "",
              tableName: elem.split(".")[0],
              columnName: elem.split(".")[1],
              id: v4(),
            };
      }
    })
    .flat();
  return result;
};

// ! INITAL DEPRECATED FUNCTION (industry standard function)
// const unwrapperFunctionForTables = (input = []) => {
//   const recursion = (data, index, HIERARCHY) => {
//     const accessor = HIERARCHY[index].accessor;
//     let grouped = groupBy(data, (item) => item[accessor]);
//     const groupedArr = [];
//     for (const row of Object.keys(grouped)) {
//       const isLastChild = index + 1 === HIERARCHY.length;
//       const newRow = {
//         name: row,
//         levelName: accessor,
//         metadata: isLastChild ? grouped[row][0] : {},
//         children: isLastChild
//           ? []
//           : recursion(grouped[row], index + 1, HIERARCHY),
//       };
//       groupedArr.push(newRow);
//     }
//     return groupedArr;
//   };

//   //  HIERARCHY : layer > database > schema > table;
//   //  TERMINOLOGY : layer_name > catalog_name > schema_name > table_name;
//   const HIERARCHY = [
//     { level: 1, accessor: "layer_name" },
//     { level: 2, accessor: "catalog_name" },
//     { level: 3, accessor: "schema_name" },
//     { level: 4, accessor: "table_name" },
//   ];

//   const output = recursion(input, 0, HIERARCHY);
//   return output;
// };

// * FINAL FUNCTION
const unwrapperFunctionForTables = (input = []) => {
  const recursion = (data, index, HIERARCHY) => {
    // STATIC VARIABLES
    const currHierarchyObj = HIERARCHY[index];
    const accessor = currHierarchyObj.accessor;
    const chilrenAlias = currHierarchyObj.chilrenAlias;
    const requiredKeys = currHierarchyObj.requiredKeys;
    const additionalKeys = currHierarchyObj.additionalKeys;
    let grouped = groupBy(data, (item) => item[accessor]);
    const groupedArr = [];

    for (const row of Object.keys(grouped)) {
      // SAMPLE OBJECT
      const sampleObject = grouped[row][0];

      // REQUIRED KEYS
      let newRow = requiredKeys.reduce((obj, row) => {
        obj[row.key] = sampleObject[row.accessor];
        return obj;
      }, {});

      // ADDITIONAL KEYS
      for (const additionalKey of additionalKeys) {
        newRow[additionalKey.key] = additionalKey.value;
      }

      // METADATA
      const isLastChild = index + 1 === HIERARCHY.length;
      if (isLastChild) {
        newRow["metadata"] = sampleObject;
      } else {
        // * RECURSION
        newRow[chilrenAlias] = recursion(grouped[row], index + 1, HIERARCHY);
      }

      groupedArr.push(newRow);
    }
    return groupedArr;
  };

  //  HIERARCHY : layer > database > schema > table;
  //  TERMINOLOGY : layer_name > catalog_name > schema_name > table_name;
  const HIERARCHY = [
    {
      level: 0,
      accessor: "layer_name",
      chilrenAlias: "databases",
      requiredKeys: [
        {
          key: "name",
          accessor: "layer_name",
        },
      ],
      additionalKeys: [],
    },
    {
      level: 1,
      accessor: "catalog_name",
      chilrenAlias: "schemas",
      requiredKeys: [
        {
          key: "id",
          accessor: "detailed_dataset_id",
        },
        { key: "name", accessor: "catalog_name" },
      ],
      additionalKeys: [],
    },
    {
      level: 2,
      accessor: "schema_name",
      chilrenAlias: "tables",
      requiredKeys: [
        { accessor: "layer_id", key: "layer_id" },
        { accessor: "schema_name", key: "name" },
        { accessor: "catalog_id", key: "meta_catalog_id" },
      ],
      additionalKeys: [],
    },
    {
      level: 3,
      accessor: "table_name",
      chilrenAlias: "children",
      requiredKeys: [
        { accessor: "table_name", key: "name" },
        { accessor: "id", key: "id" },
      ],
      additionalKeys: [{ key: "isTemporary", value: false }],
    },
  ];

  const output = recursion(input, 0, HIERARCHY);
  let modOutput = {};
  for (const row of output) {
    modOutput[row.name] = { databases: row[HIERARCHY[0]["chilrenAlias"]] };
  }
  return modOutput;
};

// ! INITAL DEPRECATED FUNCTION (industry standard function)
// const wrapperFunctionForTables = (input = []) => {
//   const recursion = (data, index, HIERARCHY, flattenedArr) => {
//     for (const row of data) {
//       const isLastChild = index + 1 === HIERARCHY.length;
//       if (isLastChild) {
//         flattenedArr.push(row.metadata);
//       } else {
//         flattenedArr = recursion(
//           row.children,
//           index + 1,
//           HIERARCHY,
//           flattenedArr
//         );
//       }
//     }
//     return flattenedArr;
//   };

//   //  HIERARCHY : layer > database > schema > table;
//   //  TERMINOLOGY : layer_name > catalog_name > schema_name > table_name;
//   const HIERARCHY = [
//     { level: 1, accessor: "layer_name" },
//     { level: 2, accessor: "catalog_name" },
//     { level: 3, accessor: "schema_name" },
//     { level: 4, accessor: "table_name" },
//   ];

//   let flattenedArr = [];
//   const output = recursion(input, 0, HIERARCHY, flattenedArr);
//   return output;
// };

// * FINAL FUNCTION
const wrapperFunctionForTables = (input = []) => {
  const recursion = (data, index, HIERARCHY, flattenedArr) => {
    const chilrenAlias = HIERARCHY[index].chilrenAlias;
    for (const row of data) {
      const isLastChild = index + 1 === HIERARCHY.length;
      if (isLastChild) {
        flattenedArr.push(row.metadata);
      } else {
        flattenedArr = recursion(
          row[chilrenAlias],
          index + 1,
          HIERARCHY,
          flattenedArr
        );
      }
    }
    return flattenedArr;
  };

  //  HIERARCHY : layer > database > schema > table;
  //  TERMINOLOGY : layer_name > catalog_name > schema_name > table_name;
  const HIERARCHY = [
    {
      level: 0,
      accessor: "layer_name",
      chilrenAlias: "databases",
      requiredKeys: [
        {
          key: "name",
          accessor: "layer_name",
        },
      ],
      additionalKeys: [],
    },
    {
      level: 1,
      accessor: "catalog_name",
      chilrenAlias: "schemas",
      requiredKeys: [
        {
          key: "id",
          accessor: "detailed_dataset_id",
        },
        { key: "name", accessor: "catalog_name" },
      ],
      additionalKeys: [],
    },
    {
      level: 2,
      accessor: "schema_name",
      chilrenAlias: "tables",
      requiredKeys: [
        { accessor: "layer_id", key: "layer_id" },
        { accessor: "schema_name", key: "name" },
        { accessor: "catalog_id", key: "meta_catalog_id" },
      ],
      additionalKeys: [],
    },
    {
      level: 3,
      accessor: "table_name",
      chilrenAlias: "children",
      requiredKeys: [
        { accessor: "table_name", key: "name" },
        { accessor: "id", key: "id" },
      ],
      additionalKeys: [{ key: "isTemporary", value: false }],
    },
  ];

  let modInput = [];
  const chilrenAliasAtL0 = HIERARCHY[0].chilrenAlias;
  for (const [key, value] of Object.entries(input)) {
    const newRow = {
      name: key,
      [chilrenAliasAtL0]: value[chilrenAliasAtL0],
    };
    modInput.push(newRow);
  }

  let flattenedArr = [];
  const output = recursion(modInput, 0, HIERARCHY, flattenedArr);
  return output;
};

export { unwrapperFunctionForTables, wrapperFunctionForTables };

export function checkNested(obj) {
  if (obj === undefined) return false;
  if (obj.databases.length) {
    let schemas = obj.databases.map((elem) =>
      elem.schemas.length ? true : false
    );
    if (schemas.every((element) => element === true)) {
      let tables = obj.databases
        .map((elem) =>
          elem.schemas.map((item) => (item.tables.length ? true : false))
        )
        .flat();
      if (tables.every((element) => element === true)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const constructNames = (obj) => {
  let res = [];
  if (obj[1].databases.length) {
    res = obj[1].databases
      .map((elem1) =>
        elem1.schemas.map((elem2) =>
          elem2.tables.map((elem3) => ({
            [obj[0]]: elem1.name + "." + elem2.name + "." + elem3.name,
          }))
        )
      )
      .flat(3);
  }
  return res;
};

export const removeEmptyTablesLayer = (hierArr) => {
  let newResult = {};
  for (let item in hierArr) {
    if (hierArr[item].databases.length > 0) {
      for (let dbItem in hierArr[item].databases) {
        if (hierArr[item].databases[dbItem].schemas.length > 0) {
          for (let schItem in hierArr[item].databases[dbItem].schemas) {
            if (
              hierArr[item].databases[dbItem].schemas[schItem].tables.length <=
              0
            ) {
              hierArr[item].databases[dbItem].schemas.splice(schItem, 1);
              if (hierArr[item].databases[dbItem].schemas.length <= 0) {
                hierArr[item].databases.splice(dbItem, 1);
                if (hierArr[item].databases.length <= 0) {
                  delete hierArr[item];
                  // hierArr.splice(item, 1);
                }
              }
            }
          }
        } else {
          hierArr[item].databases.splice(dbItem, 1);
        }
      }
    } else {
      delete hierArr[item];
    }
  }
  for (let elem in hierArr) {
    let db = hierArr[elem]?.databases.filter((elem) => elem !== null);
    if (db.length) {
      newResult[elem] = { databases: db };
    }
  }
  return newResult;
};

export const getAllColumns = (item) => {
  let columns = [];
  item.table_columns.forEach((object) => {
    let columnObj = {};
    columnObj.table_id = item.id;
    columnObj.column_name = object.column_name;
    columnObj.id = object.id;
    columnObj.datatype = object.datatype;
    columnObj.business_attribute_name = object.business_attribute_name;
    if (columnObj.column_name) {
      columns.push(columnObj);
    }
  });
  return columns;
};

export const setRulesAndColumns = (data) => {
  data.it = {};
  data.business = {};
  data.semantic = {};
  data.table_columns.forEach((object) => {
    data.it[object.column_name] = object.rules[0].it;
    data.business[object.column_name] = object.rules[1].business;
    data.semantic[object.column_name] = object.rules[2].semantic;
  });
  return data;
};

export const getUniqueCount = (data) => {
  const uniqueIds = [];
  const unique = data.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.name);
    if (!isDuplicate) {
      uniqueIds.push(element.id);
      return true;
    }
    return false;
  });
  return unique;
};

export const getTotalSelectedRules = (selectionRules = []) => {
  let count = 0;

  // below rulesIds can add multiple records
  const uniqueFieldName = [
    "fieldCompare_column",
    "isExistingCond",
    "isMapped",
    "checkstring_sap_with_condition",
    "field_compare_with_condition_sap",
  ];

  selectionRules.forEach((row) => {
    if (uniqueFieldName.includes(row.name)) {
      if (row.value?.value1?.length) {
        row.value.value1.forEach((insideRow) => {
          if (insideRow.isChecked || insideRow.isEnabled) {
            ++count;
          }
        });
      }
    } else {
      if (row.value.isEnabled) {
        ++count;
      }
    }
  });

  return count;
};

// const replaceUniqueKeyByV4 = (obj = {}) => {
//   if (Object.keys(obj).includes("uniqueKey")) {
//     return { ...obj, uniqueKey: v4() };
//   } else return { ...obj };
// };

// export const getReplaceUniqueKeyByV4 = (allRulesData) => {
//   const rulesNameForReplaceUniqueKey = ["fieldCompare_column"];
//   if (allRulesData && Array.isArray(allRulesData) && allRulesData.length) {
//     return allRulesData.map((rules) => {
//       if (rulesNameForReplaceUniqueKey.includes(rules.name)) {
//         let newElementData = [];
//         for (const elDataRow of rules.elementData) {
//           let newElDataRow = { ...elDataRow };
//           if (
//             Array.isArray(elDataRow?.metadata?.defaultValue) &&
//             elDataRow?.metadata?.defaultValue?.length
//           ) {
//             let updatedDefaultValue = replaceUniqueKeyByV4(
//               elDataRow?.metadata?.defaultValue[0]
//             );
//             newElDataRow = {
//               ...newElDataRow,
//               metadata: {
//                 ...newElDataRow.metadata,
//                 defaultValue: [{ ...updatedDefaultValue }],
//               },
//             };
//           }
//           newElementData.push(newElDataRow);
//         }
//         return { ...rules, elementData: newElementData };
//       } else {
//         return rules;
//       }
//     });
//   } else {
//     return allRulesData;
//   }
// };

export const isRuleEnabled = (ruleSelectionRow) => {
  const exceptionRulesList = [
    "fieldCompare_column",
    "isExistingCond",
    "isMapped",
    "checkstring_sap_with_condition",
    "field_compare_with_condition_sap",
  ];
  const ruleName = ruleSelectionRow.name;
  let isRuleEnabledFlag = false;
  if (exceptionRulesList.includes(ruleName)) {
    let rulesArr = ruleSelectionRow.value?.value1;
    if (Array.isArray(rulesArr)) {
      rulesArr.forEach((insideRow) => {
        if (insideRow.isChecked || insideRow.isEnabled) {
          isRuleEnabledFlag = true;
        }
      });
    }
  } else {
    isRuleEnabledFlag = ruleSelectionRow.value.isEnabled;
  }

  return isRuleEnabledFlag;
};

export const sortRulesDataFromFormselections = (
  modifiedAllRulesDataDynamic,
  finalNewFormSelections
) => {
  let newAllRulesData = [];
  finalNewFormSelections
    .sort((a, b) => (isRuleEnabled(a) > isRuleEnabled(b) ? -1 : 1))
    .map((fs) =>
      modifiedAllRulesDataDynamic.forEach((ard) => {
        if (ard.name === fs.name) {
          newAllRulesData = [...newAllRulesData, ard];
        }
      })
    );
  return newAllRulesData;
};

export const getStatusMaster = (allData = []) => {
  let allStatus = [];
  for (const value of allData) {
    allStatus.push(value.status);
  }
  let partialLoading = allStatus.some((el) => el === "loading");
  let partialSuccess = allStatus.some((el) => el === "success");
  let fullLoading = allStatus.every((el) => el === "loading");
  let fullSuccess = allStatus.every((el) => el === "success");
  let anyError = allStatus.some((el) => el === "error");
  let isNoData = false;
  if (fullSuccess) {
    allData.forEach((dataRow) => {
      if (Array.isArray(dataRow.result) && dataRow.result.length === 0) {
        isNoData = true;
      }
    });
  }

  return {
    partialLoading,
    partialSuccess,
    fullLoading,
    fullSuccess,
    anyError,
    isNoData,
  };
};

export const downloadCsvFile = (csvData = "", fileName = "csvFile") => {
  var blob = new Blob([csvData]);
  const url = window.URL.createObjectURL(blob, {
    type: "text/csv",
  });
  const hiddenElement = document.createElement("a");
  hiddenElement.href = url;
  hiddenElement.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(hiddenElement);
  hiddenElement.click();

  // Clean up and remove the hiddenElement
  hiddenElement.parentNode.removeChild(hiddenElement);
};
