// Import required libraies

import React, { useEffect, useState } from "react";
import {
  checkNested,
  initialApiData,
  removeEmptyTablesLayer,
  wrapperFunctionForTables,
} from "../../../utils/utils";
import {
  createOrUpdateTable,
  createTablePipeline,
  findDuplicateDataset,
  getUserClusters,
} from "../../../services/apis";
import {
  reloadData,
  resetUserInputs,
  updateAlertInfo,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import DQButton from "../../../components/Common/DQButton";
import { Link } from "react-router-dom";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Summary from "../../../components/Summary/Summary";
import TableInformation from "../../../components/TableInformation/TableInformation";
import TableSelection from "../../../components/TableSelection/TableSelection";
import ManageTableSelection from "../../../components/ManageTableSelection/ManageTableSelection";
import config from "../../../config/config";
import { useHistory } from "react-router-dom";

// Import Custom Component

// Import data & utils
import noDataImage from "../../../../assets/images/undraw_void.svg";

// Import actions

// # STATIC VARIABLES
const steps = ["Select Tables", "Default Rules", "Summary"];

// # COMPONENT
const AddDataTable = ({ match }) => {
  // # HOOKS
  const history = useHistory();
  const dispatch = useDispatch();
  const userInputs = useSelector((state) => state.userInputs);
  const [activeStep, setActiveStep] = useState(0);
  const [resetFlag, setResetFlag] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [errorHanlding, setErrorHandling] = useState(initialApiData);

  useEffect(() => {
    const init = async () => {
      const clusters = await getUserClusters();
      if (clusters.length > 0) {
        const updatedClusters = clusters.map((c) => {
          const { cluster = "", name = "", server = "", id = "" } = c;
          return {
            label: `${cluster
              .replace(/[0-9]/g, "")
              .toUpperCase()} - ${name} - ${server}`,
            id: id,
          };
        });
        dispatch(
          updateUserInputs("clusters", {
            ...userInputs.clusters,
            clusters: updatedClusters,
          })
        );
        dispatch(updateUserInputs("clusterName", updatedClusters[0]));
        setErrorHandling({ status: "success", message: "", result: clusters });
      } else {
        setErrorHandling({
          status: "error",
          message: "Could not load details! Please try again later!",
          result: [],
        });
      }
    };

    init();

    // eslint-disable-next-line
  }, []);

  // # HANDLERS
  const changeActiveStep = async (e) => {
    if (activeStep < 3) {
      if (activeStep === 0) {
        dispatch(
          updateUserInputs(
            "hierarchy",
            removeEmptyTablesLayer(userInputs.hierarchy)
          )
        );
        setActiveStep(activeStep + 1);
      } else if (activeStep === 1) {
        saveDataTable();
        setActiveStep(activeStep + 1);
      } else if (activeStep === 2) {
        history.push("/home/mydataset");
      }
    }
  };

  const saveDataTable = async () => {
    dispatch(
      updateAlertInfo({
        open: true,
        message: config.messages.tableSaveRequestMessage,
        severity: "info",
      })
    );
    const body = wrapperFunctionForTables(userInputs.hierarchy).map(
      (p, index) => ({
        ...p,
        cluster_id: userInputs.clusterName.id,
        tags: userInputs.tags.length ? userInputs.tags : [],
        table_columns: apiData[index],
      })
    );
    const finalBody = { pipeline_tables: [...body] };
    let respData = await createTablePipeline(finalBody);
    if (respData) {
      dispatch(reloadData());
      dispatch(updateSelections("columnSelectionPage", false));

      dispatch(
        updateAlertInfo({
          open: true,
          message: config.messages.tableCreationResponseMessage,
          severity: "success",
        })
      );
    } else {
      dispatch(
        updateAlertInfo({
          open: true,
          message: config.messages.tableCreationFailedMessage,
          severity: "error",
        })
      );
    }
  };

  const proceedToNextPage = (e) => {
    changeActiveStep();
  };

  const proceedToBackPage = (e) => {
    setActiveStep(activeStep - 1);
    setResetFlag(true);
  };
  // TODO cancelSave functionality
  const cancelSave = (e) => {
    history.push("/home/mydataset");
  };
  // TODO saveAsDraft functionality
  const saveAsDraft = (e) => {
    // console.log("Save as Draft");
  };

  // # UTIL FUNCS
  const getDuplicateDatasetStatus = async () => {
    let body = {
      name: userInputs.datasetName.name || "",
      environment_name: userInputs.environment.name || "",
      cluster_id: userInputs.environment.id,
      category_name: userInputs.categoryName.name || "",
      primary_data_domain_name: userInputs.primaryDomain.name || "",
      secondary_data_domain_name: userInputs.secondaryDomain.name || "",
      sub_data_domain_name: userInputs.subDataDomain.name || "",
      tags: userInputs.tags.length ? userInputs.tags : [],
    };
    let tableData = await findDuplicateDataset(body);
    return tableData;
  };
  const isDisabled = () => {
    //  console.log(userInputs);
    //  console.log(activeStep);
    if (activeStep === 0) {
      if (userInputs.clusterName.id) {
        let res = Object.values(userInputs.hierarchy).map((elem) =>
          checkNested(elem)
        );
        if (res.indexOf(true) > -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
      // if (res.every((element) => element === true)) {
      //   return false;
      // } else {
      //   return true;
      // }
    } else if (activeStep === 2) {
    }
  };

  // # JSX
  return (
    <div>
      {errorHanlding.status === "success" && (
        <>
          <Box sx={{ width: "50%", mx: "auto", mt: 2, p: 2 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => (
                <Step
                  key={label}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "#ea3592", // circle color (COMPLETED)
                      fontFamily: "Hind Siliguri",
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "#4eafb3", // circle color (ACTIVE)
                      fontFamily: "Hind Siliguri",
                    },
                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                      {
                        color: "#465a69", // Just text label (COMPLETED)
                        fontFamily: "Hind Siliguri",
                      },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "#fffff", // circle's number (ACTIVE)
                      fontFamily: "Hind Siliguri",
                    },
                    "& .MuiStepLabel-horizontal .Mui-disabled": {
                      color: "#465a69",
                      fill: "#465a69",
                      fontFamily: "Hind Siliguri",
                    },
                    "& .MuiStepLabel-iconContaine .Mui-disabled": {
                      color: "#465a69",
                      fill: "#465a69",
                      fontFamily: "Hind Siliguri",
                    },
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box sx={{ mt: 6, width: "95%", mx: "auto" }}>
            {activeStep === 0 && <TableSelection match={match} />}
            {activeStep === 1 && (
              <>
                <ManageTableSelection
                  setApiData={setApiData}
                  cancelSave={cancelSave}
                  proceedToBackPage={proceedToBackPage}
                  proceedToNextPage={proceedToNextPage}
                />
              </>
            )}
            {activeStep === 2 && (
              <Summary
                setActiveStep={setActiveStep}
                setResetFlag={setResetFlag}
              ></Summary>
            )}
          </Box>
          {activeStep !== 1 && (
            <Box
              sx={{
                display: "flex",
                position: "fixed",
                bottom: 40,
                right: 0,
                mr: 2,
                height: "30px",
              }}
            >
              <DQButton
                title="Cancel"
                disabled={false}
                variant="outlined"
                onclick={cancelSave}
              />
              <DQButton
                title="Back"
                disabled={activeStep === 0}
                variant="contained"
                onclick={proceedToBackPage}
              />
              {
                /* <Link className="disabled-style"> */
                // <DQButton
                //   title="Save as Draft"
                //   disabled={false}
                //   variant="contained"
                //   onClick={saveAsDraft}
                // />
                /* </Link> */
              }
              <DQButton
                title={activeStep === 2 ? "Save" : "Next"}
                disabled={isDisabled()}
                variant="contained"
                onclick={proceedToNextPage}
              />
            </Box>
          )}
        </>
      )}
      {errorHanlding.status === "error" && (
        <div className="no-tables">
          <img src={noDataImage} alt="noDataImage" />
          <p className="no-data">
            <b>{errorHanlding.message}</b>
          </p>
        </div>
      )}
    </div>
  );
};

// # EXPORTS
export default AddDataTable;
