import "./DomainHierarchy.scss";

import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TreeItem, TreeView } from "@material-ui/lab";
import { filterDeep, mapDeep } from "deepdash-es/standalone";
import {
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";

import Loader from "../../Loader/Loader";
import SearchTableSelection from "../../SearchTableSelection/SearchTableSelection";
import config from "../../../config/config";
import { connect } from "react-redux";
import { getColorFromPercentage } from "../../../utils/utils";
import { getReportHierarchicalData } from "../../../services/apis";
import { makeStyles } from "@material-ui/core/styles";
import noDataImage from "../../../../assets/images/undraw_void.svg";
import { omitBy } from "lodash";
import page_left from "../../../../assets/images/page_left.png";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "14px",
    fontFamily: "Hind Siliguri",
  },
  label: {
    fontSize: "14px",
    fontFamily: "Hind Siliguri",
  },
  activeLabel: {
    fontSize: "14px",
    fontFamily: "Hind Siliguri",
    color: "#4eafb3",
    fontWeight: 700,
  },
  tableLabel: {
    fontSize: "14px",
    fontFamily: "Hind Siliguri",
    fontWeight: 600,
    color: "#42596c",
  },
}));

const DomainHierarchy = ({
  updateUserInputs,
  data,
  updateData,
  userInputs,
  updateSelections,
}) => {
  const classes = useStyles();
  const [criteriaHierarchy, setCriteriaHierarchy] = useState({
    searchText: "",
  });
  const [expanded, setExpanded] = useState([]);
  const [isDisabled, setDisabled] = useState(true);
  const [allReportHierarchicalData, setAllReportHierarchicalData] = useState(
    []
  );

  const isFoundChild = (id, data, parents1) => {
    if (data.find((item) => item.id_new === id)) {
      return true;
    } else {
      for (let item of data) {
        if (item.children.length)
          if (isFoundChild(id, item.children, parents1)) {
            parents1.push(item);
            return true;
          }
      }
      return false;
    }
  };

  const getParentsById = (id, data) => {
    const parents = [];
    if (data.find((item) => item.id_new === id)) return [];
    else {
      for (let item of data) {
        if (item.children.length)
          if (isFoundChild(id, item.children, parents)) {
            parents.push(item);
            return parents;
          }
      }
    }
  };

  useEffect(() => {
    if (criteriaHierarchy.searchText !== "") {
      let res = mapDeep(data.reportHierarchyData.result, (v) => v.id_new, {
        childrenPath: "children",
      });
      setExpanded(res);
    }
  }, [data.reportHierarchyData.result, criteriaHierarchy]);

  const fetchReportHierarchyInfo = async (searchValue) => {
    updateData("reportHierarchyData", {
      status: "loading",
      message: "",
      result: [],
    });
    setDisabled(true);

    let reportHierarchicalData = [];
    if (searchValue) {
      reportHierarchicalData = filterDeep(
        allReportHierarchicalData,
        (value) => {
          return value.name.toLowerCase().includes(searchValue.toLowerCase());
        },
        { childrenPath: "children", onTrue: { skipChildren: true } }
      );
    } else {
      reportHierarchicalData = await getReportHierarchicalData({
        searchValue: searchValue,
      });
      setAllReportHierarchicalData(reportHierarchicalData);
    }

    if (reportHierarchicalData && reportHierarchicalData.length > 0) {
      updateData("reportHierarchyData", {
        status: "success",
        message: "",
        result: reportHierarchicalData,
      });
      if (userInputs.newidForReportSelected) {
        let parentsId = getParentsById(
          userInputs.newidForReportSelected,
          reportHierarchicalData
        )?.map((item) => item.id_new);
        if (parentsId) {
          setExpanded([...expanded, ...parentsId]);
        }
      }
    } else {
      updateData("reportHierarchyData", {
        status: "error",
        message: config.messages.noRecordFound,
        result: [],
      });
    }

    setDisabled(false);
  };

  useEffect(() => {
    fetchReportHierarchyInfo();
    // eslint-disable-next-line
  }, []);

  const changeCriteriaHierarchy = (param, value) => {
    criteriaHierarchy[param] = value;
    setCriteriaHierarchy({ ...criteriaHierarchy });
    if (param === "searchText") {
      // setData after search
      fetchReportHierarchyInfo(value);
    }
  };

  const changeIcon = (item) => {
    if (expanded.includes(item.id_new)) {
      let result = expanded.filter(
        (expandValue) => expandValue !== item.id_new
      );
      setExpanded(result);
    } else {
      setExpanded([...expanded, item.id_new]);
    }
  };

  const changeLabel = (item) => {
    if (item && item.elementName === "Table") {
      updateUserInputs("idForReportSelected", item.id_new.split("#")[0]);
      updateUserInputs("newidForReportSelected", item.id_new);
      updateUserInputs("diagReportCurrentScreen", "reportData");
      updateUserInputs("openDRFilters", false);
      updateSelections("DRFilters", {});
      updateSelections("initialDRFilters", {});
      updateData("tableHistoricalRunDetails", {
        status: "info",
        message: "",
        result: {},
      });
    }

    if (expanded.includes(item.id_new)) {
      let result = expanded.filter(
        (expandValue) => expandValue !== item.id_new
      );
      setExpanded(result);
    } else {
      setExpanded([...expanded, item.id_new]);
    }
  };

  const goBackToHistoricalRuns = () => {
    updateUserInputs("diagReportCurrentScreen", "reportData");
  };

  const getHierarchyLabel = (data) => {
    return (
      <div className="hierarchy-label">
        <span className="name" title={data.name}>
          {data.name}
        </span>
        <span
          style={{
            color: getColorFromPercentage(parseInt(data.score, 10)).color,
          }}
        >
          {"("}
          {data.score ? data.score : "NR"}
          {data.score !== "NR" && "%"}
          {")"}
        </span>
      </div>
    );
  };

  const getTreeItemsFromData = (treeItems) => {
    return treeItems?.map((treeItemData) => {
      let children = undefined;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children);
      }
      return (
        <TreeItem
          key={treeItemData.id_new}
          nodeId={treeItemData.id_new}
          label={getHierarchyLabel(treeItemData)}
          classes={{
            root: classes.root,
            label:
              treeItemData.id_new === userInputs.newidForReportSelected
                ? classes.activeLabel
                : treeItemData.elementName === "Table"
                ? classes.tableLabel
                : classes.label,
          }}
          children={children}
          onIconClick={() => changeIcon(treeItemData)}
          onLabelClick={() => changeLabel(treeItemData)}
        />
      );
    });
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        direction="row"
      >
        <SearchTableSelection
          criteria={criteriaHierarchy}
          changeCriteria={changeCriteriaHierarchy}
          placeholderText="Search Tables"
          width={"100%"}
          className="search-for-table-information"
          isDisabled={isDisabled}
        />
        <Button>
          <img
            className=""
            src={page_left}
            alt={"Go"}
            onClick={() => goBackToHistoricalRuns()}
          />
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        lg={12}
        id="reportHeaderBody2"
        style={{
          margin: "5% 5% 0 0",
          width: "100%",
        }}
      >
        {data.reportHierarchyData.status === "loading" && <Loader />}
        {data.reportHierarchyData.status === "success" && (
          <TreeView
            aria-label="rich object"
            defaultCollapseIcon={
              <span className="material-icons-outlined tree-view-icon">
                expand_more
              </span>
            }
            defaultExpandIcon={
              <span className="material-icons-outlined tree-view-icon">
                chevron_right
              </span>
            }
            style={{
              flexGrow: 1,
              maxWidth: "100%",
              overflowY: "auto",
            }}
            expanded={expanded ? expanded : []}
          >
            {getTreeItemsFromData(data.reportHierarchyData.result)}
          </TreeView>
        )}
        {data.reportHierarchyData.status === "error" && (
          <div className="no-configs">
            <img src={noDataImage} alt="noDataImage" />
            <p className="no-data">
              <b>{data.reportHierarchyData.message}</b>
            </p>
          </div>
        )}
      </Grid>
    </>
  );
};

// DomainHierarchy.propTypes = {
//   data: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => ({
  data: state.data,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateUserInputs,
  updateData,
  updateSelections,
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainHierarchy);
