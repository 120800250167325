// Import required libraries

import "./DatasetTable.scss";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Select,
  TableBody,
  TableRow,
} from "@material-ui/core";
import {
  CustomMenuItem,
  StyledTableCell,
  customMenuItemStyle,
  useStyles,
} from "./styles";
import React, { useEffect, useState } from "react";
import {
  formatDate,
  getCircleBarColor,
  getRatingColor,
  getRoundOfScore,
} from "../../../utils/utils";
import {
  resetSelections,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import DQButton from "../../Common/DQButton";
import DatasetTableHeader from "./DatasetTableHeader";
import DiagnosticReport from "../../DiagnosticReport/DiagnosticReport";
import DiagnosticReportRun from "../../DiagnosticReport/DiagnosticReportRun";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pagination } from "@material-ui/lab";
import PropTypes from "prop-types";
import config from "../../../config/config";
import noDataImage from "../../../../assets/images/undraw_void.svg";
import useTable from "../../../utils/useTable";

// Import Custom Component

// Import data & utils

// Import action creators

// Import styles

// # STATIC VARIABLES
const headCells1 = [
  { id: "table_name", label: "Table Name", disableSorting: true },
  { id: "dataset_name", label: "Dataset Name", disableSorting: true },
  { id: "no_of_columns", label: "# of Columns", disableSorting: true },
  { id: "location", label: "Platform", disableSorting: true },
  { id: "last_run_details_1", label: " ", disableSorting: true },
];

// # HELPER COMPONENTS
const StyledRating = ({ value, color }) => {
  const classes = useStyles();
  const ratingBox = getRatingColor(value);

  // # JSX
  return (
    <div className={classes.ratingContainer}>
      {ratingBox.map((rate, i) => (
        <div className={classes.ratingBox} key={i}>
          <div
            className={classes.rating}
            style={{ width: `${rate}%`, backgroundColor: color }}
          />
          <div className={classes.empty} style={{ width: `${100 - rate}%` }} />
        </div>
      ))}
    </div>
  );
};

StyledRating.propTypes = {
  value: PropTypes.any.isRequired,
  color: PropTypes.any.isRequired,
};

// # COMPONENT
const DatasetTable = ({
  data = [],
  paginationFlag,
  criteria,
  myDatasetPage = false,
}) => {
  // # HOOKS
  const classes = useStyles();
  const dispatch = useDispatch();
  const userInputs = useSelector((state) => state.userInputs);

  const filterFn = { fn: (items) => items };
  const [open, setOpen] = React.useState({
    tableId: "",
    isOpened: false,
    openCriteria: "",
  });

  //reset filters on screen change
  useEffect(() => {
    return () => {
      dispatch(resetSelections("dataLayer"));
      dispatch(resetSelections("useCase"));
    };
    // eslint-disable-next-line
  }, []);

  // HANDLERS
  const handleClickOpen = (tableData) => {
    setOpen({
      tableId: tableData.id,
      isOpened: true,
      openCriteria: "diagnosticReport",
    });
    dispatch(updateUserInputs("idForReportSelected", tableData.id));
    dispatch(
      updateUserInputs(
        "newidForReportSelected",
        `${tableData.id}#${tableData.schema_name}.${tableData.table_name}`
      )
    );
  };
  const handleClickRun = (tableData) => {
    setOpen({
      tableId: tableData.id,
      isOpened: true,
      openCriteria: "run",
    });
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen({ isOpened: false, tableId: "" });
      dispatch(updateUserInputs("idForReportSelected", ""));
      dispatch(updateUserInputs("newidForReportSelected", ""));
    }
  };

  const navigateToRow = (rowData, subtab) => {
    let checkTable = userInputs.tablesAddedForDatabase.find((p) => {
      return p.id === rowData.id;
    });
    if (!checkTable) {
      dispatch(
        updateUserInputs("tablesAddedForDatabase", [
          ...userInputs.tablesAddedForDatabase,
          {
            ...rowData,
            it: {},
            business: {},
            semantic: {},
          },
        ])
      );
    }
    dispatch(
      updateSelections("columnSelected", {
        ...rowData,
      })
    );
    dispatch(updateSelections("columnSelectionPage", true));
  };

  const getHeadCells = () => {
    return headCells1;
  };

  const pageSizes = [...config.hardCoded.pageSizes];

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { TblContainer } = useTable(
    data,
    getHeadCells(),
    filterFn,
    {},
    () => {},
    criteria
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  // # STATIC VARIABLES
  const displayRecords =
    data.length > page
      ? [...data].splice((page - 1) * pageSize, pageSize)
      : data;
  // In this screen, not using pagination
  // const displayRecords = [...data];
  const noOfPages = Math.ceil(data.length / pageSize);

  // # JSX
  return (
    <>
      {" "}
      <div className="dataset-table-list-container">
        <TblContainer>
          <DatasetTableHeader myDatasetPage={myDatasetPage} />
          {data?.length ? (
            <TableBody>
              {displayRecords.map((item, index) => {
                const { color } =
                  item?.score === "NR"
                    ? { color: "NA", remarks: "" }
                    : item?.score?.includes("%")
                    ? getCircleBarColor(Number(item?.score?.slice(0, -1)))
                    : getCircleBarColor(Number(item?.score));
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableRow} hover>
                      <StyledTableCell
                        onClick={() => navigateToRow(item, item.id)}
                        style={{
                          cursor: "pointer",
                          fontWeight: "600",
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ width: "45px" }}>
                            {item?.isDraft && (
                              <label className="draft-tag">Draft</label>
                            )}
                          </div>
                          <div
                            className="dataset-location-name"
                            title={
                              item.catalog_name +
                              "." +
                              item.schema_name +
                              "." +
                              item.table_name
                            }
                            style={{
                              textDecoration: "underline",
                              maxWidth: "280px",
                            }}
                          >
                            {item.catalog_name +
                              "." +
                              item.schema_name +
                              "." +
                              item.table_name}
                          </div>
                        </span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div
                          className="dataset-location-name"
                          title={item.use_case}
                        >
                          {item.use_case}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div
                          className="dataset-location-name"
                          title={item.layer_name}
                        >
                          {item.layer_name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        {item?.last_run_on
                          ? formatDate(+item.last_run_on, "MMMM DD, yyyy")
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "180px" }}>
                        <Box display="flex" alignItems="center">
                          <StyledRating
                            value={getRoundOfScore(item?.score) / 10}
                            color={color}
                          />
                          <div className="dataset-score">
                            {item?.score ? (
                              item?.score === "NR" ? (
                                <span title="Not Rated">NR</span>
                              ) : (
                                <span>{`${getRoundOfScore(item.score)}%`}</span>
                              )
                            ) : (
                              <span title="Not Rated">NR</span>
                            )}
                          </div>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "70px" }}>
                        <button
                          type="button"
                          className="report-btn"
                          onClick={() => handleClickRun(item)}
                        >
                          Run
                        </button>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "50px" }}>
                        <div
                          className="dataset-location-name"
                          title={item.run_status}
                        >
                          {item.run_status}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "150px" }}>
                        <button
                          type="button"
                          className="report-btn"
                          onClick={() => handleClickOpen(item)}
                        >
                          Diagnostic Report
                        </button>
                      </StyledTableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          ) : (
            <TableBody className="no-configs">
              <TableRow>
                <StyledTableCell colSpan={8}>
                  <img
                    src={noDataImage}
                    alt="noDataImage"
                    className="no-data-image"
                  />
                  <p className="no-data">
                    <b>{config.messages.noRecordsFound}</b>
                  </p>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </TblContainer>
        {open.openCriteria === "diagnosticReport" && (
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open.isOpened}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            classes={{ paper: classes.paperDialog }}
          >
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
              <Box>
                <DiagnosticReport currentScreen={"reportData"} />
              </Box>
            </DialogContent>
            <DialogActions>
              <DQButton
                onclick={handleClose}
                title="Close"
                variant="contained"
              />
            </DialogActions>
          </Dialog>
        )}
        {open.openCriteria === "run" && (
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open.isOpened}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            classes={{ paper: classes.runDialog }}
          >
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
              <Box>
                <DiagnosticReportRun
                  currentScreen={"reportData"}
                  tableId={open.tableId}
                  handleClose={handleClose}
                />
              </Box>
            </DialogContent>
            {/* <DialogActions>
              <DQButton
                onclick={handleClose}
                title="Close"
                variant="contained"
              />
            </DialogActions> */}
          </Dialog>
        )}
      </div>
      {/* paginationFlag && data.length > 10 */}
      {paginationFlag || data.length > 10 && (
        <Grid container style={{ marginTop: "15px" }}>
          <Grid item>
            <Pagination
              count={noOfPages}
              page={page}
              shape="rounded"
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={2}
              className={classes.pagination}
            />
          </Grid>
          <Grid item xs={1}>
            <Select
              onChange={handlePageSizeChange}
              value={pageSize}
              disableUnderline
              className={classes.paginationSelect}
              IconComponent={ExpandMoreIcon}
            >
              {pageSizes.map((size) => (
                <CustomMenuItem
                  key={size}
                  value={size}
                  style={customMenuItemStyle}
                >
                  View {size}
                </CustomMenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      )}{" "}
    </>
  );
};

// # EXPORTS
export default React.memo(DatasetTable);
