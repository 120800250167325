// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.runs-title {
  font-size: 14px;
  color: #46596D;
  width: 40%;
  font-family: "Hind Siliguri";
  font-weight: 600;
}

.no-historical-runs {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.no-historical-runs .no-runs-image {
  width: 15%;
  height: 10%;
}
.no-historical-runs .no-data {
  font-size: 16px;
  font-family: "Hind Siliguri";
  font-weight: 700;
}

.table-name-span {
  color: #4EAFB3;
  font-size: 14px;
  font-family: "Hind Siliguri";
  font-weight: 700;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/js/components/DiagnosticReport/ReportSideLeft/ReportSideLeft.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;EACA,UAAA;EACA,4BAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AAAI;EACI,UAAA;EACA,WAAA;AAER;AAAI;EACI,eAAA;EACA,4BAAA;EACA,gBAAA;AAER;;AAEA;EACI,cAAA;EACA,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".runs-title {\n    font-size: 14px;\n    color: #46596D;\n    width: 40%;\n    font-family: \"Hind Siliguri\";\n    font-weight: 600;\n}\n\n.no-historical-runs {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    .no-runs-image {\n        width: 15%;\n        height: 10%;\n    }\n    .no-data {\n        font-size: 16px;\n        font-family: \"Hind Siliguri\";\n        font-weight: 700;\n    }\n}\n\n.table-name-span {\n    color: #4EAFB3;\n    font-size: 14px; \n    font-family: \"Hind Siliguri\";\n    font-weight: 700;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
