//Import required libraies

import Button from "@material-ui/core/Button";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Import Custom Component

// Import contexts

// Import action creators

// Import utils

// Import styles

const useStyles = makeStyles(() => ({
  contained: {
    alignSelf: "center",
    "&:disabled": {
      backgroundColor: "#A4D4D7",
      cursor: "default",
    },
    minWidth: "100px",
    color: "white",
    backgroundColor: "#4EAFB3",
    border: "0px",
    fontSize: "13px",
    padding: "15px",
    fontFamily: "Hind Siliguri",
    textTransform: "none",
    marginRight: "10px",
    fontWeight: "700",
    "&:hover": {
      borderColor: "#4EAFB3",
      backgroundColor: "#4EAFB3",
      color: "white",
      boxShadow: "none",
    },
  },
  outlined: {
    alignSelf: "center",
    "&:disabled": {
      backgroundColor: "#A4D4D7",
      cursor: "default",
    },
    minWidth: "100px",
    color: "#4EAFB3",
    border: "1px solid #4EAFB3",
    fontSize: "13px",
    padding: "15px",
    fontFamily: "Hind Siliguri",
    textTransform: "none",
    marginRight: "10px",
    fontWeight: "700",
    "&:hover": {
      borderColor: "#4EAFB3",
      backgroundColor: "white",
      color: "#4EAFB3",
      boxShadow: "none",
    },
  },
}));

function DQButton(props) {
  const { title, disabled, variant, onclick = () => {}, styles } = props;
  const classes = useStyles();
  const customStyles = makeStyles({ custom: styles });
  const c = customStyles();

  return (
    <Button
      className={`${classes[variant]} ${c["custom"]}`}
      onClick={onclick}
      disabled={disabled}
    >
      {title}
    </Button>
  );
}

export default DQButton;
