// Import required libraies

import "./TableSelection.scss";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import DQSingleAutocomplete from "../Common/DQSingleAutocomplete";
import Loader from "../Loader/Loader";
import SearchTableSelection from "../SearchTableSelection/SearchTableSelection";
import noDataImage from "../../../assets/images/undraw_void.svg";
import { styled } from "@mui/material/styles";
import { updateUserInputs } from "../../redux/actions";
import Icon from "../../components/Icon/Icon";

// Import Custom Component

// Import data & utils

// Import Api
import {
  getCatalogs,
  getDatabases,
  getLayersData,
  postClusterData,
  getTables,
  listDirectory,
} from "../../services/apis";

// Import actions
import { useNonInitialEffect } from "../../hooks/useNonInitialEffect";

// Import styles

// # STYLES
const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

const MyComponent = styled("div")({
  backgroundColor: "aliceblue",
});

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: "#F6F6F6",
  height: "60vh",
  fontSize: "12px",
  fontcolor: "#465A69",
  marginTop: "6px",
  overflow: "auto",
}));

const useStyles = makeStyles({
  root: {
    "& .MuiListItem-root": {
      textOverflow: "ellipsis",
      padding: "0px 25px !important",
      fontSize: "14px",
      color: "#4eafb3",
      height: "38px",
      fontFamily: "Hind Siliguri !important",
    },
  },
});

// # COMPONENT
const TableSelection = (props) => {
  // # HOOKS
  const dispatch = useDispatch();
  const userInputs = useSelector((state) => state.userInputs);
  const {
    clusters: { clusters = [] },
  } = userInputs;
  const initialAPIData = {
    status: "loading",
    message: "",
    result: [],
  };
  const initialAPIDataInfo = {
    status: "info",
    message: "",
    result: [],
  };
  const [dataLayer, setDataLayer] = useState(initialAPIData);
  const [catalogs, setCatalogs] = useState(initialAPIDataInfo);
  const [databases, setDatabases] = useState(initialAPIDataInfo);
  const [tables, setTables] = useState(initialAPIDataInfo);
  const [currentDataLayer, setCurrentDataLayer] = useState({});
  const [currentCatalog, setCurrentCatalog] = useState({});
  const [currentDatabase, setCurrentDatabase] = useState({});
  const [currentTable, setCurrentTable] = useState([]);
  const [catalogsData, setCatalogsData] = useState([]);
  const [databasesData, setDatabasesData] = useState([]);
  const [tablesData, setTablesData] = useState([]);
  const [criteriaCatalog, setCriteriaCatalog] = useState({ searchText: "" });
  const [criteriaDatabase, setCriteriaDatabase] = useState({ searchText: "" });
  const [criteriaTable, setCriteriaTable] = useState({ searchText: "" });
  const [landingLayerBackButton, setLandingLayerBackButton] = useState({
    toShow: false,
  });

  useEffect(() => {
    // fetch data layer
    const fetchData = async () => {
      setDataLayer({
        status: "loading",
        message: "",
        result: [],
      });
      const reqBody = {
        cluster_id: userInputs.clusterName.id,
      };
      let dataLayerData = await postClusterData(reqBody);
      if (dataLayerData && dataLayerData?.length > 0) {
        setDataLayer({
          status: "success",
          message: "",
          result: dataLayerData,
        });
        setCurrentDataLayer(dataLayerData[0]);
      } else {
        setDataLayer({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      }
    };
    fetchData();
  }, [userInputs.clusterName]);

  // Commenting this code because on changing cluster 2 times every api gets called
  // useNonInitialEffect(() => {
  //   handleChange("layer", currentDataLayer);
  //   getCatalogsFromDataLayers(currentDataLayer);
  // }, [userInputs.clusterName]);

  // # HANDLERS
  const changeConnectedTo = async (e) => {
    dispatch(updateUserInputs("clusterName", e));
    // * This creating the bug when chaning the cluster in catalog api the correct cluster id is not going
    // handleChange("layer", currentDataLayer);
    // getCatalogsFromDataLayers(currentDataLayer);
  };

  const changeCriteriaCatalog = (param, value) => {
    criteriaCatalog[param] = value;
    setCriteriaCatalog({ ...criteriaCatalog });
    if (param === "searchText") {
      let modifiedData = null;
      if (value && value.trim()) {
        modifiedData = catalogsData.filter((obj) =>
          (obj.name || "").toLowerCase().includes(value.toLowerCase())
        );
      } else {
        modifiedData = catalogsData.slice();
      }
      setCatalogs({
        status: "success",
        message: "",
        result: [...modifiedData],
      });
    }
  };

  const changeCriteriaDatabase = (param, value) => {
    criteriaDatabase[param] = value;
    setCriteriaDatabase({ ...criteriaDatabase });
    if (param === "searchText") {
      let modifiedData = null;
      if (value && value.trim()) {
        modifiedData = databasesData.filter((obj) =>
          (obj.name || "").toLowerCase().includes(value.toLowerCase())
        );
      } else {
        modifiedData = databasesData.slice();
      }
      setDatabases({
        status: "success",
        message: "",
        result: [...modifiedData],
      });
    }
  };

  const changeCriteriaTable = (param, value) => {
    criteriaTable[param] = value;
    setCriteriaTable({ ...criteriaTable });
    if (param === "searchText") {
      let modifiedData = null;
      if (value && value.trim()) {
        modifiedData = tablesData.filter((obj) =>
          (obj.name || "").toLowerCase().includes(value.toLowerCase())
        );
      } else {
        modifiedData = tablesData.slice();
      }
      setTables({
        status: "success",
        message: "",
        result: [...modifiedData],
      });
    }
  };

  const setTable = (e, isChecked, newVal) => {
    // setCurrentTable(e);
    dispatch(
      updateUserInputs(
        "tableName",
        isChecked
          ? userInputs.tableName.filter((val) => val.name !== e.name)
          : [...userInputs.tableName, newVal]
      )
    );
  };

  const handleChange = (type, value, isChecked) => {
    let hierarchy = {};
    if (type === "layer") {
      hierarchy = {
        ...userInputs.hierarchy,
        [value.name]: {
          databases: userInputs.hierarchy[value.name]?.databases || [],
        },
      };
    }
    if (type === "catalog") {
      const currentHierarchyVal = userInputs.hierarchy[
        currentDataLayer.name
      ].databases.find((elem) => elem.id === value.id);

      // We implemented this fix because if any catalog has empty table then it disables the next button
      // So we implemented the fix by checking if catalog is changed and corresponding that catalog has
      // empty table then we are not taking that catalog into our hierarchy
      let tillNowDatabase = userInputs.hierarchy[
        currentDataLayer.name
      ].databases.filter((elem) => elem.id !== value.id);
      if (currentTable.length === 0) {
        // ! Here one thing is missing if the current database has previously selected tables then it will lost
        // const lastElement = tillNowDatabase[tillNowDatabase.length - 1];

        tillNowDatabase.pop();
      }
      hierarchy = {
        ...userInputs.hierarchy,
        [currentDataLayer.name]: {
          databases: [
            ...tillNowDatabase,
            { ...value, schemas: currentHierarchyVal?.schemas || [] },
          ],
        },
      };
      setCurrentTable([]);
    }
    if (type === "database") {
      hierarchy = {
        ...userInputs.hierarchy,
        [currentDataLayer.name]: {
          databases: userInputs.hierarchy[currentDataLayer.name].databases.map(
            (elem) => {
              if (elem.id === currentCatalog.id) {
                return {
                  ...elem,
                  schemas: [
                    {
                      ...value,
                      // Previously the tables get reset to empty array if we change the schema/Database from the UI
                      // tables:
                      //   elem?.schemas.find((val) => val.id === value.id)
                      //     ?.tables || [],
                      // In the new code changes we remember the previous selected tables
                      tables: elem?.schemas[0]?.tables
                        ? elem?.schemas.find((val) => val.id === value.id)
                            ?.tables || [...elem?.schemas[0]?.tables]
                        : [],
                    },
                  ],
                };
              } else {
                return elem;
              }
            }
          ),
        },
      };
    }
    if (type === "table") {
      setCurrentTable([...currentTable, value]);
      hierarchy = {
        ...userInputs.hierarchy,
        [currentDataLayer.name]: {
          databases: userInputs.hierarchy[currentDataLayer.name].databases.map(
            (elem) =>
              elem.id === currentCatalog.id
                ? {
                    ...elem,
                    schemas: elem.schemas.map((item) =>
                      item.id === currentDatabase.id
                        ? {
                            ...item,
                            tables: isChecked
                              ? item.tables.filter(
                                  (val) => val.name !== value.name
                                )
                              : [...item.tables, value],
                          }
                        : item
                    ),
                  }
                : elem
          ),
        },
      };
    }
    dispatch(updateUserInputs("hierarchy", hierarchy));
  };

  const deselectAllTables = () => {
    let hierarchy = {
      ...userInputs.hierarchy,
      [currentDataLayer.name]: {
        databases: userInputs.hierarchy[currentDataLayer.name].databases.map(
          (elem) =>
            elem.id === currentCatalog.id
              ? {
                  ...elem,
                  schemas: elem.schemas.map((item) =>
                    item.id !== currentDatabase.id
                      ? item
                      : { ...item, tables: [] }
                  ),
                }
              : elem
        ),
      },
    };
    dispatch(updateUserInputs("hierarchy", hierarchy));
    dispatch(updateUserInputs("tableName", []));
  };

  const handleSelectAllChange = () => {
    const isAllTablesSelected =
      tables.result.length === userInputs.tableName.length;
    if (isAllTablesSelected) {
      let hierarchy = {
        ...userInputs.hierarchy,
        [currentDataLayer.name]: {
          databases: userInputs.hierarchy[currentDataLayer.name].databases.map(
            (elem) =>
              elem.id === currentCatalog.id
                ? {
                    ...elem,
                    schemas: elem.schemas.map((item) =>
                      item.id !== currentDatabase.id
                        ? item
                        : { ...item, tables: [] }
                    ),
                  }
                : elem
          ),
        },
      };
      dispatch(updateUserInputs("hierarchy", hierarchy));
      dispatch(updateUserInputs("tableName", []));
    } else {
      let tablesData = tables.result.map((item) => ({
        ...item,
        metadata: {
          detailed_dataset_id: 0,
          catalog_name: currentCatalog.name,
          catalog_id: currentCatalog.id,
          db_name: currentDatabase.name,
          table_name: item.name,
          layer_name: currentDataLayer.name,
          layer_id: currentDataLayer.id,
        },
      }));
      let hierarchy = {
        ...userInputs.hierarchy,
        [currentDataLayer.name]: {
          databases: userInputs.hierarchy[currentDataLayer.name].databases.map(
            (elem) =>
              elem.id === currentCatalog.id
                ? {
                    ...elem,
                    schemas: elem.schemas.map((item) =>
                      item.id !== currentDatabase.id
                        ? item
                        : { ...item, tables: tablesData }
                    ),
                  }
                : elem
          ),
        },
      };
      dispatch(updateUserInputs("hierarchy", hierarchy));
      dispatch(updateUserInputs("tableName", tablesData));
    }
  };

  // # UTIL FUNCS
  const getCatalogsFromDataLayers = async (e) => {
    // fetch and set Databases
    setCatalogs({
      status: "loading",
      message: "",
      result: [],
    });
    setCurrentDataLayer(e);
    setCurrentCatalog({});
    setCurrentDatabase({});
    // setCurrentTable({});
    dispatch(updateUserInputs("dataLayer", e));
    dispatch(
      updateUserInputs(
        "catalogName",
        userInputs.hierarchy[e.name]?.databases || []
      )
    );
    dispatch(updateUserInputs("databaseName", []));
    dispatch(updateUserInputs("tableName", []));
    setCatalogs({
      status: "info",
      message: "",
      result: [],
    });
    setDatabases({
      status: "info",
      message: "",
      result: [],
    });
    setTables({
      status: "info",
      message: "",
      result: [],
    });

    let databasesData;
    if (e.name === "Landing Layer") {
      let reqObj = {
        cluster_id: userInputs.clusterName.id,
        path: "/mnt/",
      };
      dispatch(updateUserInputs("pathForLandingLayerBlob", ""));
      dispatch(updateUserInputs("pathForLandingLayerBlob", `/mnt/`));
      databasesData = await listDirectory(reqObj);
    } else {
      let reqObj = {
        cluster_id: userInputs.clusterName.id,
        layer_id: e.id,
      };
      databasesData = await getCatalogs(reqObj);
    }

    if (databasesData && databasesData.length > 0) {
      setCatalogs({
        status: "success",
        message: "",
        result: databasesData,
      });
      setCatalogsData(databasesData);
    } else {
      setCatalogs({
        status: "error",
        message: "No result found! Please try again Later!",
        result: [],
      });
      setCatalogsData([]);
    }
  };

  const getDatabasesFromCatalog = async (e) => {
    // fetch and set Databases
    setDatabases({
      status: "loading",
      message: "",
      result: [],
    });
    setCurrentCatalog(e);
    setCurrentDatabase({});
    // setCurrentTable({});
    dispatch(
      updateUserInputs("catalogName", [
        ...userInputs.hierarchy[currentDataLayer.name]?.databases,
        e,
      ])
    );
    const currDB = userInputs.hierarchy[currentDataLayer.name]?.databases.find(
      (val) => val.id === e.id
    );
    dispatch(updateUserInputs("databaseName", currDB?.schemas || []));
    dispatch(updateUserInputs("tableName", []));
    setTables({
      status: "info",
      message: "",
      result: [],
    });
    let databasesData;
    if (currentDataLayer.name === "Landing Layer") {
      const path = `${userInputs.pathForLandingLayerBlob}/${e.path}`;
      let reqObj = {
        cluster_id: userInputs.clusterName.id,
        path: e.path,
      };
      // Path logic is still pending
      dispatch(updateUserInputs("pathForLandingLayerBlob", ""));
      dispatch(updateUserInputs("pathForLandingLayerBlob", path));
      databasesData = await listDirectory(reqObj);
    } else {
      let reqObj = {
        cluster_id: userInputs.clusterName.id,
        meta_catalog_id: e.id,
        layer_id: userInputs.dataLayer.id,
        path:
          userInputs.dataLayer.name === "Landing Layer" ? e.name : undefined,
      };
      databasesData = await getDatabases(reqObj);
    }

    if (databasesData && databasesData.length > 0) {
      setDatabases({
        status: "success",
        message: "",
        result: databasesData,
      });
      setDatabasesData(databasesData);
    } else {
      setDatabases({
        status: "error",
        message: "No result found! Please try again Later!",
        result: [],
      });
      setDatabasesData([]);
    }
  };

  const getTablesFromDatabases = async (
    e,
    apiCall,
    ladingLayerFile = false
  ) => {
    let currTable = userInputs.hierarchy[currentDataLayer.name]?.databases.find(
      (val) => val.id === currentCatalog.id
    );
    currTable =
      currTable?.schemas?.find((elem) => elem.id === e.id)?.tables || [];
    // fetch and set Tables
    setTables({
      status: "loading",
      message: "",
      result: [],
    });
    //  ! Setting the currentDatabase in the first because if the default_selected is true then in the table there is no selected highlighted
    // causeing this line bug in the handleChange table conditon - item.id === currentDatabase.id
    setCurrentDatabase(e);
    // setCurrentTable([]);
    dispatch(
      updateUserInputs("databaseName", [...userInputs?.databaseName, e])
    );
    dispatch(
      updateUserInputs("tableName", [...userInputs.tableName, ...currTable])
    );
    let reqObj = {
      cluster_id: userInputs.clusterName.id,
      meta_catalog_id: currentCatalog?.id,
      layer_id: userInputs?.dataLayer?.id,
      db_name: e.name,
      meta_schema_id: e.id,
      data_set_id: userInputs?.datasetName?.id,
      path:
        userInputs.dataLayer.name === "Landing Layer"
          ? `${currentCatalog.name}/${e.name}`
          : undefined,
    };
    let tablesData;
    if (currentDataLayer.name === "Landing Layer") {
      // * This is for the first time when apiCAll is not true and user selected into landing layer

      // This is used for the caching the database box for the landing layer
      let currentDatabases = databases;
      let currentDatabasesData = databasesData;
      if (ladingLayerFile) {
        setTables({
          status: "error",
          message: "No files to Folder!",
          result: [],
        });
        setTablesData([]);
      }
      if (e.is_dir && apiCall) {
        setTables({
          status: "loading",
          message: "",
          result: [],
        });

        setDatabases({
          status: "loading",
          message: "No result found! Please try again Later!",
          result: [],
        });
        setDatabasesData([]);

        let reqObj = {
          cluster_id: userInputs.clusterName.id,
          path: e.path,
        };
        dispatch(updateUserInputs("pathForLandingLayerBlob", e.path));
        let databasesData = await listDirectory(reqObj);
        // Here we have to write thet logic for the isdir
        if (databasesData[0].is_dir) {
          if (databasesData && databasesData.length > 0) {
            setDatabases({
              status: "success",
              message: "",
              result: databasesData,
            });
            setDatabasesData(databasesData);
          } else {
            setDatabases({
              status: "error",
              message: "No result found! Please try again Later!",
              result: [],
            });
            setDatabasesData([]);
          }
          setTables({
            status: "error",
            message: "No files to Folder!",
            result: [],
          });
          setTablesData([]);
        } else {
          if (databasesData && databasesData.length > 0) {
            //* This is for the last case when the tere is file and folder will be the caching data
            setDatabases(currentDatabases);
            setDatabasesData(currentDatabasesData);

            setTables({
              status: "success",
              message: "",
              result: databasesData,
            });
            setTablesData(databasesData);
          } else {
            setTables({
              status: "error",
              message: "No result found! Please try again Later!",
              result: [],
            });
            setTablesData([]);
          }
        }
      }
      apiCall = false;
    } else {
      tablesData = await getTables(reqObj);
      if (tablesData && tablesData.length > 0) {
        setTables({
          status: "success",
          message: "",
          result: tablesData,
        });
        setTablesData(tablesData);
      } else {
        setTables({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
        setTablesData([]);
      }
    }
    // * this is used at last because to highligt the correct user selection in the database/folder box
    setCurrentDatabase(e);
  };

  const handleDataLayer = (item) => {
    handleChange("layer", item);
    getCatalogsFromDataLayers(item);
    setLandingLayerBackButton({
      toShow: false,
    });
  };

  const handleDatabase = (item) => {
    setLandingLayerBackButton({
      toShow: true,
    });
    let apiCall = true;
    getTablesFromDatabases(item, apiCall, false);
    handleChange("database", item);
  };

  const handleFolderBack = () => {
    let path = databasesData[0].path;
    let newPath = path.slice(0, path.lastIndexOf("/"));
    newPath = newPath.slice(0, newPath.lastIndexOf("/"));

    let reqObj = {
      cluster_id: userInputs.clusterName.id,
      path: newPath,
    };
    if (newPath.split("/").length === 3) {
      setLandingLayerBackButton({
        toShow: false,
      });
    }
    setDatabases({
      status: "loading",
      message: "No result found! Please try again Later!",
      result: [],
    });
    setDatabasesData([]);

    let databasesDataPromise = listDirectory(reqObj);
    databasesDataPromise
      .then((databasesData) => {
        if (databasesData[0].is_dir) {
          if (databasesData && databasesData.length > 0) {
            setDatabases({
              status: "success",
              message: "",
              result: databasesData,
            });
            setDatabasesData(databasesData);
          } else {
            setDatabases({
              status: "error",
              message: "No result found! Please try again Later!",
              result: [],
            });
            setDatabasesData([]);
          }
          setTables({
            status: "error",
            message: "No files to Folder!",
            result: [],
          });
          setTablesData([]);
        }
      })
      .catch(() => {
        setDatabases({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
        setDatabasesData([]);
      });
  };

  // # SIDE EFFECTS
  useEffect(() => {
    if (
      dataLayer.status === "success" &&
      dataLayer.result &&
      dataLayer.result.length > 0
    ) {
      handleChange("layer", dataLayer.result[0]);
      getCatalogsFromDataLayers(dataLayer.result[0]);
    }
    // eslint-disable-next-line
  }, [dataLayer]);

  useEffect(() => {
    if (
      catalogs.status === "success" &&
      catalogs.result &&
      catalogs.result.length > 0
    ) {
      handleChange("catalog", catalogs.result[0]);
      getDatabasesFromCatalog(catalogs.result[0]);
    }
    // eslint-disable-next-line
  }, [catalogs]);

  useEffect(() => {
    if (
      databases.status === "success" &&
      databases.result &&
      databases.result.length > 0
    ) {
      handleChange("database", databases.result[0]);
      getTablesFromDatabases(databases.result[0], false, true);
    }
    // eslint-disable-next-line
  }, [databases]);

  useEffect(() => {
    const updateTables = () => {
      if (
        tables.status === "success" &&
        tables.result &&
        tables.result.length > 0
      ) {
        // ! When to update the table selections?
        // * Only when there is no already existing entry in the hierarchy

        // Make required variables
        let shouldTablesBeUpdated = false;
        let alreadySelectedTablesData = [];
        let filteredTablesWithOnlyDefaultSelected = tables.result.filter(
          (r) => r.default_selected
        );
        filteredTablesWithOnlyDefaultSelected =
          filteredTablesWithOnlyDefaultSelected.map((item) => ({
            ...item,
            metadata: {
              detailed_dataset_id: 0,
              catalog_name: currentCatalog.name,
              catalog_id: currentCatalog.id,
              db_name: currentDatabase.name,
              table_name: item.name,
              layer_name: currentDataLayer.name,
              layer_id: currentDataLayer.id,
            },
          }));

        // Make the updated hierarchy
        let hierarchy = {
          ...userInputs.hierarchy,
          [currentDataLayer.name]: {
            databases: userInputs.hierarchy[
              currentDataLayer.name
            ].databases.map((elem) =>
              elem.id === currentCatalog.id
                ? {
                    ...elem,
                    schemas: elem.schemas.map((item) => {
                      if (item.id === currentDatabase.id) {
                        shouldTablesBeUpdated = item.tables.length === 0;
                        if (shouldTablesBeUpdated) {
                          return {
                            ...item,
                            tables: filteredTablesWithOnlyDefaultSelected,
                          };
                        } else {
                          alreadySelectedTablesData = [...item.tables];
                          return item;
                        }
                      } else {
                        return item;
                      }
                    }),
                  }
                : elem
            ),
          },
        };

        // Update hierarchy which is the source of truth for POST API call
        dispatch(updateUserInputs("hierarchy", hierarchy));

        // Update tableName which drives the UI
        if (shouldTablesBeUpdated) {
          dispatch(
            updateUserInputs("tableName", [
              ...userInputs.tableName,
              ...filteredTablesWithOnlyDefaultSelected,
            ])
          );
        } else {
          dispatch(
            updateUserInputs("tableName", [
              ...userInputs.tableName,
              ...alreadySelectedTablesData,
            ])
          );
        }
      }
    };
    updateTables();
    // eslint-disable-next-line
  }, [tables]);

  // # STATIC VARIABLES
  const isAllTablesSelected =
    tables.result.length === userInputs.tableName.length;
  const classes = useStyles();

  // * THIS IS THE CONDITION TO CHANGE THE TITLE OF THE TABLE LABEL WHEN THER IS LANDING LAYER
  let catalogTitle = "Select Catalog";
  let databaseTitle = "Select Database";
  let tableTitle = "Select Table";
  if (userInputs.dataLayer.name === "Landing Layer") {
    catalogTitle = "Select Storage Account";
    databaseTitle = "Select Folder";
    tableTitle = "Select Reference File";
  }

  const iconProps = {
    className: "material-icons-outlined",
    iconName: "info",
    style: {
      fontSize: "12px",
      marginLeft: "5px",
    },
    tooltipProps: {
      flag: true,
      title: "Select only CSV and Parquet files to avoid errors",
    },
  };

  // # JSX

  return (
    <Box
      sx={{ flexGrow: 1, mx: "auto", m: 5 }}
      className="table-selection-class"
    >
      <MyComponent>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "15px",
          }}
        >
          <label
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              fontFamily: "Hind Siliguri",
              color: "#465a69",
              margin: "10px",
              fontWeight: "bold",
            }}
          >
            Connected to:
          </label>
          <DQSingleAutocomplete
            value={userInputs.clusterName}
            placeholder="Connected To"
            handleChange={changeConnectedTo}
            optionsData={clusters}
            newKey="label"
          />
        </div>
      </MyComponent>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <label className="select-table-label">Select Data Layer</label>
          <Demo>
            {dataLayer.status === "loading" && (
              <div className="loader-class">
                <Loader />
              </div>
            )}
            {dataLayer.status === "success" && (
              <>
                <List dense={false}>
                  {dataLayer.result.map((item, index, list) => {
                    const divClassName =
                      item.name === currentDataLayer.name
                        ? "selected-card"
                        : userInputs?.hierarchy?.hasOwnProperty(item.name)
                        ? "selected-text"
                        : "not-selected-card";
                    const nextSign =
                      item.name === currentDataLayer.name ? (
                        <i className="material-icons-outlined">navigate_next</i>
                      ) : (
                        <></>
                      );
                    return (
                      <ListItem
                        button
                        key={item.name}
                        onClick={() => {
                          handleDataLayer(item);
                        }}
                        className={divClassName}
                      >
                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{
                            fontSize: "14px",
                            fontFamily: "Hind Siliguri",
                          }}
                          sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        />
                        <div className="delete-div">{nextSign}</div>
                      </ListItem>
                    );
                  })}
                </List>
              </>
            )}
            {dataLayer.status === "error" && (
              <div className="no-table-data">
                <img
                  src={noDataImage}
                  alt="noDataImage"
                  style={{ height: "75px" }}
                />
                <p className="no-data">
                  <b>{dataLayer.message}</b>
                </p>
              </div>
            )}
          </Demo>
        </Grid>
        <Grid item xs={12} md={3}>
          <label className="select-table-label">{catalogTitle}</label>
          <Demo>
            {catalogs.status === "loading" && (
              <div className="loader-class">
                <Loader />
              </div>
            )}
            {catalogs.status === "success" && (
              <>
                <div
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    padding: "15px",
                  }}
                >
                  <SearchTableSelection
                    criteria={criteriaCatalog}
                    changeCriteria={changeCriteriaCatalog}
                    placeholderText="Search Catalog Name"
                    width={"100%"}
                    className="search-for-table-information"
                  />
                </div>
                <List dense={false}>
                  {catalogs.result.map((item, index, list) => {
                    const catalogHierarchy = userInputs?.catalogName.filter(
                      (val) => val.id === item.id
                    );
                    const divClassName =
                      item.name === currentCatalog.name
                        ? "selected-card"
                        : catalogHierarchy.length
                        ? "selected-text"
                        : "not-selected-card";
                    const nextSign =
                      item.name === currentCatalog.name ? (
                        <i className="material-icons-outlined">navigate_next</i>
                      ) : (
                        <></>
                      );
                    return (
                      <ListItem
                        button
                        key={item.name}
                        onClick={() => {
                          handleChange("catalog", item);
                          getDatabasesFromCatalog(item);
                        }}
                        className={divClassName}
                      >
                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{
                            fontSize: "14px",
                            fontFamily: "Hind Siliguri",
                          }}
                        />
                        <div className="delete-div">{nextSign}</div>
                      </ListItem>
                    );
                  })}
                </List>
              </>
            )}
            {catalogs.status === "error" && (
              <div className="no-table-data">
                <img
                  src={noDataImage}
                  alt="noDataImage"
                  style={{ height: "75px" }}
                />
                <p className="no-data">
                  <b>{catalogs.message}</b>
                </p>
              </div>
            )}
          </Demo>
        </Grid>
        <Grid item xs={12} md={3}>
          <label className="select-table-label">{databaseTitle}</label>
          <Demo>
            {databases.status === "loading" && (
              <div className="loader-class">
                <Loader />
              </div>
            )}
            {databases.status === "success" && (
              <>
                <div
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    padding: "15px",
                  }}
                >
                  <SearchTableSelection
                    criteria={criteriaDatabase}
                    changeCriteria={changeCriteriaDatabase}
                    placeholderText="Search Database Name"
                    width={"100%"}
                    className="search-for-table-information"
                  />
                </div>
                {landingLayerBackButton.toShow &&
                  currentDataLayer.name === "Landing Layer" && (
                    <span
                      onClick={handleFolderBack}
                      class="material-symbols-outlined"
                      style={{ cursor: "pointer" }}
                    >
                      arrow_back
                    </span>
                  )}
                <List dense={false}>
                  {databases.result.map((item, index, list) => {
                    const DBHierarchy = userInputs?.databaseName.filter(
                      (val) => val.id === item.id
                    );
                    const divClassName =
                      item.name === currentDatabase.name
                        ? "selected-card"
                        : DBHierarchy.length
                        ? "selected-text"
                        : "not-selected-card";
                    const nextSign =
                      item.name === currentDatabase.name ? (
                        <i className="material-icons-outlined">navigate_next</i>
                      ) : (
                        <></>
                      );
                    return (
                      <ListItem
                        button
                        key={item.name}
                        onClick={() => {
                          handleDatabase(item);
                        }}
                        className={divClassName}
                      >
                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{
                            fontSize: "14px",
                            fontFamily: "Hind Siliguri",
                          }}
                        />
                        <div className="delete-div">{nextSign}</div>
                      </ListItem>
                    );
                  })}
                </List>
              </>
            )}
            {databases.status === "error" && (
              <div className="no-table-data">
                <img
                  src={noDataImage}
                  alt="noDataImage"
                  style={{ height: "75px" }}
                />
                <p className="no-data">
                  <b>{databases.message}</b>
                </p>
              </div>
            )}
          </Demo>
        </Grid>
        <Grid item xs={12} md={3}>
          <div style={{ display: "flex" }}>
            <label className="select-table-label">{tableTitle}</label>
            {currentDataLayer.name === "Landing Layer" && (
              <Icon {...iconProps}></Icon>
            )}
          </div>

          <Demo>
            {tables.status === "loading" && (
              <div className="loader-class">
                <Loader />
              </div>
            )}
            {tables.status === "success" && (
              <>
                <div
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    padding: "15px",
                  }}
                >
                  <SearchTableSelection
                    criteria={criteriaTable}
                    changeCriteria={changeCriteriaTable}
                    placeholderText="Search Table Name"
                    width={"100%"}
                    className="search-for-table-information"
                  />
                </div>
                <List dense={false}>
                  {/* <div
                    className="deselect-all"
                    onClick={() => {
                      deselectAllTables();
                    }}
                  >
                    De-select All
                  </div> */}
                  <div style={{ display: "flex" }}>
                    <StyledCheckbox
                      checked={isAllTablesSelected}
                      onChange={handleSelectAllChange}
                    />
                    <ListItem
                      button
                      onClick={() => {
                        deselectAllTables();
                      }}
                      sx={{ textOverflow: "ellipsis" }}
                      // className="not-selected-card"
                      className={classes.root}
                    >
                      <ListItemText
                        primary={"Select All"}
                        primaryTypographyProps={{
                          fontSize: "14px",
                          fontFamily: "Hind Siliguri",
                        }}
                      />
                    </ListItem>
                  </div>
                  {tables.result.map((item, index, list) => {
                    const isChecked = userInputs.tableName.filter(
                      (elem) => elem.name === item.name
                    ).length
                      ? true
                      : false;
                    const divClassName = "not-selected-card";
                    // item.name === currentTable.name
                    //   ? "selected-card"
                    //   : "not-selected-card";
                    return (
                      <div key={item.name} style={{ display: "flex" }}>
                        <StyledCheckbox
                          checked={isChecked}
                          onChange={() => {
                            const newVal = {
                              ...item,
                              metadata: {
                                detailed_dataset_id: 0,
                                catalog_name: currentCatalog.name,
                                catalog_id: currentCatalog.id,
                                db_name: currentDatabase.name,
                                table_name: item.name,
                                layer_name: currentDataLayer.name,
                                layer_id: currentDataLayer.id,
                              },
                            };
                            handleChange("table", newVal, isChecked);
                            setTable(item, isChecked, newVal);
                          }}
                        />
                        <ListItem
                          button
                          onClick={() => {
                            const newVal = {
                              ...item,
                              metadata: {
                                detailed_dataset_id: 0,
                                catalog_name: currentCatalog.name,
                                catalog_id: currentCatalog.id,
                                db_name: currentDatabase.name,
                                table_name: item.name,
                                layer_name: currentDataLayer.name,
                                layer_id: currentDataLayer.id,
                              },
                            };
                            handleChange("table", newVal, isChecked);
                            setTable(item, isChecked, newVal);
                          }}
                          // className={divClassName}
                          className={classes.root}
                        >
                          <ListItemText
                            primary={item.name}
                            style={{ overflowWrap: "anywhere" }}
                            primaryTypographyProps={{
                              fontSize: "14px",
                              fontFamily: "Hind Siliguri",
                            }}
                          />
                        </ListItem>
                      </div>
                    );
                  })}
                </List>
              </>
            )}
            {tables.status === "error" && (
              <div className="no-table-data">
                <img
                  src={noDataImage}
                  alt="noDataImage"
                  style={{ height: "75px" }}
                />
                <p className="no-data">
                  <b>{tables.message}</b>
                </p>
              </div>
            )}
          </Demo>
        </Grid>
      </Grid>
    </Box>
  );
};

// # EXPORTS
export default TableSelection;
