// Import required libraies

import "./Summary.scss";

import React, { useRef, useState } from "react";
import { constructNames, initialApiData } from "../../utils/utils";

import Button from "@material-ui/core/Button";
import DQMultiAutoComplete from "../Common/DQMutliAutocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { v4 } from "uuid";

// Import data & utils

// Import styles

// # STYLES
const useChipStyle = makeStyles(() => ({
  chipStyle: {
    display: "flex",
    flexDirection: "column",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      border: "1px solid #dedede",
      fontSize: 14,
      color: "white !important",
      height: "30px",
      borderRadius: "3px",
      width: "10%",
      margin: "0px 5px 5px 0",
      background: "#4EAFB3",
      "& .MuiChip-deleteIcon": {
        fontSize: 20,
        border: "none",
        color: "white",
      },
    },
  },
}));

const useDialogStyle = makeStyles(() => ({
  dialogStyle: {
    fontFamily: "Hind Siliguri",
    overflow: "auto",
    "& .MuiTypography-h6": {
      fontSize: 14,
      color: "#46596a !important",
    },
    "& .MuiDialogContent-root": {
      fontSize: 12,
      color: "#46596a !important",
      opacity: 0.8,
    },
    "& .MuiButton-textPrimary": {
      color: "#46596a",
    },
    "& .MuiButton-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 14,
    },
    "& .MuiDialogContent-dividers": {
      padding: "0px 24px",
    },
  },
}));

// # HELPER COMPONENTS
function ScrollDialog(props) {
  // # PROPS
  const { data, dialogOpen, setDialogOpen, elem } = props;
  const classes = useDialogStyle();

  // # HANDLERS
  const handleClose = () => {
    setDialogOpen({ id: "", value: false });
  };

  // # JSX
  return (
    <div className={classes.dialogStyle}>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        className={classes.dialogStyle}
      >
        <DialogTitle id="scroll-dialog-title">All Selected Tables</DialogTitle>
        <DialogContent dividers={true}>
          {data?.map((value, index) => {
            return <div key={index}>{value[elem]}</div>;
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// # HELPER COMPONENTS
function TestControlDisplayRow(props) {
  // # PROPS
  const { tableSchemaNames, elem } = props;
  // # STATIC VARIABLES
  const classes = useChipStyle();

  // # JSX
  return (
    <>
      <div
        className={classes.chipStyle}
        style={{
          display: "flex",
        }}
      >
        {tableSchemaNames?.map((value, index) => (
          <div key={index} style={{ paddingLeft: "30px" }}>
            {value[elem]}
          </div>
        ))}
      </div>
    </>
  );
}

// # COMPONENT
const Summary = (props) => {
  // # PROPS
  const { setActiveStep, setResetFlag } = props;

  // # HOOKS
  const userInputs = useSelector((state) => state.userInputs);
  const [dialogOpen, setDialogOpen] = useState({ id: "", value: false });
  const [rowIcon, setRowIcon] = useState({});

  // # HANDLERS
  const handleChange = (name) => {
    setRowIcon({ ...rowIcon, [name]: !rowIcon[name] });
  };

  // # STATIC VARIABLES
  let tableSchemaNames = Object.entries(userInputs.hierarchy)
    .map((elem) => constructNames(elem))
    .flat();

  const [tagsSelections, setTagsSelections] = useState([]); // selections
  const [tagsData, setTagsData] = useState(initialApiData);
  const autoC = useRef(null);

  const handleTagsChange = (event, newValue) => {
    let newOptions = tagsData.result.filter((x) => newValue.includes(x));

    setTagsSelections(newOptions);
  };

  const deleteValues = (regValue) => {
    let valArr = tagsSelections.filter(
      (singleValue) => singleValue !== regValue
    );
    setTagsSelections(orderBy([...valArr]));
  };

  // # JSX
  return (
    <div style={{ display: "flex" }}>
      {/* <div className="summary-container">
        <div style={{ width: "80%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className="text-placeholder">Tags</p>
            <div className="support-input-search">
              <DQMultiAutoComplete
                value={tagsSelections}
                handleChange={handleTagsChange}
                placeholderText="Add tags"
                isDisabled={false}
                autoC={autoC}
                deleteValues={deleteValues}
                optionsData={tagsData.result || []}
                styles={{ height: 120, minWidth: "initial" }}
              />
            </div>
          </div>
        </div>
      </div> */}
      <div
        style={{
          paddingLeft: "50px",
          width: "100%",
          height: "calc(100vh - 350px)",
          overflowY: "auto",
        }}
      >
        {Object.keys(userInputs.hierarchy).map((elem) => {
          const data = tableSchemaNames.filter(
            (record) => record[elem] !== undefined
          );
          return (
            <div className="sub-data" key={v4()}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  {rowIcon?.[elem] ? (
                    <i
                      className={`material-icons-outlined minus-icon`}
                      onClick={() => handleChange(elem)}
                    >
                      add_box
                    </i>
                  ) : (
                    <i
                      className={`material-icons-outlined minus-icon`}
                      onClick={() => handleChange(elem)}
                    >
                      indeterminate_check_box
                    </i>
                  )}
                  <p className="normal-text">{elem}</p>
                </div>
                {/* 
                <div>
                  <i
                    className={`material-icons-outlined layer-edit-icon`}
                    onClick={() => setActiveStep(1)}
                  >
                    edit
                  </i>
                </div>
              */}
              </div>
              {!rowIcon?.[elem] && (
                <TestControlDisplayRow
                  tableSchemaNames={data}
                  elem={elem}
                  setDialogOpen={setDialogOpen}
                />
              )}
              <ScrollDialog
                data={data}
                dialogOpen={dialogOpen.id === elem}
                setDialogOpen={setDialogOpen}
                elem={elem}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

// # EXPORTS
export default Summary;
