import React from "react";
import PropTypes from "prop-types";

import "./SearchTableSelection.scss";

function Search({
  criteria,
  changeCriteria,
  width,
  placeholderText = "",
  className,
  isDisabled
}) {
  return (
    <div className={`filter-new ${className}`}>
      <div className="input-search" style={{ width }}>
        <i className={`material-icons-outlined search-icon `}>search</i>
        <input
          type="text"
          id="search"
          name="search"
          value={criteria.searchText}
          className="input-label"
          onChange={(e) => changeCriteria("searchText", e.target.value)}
          placeholder={placeholderText || "Search"}
          autoComplete="off"
          disabled={isDisabled}
        />
        <i
          className={`material-icons-outlined cancel-icon ${
            criteria.searchText ? "visible" : ""
          }`}
          onClick={() => changeCriteria("searchText", "")}
        >
          clear
        </i>
      </div>
    </div>
  );
}

Search.propTypes = {
  criteria: PropTypes.any.isRequired,
  changeCriteria: PropTypes.any.isRequired,
  width: PropTypes.any,
  placeholderText: PropTypes.any.isRequired,
};

Search.defaultProps = {
  width: "52%",
};

//Using memo will cause React to skip rendering a component if its props have not changed. This can improve performance.
export default React.memo(Search);
