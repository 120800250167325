// Import required libraries

import "./ReportFiltersPopup.scss";

import { Chip, Popover } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  updateDRFilters,
  updateData,
  updateSelections,
  updateUserInfo,
} from "../../../redux/actions";

import DQMultiAutoCompleteWithCheckbox from "../../Common/DQMultiAutoCompleteWithCheckbox";
import DQSingleAutocomplete from "../../Common/DQSingleAutocomplete";
import Loader from "../../Loader/Loader";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

// Import styles

// Import action creators

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    height: "40px",
    marginTop: "2px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "4px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
  paper: {
    height: "auto",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    padding: "1px",
  },
  chipStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    border: "1px solid #dedede",
    color: "white !important",
    height: "30px",
    borderRadius: "3px",
    width: "fit-content",
    maxWidth: "90%",
    margin: "12px",
    background: "#4EAFB3",
    "& .MuiChip-deleteIcon": {
      fontSize: 20,
      border: "none",
      color: "white",
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function ReportFiltersPopup(props) {
  let {
    user,
    data,
    userInputs,
    updateDRFilters,
    runDateObj,
    syncDRFilters,
    updateSelections,
    updateReportFiltersPopup = false,
    filterOpen = "",
    dateRangeData = [],
    dateRangeSelection = {},
    setDateRangeSelection = () => {},
  } = props;

  const initialFilters = {
    ...user.selections.DRFilters,
  };
  const [filterSelections, setFilterSelections] = useState(initialFilters);
  const classes = useStyles();
  const [filtersData, setFiltersData] = useState(
    data.tableDiagnosticReportFilters.result || []
  );
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (data.tableDiagnosticReportFilters.status === "success") {
      const orgArray = data.tableDiagnosticReportFilters.result;
      if (!isEmpty(orgArray)) setFiltersData(orgArray);
    }
  }, [data.tableDiagnosticReportFilters]);

  useEffect(() => {
    if (updateReportFiltersPopup) {
      setFilterUpdations();
    }
  }, [updateReportFiltersPopup]);

  const handleAutoCompleteChange = (newValue, name) => {
    if (filterSelections[`${name}`] !== newValue) {
      updatingSelections(name, newValue);
    }
  };

  const deleteValues = (name, regValue) => {
    let valArr = filterSelections[`${name}`].filter(
      (singleValue) => singleValue !== regValue
    );
    updatingSelections(name, [...valArr]);
  };

  const getFilterData = (filterObj, filterSelections) => {
    return filterObj.data.filter((obj) => {
      if ("COUNTRY_CODE" in obj && "COUNTRY_CODE" in filterSelections) {
        if (Array.isArray(filterSelections["COUNTRY_CODE"])) {
          let userSelections = filterSelections["COUNTRY_CODE"].map(
            (c) => c.id
          );

          if (userSelections.includes("ALL")) return true;

          if (obj["COUNTRY_CODE"].some((r) => userSelections.includes(r))) {
            return true;
          } else return false;
        } else {
          if (filterSelections["COUNTRY_CODE"] == "ALL") return true;

          if (obj["COUNTRY_CODE"].includes(filterSelections["COUNTRY_CODE"])) {
            return true;
          } else return false;
        }
      } else return true;
    });
  };

  const deleteValuesForChip = (name, regValue) => {
    let valArr = filterSelections[`${name}`].filter(
      (singleValue) => singleValue.name !== regValue
    );
    if (valArr.length === 0) {
      updatingSelections(name, userInputs.initialDRFilters[name]);
      updateDRFilters(name, userInputs.initialDRFilters[name]);
    } else {
      updatingSelections(name, [...valArr]);
      updateDRFilters(name, [...valArr]);
    }
  };

  const openPopup = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopup = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const updatingSelections = (catName, trgtValue) => {
    let filterselection = filterSelections;
    filterselection[`${catName}`] = trgtValue;
    setFilterSelections({ ...filterselection });
  };

  const setFilterUpdations = () => {
    let fltrSelections = filterSelections;
    for (const prop in fltrSelections) {
      updateDRFilters(prop, fltrSelections[`${prop}`]);
    }
  };

  const resetFilters = () => {
    setFilterSelections({
      ...userInputs.initialDRFilters,
    });
    updateSelections("DRFilters", userInputs.initialDRFilters);
  };

  const getFilterSelectionsForPopover = () => {
    return Object.keys(filterSelections).map((key, index) => {
      return (
        <>
          <div className="row filter-popover-label">{key}</div>
          <div className="row">
            {Array.isArray(filterSelections[key]) ? (
              <>
                {filterSelections[key].filter((e) => e.name !== "ALL").length >
                0 ? (
                  filterSelections[key].map((option, index) => (
                    <Chip
                      className={classes.chipStyle}
                      key={option.id}
                      label={option.name}
                      variant="outlined"
                      onDelete={() => deleteValuesForChip(key, option.name)}
                      deleteIcon={
                        <i className="material-icons-outlined">clear</i>
                      }
                    />
                  ))
                ) : (
                  <div className="no-selections">No Selections Available</div>
                )}
              </>
            ) : (
              <>
                {filterSelections[key] !== "ALL" ? (
                  <Chip
                    label={filterSelections[key]}
                    className={classes.chipStyle}
                    onDelete={() => {
                      updateDRFilters(key, "ALL");
                      updatingSelections(key, "ALL");
                    }}
                    deleteIcon={
                      <i className="material-icons-outlined">clear</i>
                    }
                  />
                ) : (
                  <div className="no-selections">No Selections Available</div>
                )}
              </>
            )}
          </div>
        </>
      );
    });
  };
  const style1 = { paddingTop: "10px" };
  const styleForFilterRunButton = filterOpen === "filteredRun" ? style1 : {};
  const style2 = { minWidth: "600px" };
  const sytleforFiterContainer = filterOpen === "filteredRun" ? style2 : {};
  return (
    <>
      {data.tableDiagnosticReportFilters.status === "loading" && (
        <div className="loader-filters">
          <span className="loader-filters-text">
            Fetching the latest filter values
          </span>
          <Loader />
        </div>
      )}
      {data.tableDiagnosticReportFilters.status === "success" ? (
        Object.keys(data.tableDiagnosticReportFilters.result).length > 0 ? (
          <>
            {filtersData ? (
              <div className="filters" style={sytleforFiterContainer}>
                {filterOpen === "filteredRun" && (
                  <div className="select-container">
                    <label
                      style={{
                        fontWeight: "600",
                        color: "#eb367f",
                      }}
                      htmlFor="Date Range"
                    >
                      Date Range
                    </label>
                    <Select
                      name="Date Range"
                      id="Date Range"
                      value={dateRangeSelection.value}
                      onChange={(e) => {
                        setDateRangeSelection({ value: e.target.value });
                      }}
                      className={classes.multiSelect}
                      MenuProps={MenuProps}
                      title="Date Range"
                      style={{ minWidth: "100px" }}
                    >
                      {dateRangeData.map((obj) => (
                        <MenuItem
                          value={obj.name}
                          key={obj.id}
                          className={classes.singleMenuItem}
                          title={obj.name}
                        >
                          {obj.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
                {filtersData.map((filterObj) => (
                  <div
                    className={`report-filter-item ${filterObj.name}`}
                    key={filterObj.name}
                  >
                    {filterObj.type === "text" ? (
                      false
                    ) : filterObj.type === "singleSelect" ? (
                      <div className="select-container">
                        <label htmlFor={filterObj.name}>
                          {filterObj.label}
                        </label>
                        <Select
                          name={filterObj.name}
                          id={filterObj.name}
                          value={filterSelections[`${filterObj.name}`]}
                          onChange={(e) => {
                            updatingSelections(filterObj.name, e.target.value);
                          }}
                          className={classes.multiSelect}
                          MenuProps={MenuProps}
                          title={filterSelections[`${filterObj.name}`]}
                        >
                          {getFilterData(filterObj, filterSelections).map(
                            (obj) => (
                              <MenuItem
                                value={obj.name}
                                key={obj.id}
                                className={classes.singleMenuItem}
                                title={obj.name}
                              >
                                {obj.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </div>
                    ) : filterObj.type === "multiSelect" ? (
                      <>
                        <DQMultiAutoCompleteWithCheckbox
                          name={filterObj.name}
                          value={filterSelections[`${filterObj.name}`]}
                          onChange={(e, value) =>
                            handleAutoCompleteChange(e, filterObj.name)
                          }
                          placeholderText={`Enter ${filterObj.name}`}
                          disableFlag={false}
                          data={
                            getFilterData(filterObj, filterSelections) || []
                          }
                          deleteValues={(e) => deleteValues(filterObj.name, e)}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
            {filterOpen !== "filteredRun" && (
              <div className="filter-info">
                <i
                  onMouseOver={openPopup}
                  className="material-icons filter-alt-icon"
                >
                  info
                </i>
              </div>
            )}
            <div
              className="report-filters-btns"
              style={styleForFilterRunButton}
            >
              <button
                className="sync-filter-btn"
                onClick={() => {
                  syncDRFilters(runDateObj);
                }}
              >
                Sync Filters
              </button>
              <button
                className="reset-filter-btn"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset
              </button>
              {filterOpen !== "filteredRun" && (
                <button
                  className="apply-filter-btn"
                  onClick={() => {
                    setFilterUpdations();
                  }}
                >
                  Apply Filters
                </button>
              )}
            </div>
            {
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={closePopup}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  style: { width: "20%" },
                }}
              >
                <div className="report-filter-popover">
                  {getFilterSelectionsForPopover()}
                </div>
              </Popover>
            }
          </>
        ) : (
          <div className="no-filters">
            <i className="material-icons-outlined filter-error-icon">
              sync_problem
            </i>
            No Filters Available.
          </div>
        )
      ) : (
        <></>
      )}
      {data.tableDiagnosticReportFilters.status === "error" && (
        <div className="no-filters">
          <i className="material-icons-outlined filter-error-icon">
            sync_problem
          </i>
          {data.tableDiagnosticReportFilters.message}
        </div>
      )}
    </>
  );
}

ReportFiltersPopup.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  userInputs: PropTypes.object,
  updateData: PropTypes.func,
  updateDRFilters: PropTypes.func,
  updateSelections: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateData,
  updateDRFilters,
  updateUserInfo,
  updateSelections,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportFiltersPopup);
