// Import required libraies
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";

import "./ColumnContainer.scss";

// Import Custom Component
import DQButton from "../Common/DQButton";
import ColumnSelection from "./ColumnSelection/ColumnSelection";
import { DataProfiling } from "../DataProfiling";
import ClusterInformation from "../ClusterInfromation/ClusterInformation";
import LandinLayerInformation from "../LandingLayerInformation/LandingLayerInformation";
import HistoryInformation from "../HistoryInformation/HistoryInformation";

// Import data & utils
import {
  updateSelections,
  updateAlertInfo,
  updateChangeFlagUiRules,
  updateData,
} from "../../redux/actions";
import { filterMyData } from "../../utils/utils";
import config from "../../config/config";
import {
  unwrapperTablesAddedForDatabase,
  wrapperTableAddedForDatabase,
} from "../RulesSelection/schema";

import { updateTable } from "../../services/apis";

// # HELPER COMPONENTS
function TabPanel(props) {
  // # PROPS
  const { children, value, index } = props;

  // # JSX
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabs-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div style={{ paddingTop: "10px" }}>{children}</div>}
    </div>
  );
}

// # COMPONENT
const ColumnContainer = (props) => {
  // # PROPS
  const { datasetDetailsData } = props;
  const [tabNames, setTabNames] = useState([]);
  const [value, setValue] = React.useState(0);

  // HOOKS
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.data);
  const userInputs = useSelector((state) => state.userInputs);
  const changeFlag = useSelector((state) => state.changeFlag);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (user.selections.columnSelected.layer_name === "Landing Layer") {
      setTabNames([
        { name: "Columns Information" },
        { name: "Data Profiling" },
        { name: "Cluster Information" },
        { name: "Schema Information" },
        { name: "Landing Layer" },
      ]);
    } else {
      setTabNames([
        { name: "Columns Information" },
        { name: "Data Profiling" },
        { name: "Cluster Information" },
        { name: "Schema Information" },
      ]);
    }

    return () => {
      dispatch(updateSelections("columnSelectionPage", false));
    };
  }, []);

  // # STATIC VARIABLES
  const filteredData = filterMyData(datasetDetailsData || [], user.selections);

  const redirectToTableInformation = () => {
    dispatch(updateSelections("columnSelectionPage", false));
  };
  const setNextTabOrSave = async () => {
    // Show a snackbar on the UI
    dispatch(
      updateAlertInfo({
        open: true,
        message: config.messages.datasetSaveRequestMessage,
        severity: "info",
      })
    );

    try {
      const updateTablePayload = wrapperTableAddedForDatabase({
        user,
        userInputs,
        data,
      });
      let respData = await updateTable(updateTablePayload);
      if (respData && respData.status && respData.status) {
        history.go(0);
        dispatch(
          updateAlertInfo({
            open: true,
            message: config.messages.datasetUpdationResponseMessage,
            severity: "success",
          })
        );
        dispatch(updateChangeFlagUiRules(false));
        // trigger categoryTreeData reload
        dispatch(
          updateData("categoryTreeData", {
            status: "loading",
            message: "",
            result: [],
          })
        );
      } else {
        dispatch(
          updateAlertInfo({
            open: true,
            message: config.messages.datasetUpdationFailedMessage,
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: config.messages.datasetUpdationFailedMessage,
          severity: "error",
        })
      );

      console.error("error in MyDataset.js > setNextTabOrSave");
      console.log(error);
    }
  };

  // # HANDLERS
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // # JSX
  return (
    <>
      <div>
        <div className="models-menu">
          {tabNames.map((tabName, index) => (
            <div
              key={tabName.name}
              className={`models-item`}
              onClick={(e) => {
                handleChange(e, index);
              }}
            >
              <div
                className={`models-item-div ${
                  index === value ? "active" : "not-active"
                }`}
              >
                <p>{tabName.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <>
        <TabPanel value={value} index={0}>
          <ColumnSelection
            tableDetails={filteredData}
            datasetDetailsData={datasetDetailsData}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <DataProfiling />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <ClusterInformation />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <HistoryInformation />
        </TabPanel>

        {user.selections.columnSelected.layer_name === "Landing Layer" && (
          <TabPanel value={value} index={4}>
            <LandinLayerInformation />
          </TabPanel>
        )}

        {!(value === 1 || value === 2 || value === 3 || value === 4 ) && (
          <Box
            sx={{
              display: "flex",
              position: "fixed",
              bottom: 40,
              right: 0,
              mr: 2,
            }}
          >
            <Link className="disabled-style" to={"/home/mydataset"}>
              <DQButton
                title="Cancel"
                disabled={false}
                variant="outlined"
                onclick={() => {
                  redirectToTableInformation();
                }}
              />
            </Link>

            <DQButton
              title={"Update"}
              disabled={!changeFlag.uiRules}
              variant="contained"
              onclick={setNextTabOrSave}
            />
          </Box>
        )}
      </>
    </>
  );
};

// # EXPORTS
export default ColumnContainer;
