//Import required libraies

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import React from "react";
import TextField from "@material-ui/core/TextField";
import config from "../../config/config";

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    marginTop: "1px",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
      height: "28px",
      borderRadius: "12px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']":
      {
        padding: "2px",
        paddingRight: "65px !important",
      },
    "& .MuiFormControl-fullWidth": {
      width: "100%",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
  },
  root: {
    fontFamily: "Hind Siliguri !important",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  chipStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    border: "1px solid #dedede",
    color: "white !important",
    height: "30px",
    borderRadius: "3px",
    width: "fit-content",
    maxWidth: "90%",
    margin: "12px",
    background: "#4EAFB3",
    "& .MuiChip-deleteIcon": {
      fontSize: 20,
      border: "none",
      color: "white",
    },
  },
}));

const StyledAutoComplete = withStyles((theme) => ({
  tag: {
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function DQMultiAutoCompleteWithCheckbox(props) {
  let {
    data,
    name,
    value,
    disableFlag,
    onChange,
    placeholderText,
    deleteValues,
    allOption = true,
  } = props;
  const muiClass = useStyles();

  const handleChange = (e, value) => {
    let resultArr;
    if (value.length) {
      if (value[value.length - 1].name === config.hardCoded.allSelection) {
        resultArr = [{ id: "ALL", name: "ALL" }];
      } else {
        resultArr = value.filter(
          (item) => item.name !== config.hardCoded.allSelection
        );
        resultArr = [...new Set(resultArr)];
      }
    } else {
      resultArr = allOption ? [{ id: "ALL", name: "ALL" }] : [];
    }
    onChange(resultArr);
  };

  return (
    <div
      className={`autocomplete-container ${
        disableFlag ? "disabled-pointer" : ""
      }`}
    >
      <label className="bold header-text">{name}</label>
      <StyledAutoComplete
        multiple
        getOptionSelected={(option, value) => option.id === value.id}
        id="autocomplete"
        value={value}
        disableCloseOnSelect
        limitTags={5}
        onChange={(event, value) => {
          handleChange(event, value);
        }}
        options={data}
        getOptionLabel={(option) => option.name || ""}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <div key={option.id} title={option.name}>
              <Chip
                className={muiClass.chipStyle}
                label={option.name}
                onDelete={() => deleteValues(option)}
                {...getTagProps({ index })}
              />
            </div>
          ))
        }
        size="small"
        className={muiClass.typographyStyle}
        renderOption={(option, state) => (
          <>
            <StyledCheckbox checked={state.selected} />
            {option.name}
          </>
        )}
        renderInput={(params) => (
          <CssTextField
            {...params}
            placeholder={placeholderText}
            variant="outlined"
            size="small"
            className={muiClass.typographyStyle}
          />
        )}
      />
    </div>
  );
}

export default DQMultiAutoCompleteWithCheckbox;
