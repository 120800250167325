// * Import 3rd party lib
import { styled } from "@material-ui/styles";
import { Tooltip } from "@material-ui/core";

// * Define required styled components
const StyledIcon = styled(
  "span",
  {}
)(({ theme, customProps }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  color: theme.palette.hexToRGBA(theme.palette.secondary.main, 0.7),
  "&:hover": {
    color: theme.palette.hexToRGBA(theme.palette.primary.main, 0.7),
  },
  disabledIcon: {
    pointerEvents: "none",
    opacity: 0.5,
  },
}));

function Icon(props) {
  const {
    className = "material-icons",
    iconName = "home",
    style = {},
    onClick = () => {},
    tooltipProps = {},
  } = props;
  const { flag: tooltipFlag = false, title: tooltipTitle = "" } = tooltipProps;

  return (
    <>
      {tooltipFlag && (
        <Tooltip title={tooltipTitle}>
          <StyledIcon
            className={`${className}`}
            style={style}
            onClick={onClick}
          >
            {iconName}
          </StyledIcon>
        </Tooltip>
      )}
      {!tooltipFlag && (
        <StyledIcon className={`${className}`} style={style} onClick={onClick}>
          {iconName}
        </StyledIcon>
      )}
    </>
  );
}

export default Icon;
