// Import required libraries

import "./CompareDatasetsListTable.scss";

import { Box, Grid, Select, TableBody, TableRow } from "@material-ui/core";
import {
  CustomMenuItem,
  StyledTableCell,
  customMenuItemStyle,
  useStyles,
} from "./style";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import {
  deleteAdminPanelTeams,
  putAdminPanelTeams,
  runCompareDataset,
  deleteCompareDataset,
  compareDatasetdownloadReportCSV,
} from "../../../services/apis";
import {
  resetSelections,
  updateAlertInfo,
  updateDialogInfo,
  updateUserInputs,
} from "../../../redux/actions";

import CompareDatasetsListTableHeader from "./CompareDatasetsListTableHeader";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../components/Loader/Loader";
import { Pagination } from "@material-ui/lab";
import PropTypes from "prop-types";
import config from "../../../config/config";
import { connect } from "react-redux";
import noDataImage from "../../../../assets/images/undraw_void.svg";
import { useDispatch } from "react-redux";
import useTable from "../../../utils/useTable";

// Import data & utils

// Import action creators

// Import styles

const CompareDatasetsListTable = ({
  data = [],
  paginationFlag,
  criteria,
  allData = [],
  loading,
  user,
  refreshList,
}) => {
  const classes = useStyles();
  const filterFn = { fn: (items) => items };
  const dispatch = useDispatch();

  const history = useHistory();

  const { TblContainer } = useTable(data, filterFn, {}, () => {}, criteria);

  const pageSizes = [...config.hardCoded.pageSizes];

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  //reset filters on screen change
  useEffect(() => {
    return () => {};
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const displayRecords =
    data.length > page
      ? [...data].splice((page - 1) * pageSize, pageSize)
      : data;
  const noOfPages = Math.ceil(data.length / pageSize);

  const handleDelete = async (item) => {
    dispatch(updateDialogInfo({ ...user.dialogInfo, open: false }));
    dispatch(
      updateAlertInfo({
        open: true,
        message: config.configRemoveRequestMessage,
        severity: "info",
      })
    );
    let reqObj = {
      id: item.id,
    };
    let responseData = await deleteAdminPanelTeams(reqObj);
    if (responseData.status) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: config.configSuccessRemoveMessage,
          severity: "success",
        })
      );
      refreshList();
    }
  };

  const handleViewClick = useCallback((item) => {
    history.push(`/home/comparedatasets/report/${item.id}`);
  });

  const downloadCsvFile = (csvData = "", fileName = "csvFile") => {
    var blob = new Blob([csvData]);
    const url = window.URL.createObjectURL(blob, {
      type: "text/csv",
    });
    const hiddenElement = document.createElement("a");
    hiddenElement.href = url;
    hiddenElement.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(hiddenElement);
    hiddenElement.click();

    // Clean up and remove the hiddenElement
    hiddenElement.parentNode.removeChild(hiddenElement);
  };

  const handleDownloadAsCSV = async (item) => {
    let fetchProps = {
      id: item.id,
    };
    let reportDetails = await compareDatasetdownloadReportCSV(fetchProps);
    downloadCsvFile(reportDetails, item.report_name);
  };

  return (
    <>
      <div className="admin-userslist-table">
        <TblContainer>
          <CompareDatasetsListTableHeader allData={allData} />

          {loading ? (
            <TableBody className="no-configs">
              <TableRow>
                <StyledTableCell colSpan={8} style={{ height: "48vh" }}>
                  <Loader />
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data?.length ? (
            <TableBody>
              {displayRecords.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableRow} key={index} hover>
                      <StyledTableCell
                        style={{ cursor: "pointer" }}
                        onClick={() => handleViewClick(item)}
                      >
                        {item.report_name}
                      </StyledTableCell>
                      <StyledTableCell>{item.team}</StyledTableCell>
                      <StyledTableCell>{item.run_on}</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                          }}
                        >
                          <Box sx={{ marginRight: 20 }}>
                            {item.run_on !== "-" && (
                              <a
                                className="disabled-style"
                                title={"download"}
                                onClick={() => handleDownloadAsCSV(item)}
                              >
                                <i
                                  className={`material-icons-outlined`}
                                  style={{ fontSize: 22, color: "#46596a" }}
                                >
                                  download
                                </i>
                              </a>
                            )}
                            <a
                              className="disabled-style"
                              title={"delete"}
                              onClick={async () => {
                                dispatch(
                                  updateDialogInfo({
                                    title: `Do you want to delete Compare Dataset Report ?`,
                                    message: `for Report Name: ${item.report_name}`,
                                    open: true,
                                    handleYes: async () => {
                                      dispatch(
                                        updateDialogInfo({
                                          ...user.dialogInfo,
                                          open: false,
                                        })
                                      );

                                      let responseData =
                                        await deleteCompareDataset({
                                          id: item.id,
                                        });

                                      if (responseData.status) {
                                        dispatch(
                                          updateAlertInfo({
                                            open: true,
                                            message:
                                              "Deleted Compare Dataset is:  " +
                                              item.id,
                                            severity: "success",
                                          })
                                        );
                                        refreshList();
                                      } else {
                                        dispatch(
                                          updateAlertInfo({
                                            open: true,
                                            message: "Can't delete this report",
                                            severity: "success",
                                          })
                                        );
                                      }
                                    },
                                    handleNo: () => {
                                      dispatch(
                                        updateDialogInfo({
                                          ...user.dialogInfo,
                                          open: false,
                                        })
                                      );
                                    },
                                  })
                                );
                              }}
                            >
                              <i
                                className={`material-icons-outlined`}
                                style={{ fontSize: 22, color: "#46596a" }}
                              >
                                delete
                              </i>
                            </a>
                            <Link
                              style={{ marginRight: 10 }}
                              className="disabled-style"
                              to={`/home/comparedatasets/report/${item.id}`}
                            >
                              <i
                                className={`material-icons-outlined`}
                                style={{ fontSize: 22, color: "#46596a" }}
                              >
                                visibility
                              </i>
                            </Link>
                            <a
                              className="disabled-style"
                              title={"Run/Rerun"}
                              onClick={async () => {
                                dispatch(
                                  updateDialogInfo({
                                    title: `Do you want re|run Compare Dataset Report ?`,
                                    message: `for Report Name: ${item.report_name}`,
                                    open: true,
                                    handleYes: async () => {
                                      dispatch(
                                        updateDialogInfo({
                                          ...user.dialogInfo,
                                          open: false,
                                        })
                                      );

                                      let responseData =
                                        await runCompareDataset({
                                          id: item.id,
                                        });

                                      if (responseData.status) {
                                        dispatch(
                                          updateAlertInfo({
                                            open: true,
                                            message:
                                              "Requested Run for Compare Dataset Id: " +
                                              item.id,
                                            severity: "success",
                                          })
                                        );
                                        refreshList();
                                      }
                                    },
                                    handleNo: () => {
                                      dispatch(
                                        updateDialogInfo({
                                          ...user.dialogInfo,
                                          open: false,
                                        })
                                      );
                                    },
                                  })
                                );
                              }}
                            >
                              <i
                                className={`material-icons-outlined`}
                                style={{ fontSize: 22, color: "#46596a" }}
                              >
                                play_arrow
                              </i>
                            </a>
                          </Box>
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          ) : (
            <TableBody className="no-configs">
              <TableRow>
                <StyledTableCell colSpan={8} style={{ height: "48vh" }}>
                  <img
                    src={noDataImage}
                    alt="noDataImage"
                    className="no-data-image"
                  />
                  <p className="no-data">
                    <b>{config.messages.noRecordsFound}</b>
                  </p>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </TblContainer>
      </div>
      {paginationFlag && data.length > 10 && (
        <Grid container style={{ marginTop: "15px" }}>
          <Grid item>
            <Pagination
              count={noOfPages}
              page={page}
              shape="rounded"
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={2}
              className={classes.pagination}
            />
          </Grid>
          <Grid item xs={1}>
            <Select
              onChange={handlePageSizeChange}
              value={pageSize}
              disableUnderline
              className={classes.paginationSelect}
              IconComponent={ExpandMoreIcon}
            >
              {pageSizes.map((size) => (
                <CustomMenuItem
                  key={size}
                  value={size}
                  style={customMenuItemStyle}
                >
                  View {size}
                </CustomMenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      )}
    </>
  );
};

CompareDatasetsListTable.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateDialogInfo,
  updateAlertInfo,
  updateUserInputs,
  resetSelections,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CompareDatasetsListTable));
