// ### Imports
// * Import lib

import React, { useState } from "react";
import {
  selectActiveDatabase,
  selectActiveTable,
  selectAllReduxData,
} from "../../../redux/selectors";

import Checkbox from "../../../kit/Checkbox";
import Grid from "@material-ui/core/Grid";
import MenuItem from "../../../kit/MenuItem";
import Select from "../../../kit/Select";
import TextField from "../../../kit/TextField";
import Typography from "../../../kit/Typography";
import config from "../../../config/config";
import { makeStyles } from "@material-ui/core/styles";
import { useNonInitialEffect } from "../../../hooks/useNonInitialEffect";
import { useSelector } from "react-redux";
import { v4 } from "uuid";

// * Import Kit

// * Import utils

const conditionOptions = [
  {
    id: "greater_than",
    name: "Greater than",
  },
  {
    id: "less_than",
    name: "Less than",
  },
  {
    id: "greater_than_equal",
    name: "Greater than equal to",
  },
  {
    id: "less_than_equal",
    name: "Less than equal to",
  },
  {
    id: "equal_to",
    name: "Equal to",
  },
  {
    id: "not_equal",
    name: "Not equal to",
  },
  {
    id: "like_alt",
    name: "Like ALT",
  },
  {
    id: "not_like_alt",
    name: "Not Like ALT",
  },
];

const condition2Options = [
  {
    id: "in",
    name: "In",
  },
  {
    id: "not_in",
    name: "Not In",
  },
];

// # UTILITY FUNCTIONS
const modifyDataForSelect = (data) =>
  data.map((row) => ({ id: row.name, name: row.name }));

// # STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  leftMiddle: { display: "flex", alignItems: "center" },
  centerMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  select: { width: "100% !important" },
}));

// # HELPER COMPONENTS
const CommonSelect = (props = {}) => {
  // # PROPS
  const {
    value,
    options,
    onChange,
    formKey,
    disabled = false,
    placeholder,
    id,
  } = props;

  // # HANDLERS
  const handleChange = (e) => {
    onChange(formKey, e.target.value, id);
  };

  // # STATIC VARIABLES
  const classes = useStyles();

  // # JSX
  return (
    <Select
      id={id}
      className={classes.select}
      value={value}
      displayEmpty
      inputProps={{ "aria-label": "Secondary Color" }}
      color="secondary"
      onChange={handleChange}
      MenuProps={config.hardCoded.MenuProps}
      renderValue={(selected) => {
        if (["", undefined, null].includes(selected)) {
          return placeholder || <p>Select column</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
        }
        let label = options.find((row) => row.id === selected)?.name;
        return label;
      }}
      disabled={disabled}
      title={value}
    >
      {options.length ? (
        options.map((option) => (
          <MenuItem key={option.id} value={option.id} title={option.name}>
            {option.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="" disabled>
          No Options
        </MenuItem>
      )}
    </Select>
  );
};

// # COMPONENT
function CustomFieldCompareWithConditionSAP(props = {}) {
  // # PROPS
  const { columnName, onChange, selections } = props;

  // # STATES
  const [formSelections, setFormSelections] = useState(selections);
  const [error, setError] = useState("");

  useNonInitialEffect(() => {
    onChange(formSelections);
  }, [formSelections]);

  // # SELECTORS
  const allReduxData = useSelector(selectAllReduxData);
  const activeDatabase = useSelector(selectActiveDatabase);
  const activeTable = useSelector(selectActiveTable);

  // # HANDLERS

  const handleDelete = (id) => {
    // console.log("localState", localState);
    if (formSelections.length > 1) {
      let newData = formSelections.filter((item) => item.id !== id);
      // console.log("newData", newData);
      setFormSelections(newData);
      setError("");
    } else {
      setError("Cannot delete last selection");
    }
  };

  const handleChange = (key, value, id) => {
    const updatedLocalData = formSelections.map((val) =>
      val.id === id
        ? {
            ...val,
            [key]: value,
            ...(key === "tableName" && { columnName: "" }),
          }
        : { ...val }
    );
    setFormSelections(updatedLocalData);
  };

  // # STATIC VARIABLES
  const classes = useStyles();
  const allColumnOptions =
    allReduxData.allColumns.result[0].children.find(
      (row) => row.name === activeTable
    )?.children || [];
  const allTablesData =
    allReduxData.allColumns.result.find((row) => row.name === activeDatabase)
      ?.children || [];
  const allTablesOptions = modifyDataForSelect(
    allReduxData.allColumns.result.find((row) => row.name === activeDatabase)
      ?.children || []
  );
  const existFromColumnNameOptions = modifyDataForSelect(
    allTablesData.find((row) => row.name === activeTable)?.children || []
  );
  const condColumnNameOptions = modifyDataForSelect(
    allTablesData.find((row) => row.name === activeTable)?.children || []
  );

  // Add empty test row
  const addNewRow = () => {
    setFormSelections([
      ...formSelections,
      {
        id: v4(),
        isEnabled: false,
        threshold: 0,
        primaryTableName: "",
        primaryColumnName: "",
        existFromColumnName: "",
        existToTableName: "",
        existToColumnName: "",
        condColumnName: "",
        condition: "",
        condColumnName2: "",
        condition2: "",
        conditionString: "",
      },
    ]);
  };

  // # DEBUGGER

  // # JSX
  return (
    <div className={classes.root}>
      {formSelections.map((rowElem, index) => {
        return (
          <Grid container spacing={1}>
            <Checkbox
              id={rowElem.id}
              checked={rowElem.isEnabled}
              value="isEnabled"
              inputProps={{ "aria-label": "secondary checkbox" }}
              disabled={false}
              variant="secondary"
              size="small"
              onClick={(e) =>
                handleChange(e.target.value, !rowElem.isEnabled, rowElem.id)
              }
              style={{ margin: "0px 0 0 -10px" }}
            />
            <Grid item xs={2} className={classes.rightMiddle}>
              <Typography>
                Check if all records in {columnName} is same as
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.centerMiddle}>
              <CommonSelect
                id={rowElem.id}
                formKey="primaryTableName"
                value={rowElem["primaryTableName"]}
                options={allTablesOptions}
                onChange={handleChange}
                disabled={false}
                placeholder="Select table"
              />
            </Grid>
            <Grid item xs={2} className={classes.centerMiddle}>
              <CommonSelect
                id={rowElem.id}
                formKey={"primaryColumnName"}
                value={rowElem["primaryColumnName"]}
                options={
                  allTablesData.find(
                    (row) => row.name === rowElem["primaryTableName"]
                  )?.children || []
                }
                onChange={handleChange}
                disabled={rowElem["primaryTableName"] === ""}
                placeholder="Select column"
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={2} className={classes.rightMiddle}>
              <Typography>Provided that</Typography>
            </Grid>
            <Grid item xs={2} className={classes.centerMiddle}>
              <CommonSelect
                id={rowElem.id}
                formKey={"existFromColumnName"}
                value={rowElem["existFromColumnName"]}
                options={existFromColumnNameOptions}
                onChange={handleChange}
                disabled={false}
                placeholder="Select column"
              />
            </Grid>
            <Grid item xs={2} className={classes.rightMiddle}>
              <Typography>Is equal to</Typography>
            </Grid>
            <Grid item xs={2} className={classes.centerMiddle}>
              <CommonSelect
                id={rowElem.id}
                formKey="existToTableName"
                value={rowElem["existToTableName"]}
                options={allTablesOptions}
                onChange={handleChange}
                disabled={false}
                placeholder="Select table"
              />
            </Grid>
            <Grid item xs={2} className={classes.centerMiddle}>
              <CommonSelect
                id={rowElem.id}
                formKey={"existToColumnName"}
                value={rowElem["existToColumnName"]}
                options={
                  allTablesData.find(
                    (row) => row.name === rowElem["existToTableName"]
                  )?.children || []
                }
                onChange={handleChange}
                disabled={rowElem["existToTableName"] === ""}
                placeholder="Select column"
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2} className={classes.rightMiddle}>
              <Typography>Where</Typography>
            </Grid>
            <Grid item xs={2} className={classes.centerMiddle}>
              <CommonSelect
                id={rowElem.id}
                formKey={"condColumnName"}
                value={rowElem["condColumnName"]}
                options={condColumnNameOptions}
                onChange={handleChange}
                disabled={false}
                placeholder="Select column"
              />
            </Grid>
            <Grid item xs={2} className={classes.centerMiddle}>
              <CommonSelect
                id={rowElem.id}
                formKey={"condition"}
                value={rowElem["condition"]}
                options={conditionOptions}
                onChange={handleChange}
                disabled={false}
                placeholder="Select condition"
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={2} className={classes.rightMiddle}>
              <Typography>And</Typography>
            </Grid>
            <Grid item xs={2} className={classes.rightMiddle}>
              <CommonSelect
                id={rowElem.id}
                formKey={"condColumnName2"}
                value={rowElem["condColumnName2"]}
                options={allColumnOptions}
                onChange={handleChange}
                disabled={false}
                formSelections={formSelections}
              />
            </Grid>
            <Grid item xs={2} className={classes.centerMiddle}>
              <CommonSelect
                id={rowElem.id}
                formKey={"condition2"}
                value={rowElem["condition2"]}
                options={condition2Options}
                onChange={handleChange}
                disabled={false}
                placeholder="Select condition"
              />
            </Grid>
            <Grid item xs={2} className={classes.centerMiddle}>
              <TextField
                id={rowElem.id}
                value={rowElem["conditionString"]}
                placeholder="Enter value"
                onChange={(e) =>
                  handleChange("conditionString", e.target.value, rowElem.id)
                }
              />
            </Grid>
            <Grid item xs={4} className={classes.rightMiddle}>
              <TextField
                id="standard-required"
                type="number"
                value={rowElem["threshold"]}
                onChange={(e) =>
                  handleChange("threshold", e.target.value, rowElem.id)
                }
                style={{ width: "75px" }}
              />
            </Grid>
            <p
              style={{
                fontSize: "14px",
                padding: "0px 20px 0px 0px",
                color: "#4eafb3",
                cursor: "pointer",
                margin: "0px 0px 0px 10px",
              }}
              onClick={() => handleDelete(rowElem.id)}
            >
              Delete
            </p>
            {index === formSelections.length - 1 && (
              <p
                style={{
                  fontSize: "14px",
                  padding: "0px 20px 0px 0px",
                  color: "#4eafb3",
                  cursor: "pointer",
                  margin: "0px 0px 0px 10px",
                }}
                onClick={() => addNewRow()}
              >
                Add New Row
              </p>
            )}
            <hr
              style={{
                gridColumn: "1 / span 12",
                width: "100%",
                borderColor: "#ffffff40",
              }}
            />
          </Grid>
        );
      })}
    </div>
  );
}

// # EXPORTS
export default CustomFieldCompareWithConditionSAP;
