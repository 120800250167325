import { combineReducers } from "redux";
import user from "./user";
import data from "./data";
import userInputs from "./userInputs";
import changeFlag from "./changeFlag";

export default combineReducers({
  user,
  data,
  userInputs,
  changeFlag,
});
