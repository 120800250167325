// Import required libraries

import "./TeamsListTable.scss";

import { Box, Grid, Select, TableBody, TableRow } from "@material-ui/core";
import {
  CustomMenuItem,
  StyledTableCell,
  customMenuItemStyle,
  useStyles,
} from "./style";
import React, { useCallback, useEffect, useState } from "react";
import {
  deleteAdminPanelTeams,
  putAdminPanelTeams,
} from "../../../../services/apis";
import {
  resetSelections,
  updateAlertInfo,
  updateDialogInfo,
  updateUserInputs,
} from "../../../../redux/actions";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../../components/Loader/Loader";
import { Pagination } from "@material-ui/lab";
import PropTypes from "prop-types";
import TeamsListTableHeader from "./TeamsListTableHeader";
import UpdateTeamDialog from "../UpdateTeamDialog";
import config from "../../../../config/config";
import { connect } from "react-redux";
import noDataImage from "../../../../../assets/images/undraw_void.svg";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useTable from "../../../../utils/useTable";

// Import data & utils

// Import action creators

// Import styles

const TeamsListTable = ({
  data = [],
  paginationFlag,
  criteria,
  allData = [],
  loading,
  user,
  refreshList,
}) => {
  const classes = useStyles();
  const filterFn = { fn: (items) => items };
  const dispatch = useDispatch();

  const history = useHistory();

  const { TblContainer } = useTable(data, filterFn, {}, () => {}, criteria);

  const pageSizes = [...config.hardCoded.pageSizes];

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  //reset filters on screen change
  useEffect(() => {
    return () => {};
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const displayRecords =
    data.length > page
      ? [...data].splice((page - 1) * pageSize, pageSize)
      : data;
  const noOfPages = Math.ceil(data.length / pageSize);

  const handleDelete = async (item) => {
    dispatch(updateDialogInfo({ ...user.dialogInfo, open: false }));
    dispatch(
      updateAlertInfo({
        open: true,
        message: config.configRemoveRequestMessage,
        severity: "info",
      })
    );
    let reqObj = {
      id: item.id,
    };
    let responseData = await deleteAdminPanelTeams(reqObj);
    if (responseData.status) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: config.configSuccessRemoveMessage,
          severity: "success",
        })
      );
      refreshList();
    }
  };

  const handleNo = () => {
    dispatch(updateDialogInfo({ ...user.dialogInfo, open: false }));
  };

  const [selectedTeam, setSelectedTeam] = React.useState({});
  const [dialogTeamOpen, setDialogTeamOpen] = React.useState(false);

  const handleUpdate = async (newName, newGridData) => {
    /* if (selectedTeam.name === newName.toLowerCase()) {
      return;
    } */
    /* if (allData.some((i) => i.name.toLowerCase() === newName.toLowerCase())) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: "Team with same name already exists.",
          severity: "success",
        })
      );
      return;
    } */

    let reqObj = {
      ...selectedTeam,
      name: newName,
      users: newGridData,
    };

    let responseData = await putAdminPanelTeams(reqObj);

    if ("id" in responseData) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: `Team Name Updated from "${selectedTeam.name}" to "${responseData.name}"`,
          severity: "success",
        })
      );
      refreshList();
    } else {
      dispatch(
        updateAlertInfo({
          open: true,
          message: "Something went wrong...",
          severity: "error",
        })
      );
    }
  };

  const handleUpdateTeamDialog = useCallback(
    (item) => {
      setSelectedTeam(item);
      setDialogTeamOpen(true);
    },
    [selectedTeam, dialogTeamOpen]
  );

  return (
    <>
      <div className="admin-userslist-table">
        <TblContainer>
          <TeamsListTableHeader allData={allData} />

          {loading ? (
            <TableBody className="no-configs">
              <TableRow>
                <StyledTableCell colSpan={8} style={{ height: "48vh" }}>
                  <Loader />
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data?.length ? (
            <TableBody>
              {displayRecords.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableRow} key={index} hover>
                      <StyledTableCell>
                        <Box
                          sx={{
                            userSelect: "none",
                            fontWeight: 600,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleUpdateTeamDialog(item);
                          }}
                        >
                          {item.name}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{item.owner}</StyledTableCell>
                      <StyledTableCell>{item.last_modified_by}</StyledTableCell>
                      <StyledTableCell>{item.last_modified_on}</StyledTableCell>
                      {/*  <StyledTableCell>
                        {

                            item.users.map((i) => <>{i.email}</>)

                        }
                      </StyledTableCell> */}
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Box sx={{ marginRight: 20 }}>
                            <i
                              className={`material-icons-outlined`}
                              onClick={() => handleUpdateTeamDialog(item)}
                            >
                              edit
                            </i>
                          </Box>
                          <Box>
                            <i
                              style={{ userSelect: "none", cursor: "pointer" }}
                              onClick={() => {
                                dispatch(
                                  updateDialogInfo({
                                    title: `${config.messages.confirmRemove}`,
                                    message: `${item.name}`,
                                    open: true,
                                    handleYes: () => {
                                      dispatch(
                                        resetSelections(
                                          "adminTeamsListNameSearch"
                                        )
                                      );
                                      handleDelete(item);
                                    },
                                    handleNo: handleNo,
                                  })
                                );
                              }}
                              className="material-icons-outlined"
                            >
                              delete
                            </i>
                          </Box>
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          ) : (
            <TableBody className="no-configs">
              <TableRow>
                <StyledTableCell colSpan={8} style={{ height: "48vh" }}>
                  <img
                    src={noDataImage}
                    alt="noDataImage"
                    className="no-data-image"
                  />
                  <p className="no-data">
                    <b>{config.messages.noRecordsFound}</b>
                  </p>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </TblContainer>
      </div>
      {paginationFlag && data.length > 10 && (
        <Grid container style={{ marginTop: "15px" }}>
          <Grid item>
            <Pagination
              count={noOfPages}
              page={page}
              shape="rounded"
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={2}
              className={classes.pagination}
            />
          </Grid>
          <Grid item xs={1}>
            <Select
              onChange={handlePageSizeChange}
              value={pageSize}
              disableUnderline
              className={classes.paginationSelect}
              IconComponent={ExpandMoreIcon}
            >
              {pageSizes.map((size) => (
                <CustomMenuItem
                  key={size}
                  value={size}
                  style={customMenuItemStyle}
                >
                  View {size}
                </CustomMenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      )}
      <UpdateTeamDialog
        open={dialogTeamOpen}
        currentName={selectedTeam.name}
        currentUser={selectedTeam}
        onSubmit={handleUpdate}
        askForClose={() => {
          setDialogTeamOpen(false);
        }}
      />
    </>
  );
};

TeamsListTable.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateDialogInfo,
  updateAlertInfo,
  updateUserInputs,
  resetSelections,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TeamsListTable));
