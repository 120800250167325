// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amt-dv-user {
  flex: 1 1;
}

.amt-label {
  font-size: 14px;
  font-family: "Hind Siliguri";
  color: #465A69;
  font-weight: 550;
}`, "",{"version":3,"sources":["webpack://./src/js/screens/AdminPanel/AccessManagmentTab/AccessManagementTab.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;AACJ;;AAEA;EACI,eAAA;EACA,4BAAA;EACA,cAAA;EACA,gBAAA;AACJ","sourcesContent":[".amt-dv-user {\n    flex: 1\n}\n\n.amt-label {\n    font-size: 14px;\n    font-family: \"Hind Siliguri\";\n    color: #465A69;\n    font-weight: 550;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
