// Import required libraies

import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {
  getCategoryData,
  getDatasetData,
  getEnvironmentData,
  getPrimaryDomainOptions,
  getSecondaryDomainOptions,
  getSubDataDomainOptions,
} from "../../services/apis";
import { updateData, updateUserInputs } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import DQMultiAutoComplete from "../Common/DQMutliAutocomplete";
import DQSingleAutocomplete from "../Common/DQSingleAutocomplete";
import config from "../../config/config";
import { initialApiData } from "../../utils/utils";
import { isString } from "lodash";
import orderBy from "lodash/orderBy";
import { useNonInitialEffect } from "../../hooks/useNonInitialEffect";

// Import Custom hooks

// Import Custom Component

// Import data & utils

// Import actions

// # STYLES
const useStyles = makeStyles(() => ({
  metadataLabel: {
    fontSize: "14px",
    fontFamily: "Hind Siliguri",
    color: "#465a69",
    fontWeight: 550,
  },
  asterik: {
    color: "#f10000",
    marginLeft: "2px",
  },
  tags: {
    flex: "1",
  },
}));

// # COMPONENT
const TableInformation = ({ match, resetFlag }) => {
  // # HOOKS
  const classes = useStyles();
  const autoC = useRef(null);
  const dispatch = useDispatch();
  const userInputs = useSelector((state) => state.userInputs);
  const data = useSelector((state) => state.data);
  const [categoryName, setCategoryName] = useState({});
  const [primaryDomain, setPrimaryDomain] = useState({});
  const [secondaryDomain, setSecondaryDomain] = useState({});
  const [subDomain, setSubDomain] = useState({});
  const [environment, setEnvironment] = useState({});
  const [datasetName, setDatasetName] = useState({});
  const [categoryOptions, setCategoryOptions] = useState(initialApiData);
  const [environmentOptions, setEnvironmentOptions] = useState(initialApiData);
  const [datasetOptions, setDatasetOptions] = useState(initialApiData);
  const [primaryDomainOptions, setPrimaryDomainOptions] =
    useState(initialApiData);
  const [secondaryDomainOptions, setSecondaryDomainOptions] =
    useState(initialApiData);
  const [subDataDomainOptions, setSubDataDomainOptions] =
    useState(initialApiData);
  const [duplicateTagError, setDuplicateTagError] = useState(false);
  const [tagsSelections, setTagsSelections] = useState([]); // selections
  const [tagsData, setTagsData] = useState({
    status: "loading",
    data: [],
    message: "",
  });

  useEffect(() => {
    // this is needed when we navigate from summary page to dataset info page
    if (match?.params?.tab === "managedatasets" && resetFlag) {
      if (userInputs?.categoryName) setCategoryName(userInputs?.categoryName);
      if (userInputs?.primaryDomain)
        setPrimaryDomain(userInputs?.primaryDomain);
      if (userInputs?.secondaryDomain)
        setSecondaryDomain(userInputs?.secondaryDomain);
      if (userInputs?.subDataDomain) setSubDomain(userInputs?.subDataDomain);
      if (userInputs?.datasetName) setDatasetName(userInputs?.datasetName);
      if (userInputs?.environment) setEnvironment(userInputs?.environment);
      if (userInputs?.tags) setTagsSelections(userInputs?.tags);
    }
    return () => {
      dispatch(
        updateData("dataTableDetails", {
          status: "loading",
          result: {},
          message: "",
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchCategoryName = async () => {
      setCategoryOptions({
        status: "loading",
        message: "",
        result: [],
      });
      let layersData = await getCategoryData();
      if (layersData && layersData.length > 0) {
        setCategoryOptions({
          status: "success",
          message: "",
          result: layersData,
        });
        if (match?.params?.tab === "mydataset") {
          let newVal = data?.datasetDetails?.result?.category_name
            ? layersData.find(
                (elem) => elem.name === data.datasetDetails.result.category_name
              )
            : {};
          setCategoryName(newVal);
          dispatch(updateUserInputs("categoryName", newVal));
        }
      } else {
        setCategoryOptions({
          status: "error",
          message: "",
          result: [],
        });
      }
    };
    const fetchEnvironmtneData = async () => {
      setEnvironmentOptions({
        status: "loading",
        message: "",
        result: [],
      });
      let layersData = await getEnvironmentData();
      if (layersData && layersData.length > 0) {
        setEnvironmentOptions({
          status: "success",
          message: "",
          result: layersData,
        });
      } else {
        setEnvironmentOptions({
          status: "error",
          message: "",
          result: [],
        });
      }
    };
    fetchCategoryName();
    fetchEnvironmtneData();
    // eslint-disable-next-line
  }, []);

  //categoryName
  useNonInitialEffect(async () => {
    if (match?.params?.tab === "managedatasets" && categoryName) {
      let reqObj = {
        categoryId: categoryName.id,
      };
      let primaryDomainOptions = await getPrimaryDomainOptions(reqObj);
      if (primaryDomainOptions && primaryDomainOptions?.length > 0) {
        setPrimaryDomainOptions({
          status: "success",
          message: "",
          result: primaryDomainOptions,
        });
      } else {
        setPrimaryDomainOptions({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      }
    }
    if (match?.params?.tab === "mydataset" && categoryName) {
      let reqObj = {
        categoryId: categoryName.id,
      };
      let primaryDomainOptions = await getPrimaryDomainOptions(reqObj);
      if (primaryDomainOptions && primaryDomainOptions?.length > 0) {
        setPrimaryDomainOptions({
          status: "success",
          message: "",
          result: primaryDomainOptions,
        });
        let newVal = data?.datasetDetails?.result?.primary_data_domain_name
          ? primaryDomainOptions.find(
              (elem) =>
                elem.name ===
                data.datasetDetails.result.primary_data_domain_name
            )
          : {};
        setPrimaryDomain(newVal);
        dispatch(updateUserInputs("primaryDomain", newVal));
      } else {
        setPrimaryDomainOptions({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      }
    }
  }, [categoryName]);

  //primaryDomain
  useNonInitialEffect(async () => {
    if (match?.params?.tab === "managedatasets" && primaryDomain?.id) {
      setSecondaryDomainOptions({
        status: "loading",
        result: [],
        message: "",
      });
      let reqObj = {
        primaryDomainId: primaryDomain.id,
      };
      let secondaryDomainOptionsResult = await getSecondaryDomainOptions(
        reqObj
      );
      if (
        secondaryDomainOptionsResult &&
        secondaryDomainOptionsResult.length > 0
      ) {
        setSecondaryDomainOptions({
          status: "success",
          result: secondaryDomainOptionsResult,
          message: "",
        });
      } else {
        setSecondaryDomainOptions({
          status: "error",
          result: [],
          message: "No result found! Please try again Later!",
        });
      }
    }
    if (match?.params?.tab === "mydataset" && primaryDomain) {
      setSecondaryDomainOptions({
        status: "loading",
        result: [],
        message: "",
      });
      let reqObj = {
        primaryDomainId: primaryDomain.id,
      };
      let secondaryDomainOptionsResult = await getSecondaryDomainOptions(
        reqObj
      );
      if (
        secondaryDomainOptionsResult &&
        secondaryDomainOptionsResult.length > 0
      ) {
        setSecondaryDomainOptions({
          status: "success",
          result: secondaryDomainOptionsResult,
          message: "",
        });
        let newVal = data?.datasetDetails?.result?.secondary_data_domain_name
          ? secondaryDomainOptionsResult.find(
              (elem) =>
                elem.name ===
                data.datasetDetails.result.secondary_data_domain_name
            )
          : {};
        setSecondaryDomain(newVal);
        dispatch(updateUserInputs("secondaryDomain", newVal));
      } else {
        setSecondaryDomainOptions({
          status: "error",
          result: [],
          message: "No result found! Please try again Later!",
        });
      }
    }
  }, [primaryDomain]);

  //secondaryDomain
  useNonInitialEffect(async () => {
    if (match?.params?.tab === "managedatasets" && secondaryDomain) {
      let reqObj = {
        primaryDomainId: "",
        secondaryDomainId: secondaryDomain.id,
      };
      let subDataDomainOptionsResult = await getSubDataDomainOptions(reqObj);
      if (subDataDomainOptionsResult && subDataDomainOptionsResult.length > 0) {
        setSubDataDomainOptions({
          status: "success",
          result: subDataDomainOptionsResult,
          message: "",
        });
      } else {
        setSubDataDomainOptions({
          status: "error",
          result: [],
          message: "No result found! Please try again Later!",
        });
      }
    }
    if (match?.params?.tab === "mydataset" && secondaryDomain) {
      let reqObj = {
        primaryDomainId: "",
        secondaryDomainId: secondaryDomain.id,
      };
      let subDataDomainOptionsResult = await getSubDataDomainOptions(reqObj);
      if (subDataDomainOptionsResult && subDataDomainOptionsResult.length > 0) {
        setSubDataDomainOptions({
          status: "success",
          result: subDataDomainOptionsResult,
          message: "",
        });
        let newVal = data?.datasetDetails?.result?.sub_data_domain_name
          ? subDataDomainOptionsResult.find(
              (elem) =>
                elem.name === data.datasetDetails.result.sub_data_domain_name
            )
          : {};
        setSubDomain(newVal);
        dispatch(updateUserInputs("subDataDomain", newVal));
      } else {
        setSubDataDomainOptions({
          status: "error",
          result: [],
          message: "No result found! Please try again Later!",
        });
      }
    }
  }, [secondaryDomain]);

  //subDomain
  useNonInitialEffect(async () => {
    if (match?.params?.tab === "managedatasets" && subDomain) {
      setDatasetOptions({
        status: "loading",
        message: "",
        result: [],
      });
      let reqObj = {
        subDataDomainId: subDomain.id,
      };
      let layersData = await getDatasetData(reqObj);
      if (layersData && layersData.length > 0) {
        setDatasetOptions({
          status: "success",
          message: "",
          result: layersData,
        });
      } else {
        setDatasetOptions({
          status: "error",
          message: "",
          result: [],
        });
      }
    }
    if (match?.params?.tab === "mydataset" && subDomain) {
      setDatasetOptions({
        status: "loading",
        message: "",
        result: [],
      });
      let reqObj = {
        subDataDomainId: subDomain.id,
      };
      let layersData = await getDatasetData(reqObj);
      if (layersData && layersData.length > 0) {
        setDatasetOptions({
          status: "success",
          message: "",
          result: layersData,
        });
        let newVal = data?.datasetDetails?.result?.name
          ? layersData.find(
              (elem) => elem.name === data.datasetDetails.result.name
            )
          : {};
        setDatasetName(newVal);
        dispatch(updateUserInputs("datasetName", newVal));
      } else {
        setDatasetOptions({
          status: "error",
          message: "",
          result: [],
        });
      }
    }
  }, [subDomain]);

  //environment
  useEffect(() => {
    const setEnvironmentFn = async () => {
      if (
        match?.params?.tab === "mydataset" &&
        environmentOptions.status === "success"
      ) {
        let newVal = data?.datasetDetails?.result?.environment_name
          ? environmentOptions.result.find(
              (elem) =>
                elem.name === data.datasetDetails.result.environment_name
            )
          : {};
        setEnvironment(newVal);
        dispatch(updateUserInputs("environment", newVal));
      }
    };
    setEnvironmentFn();
    // eslint-disable-next-line
  }, [environmentOptions]);

  //tags
  useEffect(() => {
    const updateTags = async () => {
      if (match?.params?.tab === "mydataset") {
        setTagsSelections(data.datasetDetails.result.tags);
        dispatch(updateUserInputs("tags", data.datasetDetails.result.tags));
      }
    };
    updateTags();
    // eslint-disable-next-line
  }, []);

  // # HANDLERS
  const changeCategoryName = async (e) => {
    setCategoryName(e);
    setPrimaryDomain("");
    setSecondaryDomain("");
    setSubDomain("");
    setDatasetName({});
    dispatch(updateUserInputs("categoryName", e));
    dispatch(updateUserInputs("primaryDomain", ""));
    dispatch(updateUserInputs("secondaryDomain", ""));
    dispatch(updateUserInputs("subDataDomain", ""));
    dispatch(updateUserInputs("datasetName", ""));
    let reqObj = {
      categoryId: e.id,
    };
    let primaryDomainOptions = await getPrimaryDomainOptions(reqObj);
    if (primaryDomainOptions && primaryDomainOptions?.length > 0) {
      setPrimaryDomainOptions({
        status: "success",
        message: "",
        result: primaryDomainOptions,
      });
      let newVal = data?.datasetDetails?.result?.primary_data_domain_name
        ? primaryDomainOptions.find(
            (elem) =>
              elem.name === data.datasetDetails.result.primary_data_domain_name
          )
        : {};
      if (match?.params?.tab === "mydataset") setPrimaryDomain(newVal);
    } else {
      setPrimaryDomainOptions({
        status: "error",
        message: "No result found! Please try again Later!",
        result: [],
      });
    }
  };

  const changePrimaryDomain = async (e) => {
    setSecondaryDomainOptions({
      status: "loading",
      result: [],
      message: "",
    });
    setPrimaryDomain(e);
    setSecondaryDomain("");
    setSubDomain("");
    setDatasetName("");
    dispatch(updateUserInputs("primaryDomain", e));
    dispatch(updateUserInputs("secondaryDomain", ""));
    dispatch(updateUserInputs("subDataDomain", ""));
    dispatch(updateUserInputs("datasetName", ""));
    let reqObj = {
      primaryDomainId: e.id,
    };
    let secondaryDomainOptionsResult = await getSecondaryDomainOptions(reqObj);
    if (
      secondaryDomainOptionsResult &&
      secondaryDomainOptionsResult.length > 0
    ) {
      setSecondaryDomainOptions({
        status: "success",
        result: secondaryDomainOptionsResult,
        message: "",
      });
    } else {
      setSecondaryDomainOptions({
        status: "error",
        result: [],
        message: "No result found! Please try again Later!",
      });
    }
  };

  const changeSecondaryDomain = async (e) => {
    setSecondaryDomain(e);
    setSubDomain("");
    setDatasetName({});
    dispatch(updateUserInputs("secondaryDomain", e));
    dispatch(updateUserInputs("subDataDomain", ""));
    dispatch(updateUserInputs("datasetName", ""));
    let reqObj = {
      primaryDomainId: "",
      secondaryDomainId: e.id,
    };
    let subDataDomainOptionsResult = await getSubDataDomainOptions(reqObj);
    if (subDataDomainOptionsResult && subDataDomainOptionsResult.length > 0) {
      setSubDataDomainOptions({
        status: "success",
        result: subDataDomainOptionsResult,
        message: "",
      });
    } else {
      setSubDataDomainOptions({
        status: "error",
        result: [],
        message: "No result found! Please try again Later!",
      });
    }
  };

  const changeSubDomain = async (e) => {
    setSubDomain(e);
    setDatasetName({});
    dispatch(updateUserInputs("subDataDomain", e));
    dispatch(updateUserInputs("datasetName", ""));
    setDatasetOptions({
      status: "loading",
      message: "",
      result: [],
    });
    let reqObj = {
      subDataDomainId: e.id,
    };
    let layersData = await getDatasetData(reqObj);
    if (layersData && layersData.length > 0) {
      setDatasetOptions({
        status: "success",
        message: "",
        result: layersData,
      });
    } else {
      setDatasetOptions({
        status: "error",
        message: "",
        result: [],
      });
    }
  };

  const changeEnvironment = (e) => {
    setEnvironment(e);
    dispatch(updateUserInputs("environment", e));
  };

  const changeDatasetName = (e) => {
    let datasetNameObj = {};
    if (isString(e)) {
      datasetNameObj = {
        sub_data_domain_id: -1,
        name: e,
        id: -1,
        score: "NR",
      };
    } else {
      datasetNameObj = { ...e };
    }
    setDatasetName(datasetNameObj);
    dispatch(updateUserInputs("datasetName", datasetNameObj));
  };

  const handleTagsChange = (event, newValue) => {
    setDuplicateTagError(false);
    if (tagsSelections !== newValue) {
      setTagsData({
        ...tagsData,
        data: newValue,
      });
      setTagsSelections([...newValue]);
      dispatch(updateUserInputs("tags", [...newValue]));
    } else {
      if (tagsSelections.includes(newValue[newValue.length - 1])) {
        setDuplicateTagError(true);
      }
    }
  };

  const deleteValues = (regValue) => {
    let valArr = tagsSelections.filter(
      (singleValue) => singleValue !== regValue
    );
    setTagsSelections(orderBy([...valArr]));
    dispatch(updateUserInputs("tags", [...valArr]));
  };

  // # JSX
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <div className={classes.tags}>
          <label className={classes.metadataLabel}>Tags</label>
          <DQMultiAutoComplete
            value={tagsSelections}
            handleChange={handleTagsChange}
            isDisabled={true}
            autoC={autoC}
            deleteValues={deleteValues}
            optionsData={tagsData.data}
            height="128px"
          />
          <div className="selected-tags-container">
            {tagsSelections.length ? (
              <>
                {duplicateTagError && (
                  <p className="error">
                    {config.messages.duplicateTagFoundMessage}
                  </p>
                )}
              </>
            ) : null}
          </div>
        </div>
      </Box>
    </div>
  );
};

// # EXPORTS
export default TableInformation;
