// Import required libraries
import React from "react";

// Import styles
import "./Footer.scss";

// Import config
import config from "../../config/config";

//Import utils
import { getCurrentYear } from "../../utils/utils";

function Footer() {
  return (
    <footer className="light">
      <p className="footer-text">
        {`Copyright ©${getCurrentYear()}. `}
        {config.footerText}
      </p>
    </footer>
  );
}

export default Footer;
