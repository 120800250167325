// ### Imports
// * Import lib

import React, { useState } from "react";
import {
  selectActiveTable,
  selectAllReduxData,
} from "../../../redux/selectors";

import MenuItem from "../../../kit/MenuItem";
import Select from "../../../kit/Select";
import { TextField } from "@material-ui/core";
import Typography from "../../../kit/Typography";
import { conditionData } from "../isExisting";
import config from "../../../config/config";
import { makeStyles } from "@material-ui/core/styles";
import { useNonInitialEffect } from "../../../hooks/useNonInitialEffect";
import { useSelector } from "react-redux";
import { v4 } from "uuid";

// * Import Kit

// * Import utils

// # UTILITY FUNCTIONS

// # STYLES
const pStyle = {
  fontSize: "14px",
  padding: "0px 20px 0px 0px",
  color: "#4eafb3",
  cursor: "pointer",
  margin: "0px 0px 0px 10px",
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  leftMiddle: { display: "flex", alignItems: "center" },
  centerMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  select: { width: "120px !important" },
  customTextField: {
    "& .MuiInputBase-input": {
      fontSize: 12,
    },
  },
}));

// # HELPER COMPONENTS
const CommonSelect = (props = {}) => {
  // # PROPS
  const {
    value,
    options,
    onChange,
    formKey,
    disabled = false,
    formSelections,
    rowId,
    placeholder,
  } = props;

  // # HANDLERS
  const handleChange = (e) => {
    let result;
    switch (formKey) {
      case "columnName":
        onChange(formKey, e.target.value);
        break;
      case "operationsColumnName":
        result = formSelections.conditions.map((elem) =>
          elem.id === rowId
            ? { ...elem, [formKey]: e.target.value }
            : { ...elem }
        );
        onChange("conditions", result);
        break;
      case "operator":
        result = formSelections.conditions.map((elem) =>
          elem.id === rowId
            ? { ...elem, [formKey]: e.target.value }
            : { ...elem }
        );
        onChange("conditions", result);
        break;
      case "conditionString":
        onChange(formKey, e.target.value);
        break;
      case "inputString":
        onChange(formKey, e.target.value);
        break;
      default:
        result = e.target.value;
    }
  };

  // # STATIC VARIABLES
  const classes = useStyles();

  // # JSX
  return (
    <Select
      className={classes.select}
      value={value}
      displayEmpty
      inputProps={{ "aria-label": "Secondary Color" }}
      color="secondary"
      onChange={handleChange}
      MenuProps={config.hardCoded.MenuProps}
      renderValue={(selected) => {
        if (
          ["", undefined, null].includes(selected) &&
          (formKey === "operationsColumnName" || formKey === "columnName")
        ) {
          return <p>Select column</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
        }
        if (["", undefined, null].includes(selected)) {
          return placeholder || <p>Select option</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
        }
        let label = options.find((row) => row.id === selected)?.name;
        return label;
      }}
      disabled={disabled}
      title={value}
    >
      {options.length ? (
        options.map((option) => (
          <MenuItem key={option.id} value={option.id} title={option.name}>
            {option.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="" disabled>
          No Options
        </MenuItem>
      )}
    </Select>
  );
};

const CommonTextfield = (props = {}) => {
  // # PROPS
  const { onChange, formKey, disabled = false, type, value } = props;
  // # HANDLERS
  const handleChangeTextfield = (e) => {
    switch (formKey) {
      case "inputString":
        // result = formSelections.conditions.map((elem) =>
        //   elem.id === rowId
        //     ? { ...elem, [formKey]: e.target.value }
        //     : { ...elem }
        // );
        // onChange("conditions", result);
        onChange(formKey, e.target.value);
        break;
      default:
        return e.target.value;
    }
  };

  // # STATIC VARIABLES
  const classes = useStyles();
  // # JSX
  return (
    <TextField
      className={classes.customTextField}
      inputProps={{ "aria-label": "Secondary Color" }}
      color="secondary"
      onChange={handleChangeTextfield}
      disabled={disabled}
      type={type}
      placeholder={`Enter ${type}`}
      value={value}
    />
  );
};

// # COMPONENT
function CustomFieldCompareWithMathematicalOperations(props = {}) {
  // # PROPS
  const { onChange, selections, columnName } = props;

  // Column selected by user will be sent in value1
  const initialSelections = { ...selections, columnName };

  // # STATES
  const [formSelections, setFormSelections] = useState(initialSelections);
  const [error, setError] = useState("");

  useNonInitialEffect(() => {
    onChange(formSelections);
  }, [formSelections]);

  // # SELECTORS
  const allReduxData = useSelector(selectAllReduxData);
  const activeTable = useSelector(selectActiveTable);

  // # HANDLERS
  const addNewRow = () => {
    setFormSelections((prevState) => ({
      ...prevState,
      conditions: [
        ...prevState.conditions,
        {
          operator: "",
          operationsColumnName: "",
          id: v4(),
        },
      ],
    }));
    setError("");
  };

  const handleDelete = (id) => {
    if (formSelections.conditions.length > 1) {
      let newData = formSelections.conditions.filter((item) => item.id !== id);
      setFormSelections((prevState) => ({ ...prevState, conditions: newData }));
      setError("");
    } else {
      setError("Cannot delete last selection");
    }
  };

  const handleChange = (formKey, formValue) => {
    setFormSelections((prevState) => {
      switch (formKey) {
        case "conditions":
          return { ...prevState, [formKey]: formValue };
        default:
          return { ...prevState, [formKey]: formValue };
      }
    });
    // console.log("formSelections-", formSelections);
  };

  // # STATIC VARIABLES
  const classes = useStyles();
  const allColumnOptions =
    allReduxData.allColumns.result[0].children.find(
      (row) => row.name === activeTable
    )?.children || [];
  const operatorData = [
    {
      id: "addition",
      name: "Addition",
    },
    {
      id: "subtraction",
      name: "Subtraction",
    },
    {
      id: "multiplication",
      name: "Multiplication",
    },
    {
      id: "division",
      name: "Division",
    },
  ];

  // # JSX
  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignItems: "flexStart" }}>
        <div style={{ marginTop: "5px" }}>
          <Typography>Check if all records in {columnName}</Typography>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "700px" }}
        >
          {formSelections.conditions.map((elem, index) => (
            <div id={elem.id} style={{ display: "flex", alignItems: "center" }}>
              <CommonSelect
                formKey={"operator"}
                value={elem["operator"]}
                options={operatorData}
                onChange={handleChange}
                disabled={false}
                rowId={elem.id}
                formSelections={formSelections}
                placeholder="Select operator"
              />
              <CommonSelect
                formKey={"operationsColumnName"}
                value={elem["operationsColumnName"]}
                options={allColumnOptions}
                onChange={handleChange}
                disabled={false}
                rowId={elem.id}
                formSelections={formSelections}
                placeholder="Select column"
              />
              <p
                style={{
                  fontSize: "14px",
                  padding: "0px 20px 0px 0px",
                  color: "#4eafb3",
                  cursor: "pointer",
                  margin: "0px 0px 0px 10px",
                }}
                onClick={() => handleDelete(elem.id)}
              >
                Delete
              </p>
              {index === formSelections.conditions.length - 1 && (
                <p style={pStyle} onClick={() => addNewRow()}>
                  Add New Condition
                </p>
              )}
              {error && <p className="error-msg">{error}</p>}
            </div>
          ))}
        </div>
      </div>
      <div style={{ display: "flex", marginLeft: "20%" }}>
        <CommonSelect
          formKey={"conditionString"}
          value={formSelections["conditionString"]}
          options={conditionData}
          onChange={handleChange}
          disabled={false}
          formSelections={formSelections}
          placeholder="Select condition"
        />
        <CommonTextfield
          formKey={"inputString"}
          value={formSelections["inputString"]}
          options={allColumnOptions}
          onChange={handleChange}
          disabled={false}
          formSelections={formSelections}
          type="number"
        />
      </div>
    </div>
  );
}

// # EXPORTS
export default CustomFieldCompareWithMathematicalOperations;
