/* eslint-disable indent */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell, Box } from "@material-ui/core";
import Filters from "../../../components/Filters/Filters";

import { useStyles } from "./style";
import "./GlobalRulesListTable.scss";

const SummaryTableHeader = (props) => {
  const { user, fetchedData } = props;
  const classes = useStyles();
  const allUseCase = [...new Set(fetchedData?.map((item) => item["Use Case"]))];

  const allTable = [...new Set(fetchedData?.map((item) => item.Table))];
  const allColumn = [...new Set(fetchedData?.map((item) => item.Column))];
  const allRule = [...new Set(fetchedData?.map((item) => item.Rule))];
  const allCategory = [...new Set(fetchedData?.map((item) => item.Category))];
  const allDimension = [...new Set(fetchedData?.map((item) => item.Dimension))];

  return (
    <TableHead>
      <TableRow>
        <TableCell
          className={classes.tablecell}
          style={{ padding: "10px" }}
          align="left"
        >
          <div className="header-with-filter">
            <Box>Use Case</Box>
            <Filters
              flag={user.selections.summaryUseCase.value?.length ? true : false}
              id="summaryUseCase"
              data={allUseCase}
              filterType="checkbox"
            />
          </div>
        </TableCell>

        <TableCell
          className={classes.tablecell}
          style={{ padding: "10px" }}
          align="left"
        >
          <div className="header-with-filter">
            <Box>Table</Box>
            <Filters
              flag={user.selections.summaryTable.value?.length ? true : false}
              id="summaryTable"
              data={allTable}
              filterType="checkbox"
            />
          </div>
        </TableCell>

        <TableCell className={classes.tablecell}>
          <div className="header-with-filter">
            <Box>Column</Box>
            <Filters
              flag={user.selections.summaryColumn.value?.length ? true : false}
              id="summaryColumn"
              data={allColumn}
              filterType="checkbox"
            />
          </div>
        </TableCell>

        <TableCell
          className={classes.tablecell}
          style={{ padding: "10px" }}
          align="left"
        >
          <div className="header-with-filter">
            <Box>Rule</Box>
            <Filters
              flag={user.selections.summaryAllRule.value?.length ? true : false}
              id="summaryAllRule"
              data={allRule}
              filterType="checkbox"
            />
          </div>
        </TableCell>

        <TableCell className={classes.tablecell}>
          <Box>Description</Box>
        </TableCell>

        <TableCell className={classes.tablecell}>
          <div className="header-with-filter">
            <Box>Category</Box>
            <Filters
              flag={
                user.selections.summaryCategory.value?.length ? true : false
              }
              id="summaryCategory"
              data={allCategory}
              filterType="checkbox"
            />
          </div>
        </TableCell>

        <TableCell className={classes.tablecell}>
          <div className="header-with-filter">
            <Box>Dimension</Box>
            <Filters
              flag={
                user.selections.summaryDimension.value?.length ? true : false
              }
              id="summaryDimension"
              data={allDimension}
              filterType="checkbox"
            />
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

SummaryTableHeader.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(SummaryTableHeader);
