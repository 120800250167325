// Import required libraries

import "./Table.scss";

import { Box, Grid, Select, TableBody, TableRow } from "@material-ui/core";
import {
  CustomMenuItem,
  StyledTableCell,
  customMenuItemStyle,
  useStyles,
} from "./styles";
import React, { useEffect, useState } from "react";
import {
  getCircleBarColor,
  getRatingColor,
  getRoundOfScore,
} from "../../utils/utils";

import Dialog from "@material-ui/core/Dialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pagination } from "@material-ui/lab";
import PropTypes from "prop-types";
import TableHeader from "./TableHeader";
import config from "../../config/config";
import diagnosticReport from "../../../assets/images/diagnostic_report.png";
import noDataImage from "../../../assets/images/undraw_void.svg";
import { resetSelections } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useTable from "../../utils/useTable";

// Import data & utils

// Import action creators

// Import styles

// # HELPER COMPONENTS
const StyledRating = ({ value, color }) => {
  const classes = useStyles();
  const ratingBox = getRatingColor(value);

  // # JSX
  return (
    <div className={classes.ratingContainer}>
      {ratingBox.map((rate, i) => (
        <div className={classes.ratingBox} key={i}>
          <div
            className={classes.rating}
            style={{ width: `${rate}%`, backgroundColor: color }}
          />
          <div className={classes.empty} style={{ width: `${100 - rate}%` }} />
        </div>
      ))}
    </div>
  );
};

StyledRating.propTypes = {
  value: PropTypes.any.isRequired,
  color: PropTypes.any.isRequired,
};

// # COMPONENT
const Table = ({ data = [], paginationFlag, myDatasetPage = false }) => {
  // # HOOKS
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const history = useHistory();
  const { TblContainer } = useTable(data);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  //reset filters on screen change
  useEffect(() => {
    return () => {
      dispatch(resetSelections("primaryDomain"));
      dispatch(resetSelections("secondaryDomain"));
      dispatch(resetSelections("subDomain"));
    };
    // eslint-disable-next-line
  }, []);

  // # HANDLERS
  const navigateToRow = (subtab) => {
    history.push(`/home/mydataset/${subtab}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  // # STATIC VARIABLES
  const pageSizes = [...config.hardCoded.pageSizes];
  const displayRecords =
    data.length > page
      ? [...data].splice((page - 1) * pageSize, pageSize)
      : data;
  const noOfPages = Math.ceil(data.length / pageSize);

  // # JSX
  return (
    <>
      {" "}
      <div className="dataset-details-container-pagination">
        <TblContainer>
          <TableHeader myDatasetPage={myDatasetPage} />
          {data?.length ? (
            <TableBody>
              {displayRecords.map((item, index) => {
                const { color } =
                  item?.score === "NR"
                    ? { color: "NA", remarks: "" }
                    : item?.score?.includes("%")
                    ? getCircleBarColor(Number(item?.score?.slice(0, -1)))
                    : getCircleBarColor(Number(item?.score));
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableRow} hover>
                      <StyledTableCell
                        onClick={() => navigateToRow(item.id)}
                        style={{
                          cursor: "pointer",
                          fontWeight: "600",
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ width: "45px" }}>
                            {item?.isDraft && (
                              <label className="draft-tag">Draft</label>
                            )}
                          </div>
                          <div
                            className="dataset-location-name"
                            title={item.name}
                            style={{
                              textDecoration: "underline",
                              maxWidth: "280px",
                            }}
                          >
                            {item.name}
                          </div>
                        </span>
                      </StyledTableCell>

                      <StyledTableCell style={{ width: "200px" }}>
                        <Box display="flex" alignItems="center">
                          <StyledRating
                            value={getRoundOfScore(item?.score) / 10}
                            color={color}
                          />
                          <div className="dataset-score">
                            {item?.score ? (
                              item?.score === "NR" ? (
                                <span title="Not Rated">NR</span>
                              ) : (
                                <span>{`${getRoundOfScore(item.score)}%`}</span>
                              )
                            ) : (
                              <span title="Not Rated">NR</span>
                            )}
                          </div>
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          ) : (
            <TableBody className="no-configs">
              <TableRow>
                <StyledTableCell colSpan={8}>
                  <img src={noDataImage} alt="noDataImage" />
                  <p className="no-data">
                    <b>{config.messages.noRecordsFound}</b>
                  </p>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </TblContainer>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box>
            <img src={diagnosticReport} alt="img" style={{ height: "550px" }} />
          </Box>
        </Dialog>
      </div>
      {paginationFlag && data.length > 10 && (
        <Grid container style={{ marginTop: "15px" }}>
          <Grid item>
            <Pagination
              count={noOfPages}
              page={page}
              shape="rounded"
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={2}
              className={classes.pagination}
            />
          </Grid>
          <Grid item xs={1}>
            <Select
              onChange={handlePageSizeChange}
              value={pageSize}
              disableUnderline
              className={classes.paginationSelect}
              IconComponent={ExpandMoreIcon}
            >
              {pageSizes.map((size) => (
                <CustomMenuItem
                  key={size}
                  value={size}
                  style={customMenuItemStyle}
                >
                  View {size}
                </CustomMenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      )}{" "}
    </>
  );
};

// # EXPORTS
export default React.memo(Table);
