//Import required libraies
import React from "react";
import Radio from "@material-ui/core/Radio";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// Import Custom Component

// Import action creators

// Import utils

// Import styles

function DQRadioGroup(props) {
  const {
    value,
    onChange,
    data,
    style,
    disabled = false,
    customClassName,
  } = props;

  //Required Functions
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const SigviewRadio = withStyles({
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      //   color: themeColors["radioColor"],
      padding: "0px !important",
      "&$checked": {
        // color: themeColors["radioColor"],
      },
      "&$disabled": {
        // color: themeColors["radioDisabledColor"],
        opacity: 0.5,
      },
      "& .MuiSvgIcon-root": {
        height: 15,
        weight: 15,
      },
    },
    checked: {},
    disabled: {
      //   color: themeColors["secondaryColorLighter"],
      opacity: 0.5,
    },
  })((props) => <Radio color="default" {...props} />);

  //Styling
  const useStyles = makeStyles({
    root: { display: "flex", flexDirection: style?.rootFlexDirection || "row" },
    radioIconContainer: {
      height: "24px",
      display: "flex",
      alignItems: "center",
      marginRight: "8px",
    },
    radioLabel: {
      marginLeft: "5px",
      fontSize: "11px",
      //   color: themeColors["secondaryColor"],
      //   "&.radio-disabled": { color: themeColors["secondaryColorLighter"] },
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {data.map((row) => {
        let radioLabelClass = classes.radioLabel;
        if (row.disabled) radioLabelClass += " radio-disabled";
        return (
          <div
            className={`${classes.radioIconContainer} ${customClassName}`}
            key={row.id}
          >
            <SigviewRadio
              value={row.id}
              checked={row.id === value}
              onChange={handleChange}
              name={"name"}
              size="small"
              disabled={row.disabled || disabled}
              disableRipple
              customClassName={customClassName}
            />
            <label className={`${radioLabelClass} ${customClassName}`}>
              {row.name}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default DQRadioGroup;
