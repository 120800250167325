import React, { useEffect, useRef, useState, useReducer } from "react";
import { Box, DialogActions } from "@material-ui/core";
import { v4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import DQSingleAutocomplete from "./Common/DQSingleAutocomplete";
import DQInput from "./Common/DQInput";
import DQButton from "./Common/DQButton";
import DQRadioGroup from "./Common/DQRadioGroup";

import useReducerLogger from "../utils/useReducerLogger";
import {
  updateData,
  updateSelections,
  updateUserInputs,
} from "../redux/actions";

// Import utils, data

const useStyles = makeStyles((theme) => ({
  root: {
    height: "40vh",
  },
  buttonContainer: {
    height: "calc(100% - 40vh)",
  },
  title: {
    height: "5vh",
    borderBottom: "1px solid #eee9e5",
    display: "flex",
    alignItems: "center",
  },
  typeContainer: {
    height: "35vh",
    overflowY: "auto",
    overflowX: "none",
    paddingLeft: "12px",
  },
  filterContainer: {
    height: "30vh",
    width: "30vw",
  },
  metricCriteriaRowContainer: {
    display: "flex",
    alignItems: "center",
    // flexWrap: "wrap",
    width: "100%",
  },
  metricCriteriaRow: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flexDirection: "column",
    marginRight: "10px",
  },
  alertMeInputContainer: {
    display: "flex",
    alignItems: "center",
    // flexWrap: "wrap",
    backgroundColor: "#fff",
    // padding: "13px 11px 0px 11px !important",
    borderRadius: "3px",
    boxSizing: "border-box",
    // border: "1px solid #edf1ff",
    width: "100%",
  },
}));
const logicalConditionData = [
  {
    id: "and",
    name: "AND",
    disabled: false,
  },
  {
    id: "or",
    name: "OR",
    disabled: false,
  },
];
const conditionOptions = [
  {
    id: "greater_than",
    name: "Greater than",
  },
  {
    id: "less_than",
    name: "Less than",
  },
  {
    id: "greater_than_equal",
    name: "Greater than equal to",
  },
  {
    id: "less_than_equal",
    name: "Less than equal to",
  },
  {
    id: "equal_to",
    name: "Equal to",
  },
  {
    id: "not_equal",
    name: "Not equal to",
  },
  {
    id: "like_alt",
    name: "Like ALT",
  },
  {
    id: "not_like_alt",
    name: "Not Like ALT",
  },
  {
    id: "in",
    name: "In",
  },
  {
    id: "not_in",
    name: "Not in",
  },
];

const selection = {
  message: "",
  status: "invalid",
  isSelected: false,
  value: [
    {
      id: v4(),
      columnName: {
        message: "Please select a table",
        value: "selectTable",
        status: "invalid",
      },
      condition: {
        message: "Please select a condition",
        value: "selectCondition",
        status: "invalid",
      },
      inputValue: {
        message: "Please enter a value",
        value: "",
        status: "invalid",
      },
      operator: {
        message: "",
        value: "and",
        status: "valid",
      },
    },
  ],
};

const getNewAlertCriteriaRow = () => ({
  id: v4(),
  columnName: {
    message: "Please select a table",
    value: "selectTable",
    status: "invalid",
  },
  condition: {
    message: "Please select a condition",
    value: "selectCondition",
    status: "invalid",
  },
  inputValue: {
    message: "Please enter a value",
    value: "",
    status: "invalid",
  },
  operator: {
    message: "",
    value: "and",
    status: "valid",
  },
});

const FilterCriteriaCondition = (props) => {
  const { dataTable, selection } = props;

  // Defining the AlertCriteria reducer
  const [dqRunState, dispatch] = useReducer(
    useReducerLogger(reducer),
    selection
  );

  // * Define states
  const initilaMount = useRef(true);
  const [tabelSelectionSingleSelect, setTabelSelectionSingleSelect] = useState([
    {
      id: "selectTable",
      name: "Select Table",
      disabled: true,
    },
  ]);
  const [conditionSelectionSingleSelect, setconditionSelectionSingleSelect] =
    useState([
      {
        id: "selectCondition",
        name: "Select Condition",
        disabled: true,
      },
    ]);

  const transformTableSelectionDataFromBackend = (data) => {
    const uiData = data.map((el) => ({
      id: el,
      name: el,
      disabled: false,
    }));

    return uiData;
  };

  const transformConditionDataFromBackend = (data) => {
    const uiData = data.conditions.map((el) => ({
      id: el.name,
      name: el.name,
      disabled: false,
    }));

    return uiData;
  };

  // async function getData() {
  //   // Here we will make two independent api call for Column and condition
  //   let fetchProps = {
  //     id: tableId,
  //   };
  //   let reportDetails = await Promise.all([
  //     getMyDatasetColumns(fetchProps),
  //     getMyDatasetCondition(),
  //   ]);
  //   if (reportDetails[0] === undefined || reportDetails[1] === undefined) {
  //     console.log("sonu went wrong");
  //   }

  //   const tabelSelectionSingleSelect = transformTableSelectionDataFromBackend(
  //     reportDetails[0]
  //   );
  //   const conditionSingleSelect = transformConditionDataFromBackend(
  //     reportDetails[1]
  //   );
  //   setTabelSelectionSingleSelect(tabelSelectionSingleSelect);
  //   setconditionSelectionSingleSelect(conditionSingleSelect);
  // }

  // useEffect(() => {
  //   if (initilaMount.current) {
  //     getData();
  //     initilaMount.current = false;
  //   }
  // }, []);
  // * Define required side effects

  // Reducer function of DQ Report Run
  function reducer(dqRunState, action) {
    const { type, payload = {} } = action;
    const { key, value, id } = payload;

    let newData;

    switch (type) {
      case "UPDATE_METRIC_ALERT":
        switch (key) {
          case "columnName":
          case "condition":
          case "inputValue":
          case "operator":
            newData = {
              ...dqRunState,
              value: dqRunState.value.map((el) =>
                el.id === id
                  ? {
                      ...el,
                      [key]: {
                        ...el[key],
                        value,
                      },
                    }
                  : el
              ),
            };

            break;
        }
        break;

      case "deleteRow":
        if (dqRunState.value.length > 1) {
          newData = {
            ...dqRunState,
            value: dqRunState.value.filter((el) => el.id !== id),
          };
        }

        break;
      case "replace":
        newData = [...value];
        break;
      case "addRow":
        newData = {
          ...dqRunState,
          value: [...dqRunState.value, getNewAlertCriteriaRow()],
        };
        break;
      default:
        newData = [...dqRunState];
        break;
    }
    return newData;
  }
  // 2. ACTION CREATORS
  const handleChangeMetric = (action) => {
    dispatch(action);
  };

  const classes = useStyles();

  return (
    <>
      <Box className={classes.typeContainer}>
        <Box className={classes.filterContainer}>
          {dqRunState.value.map((el, index) => {
            const { id } = el;

            return (
              <Box key={id} className={classes.metricCriteriaRowContainer}>
                <div className={classes.metricCriteriaRow}>
                  <div className={classes.alertMeInputContainer}>
                    <Box
                      css={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        borderRadius: "3px",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <DQSingleAutocomplete
                        value={el.columnName.value}
                        styles={{
                          width: "170px",
                          marginRight: "20px",
                          height: "21px !important",
                        }}
                        optionsData={dataTable || []}
                        handleChange={(newValue) => {
                          const payload = {
                            key: "columnName",
                            value: newValue.name,
                            id,
                          };
                          const action = {
                            type: "UPDATE_METRIC_ALERT",
                            payload,
                          };
                          handleChangeMetric(action);
                        }}
                        placeholderText="Enter L0 Domain"
                        isDisabled={false}
                        newKey="name"
                      />

                      <DQSingleAutocomplete
                        value={el.condition.value}
                        styles={{
                          width: "170px",
                          marginRight: "20px",
                          height: "21px !important",
                        }}
                        optionsData={conditionOptions || []}
                        handleChange={(newValue) => {
                          const payload = {
                            key: "condition",
                            value: newValue.id,
                            id,
                          };
                          const action = {
                            type: "UPDATE_METRIC_ALERT",
                            payload,
                          };

                          handleChangeMetric(action);
                        }}
                        placeholderText="Enter L0 Domain"
                        isDisabled={false}
                        newKey="name"
                      />
                      <DQInput
                        maxWidth="150px"
                        name="ruleName"
                        value={el.inputValue.value}
                        placeholder="Enter the value"
                        isDisabled={false}
                        handleChange={(newValue) => {
                          const payload = {
                            key: "inputValue",
                            value: newValue,
                            id,
                          };
                          const action = {
                            type: "UPDATE_METRIC_ALERT",
                            payload,
                          };
                          handleChangeMetric(action);
                        }}
                      />

                      {/* <TextField
                          styles={{
                            width: "200px",
                            marginRight: "10px",
                            backgroundColor: "#465a69",
                            color: "#46596a !important",
                            fontFamily: "Hind Siliguri !important",
                            height: "21px !important",
                          }}
                          id="standard-required"
                          value={el.inputValue.value}
                          placeholder={"Enter the value"}
                          onChange={(newValue) => {
                            const payload = {
                              key: "inputValue",
                              value: newValue.target.value,
                              id,
                            };
                            const action = {
                              type: "UPDATE_METRIC_ALERT",
                              payload,
                            };
                            handleChangeMetric(action);
                          }}
                        /> */}
                    </Box>
                    {/* Delete Icon */}
                    {dqRunState.value.length > 1 && (
                      <Box
                        css={{
                          position: "relative",
                          paddingLeft: "10px",
                          "&:hover": {
                            // boxShadow: "none !important",
                            color: "red",
                          },
                        }}
                        onClick={(newValue) => {
                          const payload = { value: newValue, id };
                          const action = {
                            type: "deleteRow",
                            payload,
                          };
                          handleChangeMetric(action);
                        }}
                        className={"material-icons-outlined delete-btn"}
                      >
                        cancel
                      </Box>
                    )}
                  </div>
                  {/* Condition Radio Button */}
                  {dqRunState.value.length - 1 !== index && (
                    <div>
                      <DQRadioGroup
                        value={el.operator.value}
                        data={logicalConditionData}
                        onChange={(newValue) => {
                          const payload = {
                            key: "operator",
                            value: newValue,
                            id,
                          };
                          const action = {
                            type: "UPDATE_METRIC_ALERT",
                            payload,
                          };
                          handleChangeMetric(action);
                        }}
                      />
                    </div>
                  )}
                </div>
                {/* ADD Icon  */}
                {dqRunState.value.length - 1 === index && (
                  <Box
                    css={{
                      "&:hover": {
                        // boxShadow: "none !important",
                        backgroundColor: "#4EAFB3",
                        borderRadius: "4px",
                        color: "white",
                      },
                    }}
                    className="material-icons add-new-row-btn"
                    onClick={() => {
                      const payload = {};
                      const action = {
                        type: "addRow",
                        payload,
                      };
                      handleChangeMetric(action);
                    }}
                  >
                    add
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
  userInputs: state.userInputs,
});

export default connect(mapStateToProps)(FilterCriteriaCondition);
