// ### Imports
// * Import lib

import React, { useState } from "react";
import {
  selectActiveTable,
  selectAllReduxData,
} from "../../../redux/selectors";

import MenuItem from "../../../kit/MenuItem";
import Select from "../../../kit/Select";
import { TextField } from "@material-ui/core";
import Typography from "../../../kit/Typography";
import { conditionData } from "../isExisting";
import config from "../../../config/config";
import { makeStyles } from "@material-ui/core/styles";
import { useNonInitialEffect } from "../../../hooks/useNonInitialEffect";
import { useSelector } from "react-redux";
import { v4 } from "uuid";

// * Import Kit

// * Import utils

// # UTILITY FUNCTIONS

// # STYLES
const pStyle = {
  fontSize: "14px",
  padding: "0px 20px 0px 0px",
  color: "#4eafb3",
  cursor: "pointer",
  margin: "0px 0px 0px 10px",
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  leftMiddle: { display: "flex", alignItems: "center" },
  centerMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  select: { width: "120px !important" },
  customTextField: {
    "& .MuiInputBase-input": {
      fontSize: 12,
    },
  },
}));

// # HELPER COMPONENTS
const CommonSelect = (props = {}) => {
  // # PROPS
  const {
    value,
    options,
    onChange,
    formKey,
    disabled = false,
    formSelections,
    rowId,
    placeholder,
  } = props;

  // # HANDLERS
  const handleChange = (e) => {
    let result;
    switch (formKey) {
      case "columnName":
        onChange(formKey, e.target.value);
        break;
      case "operationsConditionString":
        result = formSelections.conditions.map((elem) =>
          elem.id === rowId
            ? { ...elem, [formKey]: e.target.value }
            : { ...elem }
        );
        onChange("conditions", result);
        break;
      case "operationsColumnName":
        result = formSelections.conditions.map((elem) =>
          elem.id === rowId
            ? { ...elem, [formKey]: e.target.value }
            : { ...elem }
        );
        onChange("conditions", result);
        break;
      case "columnOperator":
        result = formSelections.conditions.map((elem) =>
          elem.id === rowId
            ? { ...elem, [formKey]: e.target.value }
            : { ...elem }
        );
        onChange("conditions", result);
        break;
      default:
        onChange(formKey, e.target.value);
    }
  };

  // # STATIC VARIABLES
  const classes = useStyles();

  // # JSX
  return (
    <Select
      className={classes.select}
      value={value}
      displayEmpty
      inputProps={{ "aria-label": "Secondary Color" }}
      color="secondary"
      onChange={handleChange}
      MenuProps={config.hardCoded.MenuProps}
      renderValue={(selected) => {
        if (
          ["", undefined, null].includes(selected) &&
          formKey === "columnName"
        ) {
          return <p>Select column</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
        }
        if (["", undefined, null].includes(selected)) {
          return placeholder || <p>Select option</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
        }
        let label = options.find((row) => row.id === selected)?.name;
        return label;
      }}
      disabled={disabled}
      title={value}
    >
      {options.length ? (
        options.map((option) => (
          <MenuItem key={option.id} value={option.id} title={option.name}>
            {option.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="" disabled>
          No Options
        </MenuItem>
      )}
    </Select>
  );
};

const CommonTextfield = (props = {}) => {
  // # PROPS
  const {
    onChange,
    formKey,
    disabled = false,
    formSelections,
    rowId,
    type,
    value,
  } = props;
  // # HANDLERS
  const handleChangeTextfield = (e) => {
    let result;
    switch (formKey) {
      case "optionsInputString":
        result = formSelections.conditions.map((elem) =>
          elem.id === rowId
            ? { ...elem, [formKey]: e.target.value }
            : { ...elem }
        );
        onChange("conditions", result);
        break;
      default:
        onChange(formKey, e.target.value);
    }
  };

  // # STATIC VARIABLES
  const classes = useStyles();
  // # JSX
  return (
    <TextField
      className={classes.customTextField}
      inputProps={{ "aria-label": "Secondary Color" }}
      color="secondary"
      onChange={handleChangeTextfield}
      disabled={disabled}
      type={type}
      placeholder={`Enter ${type}`}
      value={value}
    />
  );
};

// # COMPONENT
function CustomFieldCompareWithCondition(props = {}) {
  // # PROPS
  const { onChange, selections } = props;

  // # STATES
  const [formSelections, setFormSelections] = useState(selections);
  const [error, setError] = useState("");

  useNonInitialEffect(() => {
    onChange(formSelections);
  }, [formSelections]);

  // # SELECTORS
  const allReduxData = useSelector(selectAllReduxData);
  const activeTable = useSelector(selectActiveTable);

  // # HANDLERS
  const addNewRow = () => {
    setFormSelections((prevState) => ({
      ...prevState,
      conditions: [
        ...prevState.conditions,
        {
          columnOperator: "",
          operationsColumnName: "",
          operationsConditionString: "",
          optionsInputString: "",
          id: v4(),
        },
      ],
    }));
    setError("");
  };

  const handleDelete = (id) => {
    if (formSelections.conditions.length > 1) {
      let newData = formSelections.conditions.filter((item) => item.id !== id);
      setFormSelections((prevState) => ({ ...prevState, conditions: newData }));
      setError("");
    } else {
      setError("Cannot delete last selection");
    }
  };

  const handleChange = (formKey, formValue) => {
    setFormSelections((prevState) => {
      switch (formKey) {
        case "conditions":
          return { ...prevState, [formKey]: formValue };
        default:
          return { ...prevState, [formKey]: formValue };
      }
    });
  };

  // # STATIC VARIABLES
  const classes = useStyles();
  const allColumnOptions =
    allReduxData.allColumns.result[0].children.find(
      (row) => row.name === activeTable
    )?.children || [];

  const optionsdata = [
    { id: "or", name: "or" },
    { id: "and", name: "and" },
  ];

  // # JSX
  return (
    <div className={classes.root}>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex" }}>
          <div style={{ marginTop: "7px" }}>
            <Typography>
              Check if the all records in this column are{" "}
            </Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <CommonSelect
                formKey={"conditionString"}
                value={formSelections["conditionString"]}
                options={conditionData}
                onChange={handleChange}
                disabled={false}
                formSelections={formSelections}
                placeholder="Select condition"
              />
              <CommonTextfield
                formKey={"inputString"}
                onChange={handleChange}
                formSelections={formSelections}
                type="text"
                value={formSelections["inputString"]}
              />
            </div>
          </div>
          <div style={{ margin: "10px" }}>
            <Typography> if </Typography>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formSelections.conditions.map((elem, index) => {
            let marginVariable = index > 0 ? "-18%" : "0px";
            return (
              <div
                id={elem.id}
                style={{ display: "flex", alignItems: "baseline" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: marginVariable,
                  }}
                >
                  {index > 0 && (
                    <CommonSelect
                      formKey={"columnOperator"}
                      value={elem["columnOperator"]}
                      options={optionsdata}
                      onChange={handleChange}
                      disabled={false}
                      formSelections={formSelections}
                      rowId={elem.id}
                      placeholder="Select operator"
                    />
                  )}
                  <CommonSelect
                    formKey={"operationsColumnName"}
                    value={elem["operationsColumnName"]}
                    options={allColumnOptions}
                    onChange={handleChange}
                    disabled={false}
                    formSelections={formSelections}
                    rowId={elem.id}
                    placeholder="Select column"
                  />
                  <CommonSelect
                    formKey={"operationsConditionString"}
                    value={elem["operationsConditionString"]}
                    options={conditionData}
                    onChange={handleChange}
                    disabled={false}
                    rowId={elem.id}
                    formSelections={formSelections}
                    placeholder="Select condition"
                  />
                  <CommonTextfield
                    formKey={"optionsInputString"}
                    onChange={handleChange}
                    rowId={elem.id}
                    formSelections={formSelections}
                    type="text"
                    value={elem["optionsInputString"]}
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      padding: "0px 20px 0px 0px",
                      color: "#4eafb3",
                      cursor: "pointer",
                      margin: "0px 0px 0px 10px",
                    }}
                    onClick={() => handleDelete(elem.id)}
                  >
                    Delete
                  </p>
                  {index === formSelections.conditions.length - 1 && (
                    <p style={pStyle} onClick={() => addNewRow()}>
                      Add New Condition
                    </p>
                  )}
                  {error && <p className="error-msg">{error}</p>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

// # EXPORTS
export default CustomFieldCompareWithCondition;
