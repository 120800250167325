// Import required libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TableBody, TableRow, Grid, Select, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pagination } from "@material-ui/lab";

// Import data & utils
import config from "../../../config/config";
import useTable from "../../../utils/useTable";
import {
  getRatingColor,
  getCircleBarColor,
  getRoundOfScore,
} from "../../../utils/utils";
import noDataImage from "../../../../assets/images/undraw_void.svg";
import { getSubPathName, getHierarchyName } from "./utils";

// Import action creators
import { updateSelections } from "../../../redux/actions";

// Import styles
import {
  StyledTableCell,
  useStyles,
  customMenuItemStyle,
  CustomMenuItem,
} from "../../../../styles/tblStyle";
import "./ExploreDatasetsTable.scss";

// # HELPER COMPONENTS
const StyledRating = ({ value, color }) => {
  const classes = useStyles();
  const ratingBox = getRatingColor(value);

  return (
    <div className={classes.ratingContainer}>
      {ratingBox.map((rate, i) => (
        <div className={classes.ratingBox} key={i}>
          <div
            className={classes.rating}
            style={{ width: `${rate}%`, backgroundColor: color }}
          />
          <div className={classes.empty} style={{ width: `${100 - rate}%` }} />
        </div>
      ))}
    </div>
  );
};

StyledRating.propTypes = {
  value: PropTypes.any.isRequired,
  color: PropTypes.any.isRequired,
};

// # COMPONENT
const ExploreDatasetsTable = ({
  data = [],
  paginationFlag,
  criteria,
  tblHeaders,
}) => {
  // # STATES & HOOKS
  const dispatch = useDispatch();
  const filterFn = { fn: (items) => items };
  const history = useHistory();
  const { TblContainer, TblHead } = useTable(
    data,
    tblHeaders,
    filterFn,
    {},
    () => {},
    criteria
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const classes = useStyles();

  // # HANDLERS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const navigateToRow = (item) => {
    let subPathName = "";
    dispatch(updateSelections("searchText", ""));
    dispatch(updateSelections("selectedSearchTags", []));

    if (item.metaData) {
      subPathName = getSubPathName(item);
      history.push(`/home/exploredatasets/${subPathName}`);
    } else {
      subPathName = item.name.includes("/")
        ? item.name.split("/").join("---")
        : item.name;
      const path = window.location.pathname.endsWith("/")
        ? `${window.location.pathname}${subPathName}`
        : `${window.location.pathname}/${subPathName}`;
      history.push(path);
    }
  };

  const getIconName = (item) => {
    let iconName = "folder";
    if (item.elementName === "Table") {
      iconName = "view_module";
    }
    return iconName;
  };

  // # STATIC VARIABLES
  const pageSizes = [...config.hardCoded.pageSizes];
  const displayRecords =
    paginationFlag && data.length > page
      ? [...data].splice((page - 1) * pageSize, pageSize)
      : data;
  const noOfPages = Math.ceil(data.length / pageSize);

  // # JSX
  return (
    <>
      <>
        {" "}
        <div className="dataset-domains-container">
          <TblContainer>
            <TblHead />
            {data?.length ? (
              <TableBody>
                {displayRecords.map((item, index) => {
                  const { color } =
                    item?.score === "NR"
                      ? { color: "NA", remarks: "" }
                      : item?.score?.includes("%")
                      ? getCircleBarColor(Number(item?.score?.slice(0, -1)))
                      : getCircleBarColor(Number(item?.score));
                  return (
                    <React.Fragment key={index}>
                      <TableRow className={classes.tableRow} hover>
                        <StyledTableCell
                          onClick={() => navigateToRow(item)}
                          style={{
                            cursor: "pointer",
                            fontWeight: "600",
                          }}
                        >
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ width: "45px" }}>
                              <i
                                className={`material-icons-outlined ${
                                  getIconName(item) === "view_module"
                                    ? "table-icon"
                                    : "filter-alt-icon"
                                }`}
                              >
                                {getIconName(item)}
                              </i>
                            </div>
                            <div
                              className="dataset-location-name"
                              title={item.name}
                            >
                              {getHierarchyName(item)}
                              <span
                                style={{
                                  textDecoration: "underline",
                                  maxWidth: "280px",
                                }}
                              >
                                {item.name}
                              </span>
                            </div>
                          </span>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: "200px" }}
                          align="right"
                        >
                          <Box display="flex" alignItems="center">
                            <StyledRating
                              value={getRoundOfScore(item?.score) / 10}
                              color={color}
                            />
                            <div className="dataset-score">
                              {item?.score ? (
                                item?.score === "NR" ? (
                                  <span title="Not Rated">NR</span>
                                ) : (
                                  <span>{`${getRoundOfScore(
                                    item.score
                                  )}%`}</span>
                                )
                              ) : (
                                <span title="Not Rated">NR</span>
                              )}
                            </div>
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody className="no-configs">
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <img src={noDataImage} alt="noDataImage" />
                    <p className="no-data">
                      <b>{config.messages.noRecordsFound}</b>
                    </p>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            )}
          </TblContainer>
        </div>
        {paginationFlag && data.length > 10 && (
          <Grid container style={{ marginTop: "15px" }}>
            <Grid item>
              <Pagination
                count={noOfPages}
                page={page}
                shape="rounded"
                onChange={handleChangePage}
                siblingCount={0}
                boundaryCount={2}
                className={classes.pagination}
              />
            </Grid>
            <Grid item xs={1}>
              <Select
                onChange={handlePageSizeChange}
                value={pageSize}
                disableUnderline
                className={classes.paginationSelect}
                IconComponent={ExpandMoreIcon}
              >
                {pageSizes.map((size) => (
                  <CustomMenuItem
                    key={size}
                    value={size}
                    style={customMenuItemStyle}
                  >
                    View {size}
                  </CustomMenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}{" "}
      </>
    </>
  );
};

export default React.memo(ExploreDatasetsTable);
