// ### Imports
// * Import lib

import React, { useState } from "react";
import {
  selectActiveTable,
  selectAllReduxData,
} from "../../../redux/selectors";

import MenuItem from "../../../kit/MenuItem";
import Select from "../../../kit/Select";
import Typography from "../../../kit/Typography";
import config from "../../../config/config";
import { makeStyles } from "@material-ui/core/styles";
import { useNonInitialEffect } from "../../../hooks/useNonInitialEffect";
import { useSelector } from "react-redux";
import { v4 } from "uuid";

// * Import Kit

// * Import utils

// # UTILITY FUNCTIONS

// # STYLES
const pStyle = {
  fontSize: "14px",
  padding: "0px 20px 0px 0px",
  color: "#4eafb3",
  cursor: "pointer",
  margin: "0px 0px 0px 10px",
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  leftMiddle: { display: "flex", alignItems: "center" },
  centerMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightMiddle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  select: { width: "120px% !important" },
}));

// # HELPER COMPONENTS
const CommonSelect = (props = {}) => {
  // # PROPS
  const {
    value,
    options,
    onChange,
    formKey,
    disabled = false,
    formSelections,
    rowId,
  } = props;

  // # HANDLERS
  const handleChange = (e) => {
    let result;
    switch (formKey) {
      case "columnName":
        onChange(formKey, e.target.value);
        break;
      case "operator":
        result = formSelections.conditions.map((elem) =>
          elem.id === rowId
            ? { ...elem, [formKey]: e.target.value }
            : { ...elem }
        );
        onChange("conditions", result);
        break;
      case "condColumnName":
        result = formSelections.conditions.map((elem) =>
          elem.id === rowId
            ? { ...elem, [formKey]: e.target.value }
            : { ...elem }
        );
        onChange("conditions", result);
        break;
      default:
        result = e.target.value;
    }
  };

  // # STATIC VARIABLES
  const classes = useStyles();

  // # JSX
  return (
    <Select
      className={classes.select}
      value={value}
      displayEmpty
      inputProps={{ "aria-label": "Secondary Color" }}
      color="secondary"
      onChange={handleChange}
      MenuProps={config.hardCoded.MenuProps}
      renderValue={(selected) => {
        if (
          ["", undefined, null].includes(selected) &&
          (formKey === "columnName" || formKey === "condColumnName")
        ) {
          return <p>Select column</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
        }
        if (
          ["", undefined, null].includes(selected) &&
          formKey === "operator"
        ) {
          return <p>Select operator</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
        }
        let label = options.find((row) => row.id === selected)?.name;
        return label;
      }}
      disabled={disabled}
      title={value}
    >
      {options.length ? (
        options.map((option) => (
          <MenuItem key={option.id} value={option.id} title={option.name}>
            {option.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="" disabled>
          No Options
        </MenuItem>
      )}
    </Select>
  );
};

// # COMPONENT
function CustomMathematicalOperationsWithColumn(props = {}) {
  // # PROPS
  const { onChange, selections, columnName } = props;

  // # STATES
  const [formSelections, setFormSelections] = useState(selections);
  const [error, setError] = useState("");

  useNonInitialEffect(() => {
    onChange(formSelections);
  }, [formSelections]);

  // # SELECTORS
  const allReduxData = useSelector(selectAllReduxData);
  const activeTable = useSelector(selectActiveTable);

  // # HANDLERS
  const addNewRow = () => {
    setFormSelections((prevState) => ({
      ...prevState,
      conditions: [
        ...prevState.conditions,
        {
          condColumnName: "",
          operator: "",
          id: v4(),
        },
      ],
    }));
    setError("");
  };

  const handleDelete = (id) => {
    if (formSelections.conditions.length > 1) {
      let newData = formSelections.conditions.filter((item) => item.id !== id);
      setFormSelections((prevState) => ({ ...prevState, conditions: newData }));
      setError("");
    } else {
      setError("Cannot delete last selection");
    }
  };

  const handleChange = (formKey, formValue) => {
    setFormSelections((prevState) => {
      switch (formKey) {
        case "conditions":
          return { ...prevState, [formKey]: formValue };
        default:
          return { ...prevState, [formKey]: formValue };
      }
    });
  };

  // # STATIC VARIABLES
  const classes = useStyles();
  const allColumnOptions =
    allReduxData.allColumns.result[0].children.find(
      (row) => row.name === activeTable
    )?.children || [];
  const operatorData = [
    {
      id: "addition",
      name: "Addition",
    },
    {
      id: "subtraction",
      name: "Subtraction",
    },
    {
      id: "multiplication",
      name: "Multiplication",
    },
    {
      id: "division",
      name: "Division",
    },
  ];

  // # DEBUGGER
  //   console.log("formSelections", formSelections);
  //   console.log("allColumnOptions", allColumnOptions);
  //   console.log("activeTable", activeTable);

  // # JSX
  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignItems: "flexStart" }}>
        <div style={{ marginTop: "5px" }}>
          <Typography>
            {"Check if all records in " + columnName + " is equal to "}
          </Typography>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formSelections.conditions.map((elem, index) => {
            let marginVariable = index > 0 ? "16%" : "0px";
            return (
              <div
                id={elem.id}
                style={{
                  display: "flex",
                  width: "800px",
                }}
              >
                {index === 0 && (
                  <CommonSelect
                    formKey={"columnName"}
                    value={formSelections["columnName"]}
                    options={allColumnOptions}
                    onChange={handleChange}
                    disabled={false}
                    formSelections={formSelections}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: marginVariable,
                  }}
                >
                  <CommonSelect
                    formKey={"operator"}
                    value={elem["operator"]}
                    options={operatorData}
                    onChange={handleChange}
                    disabled={false}
                    rowId={elem.id}
                    formSelections={formSelections}
                  />
                  <CommonSelect
                    formKey={"condColumnName"}
                    value={elem["condColumnName"]}
                    options={allColumnOptions}
                    onChange={handleChange}
                    disabled={false}
                    rowId={elem.id}
                    formSelections={formSelections}
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      padding: "0px 20px 0px 0px",
                      color: "#4eafb3",
                      cursor: "pointer",
                      margin: "0px 0px 0px 10px",
                    }}
                    onClick={() => handleDelete(elem.id)}
                  >
                    Delete
                  </p>
                  {index === formSelections.conditions.length - 1 && (
                    <p style={pStyle} onClick={() => addNewRow()}>
                      Add New Condition
                    </p>
                  )}
                  {error && <p className="error-msg">{error}</p>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

// # EXPORTS
export default CustomMathematicalOperationsWithColumn;
