import React from "react";

import "./Search.scss";

function Search({
  criteria,
  changeCriteria,
  width,
  placeholderText = "",
  className,
  user,
  updateSelections,
}) {
  return (
    <div className={`filter-new ${className}`}>
      <div className="input-search" style={{ width }}>
        <i className={`material-icons-outlined search-icon `}>search</i>
        <input
          type="text"
          id="search"
          name="search"
          value={user.selections["searchText"]}
          className="input-label"
          onChange={(e) => updateSelections("searchText", e.target.value)}
          placeholder={placeholderText || "Search"}
          autoComplete="off"
        />
        <i
          className={`material-icons-outlined cancel-icon ${
            user.selections["searchText"] ? "visible" : ""
          }`}
          onClick={() => updateSelections("searchText", "")}
        >
          clear
        </i>
      </div>
    </div>
  );
}

Search.defaultProps = {
  width: "52%",
};

//Using memo will cause React to skip rendering a component if its props have not changed. This can improve performance.
export default React.memo(Search);
