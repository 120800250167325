//Import required libraies

import React, { useEffect, useState } from "react";
import {
  selectActiveTable,
  selectAllReduxData,
} from "../../../redux/selectors";

import RulesComponent from "../RulesComponent";
import TextField from "../../../kit/TextField";
import { useSelector } from "react-redux";
import { v4 } from "uuid";

// * Import Kit

// * Import utils

const fieldCompareData = [
  {
    type: "checkbox",
    name: "isChecked",
  },
  {
    type: "typography",
    name: "abc",
    value: "Check if all the records in this column is mapped to only one ",
  },
  {
    type: "select",
    name: "column",
    placeholder: "Select column",
    data: [],
  },
];

function IsMappedRuleComponent(props) {
  let { onChange, columnName, name, selections } = props;
  // let tableOptions = [];

  const [localState, setLocalState] = useState(selections);
  const [error, setError] = useState("");

  // # SELECTORS
  const allReduxData = useSelector(selectAllReduxData);
  const activeTable = useSelector(selectActiveTable);

  // # STATIC VARIABLES
  const allColumnOptions =
    allReduxData.allColumns.result[0].children.find(
      (row) => row.name === activeTable
    )?.children || [];

  useEffect(() => {
    onChange(localState);
    return () => {};
    // eslint-disable-next-line
  }, [localState]);

  // Add empty test row
  const addNewRow = () => {
    setLocalState([
      ...localState,
      {
        isChecked: false,
        threshold: 0,
        name,
        columnName,
        value: {},
        uniqueKey: v4(),
      },
    ]);
    setError("");
  };

  const handleDelete = (id) => {
    // console.log("localState", localState);
    if (localState.length > 1) {
      let newData = localState.filter((item) => item.uniqueKey !== id);
      // console.log("newData", newData);
      setLocalState(newData);
      setError("");
    } else {
      setError("Cannot delete last selection");
    }
  };

  const handleChange = (a, b) => {
    setLocalState((prevLocalState) => {
      let nextLocalState = prevLocalState.map((row) =>
        row.uniqueKey === b.uniqueKey
          ? {
              ...row,
              value: { ...row.value, ...b.value },
              isChecked: b.isChecked,
              threshold: b.threshold,
            }
          : row
      );
      return nextLocalState;
    });
  };

  const pStyle = {
    fontSize: "14px",
    padding: "0px 20px 0px 0px",
    color: "#4eafb3",
    cursor: "pointer",
    margin: "0px 0px 0px 10px",
  };

  return (
    <div style={{ width: "100%" }}>
      {localState.map((selectionRow, index) => {
        return (
          <div
            key={index}
            style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gridColumn: "1 / span 11",
              }}
            >
              {fieldCompareData.map((item, index) => {
                return (
                  <div key={index}>
                    {item.type === "checkbox" && (
                      <RulesComponent
                        onChange={(id, value) =>
                          handleChange(id, {
                            ...value,
                            threshold: selectionRow?.threshold || 0,
                          })
                        }
                        dataElem={item}
                        display_type={item.type}
                        uniqueKey={selectionRow?.uniqueKey || v4()}
                        isChecked={selectionRow?.isChecked}
                        showName={true}
                      />
                    )}
                    {item.type !== "checkbox" && (
                      <RulesComponent
                        onChange={(id, value) =>
                          handleChange(id, {
                            ...value,
                            threshold: selectionRow?.threshold || 0,
                          })
                        }
                        value={selectionRow?.value?.[item.name] || ""}
                        dataElem={item}
                        display_type={item.type}
                        optionsData={allColumnOptions}
                        uniqueKey={selectionRow?.uniqueKey || v4()}
                        valueObj={selectionRow?.value}
                        showName={true}
                        placeholder="Select column"
                      />
                    )}
                  </div>
                );
              })}
              <p
                style={{
                  fontSize: "14px",
                  padding: "0px 20px 0px 0px",
                  color: "#4eafb3",
                  cursor: "pointer",
                  margin: "0px 0px 0px 10px",
                }}
                onClick={() => handleDelete(selectionRow.uniqueKey)}
              >
                Delete
              </p>
              {index === localState.length - 1 && (
                <p style={pStyle} onClick={() => addNewRow()}>
                  Add New Row
                </p>
              )}
              {error && <p className="error-msg">{error}</p>}
            </div>
            <div style={{ gridColumn: "12" }}>
              <TextField
                id="standard-required"
                type="number"
                value={selectionRow["threshold"]}
                onChange={(e) =>
                  handleChange(null, {
                    ...selectionRow,
                    threshold: e.target.value,
                  })
                }
                style={{ width: "75px" }}
              />
            </div>
            <hr
              style={{
                gridColumn: "1 / span 12",
                width: "100%",
                borderColor: "#ffffff40",
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default IsMappedRuleComponent;
