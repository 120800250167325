// Import required libraies

import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  resetUserInputs,
  updateAlertInfo,
  updateChangeFlagUiRules,
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import DQButton from "../../../components/Common/DQButton";
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import { ListItemButton } from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCatalogsColumns } from "../../../services/apis";
import { initialApiData } from "../../../utils/utils";
import { makeStyles } from "@material-ui/core/styles";

// # STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  rightAlign: {
    marginLeft: "auto",
  },
}));

// # COMPONENT
function SelectColumns(props) {
  const { user, data, updateData, match, updateAlertInfo, userInputs } = props;

  // # HOOKS
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [compareDatasetState] = useState(userInputs.compareDataset);

  const [sourceChecked, setSourceChecked] = React.useState(
    userInputs.compareDatasetColumns.selectedSource
  );

  const [destinationChecked, setDestinationChecked] = React.useState(
    userInputs.compareDatasetColumns.selectedDestination
  );

  const [sourceColumnsData, setSourceColumnsData] = useState(initialApiData);
  const [destinationColumnsData, setDestinationColumnsData] =
    useState(initialApiData);

  const [comparisonMap, setComparisonMap] = useState(
    userInputs.compareDatasetColumns.map || {}
  );

  useEffect(() => {
    if (!compareDatasetState.reportName) return;
    dispatch(
      updateUserInputs("compareDatasetColumns", {
        map: comparisonMap,
        fromTableSource: compareDatasetState.selectedSourceTable.name,
        fromTableDestination: compareDatasetState.selectedDestinationTable.name,
        selectedSource: sourceChecked,
        selectedDestination: destinationChecked,
      })
    );
  }, [sourceChecked]);

  useEffect(() => {
    if (!compareDatasetState.reportName) return;
    dispatch(
      updateUserInputs("compareDatasetColumns", {
        map: comparisonMap,
        fromTableSource: compareDatasetState.selectedSourceTable.name,
        fromTableDestination: compareDatasetState.selectedDestinationTable.name,
        selectedSource: sourceChecked,
        selectedDestination: destinationChecked,
      })
    );
  }, [destinationChecked]);

  const fetchColumnsData = async (cluster, catalog, schema_name, table_name, origin) => {
    let data = await getCatalogsColumns({ cluster, catalog, schema_name, table_name });
    if (data && data?.length > 0) {
      if (origin == "source") {
        setSourceColumnsData({
          status: "success",
          message: "",
          result: data,
        });
      } else {
        setDestinationColumnsData({
          status: "success",
          message: "",
          result: data,
        });
      }
    } else {
      if (origin == "source") {
        setSourceColumnsData({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      } else {
        setDestinationColumnsData({
          status: "error",
          message: "No result found! Please try again Later!",
          result: [],
        });
      }
    }
  };

  useEffect(() => {
    dispatch(
      updateAlertInfo({
        open: false,
        message: "",
        severity: "info",
      })
    );
    if (compareDatasetState.reportName) {
      fetchColumnsData(
        compareDatasetState.selectedCluster.cluster,
        compareDatasetState.selectedSourceCatalog,
        compareDatasetState.selectedSourceDatabase,
        compareDatasetState.selectedSourceTable.name,
        "source"
      );

      fetchColumnsData(
        compareDatasetState.selectedCluster.cluster,
        compareDatasetState.selectedDestinationCatalog,
        compareDatasetState.selectedDestinationDatabase,
        compareDatasetState.selectedDestinationTable.name,
        "destination"
      );
    } else {
      history.push("/home/comparedatasets");
    }
  }, []);

  const handleToggle = (value, origin) => () => {
    if (origin == "source") {
      const currentIndex = sourceChecked.indexOf(value);
      const newChecked = [...sourceChecked];

      if (currentIndex === -1) {
        newChecked.push(value);
        setComparisonMap({ ...comparisonMap, [value]: "" });
      } else {
        let newComparisonMap = { ...comparisonMap };

        var n = newComparisonMap[value];
        let currentIndex1 = destinationChecked.indexOf(n);
        if (currentIndex1 !== -1) {
          let newChecked1 = [...destinationChecked];
          newChecked1.splice(currentIndex1, 1);
          setDestinationChecked(newChecked1);
        }
        delete newComparisonMap[value];
        setComparisonMap(newComparisonMap);
        newChecked.splice(currentIndex, 1);
      }

      setSourceChecked(newChecked);
    } else {
      const currentIndex = destinationChecked.indexOf(value);
      const newChecked = [...destinationChecked];

      if (currentIndex === -1) {
        newChecked.push(value);
        try {
          let sourceKey = Object.entries(comparisonMap).find(([k, v]) => {
            return v === "";
          })[0];
          setComparisonMap({ ...comparisonMap, [sourceKey]: value });
          setDestinationChecked(newChecked);
        } catch (error) {
          dispatch(
            updateAlertInfo({
              open: true,
              message: `You have to select Source Column First`,
              severity: "error",
            })
          );
        }
      } else {
        let newComparisonMap = { ...comparisonMap };
        let itemKey = Object.entries(newComparisonMap).find(([k, v]) => {
          return v === value;
        })[0];
        newComparisonMap[itemKey] = "";
        setComparisonMap(newComparisonMap);

        newChecked.splice(currentIndex, 1);
        setDestinationChecked(newChecked);
      }
    }
  };
  const handleSubmit = () => {
    history.push(
      `/home/comparedatasets/onboarding/${compareDatasetState.reportName}/SelectCriteria`
    );
  };
  const isSubmitDisabled =
    sourceChecked.length === 0
      ? false
      : sourceChecked.length === destinationChecked.length;

  return (
    <LayoutTopSideBottom match={match}>
      <Breadcrumbs match={match} />
      <>
        <>
          <h1
            style={{
              textAlign: "center",
              fontWeight: 600,
              textTransform: "uppercase",
              color: "#46596a",
              marginBottom: 0,
            }}
          >
            Select Columns
          </h1>
          <p
            style={{
              textAlign: "center",
              fontWeight: 600,
              textTransform: "uppercase",
              color: "#46596a",
              margin: 0,
              fontSize: 12,
            }}
          >
            for comparison
          </p>

          {/*<pre>{JSON.stringify(comparisonMap, null, 2)}</pre> */}

          <Box sx={{ display: "flex" }}></Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              mt: 3,
            }}
          >
            <Box
              sx={{
                flex: 1,
                border: " 2px solid",
                borderColor: "#46596a",
                borderRadius: 4,
              }}
            >
              <Box sx={{ flex: 1, textAlign: "center" }}>
                <p
                  style={{
                    marginBottom: 0,
                    fontWeight: 600,
                    fontSize: 15,
                    textTransform: "uppercase",
                    color: "#46596a",
                  }}
                >
                  Source
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: 12,
                    fontWeight: 600,
                    color: "#4eafb3",
                    textTransform: "uppercase",
                  }}
                >
                  {compareDatasetState.selectedCluster
                    ? `${compareDatasetState.selectedCluster.cluster} -
    ${compareDatasetState.selectedSourceDatabase} -
      ${compareDatasetState.selectedSourceTable.name}`
                    : null}
                </p>
              </Box>

              <List
                style={{
                  width: "100%",
                  overflow: "auto",
                }}
              >
                {sourceColumnsData.status === "loading" ? (
                  <Loader />
                ) : (
                  sourceColumnsData.result
                    .map((c) => c.name)
                    .map((value) => {
                      const labelId = `checkbox-list-label-${value}`;

                      return (
                        <ListItem key={value} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={handleToggle(value, "source")}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={sourceChecked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              style={{ flex: 1 }}
                              id={labelId}
                              primary={`${value}`}
                            />
                            {comparisonMap[value] ? (
                              <ListItemText style={{ flex: 1 }}>
                                <p style={{ margin: 0 }}>
                                  <span
                                    style={{
                                      margin: 0,
                                      fontSize: 10,
                                      fontWeight: 600,
                                      color: "#46596a",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    Compare with:{" "}
                                  </span>
                                  <span>{comparisonMap[value]}</span>
                                </p>
                              </ListItemText>
                            ) : null}
                          </ListItemButton>
                        </ListItem>
                      );
                    })
                )}
              </List>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ flex: 1, textAlign: "center" }}>
                <p
                  style={{
                    marginBottom: 0,
                    fontWeight: 600,
                    fontSize: 15,
                    textTransform: "uppercase",
                    color: "#46596a",
                  }}
                >
                  Destination
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: 12,
                    color: "red",
                    fontWeight: 600,
                    color: "#4eafb3",
                    textTransform: "uppercase",
                  }}
                >
                  {compareDatasetState.selectedDestination
                    ? `${compareDatasetState.selectedDestination.cluster} -
        ${compareDatasetState.selectedDestinationDatabase} -
          ${compareDatasetState.selectedDestinationTable.name}`
                    : null}
                </p>
              </Box>
              <List
                style={{
                  flex: 1,
                  width: "100%",
                  overflow: "auto",
                }}
              >
                {destinationColumnsData.status === "loading" ? (
                  <Loader />
                ) : (
                  destinationColumnsData.result
                    .map((c) => c.name)
                    .map((value) => {
                      const labelId = `checkbox-list-label-${value}`;

                      return (
                        <ListItem key={value} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={handleToggle(value, "destination")}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={
                                  destinationChecked.indexOf(value) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value}`} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })
                )}
              </List>
            </Box>
          </Box>

          <p
            style={{
              fontWeight: 600,
              textTransform: "uppercase",
              color: "#46596a",
              margin: 0,
              fontSize: 13,
            }}
          >
            select first source and then destination to map properly{" "}
          </p>
        </>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            bottom: 40,
            right: 0,
            mr: 2,
          }}
        >
          <Link
            className="disabled-style"
            to={"/home/comparedatasets/onboarding/"}
          >
            <DQButton title="Back" disabled={false} variant="outlined" />
          </Link>
          <Box className="disabled-style">
            <DQButton
              title="Continue"
              disabled={!isSubmitDisabled}
              onclick={handleSubmit}
              variant="contained"
            />
          </Box>
        </Box>
      </>
    </LayoutTopSideBottom>
  );
}

SelectColumns.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  userInputs: state.userInputs,
});

const mapDispatchToProps = {
  updateData,
  updateUserInputs,
  updateSelections,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectColumns);
