/* eslint-disable indent */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell, Box } from "@material-ui/core";
import Filters from "../../../components/Filters/Filters";

import { useStyles } from "./style";
import "./GlobalRulesListTable.scss";

const GlobalRulesListTableHeader = (props) => {
  const { user, fetchedData } = props;
  const classes = useStyles();
  const allRulesDimension = [
    ...new Set(fetchedData?.map((item) => item.dimension)),
  ];
  let data = [];
  fetchedData?.forEach((item) => {
    data.push(...item.supported_datatypes);
  });
  const allSupportedDatatypes = [...new Set(data)];
  const allStatus = [...new Set(fetchedData?.map((item) => item.status))];
  return (
    <TableHead>
      <TableRow>
        <TableCell
          className={classes.tablecell}
          style={{ padding: "10px" }}
          align="left"
        >
          <Box>Rule Name</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Box>Rule Description</Box>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <div className="header-with-filter">
            <Box>Rule Dimension</Box>
            <Filters
              flag={
                user.selections.ruleDimensionForGlobalRuleManager.value?.length
                  ? true
                  : false
              }
              id="ruleDimensionForGlobalRuleManager"
              data={allRulesDimension}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <div className="header-with-filter">
            <Box>Supported Datatypes</Box>
            <Filters
              flag={
                user.selections.supportedDatatypesForGRM.value?.length
                  ? true
                  : false
              }
              id="supportedDatatypesForGRM"
              data={allSupportedDatatypes}
              filterType="checkbox"
            />
          </div>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <div className="header-with-filter">
            <Box>Status</Box>
            <Filters
              flag={
                user.selections.statusForGlobalRulesManager.value?.length
                  ? true
                  : false
              }
              id="statusForGlobalRulesManager"
              data={allStatus}
              filterType="checkbox"
            />
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

GlobalRulesListTableHeader.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(GlobalRulesListTableHeader);
