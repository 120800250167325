// * Import 3rd party
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core";

const StyledTypography = styled(
  Typography,
  {}
)(({ theme, customProps, fontSize }) => ({
  fontSize: fontSize ? fontSize : "14px",
}));

export default StyledTypography;
