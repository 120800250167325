// Import required libraries
import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  list: {
    width: 500,
  },
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    minWidth: 400,
    maxWidth: 600,
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

const alertTheme = createTheme({
  palette: {
    success: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
    info: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
    error: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
  },
  "& .MuiAlert-filledSuccess": {
    color: "#46596a",
    //font-weight: 1000,
    backgroundColor: "#f0f2f4",
    width: "800px",
  },
});

function Alert(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={alertTheme}>
      <MuiAlert
        className={classes.typographyStyle}
        elevation={6}
        variant="filled"
        {...props}
      />
    </ThemeProvider>
  );
}
export default Alert;
