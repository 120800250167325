const addUiFriendlySentenceForDiagnosticReport = () => {};

const OPERATOR_SYMBOL = {
  addition: "+",
  subtraction: "-",
  multiplication: "*",
  division: "/",
  equal: "=",
  greater_than: ">",
  less_than: "<",
  greater_than_equal: ">=",
  less_than_equal: "<=",
  equal_to: "=",
  not_equal: "!=",
};

const addCsvFriendlySentenceForDiagnosticReport = (allRules = []) => {
  return allRules.map((rules) => {
    let text = rules.description;
    // ! BACKWARD COMPATIBILITY
    if (Object.keys(rules.value).length > 0) {
      try {
        switch (rules.name) {
          case "validate_cas_number": {
            text = `Checks if CAS number is null, checks length, and verifies checksum`;
            break;
          }
          case "validate_ec_number": {
            text = `Checks if EC number is null, checks length, and verifies checksum`;
            break;
          }
          case "validate_ecn_alt_bom": {
            text = `Checks if ECN Alt boms exist in SAP, with completion status`;
            break;
          }
          case "isAlphabet": {
            text = `Check if the data format for all records in this column is an alphabet (only A-Z or a-z, no numbers or special characters)`;
            break;
          }
          case "isASCII": {
            text = `Check if the data format for all records in this column contains only ASCII characters`;
            break;
          }
          case "isBlank": {
            text = `Check if this column is not blank after removing leading and trailing spaces`;
            break;
          }
          case "isBoolean": {
            const formateValue = rules.value.format?.join(",");
            text = `Check if the data format for all records in this column is boolean and the format is one of these values: [${formateValue}]`;
            break;
          }
          case "isDecimal": {
            text = `Check if the data format for all records in this column is in decimal format`;
            break;
          }
          case "isDecimalWithCondition": {
            const conditionValue = rules.value.condition;
            const conditionStringValue = rules.value.conditionString;
            text = `Check if the data format for all records in this column is in decimal format and the value is ${conditionValue} ${conditionStringValue}`;
            break;
          }
          case "isNull": {
            text = `Check if this column has a value after removing leading and trailing spaces`;
            break;
          }
          case "isNumber": {
            text = `Check if the data format for all records in this column is a whole number`;
            break;
          }
          case "isSpace": {
            text = `Check if all records in this column doesn't contain empty space between words (only 1 space allowed between words)`;
            break;
          }
          case "isTimestamp": {
            text = `Check if the data format for all records in this column is a valid timestamp`;
            break;
          }
          case "isUpperCase": {
            text = `Check if the all records in this column are upper case`;
            break;
          }
          case "isURL": {
            text = `Check if the data format for all records in this column is a valid URL`;
            break;
          }
          case "leadingZero": {
            const zeroCountValue = rules.value.zerosCount;
            text = `Check if this column has ${zeroCountValue} leading zeros`;
            break;
          }
          case "textToNumberCheck": {
            text = `Check if the data format for all records in this column is a whole number (no decimals)`;
            break;
          }
          case "endsWith": {
            const conditionStringValue = rules.value.conditionString;
            text = `Check if the all records in this column ends with \`${conditionStringValue}\``;
            break;
          }
          case "customPatternCheck": {
            const conditionStringValue = rules.value.conditionString;
            text = `Check if the all records in this column matches the regex \`${conditionStringValue}\``;
            break;
          }
          case "isDate": {
            const formatValue = rules.value.format;
            text = `Check if the data format for all records in this column is a valid date and the format is \`${formatValue}\``;
            break;
          }
          case "isDuplicate": {
            const columnValue = rules.value.column?.join(", ");
            text = `Check if the record is not duplicated across the dataset for these combination of columns: [${columnValue}]`;
            break;
          }
          case "isEmail": {
            text = `Check if the data format for all records in this column is email`;
            break;
          }
          case "isInList": {
            const conditionStringValue =
              rules.value.conditionString?.join(", ");
            text = `Check if these values [${conditionStringValue}] are present in the current column`;
            break;
          }
          case "isInListWithCondition": {
            const listOfValues = rules.value.listOfValues?.join(", ");
            const columnNameValue = rules.value.columnName;
            const conditionValue = rules.value.condition;
            const conditionStringValue = rules.value.conditionString;
            text = `Check if these values [${listOfValues}] are present in the current column when ${columnNameValue} ${conditionValue} ${conditionStringValue}`;
            break;
          }
          case "isNotInListWithCondition": {
            const listOfValues = rules.value.listOfValues?.join(", ");
            const api_response = rules.value.api_response;
            text = `Check if these values [${listOfValues}] are present in the current column when ${api_response}`;
            break;
          }
          case "isNegativeNumber": {
            text = `Checks if the field value is a negative number (less than 0)`;
            break;
          }
          case "isNotInList": {
            const conditionStringValue =
              rules.value.conditionString?.join(", ");
            text = `Check if these values [${conditionStringValue}] are NOT present in the current column`;
            break;
          }
          case "isNullCond": {
            const columnValue = rules.value.column;
            const conditionValue = rules.value.condition;
            const conditionStringValue = rules.value.conditionString;
            text = `Check if all records in this column have values if ${columnValue} ${conditionValue} ${conditionStringValue}`;
            break;
          }
          case "isPositiveNumber": {
            text = `Checks if the field value is a positive number (greater than 0)`;
            break;
          }
          case "lengthCheck": {
            const maxlenValue = rules.value.maxLen;
            const minLenValue = rules.value.minLen;
            text = `Check if the length range for all records in this column is between ${minLenValue} and ${maxlenValue}`;
            break;
          }
          case "startsWith": {
            const conditionStringValue = rules.value.conditionString;
            text = `Check if the all records in this column starts with \`${conditionStringValue}\``;
            break;
          }
          case "fieldCompare_column": {
            const tableValue = rules.value.table;
            const columnValue = rules.value.column;
            const conditionValue = rules.value.condition;
            text = `Check if all the records in this column are ${conditionValue} records in ${tableValue}.${columnValue}`;
            break;
          }
          case "fieldCompare_timestamp": {
            const conditionValue = rules.value.condition;
            text = `Check if the all records in this column are ${conditionValue} current timestamp`;
            break;
          }
          case "fieldCompare_zero": {
            const conditionValue = rules.value.condition;
            const value1 = rules.value.value1;
            text = `Check if the all records in this column are ${conditionValue} specific number ${value1}`;
            break;
          }
          case "isExisting": {
            const formatedValue = rules.value.value1.replace(/%/g, " ");
            text = `Check if all records in this column exists in ${formatedValue}`;
            break;
          }
          case "isNotExisting": {
            const formatedValue = rules.value.value1.replace(/%/g, " ");
            text = `Check if all records in this column does not exist in ${formatedValue}`;
            break;
          }
          case "isExistingCond": {
            const tablesValue = rules.value.tables
              ?.map((table) => {
                if (table.operator !== "") {
                  const upperCareOperator = table.operator.toUpperCase();
                  return `${table.tableName}.${table.columnName} ${upperCareOperator} `;
                }
                return `${table.tableName}.${table.columnName}`;
              })
              .join("");
            const conditionsValue = rules.value.conditions
              ?.map((condition) => {
                if (condition.operator !== "") {
                  const upperCareOperator = condition.operator.toUpperCase();
                  return `${condition.tableName}.${condition.columnName} ${condition.condition} ${condition.conditionString} ${upperCareOperator} `;
                }
                return `${condition.tableName}.${condition.columnName} ${condition.condition} ${condition.conditionString}`;
              })
              .join("");

            text = `Check if all records in this column exists in ${tablesValue} with conditions that ${conditionsValue}`;
            break;
          }
          case "isExistingCondAdvanced": {
            const primaryTableName = rules.value.value1?.primaryTableName;
            const primaryColumnName = rules.value.value1?.primaryColumnName;
            const existFromTableName = rules.value.value1?.existFromTableName;
            const existFromColumnName = rules.value.value1?.existFromColumnName;
            const existToTableName = rules.value.value1?.existToTableName;
            const existToColumnName = rules.value.value1?.existToColumnName;
            const condTableName = rules.value.value1?.condTableName;
            const condColumnName = rules.value.value1?.condColumnName;
            const conditionValue = rules.value.value1?.condition;
            const conditionString = rules.value.value1?.conditionString;

            text = `Check if all records exist in ${primaryTableName}.${primaryColumnName} provided that ${existFromTableName}.${existFromColumnName} should exist in ${existToTableName}.${existToColumnName} and ${condTableName}.${condColumnName} ${conditionValue} ${conditionString}`;
            break;
          }
          case "fieldCompareWithCondition": {
            const conditionStringValue = rules.value.value1?.conditionString;
            const inputStringValue = rules.value.value1?.inputString;
            const conditionsValue = rules.value.value1?.conditions
              .map(
                (condition) =>
                  `${condition.columnOperator} ${condition.operationsColumnName} ${condition.operationsConditionString} ${condition.optionsInputString}`
              )
              .join(" ");

            text = `Check if the all records in this column are ${conditionStringValue} ${inputStringValue} if ${conditionsValue}`;
            break;
          }
          case "fieldCompareWithMathematicalOperations": {
            const parentColumnNameValue = rules.columnName;
            const columnNameValue = rules.value.value1?.columnName;
            const conditionsValue = rules.value.value1?.conditions
              .map(
                (condition) =>
                  `${OPERATOR_SYMBOL[condition.operator]} ${
                    condition.condColumnName
                  }`
              )
              .join(" ");

            text = `Check if all records in ${parentColumnNameValue} is equal to ${columnNameValue} ${conditionsValue}`;
            break;
          }
          case "mathematicalOperationsWithColumn": {
            const columnNameValue = rules.value.value1?.columnName;
            const conditionsValue = rules.value.value1?.conditions
              .map(
                (condition) =>
                  `${OPERATOR_SYMBOL[condition.operator]} ${
                    condition.operationsColumnName
                  }`
              )
              .join(" ");
            const conditionStringValue = rules.value.value1?.conditionString;
            const inputStringValue = rules.value.value1?.inputString;

            text = `Check if all records in ${columnNameValue} ${conditionsValue} ${OPERATOR_SYMBOL[conditionStringValue]} ${inputStringValue}`;
            break;
          }
          case "isMapped": {
            const columnNameValue = rules.value?.column;
            text = `Check if all the records in this column is mapped to only one column - ${columnNameValue}`;
            break;
          }
          case "checkstring_with_condition": {
            const columnName = rules.column_name;
            const columnNameValue = rules.value.value1?.columnName;
            const conditionsValue = rules.value.value1?.conditions
              .map(
                (condition) =>
                  `${condition.operator} ${condition.condColumnName} ${condition.conditionString} ${condition.optionsInputString}`
              )
              .join(" ");
            text = `Check if all the records in ${columnName} matches with ${columnNameValue} when ${conditionsValue}`;
            break;
          }
          case "checkstring_sap_with_condition": {
            const primaryTableName = rules.value.value1?.primaryTableName;
            const primaryColumnName = rules.value.value1?.primaryColumnName;
            const condTableName = rules.value.value1?.condTableName;
            const condColumnName = rules.value.value1?.condColumnName;
            const condition = rules.value.value1?.condition;
            const condTableName2 = rules.value.value1?.condTableName2;
            const condColumnName2 = rules.value.value1?.condColumnName2;
            const condition2 = rules.value.value1?.condition2;
            const conditionString = rules.value.value1?.conditionString;
            text = `Check if all the records exist in ${primaryTableName}.${primaryColumnName} where ${condTableName}.${condColumnName} ${condition} and ${condTableName2}.${condColumnName2} ${condition2} (${conditionString})`;
            break;
          }
          case "field_compare_with_condition_sap": {
            const columnName = rules.column_name;
            const primaryTableName = rules.value.value1?.primaryTableName;
            const primaryColumnName = rules.value.value1?.primaryColumnName;
            const existFromColumnName = rules.value.value1?.existFromColumnName;
            const existToTableName = rules.value.value1?.existToTableName;
            const existToColumnName = rules.value.value1?.existToColumnName;
            const condColumnName = rules.value.value1?.condColumnName;
            const condition = rules.value.value1?.condition;
            const condColumnName2 = rules.value.value1?.condColumnName2;
            const condition2 = rules.value.value1?.condition2;
            const conditionString = rules.value.value1?.conditionString;
            text = `Check if all the records in ${columnName} is same as ${primaryTableName}.${primaryColumnName} provided that ${existFromColumnName} is equal to ${existToTableName}.${existToColumnName} where ${condColumnName} ${condition} and ${condColumnName2} ${condition2} (${conditionString})`;
            break;
          }
          case "fieldCompare_date":
            const conditionStringValue = rules.value?.condition;
            text = `Check if the all records in this column are ${conditionStringValue} current date`;
            break;

          case "isExistingCond_trim":
            const mapping_table = rules.value?.mapping_table;
            const left_join_condition = rules.value?.left_join_condition;
            text = `Check if all records in this column exists in ${mapping_table} when ${left_join_condition}`;
            break;

          case "lengthCheckCondition":
            const lengths = rules.value?.lengths;
            const conditions = rules.value?.conditions;
            text = `Length should be between ${lengths.replaceAll('#',' ')} when ${conditions.replaceAll('#', ' ')}`;
            break;

          default:
            break;
        }
      } catch (error) {
        console.error(
          "ERROR IN diagnosticReportUtils.js > addCsvFriendlySentenceForDiagnosticReport"
        );
        console.log(error);
      }
    }

    return { ...rules, csvText: text };
  });
};

const makeDiagnosticReportJsonForCsvDownload = (backendData) => {
  const commonRows = {
    reportID: backendData.id,
    processDate: backendData.last_score_on,
    source: backendData.source,
    category: backendData.category,
    l0_domain: backendData.l0_domain,
    l1_domain: backendData.l1_domain,
    l2_domain: backendData.l2_domain,
    datasetName: backendData.dataset_name,
    targetlayer: backendData.layer_name,
    tableName: backendData.table_name,
  };

  let finalData = [];
  for (const row of backendData.report_details) {
    let newRow = {
      ...commonRows,
      column_name: row.column_name,
      ruleID: row.ruleID,
      totalRecords: row.totalRecords,
      dqCheckApplied: row.name,
      dqRuleDescription: row.csvText,
      dqAction: row.dqAction,
      dqPasses: row.dqPasses,
      dqFails: row.dqFails,
      dqPassedPercent: row.pass_percentage,
    };
    finalData.push(newRow);
  }
  return finalData;
};

export {
  addUiFriendlySentenceForDiagnosticReport,
  addCsvFriendlySentenceForDiagnosticReport,
  makeDiagnosticReportJsonForCsvDownload,
};
