// Import required libraies

import "./AccessManagementTab.scss";

import React, { useEffect, useRef, useState } from "react";
import {
  filterColumnsForSelectionsAdminPanel,
  initialApiData,
} from "../../../utils/utils";
import {
  getAdminPanelAccessManagmentUsersList,
  getReckittUsersList,
  postAdminPanelAccessManagmentUser,
  putAdminPanelAccessManagmentUser,
} from "../../../services/apis";
import {
  updateAlertInfo,
  updateData,
  updateSelections,
  updateUserInputs,
} from "../../../redux/actions";

import AdminUsersListTable from "./AdminUsersListTable/AdminUsersListTable";
import AlterUserDialog from "./AlterUserDialog";
import { Box } from "@material-ui/core";
import DQButton from "./../../../components/Common/DQButton";
import DQSingleAutocomplete from "../../../components/Common/DQSingleAutocomplete";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

// Import Custom Component

const AccessManagementTab = (props) => {
  const { user, data, updateData, match, updateAlertInfo } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const datasetsObj = data.adminPanelUserList.result;
  const [filteredData, setFilteredData] = useState(datasetsObj || []);

  const [adminReckittUserSelected, setAdminReckittUserSelected] = useState({});
  const [adminReckittUserSelectedOptions, setAdminReckittUserSelectedOptions] =
    useState(initialApiData);

  const [selectedUser, setSelectedUser] = React.useState({});
  const [dialogUserOpen, setDialogUserOpen] = React.useState(false);
  const [dialogUserEmail, setDialogUserEmail] = React.useState("");
  const [dialogUserTeamsSelected, setDialogUserTeamsSelected] = React.useState(
    []
  );

  const handleClickOpen = () => setDialogUserOpen(true);

  const fetchReckittUsers = async (query) => {
    let adminReckittUserSelectedOptions = await getReckittUsersList({
      key: query,
    });
    if (
      adminReckittUserSelectedOptions &&
      adminReckittUserSelectedOptions?.length > 0
    ) {
      setAdminReckittUserSelectedOptions({
        status: "success",
        message: "",
        result: adminReckittUserSelectedOptions,
      });
    } else {
      setAdminReckittUserSelectedOptions({
        status: "error",
        message: "No result found! Please try again Later!",
        result: [],
      });
    }
  };

  const fetchUsersList = async () => {
    updateData("adminPanelUserList", {
      status: "loading",
      result: [],
      message: "",
    });
    let usersList = await getAdminPanelAccessManagmentUsersList();
    if (usersList && usersList.length > 0) {
      updateData("adminPanelUserList", {
        status: "success",
        result: usersList,
        // status: "loading",
        // result: [],
        message: "",
      });
    } else {
      updateData("adminPanelUserList", {
        status: "error",
        result: [],
        message: "Could not load Users! Please try again later!",
      });
    }
  };

  const [inputValue, setInputValue] = React.useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (inputValue)
        if (inputValue.length >= 3) {
          //await new Promise((r) => setTimeout(r, 1000));
          await fetchReckittUsers(inputValue);
        }
      if (inputValue === "") {
        changeAdminReckittUserSelected({});
        await fetchReckittUsers(inputValue);
      }
    };

    fetchData();
  }, [inputValue]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchReckittUsers("");
      await fetchUsersList();
    };

    fetchData();
  }, []);

  const changeAdminReckittUserSelected = async (e) => {
    setAdminReckittUserSelected(e);
    setDialogUserEmail(e.email);
    setDialogUserTeamsSelected([]);
    dispatch(updateUserInputs("adminReckittUserSelected", e));
  };

  useEffect(() => {
    const updatedData = filterColumnsForSelectionsAdminPanel(
      data.adminPanelUserList.result,
      user.selections
    );
    setFilteredData(updatedData);

    // eslint-disable-next-line
  }, [user.selections, data.adminPanelUserList]);

  const refEnter = useRef(false);

  const handleSubmitDialog = async (tagsSelections) => {
    if (isEmpty(tagsSelections)) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: "Must select at least one team",
          severity: "info",
        })
      );
      return;
    }

    let responseData = null;
    if (dialogUserTeamsSelected.length > 0) {
      responseData = await putAdminPanelAccessManagmentUser({
        ...selectedUser,
        team: tagsSelections,
      });
    } else {
      let reqObj = {
        email: adminReckittUserSelected.email,
        name: adminReckittUserSelected.name,
        team: tagsSelections,
      };
      responseData = await postAdminPanelAccessManagmentUser(reqObj);
    }

    if ("email" in responseData) {
      if ("id" in responseData) {
        dispatch(
          updateAlertInfo({
            open: true,
            message: `User Updated: ${responseData.email}`,
            severity: "success",
          })
        );
      } else {
        dispatch(
          updateAlertInfo({
            open: true,
            message: `User Added: ${responseData.email}`,
            severity: "success",
          })
        );
      }
      changeAdminReckittUserSelected({});
      await fetchUsersList();
    } else if (!responseData.status) {
      dispatch(
        updateAlertInfo({
          open: true,
          message: responseData.message,
          severity: "info",
        })
      );
    } else {
      dispatch(
        updateAlertInfo({
          open: true,
          message: "Something went wrong...",
          severity: "error",
        })
      );
    }
  };

  const handleUpdateSelectedUser = async (userSelected) => {
    setSelectedUser(userSelected);
    setDialogUserEmail(userSelected.email);
    setDialogUserTeamsSelected(userSelected.team);
    handleClickOpen();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3,
        }}
      >
        <div className="amt-dv-user">
          <DQSingleAutocomplete
            value={adminReckittUserSelected}
            optionsData={adminReckittUserSelectedOptions.result}
            handleChange={async (value) => {
              await changeAdminReckittUserSelected(value);
              if (refEnter.current) {
                handleClickOpen();
                refEnter.current = false;
              }
            }}
            placeholderText="Add New Users"
            isDisabled={false}
            newKey="email"
            onInputChange={(newInputValue) => {
              setInputValue(newInputValue);
            }}
            onEnter={(e) => {
              refEnter.current = true;
            }}
            styles={{ width: "102%" }}
          />
        </div>
        <DQButton
          title="Add"
          disabled={false}
          variant="contained"
          styles={{ height: 44, minWidth: "0" }}
          onclick={() => {
            if (isEmpty(adminReckittUserSelected)) {
              dispatch(
                updateAlertInfo({
                  open: true,
                  message: "Select a User First",
                  severity: "info",
                })
              );
              return;
            }
            setDialogUserEmail(adminReckittUserSelected.email);
            setDialogUserTeamsSelected([]);
            handleClickOpen();
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3,
          flexDirection: "column",
        }}
      >
        <label className="amt-label">
          List of all user with application access:
        </label>

        <AdminUsersListTable
          data={filteredData}
          allData={data.adminPanelUserList.result}
          paginationFlag={true}
          loading={data.adminPanelUserList.status === "loading"}
          refreshList={fetchUsersList}
          updateSelectedUser={handleUpdateSelectedUser}
        />
      </Box>
      <AlterUserDialog
        onSubmit={handleSubmitDialog}
        teamsSelected={dialogUserTeamsSelected}
        open={dialogUserOpen}
        userEmail={dialogUserEmail}
        askForClose={() => setDialogUserOpen(false)}
      />
    </>
  );
};

AccessManagementTab.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateUserInputs,
  updateSelections,
  updateAlertInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessManagementTab);
