const conditionData = [
  {
    id: "greater_than",
    name: "Greater than",
  },
  {
    id: "less_than",
    name: "Less than",
  },
  {
    id: "greater_than_equal",
    name: "Greater than equal to",
  },
  {
    id: "less_than_equal",
    name: "Less than equal to",
  },
  {
    id: "equal_to",
    name: "Equal to",
  },
  {
    id: "not_equal",
    name: "Not equal to",
  },
  {
    id: "in",
    name: "In",
  },
  {
    id: "not_in",
    name: "Not in",
  },
];
const optionsData = [
  { id: "or", name: "or" },
  { id: "and", name: "and" },
];

export { conditionData, optionsData };
