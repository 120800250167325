// ### Imports
// * Import lib

import React, { useState } from "react";
import { conditionData, optionsData } from "../isExisting.js";

import Checkbox from "../../../kit/Checkbox";
import MenuItem from "../../../kit/MenuItem";
import Select from "../../../kit/Select";
import TextField from "../../../kit/TextField";
import Typography from "../../../kit/Typography";
import { useNonInitialEffect } from "../../../hooks/useNonInitialEffect";
import { v4 } from "uuid";

// * Import Kit

// * Import utils

// # UTILITY FUNCTIONS

// # STYLES

// # HELPER COMPONENTS
function NoSelectionAvailable() {
  return (
    <Select
      value="No Options Available"
      renderValue={(selected) => {
        return selected;
      }}
      disabled
    >
      <MenuItem disabled>No Options</MenuItem>
    </Select>
  );
}

function TableSelection({ ruleRow, tableData, localData, setLocalData }) {
  const [error, setError] = useState("");
  const data = [
    { id: "or", name: "or" },
    { id: "and", name: "and" },
  ];
  const addNewRow = () => {
    const newData = [
      ...ruleRow.tables,
      {
        id: v4(),
        tableName: "",
        columnName: "",
        operator: "",
      },
    ];
    const newLocalData = localData.map((elem) =>
      elem.id === ruleRow.id ? { ...elem, tables: newData } : { ...elem }
    );
    setLocalData(newLocalData);
    setError("");
  };
  const handleDelete = (id) => {
    if (ruleRow.tables.length > 1) {
      let newData = ruleRow.tables.filter((item) => item.id !== id);
      const newLocalData = localData.map((elem) =>
        elem.id === ruleRow.id ? { ...elem, tables: newData } : { ...elem }
      );
      setLocalData(newLocalData);
      setError("");
    } else {
      setError("Cannot delete last selection");
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {ruleRow?.tables?.map((tblElem, elemIndex) => {
        const columnData =
          tableData.find((elem) => elem.name === tblElem.tableName)?.children ||
          [];
        const handleChange = (key, value) => {
          const modifiedData = ruleRow.tables.map((elem) =>
            elem.id === tblElem.id
              ? {
                  ...elem,
                  [key]: value,
                  ...(key === "tableName" && { columnName: "" }),
                }
              : { ...elem }
          );
          const updatedLocalData = localData.map((val) =>
            val.id === ruleRow.id
              ? { ...val, tables: modifiedData }
              : { ...val }
          );
          setLocalData(updatedLocalData);
        };
        return (
          <div
            key={tblElem.id}
            style={{ display: "flex", alignItems: "center" }}
          >
            {elemIndex > 0 && (
              <Select
                value={tblElem.operator}
                onChange={(e) => {
                  handleChange("operator", e.target.value);
                }}
                inputProps={{ "aria-label": "Secondary Color" }}
                displayEmpty
                renderValue={(selected) => {
                  if (["", undefined, null].includes(selected)) {
                    return <p>Select operator</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
                  }
                  let label = data.find((row) => row.id === selected)?.name;
                  return label;
                }}
                style={{
                  width: "120px",
                  marginRight: "10px",
                  marginLeft: "-130px",
                }}
                title={tblElem.operator}
              >
                {optionsData.length ? (
                  optionsData.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id} title={obj.name}>
                      {obj.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No Options
                  </MenuItem>
                )}
              </Select>
            )}
            <Select
              value={tblElem.tableName}
              displayEmpty
              inputProps={{ "aria-label": "Secondary Color" }}
              color="secondary"
              onChange={(e) => {
                handleChange("tableName", e.target.value);
              }}
              renderValue={(selected) => {
                if (["", undefined, null].includes(selected)) {
                  return <p>Select table</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
                }
                let label = tableData.find((row) => row.id === selected)?.name;
                return label;
              }}
              title={tblElem.tableName}
            >
              {tableData.length ? (
                tableData.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    title={option.name}
                  >
                    {option.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No Options
                </MenuItem>
              )}
            </Select>
            {columnData.length ? (
              <Select
                value={tblElem.columnName}
                displayEmpty
                inputProps={{ "aria-label": "Secondary Color" }}
                color="secondary"
                onChange={(e) => {
                  handleChange("columnName", e.target.value);
                }}
                renderValue={(selected) => {
                  if (["", undefined, null].includes(selected)) {
                    return <p>Select column</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
                  }
                  if (columnData.length === 0) {
                    return <p>No options available</p>;
                  }
                  let label = columnData.find(
                    (row) => row.id === selected
                  )?.name;
                  return label;
                }}
                title={tblElem.columnName}
              >
                {columnData.length ? (
                  columnData.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      title={option.name}
                    >
                      {option.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No Options
                  </MenuItem>
                )}
              </Select>
            ) : (
              <NoSelectionAvailable />
            )}
            <p
              style={{
                fontSize: "14px",
                padding: "0px 20px 0px 0px",
                color: "#4eafb3",
                cursor: "pointer",
                margin: "0px 0px 0px 10px",
              }}
              onClick={() => handleDelete(tblElem.id)}
            >
              Delete
            </p>
            {elemIndex === ruleRow.tables.length - 1 && (
              <p
                style={{
                  fontSize: "14px",
                  padding: "0px 20px 0px 0px",
                  color: "#4eafb3",
                  cursor: "pointer",
                  margin: "0px 0px 0px 10px",
                }}
                onClick={() => addNewRow()}
              >
                Add New Condition
              </p>
            )}
            {error && <p className="error-msg">{error}</p>}
          </div>
        );
      })}
    </div>
  );
}

function ConditionSelection({
  ruleRow,
  localData,
  setLocalData,
  tableData,
  currentTable,
}) {
  const [error, setError] = useState("");
  //Filtering recoreds to remove empty table names
  let localTableData = ruleRow.tables
    .filter((elem) => elem.tableName !== "")
    .map((elem) => ({
      id: elem.tableName,
      name: elem.tableName,
    }));
  localTableData = [
    ...localTableData,
    { id: currentTable, name: currentTable },
  ];
  const addNewRow = () => {
    const newData = [
      ...ruleRow.conditions,
      {
        id: v4(),
        tableName: "",
        columnName: "",
        operator: "",
        condition: "",
        conditionString: "",
      },
    ];
    const newLocalData = localData.map((elem) =>
      elem.id === ruleRow.id ? { ...elem, conditions: newData } : { ...elem }
    );
    setLocalData(newLocalData);
    setError("");
  };
  const handleDelete = (id) => {
    if (ruleRow.conditions.length > 1) {
      let newData = ruleRow.conditions.filter((item) => item.id !== id);
      const newLocalData = localData.map((elem) =>
        elem.id === ruleRow.id ? { ...elem, conditions: newData } : { ...elem }
      );
      setLocalData(newLocalData);
      setError("");
    } else {
      setError("Cannot delete last selection");
    }
  };
  return (
    <div style={{ marginLeft: "75px", display: "flex" }}>
      <p style={{ fontSize: "14px", width: "100px" }}>With Conditions</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "5px 0",
        }}
      >
        {ruleRow?.conditions?.map((tblElem, elemIndex) => {
          const columnData =
            tableData.find((elem) => elem.name === tblElem.tableName)
              ?.children || [];
          const handleChange = (key, value) => {
            const modifiedData = ruleRow.conditions.map((elem) =>
              elem.id === tblElem.id
                ? {
                    ...elem,
                    [key]: value,
                    ...(key === "tableName" && { columnName: "" }),
                  }
                : { ...elem }
            );
            const updatedLocalData = localData.map((val) =>
              val.id === ruleRow.id
                ? { ...val, conditions: modifiedData }
                : { ...val }
            );
            setLocalData(updatedLocalData);
          };
          return (
            <div
              key={tblElem.id}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {elemIndex > 0 && (
                <Select
                  value={tblElem.operator}
                  onChange={(e) => {
                    handleChange("operator", e.target.value);
                  }}
                  inputProps={{ "aria-label": "Secondary Color" }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (["", undefined, null].includes(selected)) {
                      return <p>Select operator</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
                    }
                    let label = optionsData.find(
                      (row) => row.id === selected
                    )?.name;
                    return label;
                  }}
                  style={{
                    width: "120px", // correct
                    marginRight: "10px",
                    marginLeft: "-130px",
                  }}
                  title={tblElem.operator}
                >
                  {optionsData.map((obj) => (
                    <MenuItem key={obj.id} value={obj.name} title={obj.name}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <Select
                value={tblElem.tableName}
                displayEmpty
                inputProps={{ "aria-label": "Secondary Color" }}
                color="secondary"
                onChange={(e) => {
                  handleChange("tableName", e.target.value);
                }}
                renderValue={(selected) => {
                  if (["", undefined, null].includes(selected)) {
                    return <p>Select table</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
                  }
                  let label = localTableData.find(
                    (row) => row.id === selected
                  )?.name;
                  return label;
                }}
                title={tblElem.tableName}
              >
                {localTableData.length ? (
                  localTableData.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      title={option.name}
                    >
                      {option.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No Options
                  </MenuItem>
                )}
              </Select>
              {columnData.length ? (
                <Select
                  value={tblElem.columnName}
                  displayEmpty
                  inputProps={{ "aria-label": "Secondary Color" }}
                  color="secondary"
                  onChange={(e) => {
                    handleChange("columnName", e.target.value);
                  }}
                  renderValue={(selected) => {
                    if (["", undefined, null].includes(selected)) {
                      return <p>Select column</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
                    }
                    if (columnData.length === 0) {
                      return <p>No options available</p>;
                    }
                    let label = columnData.find(
                      (row) => row.id === selected
                    )?.name;
                    return label;
                  }}
                  title={tblElem.columnName}
                >
                  {columnData.length ? (
                    columnData.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        title={option.name}
                      >
                        {option.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled>
                      No Options
                    </MenuItem>
                  )}
                </Select>
              ) : (
                <NoSelectionAvailable />
              )}
              <Select
                value={tblElem.condition}
                onChange={(e) => {
                  handleChange("condition", e.target.value);
                }}
                inputProps={{ "aria-label": "Secondary Color" }}
                displayEmpty
                renderValue={(selected) => {
                  if (["", undefined, null].includes(selected)) {
                    return <p>Select condition</p>; // ! HARD CODED FOR NOW (DRIVE IT FROM RULES OBJECT LATER)
                  }
                  let label = conditionData.find(
                    (row) => row.id === selected
                  )?.name;
                  return label;
                }}
                style={{
                  // width: "60px",
                  marginRight: "10px",
                  // marginLeft: "70px",
                }}
                title={tblElem.condition}
              >
                {conditionData.length ? (
                  conditionData.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id} title={obj.name}>
                      {obj.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No Options
                  </MenuItem>
                )}
              </Select>
              <TextField
                id="standard-required"
                type="any"
                value={tblElem.conditionString}
                onChange={(e) =>
                  handleChange("conditionString", e.target.value)
                }
                placeholder="Enter value"
              />
              <p
                style={{
                  fontSize: "14px",
                  padding: "0px 20px 0px 0px",
                  color: "#4eafb3",
                  cursor: "pointer",
                  margin: "0px 0px 0px 10px",
                }}
                onClick={() => handleDelete(tblElem.id)}
              >
                Delete
              </p>
              {elemIndex === ruleRow.conditions.length - 1 && (
                <p
                  style={{
                    fontSize: "14px",
                    padding: "0px 20px 0px 0px",
                    color: "#4eafb3",
                    cursor: "pointer",
                    margin: "0px 0px 0px 10px",
                    width: "140px",
                  }}
                  onClick={() => addNewRow()}
                >
                  Add New Condition
                </p>
              )}
              {error && <p className="error-msg">{error}</p>}
            </div>
          );
        })}
      </div>
    </div>
  );
}

// # COMPONENT
function IsExistingRule({ id, onChange, selections, stateData, currentTable }) {
  // # PROPS
  const [localData, setLocalData] = useState(selections);

  useNonInitialEffect(() => {
    onChange(localData);
  }, [localData]);

  // # SELECTORS

  // # HANDLERS
  const addNewRow = () => {
    const newData = [
      ...localData,
      {
        id: v4(),
        isEnabled: true,
        threshold: 0,
        tables: [
          {
            id: v4(),
            tableName: "",
            columnName: "",
            operator: "",
          },
        ],
        conditions: [
          {
            id: v4(),
            tableName: "",
            columnName: "",
            condition: "",
            conditionString: "",
            operator: "",
          },
        ],
      },
    ];
    setLocalData(newData);
  };

  const handleChange = (id, value, recordId) => {
    const newData = localData.map((elem) =>
      elem.id === recordId ? { ...elem, [id]: value } : { ...elem }
    );
    setLocalData(newData);
  };

  // # STATIC VARIABLES
  const tableData = stateData.allColumns.result[0].children.map((elem) => ({
    ...elem,
    id: elem.name,
    name: elem.name,
  }));

  // # JSX
  return (
    <div style={{ width: "100%" }}>
      {localData.map((record, index) => (
        <div
          key={index}
          style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)" }}
        >
          <div style={{ gridColumn: "1 / span 11" }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={record.isEnabled}
                  value="isEnabled"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  disabled={false}
                  variant="secondary"
                  size="small"
                  onClick={(e) =>
                    handleChange(e.target.value, !record.isEnabled, record.id)
                  }
                  style={{ margin: "0px 0 0 -10px" }}
                />

                <Typography style={{ margin: "0 0 0 10px" }}>
                  Check if all records in this column exists in
                </Typography>
              </div>
              <TableSelection
                ruleRow={record}
                tableData={tableData}
                localData={localData}
                setLocalData={setLocalData}
              />
            </div>
            <ConditionSelection
              ruleRow={record}
              tableData={tableData}
              localData={localData}
              setLocalData={setLocalData}
              currentTable={currentTable}
            />
            {index === localData.length - 1 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    padding: "0px 20px 0px 0px",
                    color: "#4eafb3",
                    cursor: "pointer",
                    margin: "0px 0px 0px 10px",
                  }}
                  onClick={() => addNewRow()}
                >
                  Add New Row
                </span>
              </div>
            )}
          </div>
          <div style={{ gridColumn: "12" }}>
            <TextField
              id="standard-required"
              type="number"
              value={record["threshold"]}
              onChange={(e) =>
                handleChange("threshold", e.target.value, record.id)
              }
              style={{ width: "75px" }}
            />
          </div>
          <hr
            style={{
              gridColumn: "1 / span 12",
              width: "100%",
              borderColor: "#ffffff40",
            }}
          />
        </div>
      ))}
    </div>
  );
}

// # EXPORTS
export default IsExistingRule;
