//Import required libraies
import React from "react";

function CustomInputNumber(props) {
  let {
    onChange,
    id,
    columnName,
    columnDatatype,
    dataElem,
    name,
    uniqueKey,
    valueObj,
  } = props;

  const inputVal =
    valueObj?.[dataElem.name] === undefined ||
    valueObj?.[dataElem.name] === "" ||
    isNaN(valueObj?.[dataElem.name])
      ? ""
      : valueObj?.[dataElem.name];

  return (
    <input
      type="number"
      className="custom-inputbox"
      value={inputVal}
      placeholder="Please enter a number"
      onChange={(e) => {
        onChange(id, {
          isChecked: true,
          uniqueKey,
          groupdId: -1,
          id,
          name,
          columnName,
          columnDatatype: columnDatatype,
          value: {
            ...valueObj,
            [dataElem.name]: parseFloat(e.target.value),
          },
        });
      }}
    />
  );
}

export default CustomInputNumber;
